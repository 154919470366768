import { useState, useEffect } from 'react'
import { isset, getType, is_object, localMessage, objectKeyDifference, objectsAreEqual, objectCopy } from '../functions/library';


function toggle(sourceObject, primaryObjectIn) {
    const keyDiff = objectKeyDifference(sourceObject, primaryObjectIn);
    let newToggleObject = {};
    //  console.log(sourceObject);
    if (keyDiff.additional.length) {
        keyDiff.additional.forEach((key) => {
            if (sourceObject.hasOwnProperty(key)) {
                newToggleObject[key] = sourceObject[key];
                return;
            }
            localMessage(`Object Mismatch from source. An additional key as been added and does not exist in the source object. Please re-set the source object. Mismatched key: ${key}.`, "log", "error");
        });
        return primaryObjectIn;
    }
    keyDiff.missing.forEach((key) => {
        newToggleObject[key] = sourceObject[key];
    })
    return newToggleObject;
}

function useToggleObjectState(sourceObjectIn) {
    const [sourceObject, setSourceObject] = useState(sourceObjectIn);
    const [lastObjectIn, setLastObjectIn] = useState({});
    const [toggleObject, setToggleObject] = useState({});
    let pendingSourceObject = {}; // Used to recall a new sourceObject in current render before it's reset

    useEffect(() => {
        let mounted = true;
        if (!is_object(sourceObject)) {
            if (mounted) localMessage(`'sourceObject' type must be object. ${getType(sourceObject)} was supplied.`, "log", "error");
        }
        if (mounted && isset(sourceObject)) {
            const newToggleObject = toggle(sourceObject, lastObjectIn);
            if (!objectsAreEqual(toggleObject, newToggleObject)) {
                if (mounted) setToggleObject(newToggleObject);
            }
            return () => {
                mounted = false;
            }
        }
        // eslint-disable-next-line
    }, [sourceObject]);


    const setToggleObjectState = (newObjectIn) => {
        if (getType(newObjectIn, "function")) {
            const newSourceObject = newObjectIn();
            if (!objectsAreEqual(sourceObject, newSourceObject)) {
                pendingSourceObject = objectCopy(newSourceObject);
                setSourceObject(newSourceObject);
            }
            return true;
        }
        if (!is_object(newObjectIn)) {
            localMessage(`'setToggleObjectState' supplied type must be an object. ${getType(newObjectIn)} was supplied.`, "log", "error");
            return false;
        }
        if (isset(sourceObject) || isset(pendingSourceObject)) {
            const useSource = isset(sourceObject) ? sourceObject : pendingSourceObject;
            const newToggleObject = toggle(useSource, newObjectIn);
            if (!objectsAreEqual(toggleObject, newToggleObject)) {
                setLastObjectIn(newObjectIn);
                setToggleObject(newToggleObject);
            }
            return true;
        }
        return false;
    }


    return [toggleObject, setToggleObjectState];
}

export default useToggleObjectState
