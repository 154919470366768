import { useState } from "react"
import { getType, isset, is_number } from "../functions/library";

/**
 * A centeralized timeout that will not duplicate timeouts if supplied the ID in the second argument of the set function.
 * @param {*} defaultDuration 
 * @returns {[setTimeout:function, timeoutID:number, setDuration:function ]} An array containing the set function, the timeout ID, and a set duration function.
 */
const useTimeout = (defaultDuration = 30000) => {
    const [duration, setDuration] = useState(defaultDuration);
    const [timeoutID, setTimeoutID] = useState(null);

    /**
     * 
     * @param {function} callBack A callback function to execute after a set duration
     * @param {*} id The id of the time out - supply null to create multiple timeouts
     * @param {*} durationIn The duration for this specific timeout
     */
    const set = (callBack, id = null, durationIn) => {
        const thisID = isset(id) ? id : timeoutID;
        const thisDuration = is_number(durationIn) ? durationIn : duration;
        if (!thisID || (thisID !== timeoutID && timeoutID !== null)) {
            if (is_number(thisDuration) && thisDuration > 0) {
                setTimeoutID(
                    setTimeout(() => {
                        if (getType(callBack, 'function')) {
                            callBack();
                            setTimeoutID(null);
                            return;
                        }
                    }, thisDuration)
                );

            }
        }

    }
    return [set, timeoutID, setDuration];
}

export default useTimeout