import React from 'react'
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap'
import './scss/siterefurb.scss';

import BannerImage from '../../images/siterefurb-banner.jpg'
import Hammer from '../../images/siterefurb-section-1.jpg';
import Photoshop from '../../images/adobe-photoshop-logo.png';
import Xd from '../../images/adobe-xd-logo.png';
import MySQL from '../../images/mysql-logo.png';
import PHP from '../../images/php-logo.png';
import Laravel from '../../images/laravel-logo.png';
import Node from '../../images/node-logo.png';
import Firebase from '../../images/firebase-logo.png';

function SiteRefurb() {
    return (
        <div className="siterefurb-page bg-light">
            <div className="header">
                <Image className="header-image" src={BannerImage} alt="Card image" fluid />
                <div className="header-overlay">
                    <h1 className="header-title">Site Refurbishment</h1>
                    <h2 className="header-subtitle">
                        Have an existing site that needs a touch up or even a complete overhaul?
                    </h2>
                    <h2 className="header-subtitle">
                        <div className='text-secondary text-shadow italic'>We got you covered.</div>

                    </h2>
                </div>
            </div>

            <Container className='mt-5 mb-5 pb-5'>
                <Row className='d-flex justify-content-evenly' >
                    <Col xs={12} md={5} className='no-mobile'>
                        <Image src={Hammer} alt="thor's hammer" fluid />
                    </Col>
                    <Col xs={12} md={7}>
                        <div className='d-flex justify-content-start'>
                            <h3 className='card-title text-secondary text-shadow pb-2'>Web Solution Super Powers</h3>
                        </div>
                        <div className='d-flex justify-content-evenly'>
                            <div className='border border-3 border-primary m-1'></div>
                            <div>
                                <h4>Re-Energize Your Site:</h4>
                                <ul className='mt-3'>
                                    <li><h5>Redesign</h5></li>
                                    <li><h5>Redevelop</h5></li>
                                    <li><h5>Redeploy</h5></li>
                                </ul>
                            </div>
                            <div>
                                <h4>Maximize Your Results:</h4>
                                <ul className='mt-3'>
                                    <li><h5>Enhanced Performance</h5></li>
                                    <li><h5>Increased Value</h5></li>
                                    <li><h5>Streamlined Accessibility</h5></li>
                                </ul>
                            </div>
                        </div>
                        <div className='mb-2'>
                            <p className='mt-1 mt-lg-5'>
                                Everything from small alterations to complete renovation - we got you covered.  TrinityRecode will help you overcome any existing challenges, delivering a legendary customer service experience throughout the process.
                            </p>
                            <Button className='no-mobile' href="/Contact?select=Web_Development">Risk Free Evaluation</Button>
                            <Button className='mobile-only mx-5' href="/Contact?select=Web_Development">Risk Free Evaluation</Button>
                        </div>
                    </Col>
                    <Col xs={12} md={5} className='mobile-only mt-3'>
                        <Image src={Hammer} alt="thor's hammer" fluid />
                    </Col>
                </Row>
            </Container>





        </div >
    )
}

export default SiteRefurb
