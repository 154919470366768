import React, { useEffect, useState } from 'react'
import { Img } from '../../../components/Img';
import SiteImage from '../../../classes/SiteImage';
import { isset } from '../../../functions/library';
import emptyUserImage from '../../../images/user.png';

import './scss/profileImage.scss';

const ProfileImage = ({ className, image, size }) => {
    const baseWidth = 20;
    const [profileImage, setProfileImage] = useState(image);
    const [width, setWidth] = useState(baseWidth);
    const sizes = ["xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl"];

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (image === "") setProfileImage(emptyUserImage);
            if (image.length && image !== profileImage) setProfileImage(image);
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [image]);

    useEffect(() => {
        let mounted = true;
        if (mounted && isset(size)) {
            if (sizes.includes(size)) {
                setWidth(() => ((sizes.indexOf(size) + 1) * baseWidth));
            }
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [size]);


    return (

        <Img className={`image ${className}`} widthIn={`${width}px`} heightIn={`${width}px`} SiteImageIn={new SiteImage("profilePhoto", { fullImageString: profileImage, description: "User Profile" })} crop={true} protectImage={true} />

    )
}

export default ProfileImage
