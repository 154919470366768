import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from "react-router-dom";

import './App.scss';
import ProvideAuth from './sections/router/ProvideAuth.js';
import PrivateRoute from "./sections/router/PrivateRoute";
import Navigation from './sections/header/Navigation';
import About from './sections/pages/About';
import Dashboard from './sections/pages/account/Dashboard';
import Login from './sections/pages/Login';
import Setup from "./sections/pages/account/Setup";
import Register from "./sections/pages/Register";
import ProvideAPI from "./hooks/useAPI";
import Profile from "./sections/pages/account/Profile";
import Home from "./sections/pages/Home";
import Footer from "./sections/footer/Footer";
import Services from "./sections/pages/Services";
import Contact from "./sections/pages/Contact";
import WebDev from "./sections/pages/WebDev";
import Solutions from "./sections/pages/Solutions";
import TechSupport from "./sections/pages/TechSupport";
import SiteRefurb from "./sections/pages/SiteRefurb";
import Consulting from "./sections/pages/Consulting";
import Logo from "./sections/pages/branding/Logo";



function App() {


  return (

    <ProvideAuth>
      <ProvideAPI>
        <Router>
          <header>
            <Navigation />
          </header>
          <div className="page-content">
            <Switch>
              <Route exact path="/">
                <Redirect to="/Home" />
              </Route>
              <Route exact path="/Home">
                <Home />
              </Route>
              <Route exact path="/Services">
                <Services />
              </Route>
              <Route exact path="/Services/WebDev">
                <WebDev />
              </Route>
              <Route exact path="/Services/TechSupport">
                <TechSupport />
              </Route>
              <Route exact path="/Services/SiteRefurb">
                <SiteRefurb />
              </Route>
              <Route exact path="/Services/Solutions">
                <Solutions />
              </Route>
              <Route exact path="/Services/Consulting">
                <Consulting />
              </Route>
              <Route exact path="/Contact">
                <Contact />
              </Route>
              <Route exact path="/About">
                <About />
              </Route>
              <Route exact path="/Login">
                <Login />
              </Route>
              <Route exact path="/Register">
                <Register />
              </Route>
              <Route exact path="/Branding/Logo">
                <Logo />
              </Route>
              <PrivateRoute exact path="/Dashboard">
                <Dashboard />
              </PrivateRoute>
              <PrivateRoute exact path="/Setup">
                <Setup />
              </PrivateRoute>
              <PrivateRoute exact path="/Setup/Profile">
                <Profile />
              </PrivateRoute>
            </Switch>
          </div>
          <Footer />
        </Router>
      </ProvideAPI>
    </ProvideAuth>

  );
}

export default App;
