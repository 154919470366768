import React from 'react';
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap';
//import BannerImage from "../../images/about-banner.jpg";
import SynergyImage from "../../images/about-world.jpg";
import CommunityImage from "../../images/about-community.jpg";
import ExperienceImage1 from "../../images/about-tri-top-1.jpg";
import ExperienceImage2 from "../../images/about-tri-top-2.jpg";
import ExperienceImage3 from "../../images/about-tri-bottom.jpg";


import './scss/about.scss'

function About() {

    //TODO: Create Services individual pages for web dev, consulting, etc for links in footer, and possibly links from services page. 

    return (

        <div className="about-page bg-dark pb-5">
            <div className="header bg-dark text-white">
                <h1 className="header-title text-white">True Partnership</h1>
                <h2 className="header-subtitle text-white">
                    At TrinityRecode, we feel the integrity of a company is most important. We pursue mutually beneficial partnerships, and scaled effective solutions. We believe your success is our success.
                </h2>

            </div>

            <Container className="service-cards mt-5 p-3 bg-light rounded-3">
                <Row className='mt-5 mb-5 pb-5 p-lg-2'>
                    <Col md={6} className="no-mobile">
                        <Row className='pb-2'>
                            <Col className='px-2'>
                                <Image src={ExperienceImage1} alt="woman holding world watching sunset" fluid />
                            </Col>
                            <Col className='px-2'>
                                <Image src={ExperienceImage2} alt="woman holding world watching sunset" fluid />
                            </Col>
                        </Row>
                        <Row className='pt-2'>
                            <Col className='px-2'>
                                <Image src={ExperienceImage3} alt="woman holding world watching sunset" fluid />
                            </Col>
                        </Row>

                    </Col>

                    <Col md={6}>
                        <div className='d-flex align-items-baseline'>
                            <i className="fab fa-buffer fa-3x text-primary pe-3 ms-0" />
                            <h3 className='text-black'>Diversely Experienced</h3>
                        </div>
                        <h4 className='text-secondary'>TrinityRecode has over 20 years of experience in the technology sector.</h4>
                        <p className='text-dark pt-1 pe-4  mt-1'>From the dot.com boom to modern cloud computing - our expertise spans multiple technology diciplines. We've consulted on and deployed solutions in areas such as:</p>
                        <div className='d-flex justify-content-start'>
                            <ul className='me-2'>
                                <li><a href="/Services/Consulting?tab=event_tech">Audio &amp; Visual Technologies</a></li>
                                <li><a href="/Services/Consulting?tab=computer_hardware">Computer Hardware</a></li>
                                <li><a href="/Services/Consulting?tab=event_tech">Event Technology</a></li>
                                <li>Networking</li>
                                <li>Server Environment</li>
                            </ul>
                            <ul className=''>
                                <li>Software Development</li>
                                <li>Troubleshooting &amp; Repair</li>
                                <li>Virtual Platforms</li>
                                <li><a href="/Services/WebDev">Web Development</a></li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={6} className="mobile-only">
                        <Row className='pb-2'>
                            <Col className='px-2'>
                                <Image src={ExperienceImage1} alt="woman holding world watching sunset" fluid />
                            </Col>
                            <Col className='px-2'>
                                <Image src={ExperienceImage2} alt="woman holding world watching sunset" fluid />
                            </Col>
                        </Row>
                        <Row className='pt-2'>
                            <Col className='px-2'>
                                <Image src={ExperienceImage3} alt="woman holding world watching sunset" fluid />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="p-lg-2 mb-5 pb-5 ">
                    <Col md={6}>
                        <div className='d-flex align-items-baseline'>
                            <i className="fas fa-bolt fa-3x text-primary pe-3 ms-0" />
                            <h3 className='text-black'>Technological Synergy</h3>
                        </div>
                        <h4 className='text-secondary'>When you partner on a solution with TrinityRecode, you'll feel like you have the whole world in your hands. </h4>
                        <p className='text-dark pt-2 pe-4'>We'll ensure Your goals, budget, and comfort level perfectly align with our plan for success. From proposal to project completion, you'll see the value of partnering with TrinityRecode on your technology solution.</p>
                    </Col>

                    <Col md={6}>
                        <Image src={SynergyImage} alt="woman holding world watching sunset" fluid />
                    </Col>
                </Row>

                <Row className='mt-5 mb-5 p-lg-2'>
                    <Col md={6} className="no-mobile">
                        <Image src={CommunityImage} alt="woman holding world watching sunset" fluid />
                    </Col>

                    <Col md={6}>
                        <div className='d-flex align-items-baseline'>
                            <i className="fas fa-handshake fa-3x text-primary pe-3 ms-0" />
                            <h3 className='text-black'>Community Focused</h3>
                        </div>
                        <h4 className='text-secondary'>We believe in small businesses, local community outreach, and giving back.</h4>
                        <p className='text-dark pt-2 pe-4'>Local and small bussiness should have access to the same technological solutions as large businesses. That's why at TrinityRecode, we put you first to ensure your success. Additionally, we're commited to giving a minimum of 10% of our profits to charity. </p>
                    </Col>
                    <Col md={6} className="mobile-only">
                        <Image src={CommunityImage} alt="woman holding world watching sunset" fluid />
                    </Col>
                </Row>

            </Container>





        </div>
    )
}

export default About
