import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import { arrayCopy, exists, genValidationObject, getArrayTotal, getEmptyMessages, getRandomNum, inString, isset, is_number, is_object, localMessage, objectCopy, objectKeyDifference, objectKeyToText, objectsAreEqual, objectTextToKey, returnFakeAsyncRequest, roundTo, validateInput, validateInputs } from '../../../functions/library';
import useGetMinMax from '../../../hooks/useGetMinMax';
import { useLocalStorage } from '../../../hooks/useStorage';
import useToggleObjectState from '../../../hooks/useToggleObjectState';
import EmptyPanel from '../components/EmptyPanel';
import MultiSelectList from '../components/parts/input/MultiSelectList';
import TextInput from '../components/parts/input/TextInput';
import useMyConsole from '../../../hooks/useMyConsole';
import './scss/setup.scss';
import { useAuth } from '../../router/ProvideAuth';
import useLoading from '../../../hooks/useLoading';



const categories = {
    Debt: "All moneies owed for assets, either fixed or unfixed. e.g. Your mortagage or credit cards.",
    TV_32_38_32_Internet: "All costs associated with TV and Internet. e.g. The cable bill, Netflix subscription, or internet usage bill.",
    Food: "Food... it's what you eat! This includes groceries and meals purchased that are for strictly consumption.",
    Supplies: "Home and daily life supplies.  e.g. Toothpaste, tissues, home cleaning products.",
    Entertainment: "All entertainment costs outside of other categories.",
}

const apiSelected = {
    Food: "Food",
    Supplies: "Supplies",
}


function getCategoryNames(categoryAPIData) {
    let returnObject = {};
    Object.keys(categoryAPIData).forEach((key) => {
        returnObject[key] = objectKeyToText(key);
    });
    return returnObject;
}


function Setup() {
    // const componentId = useConstructor(() => getRandomNum(), "Setup_Construtor_RandomNumber");
    const pageName = "Setup";
    const pageId = getRandomNum(1000000000, 9999999999);
    const auth = useAuth();
    const [budgetIsNew, setBudgetIsNew] = useState(true);
    const [categoryData, setCategoryData] = useLocalStorage("categoryData", {});
    const [selectedCategories, setSelectedCategories] = useLocalStorage("selectedBudgetCategories", {});
    const [userBudgetAmounts, setUserBudgetAmounts] = useState({});
    const [userBudgetTotal, setUserBudgetTotal] = useState(0);
    const [minLabelWidth, setMinLabelWidth] = useGetMinMax(null, "max");
    const [budgetAmountValidation, setBudgetAmountValidation] = useState(genValidationObject());
    const [resetList1, setResetList1] = useState(null);
    const [resetList2, setResetList2] = useState(null);
    const [customCategory, setCustomCategory] = useState({ NewCategoryName: "", NewCategoryDescription: "" });
    const [toggleCustom, setToggleCustom] = useState(false);
    const [localLoading, setLocalLoading] = useState(true);
    const [messages, setMessages] = useState(getEmptyMessages());
    const [unselectedCategories, setCategoryToggle] = useToggleObjectState(getCategoryNames(categoryData));
    const [loading, setLoading] = useLoading([localLoading]);
    const [log] = useMyConsole(true);



    //TODO: Continue building setup - may need to create actual endpoints to make properly. Consider building out entire API endpoint structure first...

    //============= ON PAGE REFRESH
    useEffect(() => {
        let mounted = true;
        if (!isset(categoryData)) setLocalLoading(true);

        (
            //TODO: Here we need to load all applicapable user page data sequentially with some logic
            async () => {
                setLocalLoading(true);
                const apiCategories = await returnFakeAsyncRequest(categories, false, 500);
                const apiSelectedCats = await returnFakeAsyncRequest(apiSelected, false, 500);

                // HERE WE UPDATE ONLY IF NECESSARY - otherwise the cache stays loaded.
                if (!objectsAreEqual(apiCategories, categoryData)) {
                    if (mounted) setCategoryData(apiCategories);
                    setResetList1(resetList1 ? !resetList1 : true);
                    setResetList2(resetList2 ? !resetList2 : true);
                }
                if (!objectsAreEqual(selectedCategories, apiSelectedCats)) {
                    if (mounted) setSelectedCategories(apiSelectedCats);
                    setResetList1(resetList1 ? !resetList1 : true);
                    setResetList2(resetList2 ? !resetList2 : true);
                }
                if (isset(apiSelectedCats)) setBudgetIsNew(false); //TODO: Need more logic here to determine if it's new or not.
            }

        )();
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line
    }, [])

    //Sets category toggle data from localStorage or from API download
    useEffect(() => {
        let mounted = true;
        if (isset(categoryData)) {
            if (mounted) setCategoryToggle(() => { return getCategoryNames(categoryData) });
            if (is_object(selectedCategories)) {
                if (mounted) setCategoryToggle(selectedCategories);
            }
            setResetList1(resetList1 ? !resetList1 : true);
            setResetList2(resetList2 ? !resetList2 : true);
        }

        if (mounted) setLocalLoading(false); //TODO: Needs a better system for localLoading.. perhaps a useLoading hook that handles multiple localLoading states. Hook can be set towards the bottom of all hooks to collect the default states, then a setLocalLoading that takes an object or even a reducer.

        return () => mounted = false;
        // eslint-disable-next-line 
    }, [categoryData, selectedCategories]);


    useEffect(() => {
        let mounted = true;

        if (isset(selectedCategories)) {
            const newUserBudgetAmounts = objectCopy(userBudgetAmounts);
            Object.values(selectedCategories).forEach((name) => {
                const key = objectTextToKey(name);
                const description = categoryData[key];
                if (!newUserBudgetAmounts.hasOwnProperty(key)) {
                    newUserBudgetAmounts[key] = {
                        category: name,
                        description: description,
                        amount: ""
                    }
                }
                const keyDiff = objectKeyDifference(selectedCategories, newUserBudgetAmounts);
                if (!keyDiff.match && keyDiff.additional.length) {
                    keyDiff.additional.forEach((key) => {
                        if (newUserBudgetAmounts.hasOwnProperty(key)) {
                            delete newUserBudgetAmounts[key];
                        }
                    });
                }
            });
            if (mounted) setUserBudgetAmounts(newUserBudgetAmounts);
        }
        if (is_object(selectedCategories) && !isset(selectedCategories)) {
            const newUserBudgetAmounts = objectCopy(userBudgetAmounts)
            const keyDiff = objectKeyDifference(selectedCategories, newUserBudgetAmounts);
            if (!keyDiff.match && keyDiff.additional.length) {
                keyDiff.additional.forEach((key) => {
                    if (newUserBudgetAmounts.hasOwnProperty(key)) {
                        delete newUserBudgetAmounts[key];
                    }
                });
                if (mounted) setUserBudgetAmounts(newUserBudgetAmounts);
            }
        }
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line
    }, [selectedCategories])


    useEffect(() => {
        let mounted = true;
        if (isset(userBudgetAmounts)) {
            const total = getUserBudgetAmountsTotal(userBudgetAmounts);
            if (mounted) setUserBudgetTotal(total);
        }
        return () => mounted = false;
    }, [userBudgetAmounts]);


    const getUserBudgetAmountsTotal = (newUserBudgetAmounts) => {
        const totalArray = []; const amounts = newUserBudgetAmounts;
        Object.keys(amounts).forEach(key => {
            const thisAmount = amounts[key]["amount"];
            if (is_number(parseInt(thisAmount))) return totalArray.push(thisAmount);
            totalArray.push(0);
        });
        return getArrayTotal(totalArray);
    }

    const handleAvaliableCategories = (data) => {
        //data properties: selected, optional:(mouseover, mouseout)
        if (data.hasOwnProperty("selected") && isset(data.selected)) {
            const thisCategory = arrayCopy(data.selected).pop();
            const newSelectedCategories = {
                ...selectedCategories,
                [objectTextToKey(thisCategory)]: thisCategory,
            }
            if (pageId) setSelectedCategories(newSelectedCategories);
            if (pageId) setCategoryToggle(newSelectedCategories);
        }
    }

    const handleSelectedCategoryList = (data) => {
        // console.log("handleSelectedCategoryList");
        //  console.log(data);
        const removedSelection = objectCopy(data).selected.pop();
        if (removedSelection) {
            const newSelectedList = removeSelectedItem(removedSelection, selectedCategories);
            if (pageId) setSelectedCategories(newSelectedList);
            if (pageId) setCategoryToggle(newSelectedList);
        }
    }

    const removeSelectedItem = (selectedItem, objectHaystack) => {
        let newHaystack = objectCopy(objectHaystack);
        Object.entries(objectHaystack).forEach((entry) => {
            const key = entry[0];
            const value = entry[1];
            if (selectedItem === value) delete newHaystack[key];
        });
        return newHaystack;
    }


    const handleCustomCategory = (data) => {
        if (inString(data.name, "_")) data.name = data.name.split("_").slice(1).join("_");
        let validation = genValidationObject(null, { runValidation: true });
        validation = genValidationObject(validation, {
            field: {
                key: "NewCategoryName",
                rule: "TEXT_SINGLE_LINE",
            }
        });
        validation = genValidationObject(validation, {
            field: {
                key: "NewCategoryDescription",
                rule: "TEXT_SINGLE_LINE"
            }
        });
        if (validateInput(validation, data, () => { }, setMessages)) {
            if (categoryData.hasOwnProperty(objectTextToKey(data.value))) {
                if (pageId) setMessages({
                    ...getEmptyMessages(),
                    fields: { [data.name]: `Category name '${data.value}' already exists. Please select a different name.` }
                });
                return;
            }
            //setLocalLoading(true);
            if (pageId) setCustomCategory({
                ...customCategory,
                [data.name]: data.value
            });
        }
    }

    const handleCustomCategoryCancelButton = () => {
        if (pageId) setCustomCategory({ NewCategoryName: "", NewCategoryDescription: "" });
        if (pageId) setMessages(getEmptyMessages());
        if (pageId) setToggleCustom(false);
    }

    const handleCustomCategoryAddButton = () => {
        let validation = genValidationObject();
        validation = genValidationObject(validation, {
            runValidation: true,
            field: {
                key: "NewCategoryName",
                rule: "TEXT_SINGLE_LINE"
            },
            globalRule: "NOTBLANK",
        });
        validation = genValidationObject(validation, {
            field: {
                key: "NewCategoryDescription",
                rule: "TEXT_SINGLE_LINE"
            }
        });
        if (validateInputs(validation, customCategory, setMessages)) {
            let newCategoryData = objectCopy(categoryData);
            const newName = customCategory.NewCategoryName;
            const newDesc = customCategory.NewCategoryDescription;
            newCategoryData[objectTextToKey(newName)] = newDesc;
            if (pageId) setCategoryData(newCategoryData);
            if (pageId) handleCustomCategoryCancelButton();
        }
    }

    const saveCategories = () => {
        if (!isset(selectedCategories)) {
            localMessage("Cannot save - No categories selected. Disabled button bypassed by user.", "log", "warning");
            return;
        }
        console.log("Save Categories");
    }


    const handleBudgetAmount = (data) => {
        if (!isset(data)) return;
        let newUserBudgetAmounts = objectCopy(userBudgetAmounts);
        const validation = genValidationObject(budgetAmountValidation, {
            runValidation: true,
            field: data.name,
            rule: "NUMBERS",
            fieldResponse: "Only numbers are allowed."
        });
        log(validation);
        if (pageId) setBudgetAmountValidation(validation);
        if (validateInput(validation, data, () => { }, setMessages)) {
            const name = data.name;
            console.log(name, newUserBudgetAmounts);
            newUserBudgetAmounts[name].amount = data.value;
            if (pageId) setUserBudgetAmounts(newUserBudgetAmounts);
            return;
        }

    }

    const handleBudgetAmountAutoSize = (data) => {
        //console.log(data);
        if (isset(data) && exists(data, "elementName")) {
            const name = `inputText1_${data.elementName}`;
            if (pageId && data.hasOwnProperty(name) && isset(data[name]) && exists(data[name], "width")) {
                const labelWidth = exists(data, "action") && data.action === "UNSET" ? parseInt(data[name].width * -1) : parseInt(data[name].width);
                setMinLabelWidth(labelWidth + (labelWidth * .3));
            }
        }
    }



    /*##### RENDER COMPONENT ####### RENDER COMPONENT ####### RENDER COMPONENT ####### RENDER COMPONENT #####*/
    return (
        <div id={`pagemain_${pageName}`} className="setup-page">


            <Container fluid className="widget-panel">
                {loading ? <div className="d-flex justify-content-center p-4">
                    <Spinner animation="border" role="status" />
                    <h5>Loading, please wait...</h5>
                </div> :
                    budgetIsNew ? <div className="d-flex justify-content-center p-4"><h4>Looks like this is a new budget!  Let's get things setup. </h4></div> :
                        <div className="d-flex justify-content-center p-4"><h4>Welcome Back {auth.profile.userDisplayName ? auth.profile.userDisplayName : auth.user.userName}</h4></div>
                }
                <EmptyPanel
                    classMainIn="widget-nohover"
                    showHeader={false}
                >
                    <Row>
                        {/*### BUDGET CATEGORY SELECTION ###*/}
                        <Col xs={12} md={6}>
                            <EmptyPanel
                                classMainIn="widget-nohover"
                                title={`${budgetIsNew ? "Step 1: " : ""}Budget Categories`}
                                headBGColor="#0dcaf0"
                                headTXTColor="black"
                            >
                                <Row >
                                    <Col className="pe-1">
                                        <EmptyPanel
                                            classMainIn="widget-nohover"
                                            title="Available"
                                        >
                                            {
                                                localLoading ? <Spinner animation="border" role="status" /> :
                                                    <React.Fragment>
                                                        <MultiSelectList
                                                            className=""
                                                            inputOptions={{
                                                                multiItems: unselectedCategories,
                                                                textFontSize: "80%",
                                                                removeSelected: true,
                                                                resetAble: false,
                                                                mouseOver: true,
                                                                mouseOut: true,
                                                                toolTips: categoryData,
                                                                toolTipSpeed: "slow"
                                                            }}
                                                            inputType="MultiSelect"
                                                            inputName="Available_Categories"
                                                            // inputValue={preSelected} //This is for a preselected Value - currently not using.
                                                            inputFunction={handleAvaliableCategories}
                                                            resetList={resetList2}
                                                        />

                                                        {!toggleCustom ? <div className="d-flex justify-content-end">
                                                            <Button className="mt-2" size="sm" variant="info" onClick={() => setToggleCustom(true)}
                                                            >Create New</Button>
                                                        </div> : <div></div>}

                                                        {toggleCustom ? <div className="m-2 p-1 border border-3 border-warning">
                                                            <h6>Create Custom Category</h6>
                                                            <TextInput
                                                                className=""
                                                                inputOptions={{
                                                                    inputText1: "Name",
                                                                    text1FontSize: "80%"
                                                                }}
                                                                inputType="NewCategoryName"
                                                                inputName="NewCategoryName"
                                                                inputValue={customCategory["NewCategoryName"]}
                                                                inputFunction={handleCustomCategory}
                                                                messages={messages}
                                                            />
                                                            <TextInput
                                                                className=""
                                                                inputOptions={{
                                                                    inputText1: "Description",
                                                                    text1FontSize: "80%"
                                                                }}
                                                                inputType="NewCategoryDescription"
                                                                inputName="NewCategoryDescription"
                                                                inputValue={customCategory["NewCategoryDescription"]}
                                                                inputFunction={handleCustomCategory}
                                                                messages={messages}
                                                            />
                                                            <div className="d-flex justify-content-evenly mt-1">
                                                                <Button size="sm" variant="secondary" onClick={handleCustomCategoryCancelButton}>Cancel</Button>
                                                                <Button size="sm" onClick={handleCustomCategoryAddButton}>Add</Button>
                                                            </div>
                                                        </div> : <div></div>}
                                                    </React.Fragment>
                                            }

                                        </EmptyPanel>
                                    </Col>
                                    <Col className="ps-1"
                                    >
                                        <EmptyPanel
                                            classMainIn="widget-nohover"
                                            title="Selected"
                                        >
                                            {
                                                localLoading ? <Spinner animation="border" role="status" /> :
                                                    <React.Fragment>
                                                        <MultiSelectList
                                                            className=""
                                                            inputOptions={{
                                                                multiItems: selectedCategories,
                                                                removeSelected: true,
                                                                resetAble: false,
                                                                textFontSize: "80%",
                                                            }}
                                                            inputType="MultiSelect"
                                                            inputName="Selected_Categories"
                                                            inputValue=""
                                                            inputFunction={handleSelectedCategoryList}
                                                            resetList={resetList1}
                                                        />
                                                        <div className="d-flex my-2 me-1 justify-content-end">
                                                            <Button size="sm" disabled={!isset(selectedCategories)} onClick={saveCategories}>Save</Button>
                                                        </div>
                                                    </React.Fragment>
                                            }

                                        </EmptyPanel>
                                    </Col>
                                </Row>
                            </EmptyPanel>
                        </Col>

                        <Col className="" xs={12} md={6}>
                            <EmptyPanel
                                classMainIn="widget-nohover"
                                title={`${budgetIsNew ? "Step 2: " : ""}Budget Amounts`}
                                headBGColor="#0dcaf0"
                                headTXTColor="black"
                            >
                                <Row className="budget-amount-headers">
                                    <Col className="" xs={8}>
                                        <div className="header header-amounts">
                                            <h6 className="title title-amounts" style={{ minWidth: `${minLabelWidth}px` }}>Category</h6>
                                            <h6 className="title title-amounts text-start ps-4" style={{ width: "100%" }} >Amount</h6>
                                        </div>
                                    </Col>
                                    <Col className="" xs={4}>
                                        <div className="header header-amounts">
                                            <h6 className="title title-amounts w-100">Breakdown</h6>

                                        </div>
                                    </Col>

                                </Row>
                                <Row className="">
                                    <Col className="" xs={8}>
                                        <div className="d-flex">

                                            {isset(userBudgetAmounts) ? <div>
                                                {Object.entries(userBudgetAmounts).map((entry, index, array) => {
                                                    const nameKey = entry[0];
                                                    const name = entry[1].category;
                                                    const amount = entry[1].amount;
                                                    return <div key={`nameKey-${index}`}>
                                                        <TextInput
                                                            className="m-2"
                                                            inputOptions={{
                                                                useLabel: false,
                                                                inputText1: name,
                                                                text1MinWidth: `${minLabelWidth}px`,
                                                                text1Align: "right"
                                                            }}
                                                            inputType={nameKey}
                                                            inputName={nameKey}
                                                            inputValue={amount}
                                                            inputFunction={handleBudgetAmount}
                                                            messages={messages}
                                                            infoCallBack={handleBudgetAmountAutoSize}
                                                        />
                                                    </div>
                                                })}
                                            </div> : <div>No Budget Categories selected.</div>}


                                        </div>

                                    </Col>
                                    <Col className="" xs={4}>
                                        <div className="d-flex justify-content-center">

                                            {isset(userBudgetAmounts) ? <div>
                                                {Object.entries(userBudgetAmounts).map((entry, index) => {
                                                    const nameKey = entry[0];
                                                    const amount = entry[1].amount;
                                                    const percent_raw = (amount / userBudgetTotal) * 100;
                                                    const percent = roundTo(percent_raw, 1, false, false);
                                                    return <div key={`perTotal-${index}`}>
                                                        <TextInput
                                                            className="m-2 w-75"
                                                            inputOptions={{
                                                                useLabel: false,
                                                                inputTextBack: "%",
                                                                text1MinWidth: `0px`,
                                                                // text1Align: "right"
                                                            }}
                                                            inputType={`${nameKey}_pertotal`}
                                                            inputName={`${nameKey}_pertotal`}
                                                            inputValue={percent}
                                                            readOnly={true}
                                                            // infoCallBack={handleBudgetAmountAutoSize}
                                                            messages={messages}

                                                        />
                                                    </div>
                                                })}
                                            </div> : <div>No Budget Categories selected.</div>}


                                        </div>

                                    </Col>

                                </Row>
                            </EmptyPanel>
                        </Col>

                    </Row>
                </EmptyPanel>



            </Container>
        </div >
    )
}

export default Setup
