import APIReturn from "./APIReturn";


export default class ProfileObject extends APIReturn {
    type = "ProfileObject";
    userID = 0;
    profileID = 0;
    userFirstName = "";
    userLastName = "";
    userStreet1 = "";
    userStreet2 = "";
    userCity = "";
    userState = "";
    userZip = "";
    userMobileNumber = "";
    userHomeNumber = "";
    profilePhoto = "";
    userProfilePhotoFileName = "";
    profilePhotoMime = "";
    userDisplayName = "";

    constructor(data) {
        super(data);
        if (!this.isset) {
            delete this.data;
            delete this.messages;
            return;
        }
        if (
            !this.data
            || (!this.data.hasOwnProperty("userID")
                && !this.data.hasOwnProperty("profileID")
                && !this.data.hasOwnProperty("userFirstName")
                && !this.data.hasOwnProperty("userLastName")
                && !this.data.hasOwnProperty("userStreet1")
                && !this.data.hasOwnProperty("userStreet2")
                && !this.data.hasOwnProperty("userCity")
                && !this.data.hasOwnProperty("userState")
                && !this.data.hasOwnProperty("userZip")
                && !this.data.hasOwnProperty("userMobileNumber")
                && !this.data.hasOwnProperty("userHomeNumber")
                && !this.data.hasOwnProperty("profilePhoto")
                && !this.data.hasOwnProperty("userProfilePhotoFileName")
                && !this.data.hasOwnProperty("profilePhotoMime")
                && !this.data.hasOwnProperty("userDisplayName"))
        ) {
            delete this.data;
            delete this.messages;
            return;
        }
        this.userID = this.data.userID;
        this.profileID = this.data.profileID;
        this.userFirstName = this.data.userFirstName;
        this.userLastName = this.data.userLastName;
        this.userStreet1 = this.data.userStreet1;
        this.userStreet2 = this.data.userStreet2;
        this.userCity = this.data.userCity;
        this.userState = this.data.userState;
        this.userZip = this.data.userZip;
        this.userMobileNumber = this.data.userMobileNumber;
        this.userHomeNumber = this.data.userHomeNumber;
        this.profilePhoto = this.data.profilePhoto;
        this.userProfilePhotoFileName = this.data.userProfilePhotoFileName;
        this.profilePhotoMime = this.data.profilePhotoMime;
        this.userDisplayName = this.data.userDisplayName;
        delete this.data;
        delete this.messages;
        return;
    }
}
