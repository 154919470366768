import React from 'react'
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import './scss/webdev.scss';

import BannerImage from '../../images/webdev-banner.jpg'
import Illustrator from '../../images/adobe-illustrator-logo.png';
import Photoshop from '../../images/adobe-photoshop-logo.png';
import Xd from '../../images/adobe-xd-logo.png';
import MySQL from '../../images/mysql-logo.png';
import PHP from '../../images/php-logo.png';
import Laravel from '../../images/laravel-logo.png';
import Node from '../../images/node-logo.png';
import Firebase from '../../images/firebase-logo.png';

function WebDev() {
    return (
        <div className="webdev-page">
            <div className="header bg-dark text-white">
                <Image className="header-image" src={BannerImage} alt="Card image" fluid />
                <div className="header-overlay">
                    <h1 className="header-title">Professional Web Development</h1>
                    <h2 className="header-subtitle">
                        Our web development services cover the full range of small and medium size business needs. We can help you design, build, test, and deploy everything from a simple site, to a full stack web application.
                    </h2>
                </div>
            </div>

            <Container className='mt-5'>
                <Row className='d-flex justify-content-evenly'>
                    <Col xs={12} md={3}>
                        <Card className='border border-dark border-1 rounded-5 my-5 my-md-3'>
                            <Card.Body>
                                <div className='d-flex justify-content-center'>
                                    <h3 className='card-title pb-2'>Modern Design</h3>
                                </div>
                                <div className='d-flex align-items-baseline justify-content-evenly p-1 icons-top'>
                                    <i className="fab fa-html5 fa-2x"></i>
                                    <i className="fab fa-css3 fa-2x"></i>
                                </div>
                                <div className='d-flex align-items-baseline justify-content-evenly p-1 pt-2 icons-bottom'>
                                    <Image src={Photoshop} style={{ maxWidth: "32px" }} />
                                    <Image src={Xd} style={{ maxWidth: "32px" }} />
                                    <Image src={Illustrator} style={{ maxWidth: "32px" }} />
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <p className='card-text text-center p-3'>
                                        We use the latest tools and technologies to bring your site from concept to reality.
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={3}>
                        <Card className='border border-dark border-1 rounded-5 my-5 my-md-3'>
                            <Card.Body>
                                <div className='d-flex justify-content-center'>
                                    <h3 className='card-title pb-2'>Web Applications</h3>
                                </div>
                                <div className='d-flex align-items-baseline justify-content-evenly p-1 icons-top'>
                                    <i className="fab fa-js-square fa-2x"></i>
                                    <i className="fab fa-react fa-2x"></i>
                                </div>
                                <div className='d-flex align-items-baseline justify-content-evenly p-1 pt-2 icons-bottom'>
                                    <i className="fab fa-angular fa-2x"></i>
                                    <i className="fab fa-bootstrap fa-2x"></i>
                                    <i className="fab fa-vuejs fa-2x"></i>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <p className='card-text text-center p-3'>
                                        We build fast &amp; reactive mission critical applications, with the latest frameworks.
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={3}>
                        <Card className='border border-dark border-1 rounded-5 my-5 my-md-3'>
                            <Card.Body>
                                <div className='d-flex justify-content-center'>
                                    <h3 className='card-title pb-2'>API &amp; Back End</h3>
                                </div>
                                <div className='d-flex align-items-baseline justify-content-evenly p-1 icons-top'>
                                    <Image src={PHP} style={{ maxHeight: "32px" }} />
                                    <Image src={MySQL} style={{ maxHeight: "32px" }} />
                                </div>
                                <div className='d-flex justify-content-evenly p-1 pt-2 icons-bottom'>
                                    <Image src={Laravel} style={{ maxHeight: "32px" }} />
                                    <Image src={Firebase} style={{ maxHeight: "32px" }} />
                                    <Image src={Node} style={{ maxHeight: "32px" }} />
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <p className='card-text text-center p-3'>
                                        Data driven sites will benefit from secure, efficient API development.  We'll scale the solution to fit.
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container className='how-it-works my-5'>
                <div className='d-flex justify-content-center pt-5 pb-4'>
                    <i className="fas fa-project-diagram fa-2x pe-4 text-secondary"></i> <h3 className='card-title'>How It Works</h3>
                </div>
                <Row className='d-flex justify-content-evenly px-5'>
                    <Col xs={12} md={3} className='my-3 my-md-0'>
                        <div className='d-flex justify-content-start'>
                            <i className="fas fa-check fa-2x pe-2"></i>
                            <h4 className='text-dark'>Your Vision</h4>
                        </div>

                        <p>
                            Bring us your ideas, concepts, &amp; content and we'll consult with you on the best design.
                        </p>
                    </Col>
                    <Col xs={12} md={3} className='my-3 my-md-0'>
                        <div className='d-flex justify-content-start'>
                            <i className="fas fa-check fa-2x pe-2"></i>
                            <h4 className='text-dark'>Our Experience</h4>
                        </div>

                        <p>
                            We'll help you select the right technologies scaled for value and performance as a plan for success.
                        </p>
                    </Col>
                    <Col xs={12} md={3} className='my-3 my-md-0'>
                        <div className='d-flex justify-content-start'>
                            <i className="fas fa-check fa-2x pe-2"></i>
                            <h4 className='text-dark'>Team Production</h4>
                        </div>

                        <p>
                            Every step of the way, you'll be guided by TrinityRecode as we develop and deploy your web solution.
                        </p>
                    </Col>
                </Row>


            </Container>


        </div>
    )
}

export default WebDev
