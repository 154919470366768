import React, { createContext, useContext, useEffect, useState } from 'react'
import APICall from '../classes/APICall';
import APIReturn from '../classes/APIReturns/APIReturn';
import MessagesObject from '../classes/APIReturns/MessagesObject';
import Endpoint from '../classes/EndpointToken';
import { getType, isset, is_number, is_object, propExists, } from '../functions/library';
import { useAuth } from '../sections/router/ProvideAuth';
import useTimeout from './useTimeout';




export const apiContext = createContext();



export default function ProvideAPI({ children }) {
    const api = useProvideAPI();
    return (
        <apiContext.Provider value={api}>
            <div className="api-wrapper-div">
                {children}
            </div>
        </apiContext.Provider>
    );
}

/**
 * Returns an instance of the useProvideAPI()
 * @returns {[busy:boolean,request:function, call:function, result:{}]}
 */
export function useAPI() {
    return useContext(apiContext);
}

/**
 * A global context message handler.
 * @returns {[messages:MessagesObject, setMessages:function, setMessageTimeout:function]} An array containing the current context MessagesObject, a fucntion to set the messages, and finally a function to set the message clear timeout.
 * @property {MessagesObject} messages A MessagesObject 
 * @function setMessages(messages) Takes in a MessageObject or an object containing at least one of: form, fields, or request keys with appropriate values.
 * @function setMessageTimeout() Sets a time out duration to clear the messages
 */
export const useMessages = () => {
    const apiContext = useAPI();
    const messages = apiContext[4];
    const setContextMessages = apiContext[5];
    const [timeoutDuration, setLocalTimeout] = useState(0);
    const [setClear, timeoutID, setDuration] = useTimeout(timeoutDuration);

    useEffect(() => {
        let mounted = true;
        setClear(() => {
            if (mounted) setContextMessages(new MessagesObject());
        }, timeoutID);
        return () => mounted = clearTimeout(timeoutID);
        // eslint-disable-next-line
    }, [messages.request, messages.form, messages.fields, messages.success, timeoutDuration]);


    /**
     * Sets the current messages in the context
     * @param {function|{}} messagesIn Accepts a messages object or individual fields and properties, or a callback function that supplies the current messages as an argument.
     * @returns 
     */
    const setMessages = (messagesIn, optionalTimeout) => {
        if (is_number(optionalTimeout)) {
            const originalTimeout = timeoutDuration;
            setLocalTimeout(optionalTimeout);
            setTimeout(() => {
                setContextMessages(new MessagesObject())
                setLocalTimeout(originalTimeout);
                setDuration(originalTimeout);
            }, (optionalTimeout + 1));
        }

        if (getType(messagesIn, "function")) {
            setContextMessages(messagesIn(messages));
        }

        if (getType(messagesIn, "object")) {
            if (isset(messagesIn.type) && messagesIn.type === "MessagesObject") { setContextMessages(messagesIn); return; }
            const newMessages = new MessagesObject();
            newMessages.request = propExists(messagesIn, "request", null);
            newMessages.form = propExists(messagesIn, "form", null);
            newMessages.fields = propExists(messagesIn, "fields", null);
            newMessages.success = propExists(messagesIn, "success", false);
            newMessages.statusCode = propExists(messagesIn, "statusCode", 0);
            newMessages.responseID = propExists(messagesIn, "responseID", "");
            setContextMessages(newMessages);
        }
    }

    const setMessageTimeout = (duration = 0) => {
        if (is_number(duration)) {
            setLocalTimeout(duration);
            return;
        }
        setLocalTimeout(0);
    }
    return [messages, setMessages, setMessageTimeout];
}

// Ensure that everywhere that calls this hook has access to the same exact data!
export function useProvideAPI() {
    const [queue, setQueue] = useState([]);
    const [busy, setBusy] = useState(false);
    const [result, setResult] = useState({});
    const [refreshAttempt, setRefreshAttempt] = useState(false);
    const [messages, setMessages] = useState(new MessagesObject());
    let auth = useAuth();


    // eslint-disable-next-line
    useEffect(() => {
        let mounted = true;
        if (mounted && !busy && isset(queue) && queue.length) {
            setBusy(true);
            (async () => {
                const thisCall = queue.shift();
                const response = await execute(thisCall);
                // console.log("response from execute in use effect for id:", thisCall.id, response);

                //Check to see if 403 for possible token expiration - send refresh attempt once.
                if ((response.statusCode === 401 || response.statusCode === 403) && !refreshAttempt) {
                    //Return the last failed call to the queue
                    queue.push(thisCall);
                    setRefreshAttempt(true);
                    const refreshResponse = await auth.refreshSession(() => {
                        console.log("SESSION REFRESHING CALLBACK");
                    }, auth.user, auth.session);
                    console.log("SESSION REFRESHED", refreshResponse);
                    if (isset(refreshResponse.data) && refreshResponse.data.hasOwnProperty("accessToken")) {
                        const accessToken = refreshResponse.data.accessToken;
                        //Refresh Success, resume queue
                        if (refreshResponse.success) {
                            updateAllCalls(accessToken);
                            setRefreshAttempt(false);
                            setBusy(false);
                            return;
                        }
                    }
                    //Token still expired or bad, log user out
                    auth.signout(() => {
                        console.log("Forcing signout");
                    });
                    setBusy(false);
                    return;
                }
                // Otherwise return result
                const newResult = { ...result, [thisCall.id]: new Promise((resolve, reject) => { resolve(response) }) };
                setQueue(queue);
                setResult(newResult);
                setBusy(false);
                return;
            }
            )();
        }
        return () => mounted = false;
    });

    const execute = async (APICall) => {
        //  console.log("Calling execute on the APICall", APICall.url);
        return await APICall.execute();
    }

    const pushToQueue = (NewCall) => {
        queue.push(NewCall);
        setQueue(queue);
    }

    const updateAllCalls = (accessToken) => {
        setQueue(currentQueue => {
            return currentQueue.map((thisCall) => {
                thisCall.endpoint.Authorization = accessToken;
                return thisCall;
            });
        });
    }

    /**
     * 
     * @param {*} EndpointClass 
     * @param {*} requester 
     * @returns {function}
     */
    const request = (EndpointClass = new Endpoint(), requester) => {
        if (isset(requester)) {
            const NewCall = new APICall(EndpointClass, requester);
            Object.defineProperty(NewCall, 'id', {
                writable: false
            });
            const newResult = { ...result, [NewCall.id]: new Promise(() => { }) };
            setResult(newResult);
            return returnNewCall(NewCall);
        }
    }

    const returnNewCall = (NewCall = new APICall()) => {
        return NewCall;
    }

    const call = (NewCall = new APICall()) => {
        //Set the queue
        pushToQueue(NewCall);
        //Return the call id
        return NewCall.id;
    }




    return [busy, request, call, result, messages, setMessages];
}

/**
 * 
 * @param {*} callID 
 * @returns {[loading:boolean, error:boolean, response:{}, complete:boolean]}
 */
export function useAPIResult(callID) {
    const [, , , queue] = useAPI();
    const [id, setId] = useState(callID);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState(null);
    const [complete, setComplete] = useState(false);
    if (callID && !id) {
        setId(callID);
        setComplete(false);
    };

    useEffect(() => {
        let mounted = true;
        if (id !== callID && mounted) {
            setId(callID);
            setComplete(false);
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [callID]);

    useEffect(() => {
        let mounted = true;
        if (mounted && id && is_object(queue) && queue.hasOwnProperty(id) && !complete && !loading) {

            setLoading(true);
            setError(false);
            setResponse(null);
            (
                async () => {
                    queue[id].then((result) => {
                        const ThisReturn = new APIReturn(result);
                        setResponse(ThisReturn);
                        if (!result.success) setError(true);
                        setComplete(true);
                    }).catch((error) => {
                        const ThisReturn = new APIReturn(error);
                        setResponse(ThisReturn);
                        setError(true);
                        setComplete(true);
                    });
                    setLoading(false);
                }
            )();
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [id, callID, queue]);

    return [loading, error, response, complete];

}