import React from 'react';
import { Button, Card, Container, Row } from 'react-bootstrap';
import HomeBanner from '../../images/home-banner.jpg'
import WebDev from '../../images/web-development.jpg'
import Solution from '../../images/solution.jpg'
import Consulting from '../../images/consulting.jpg'
import './scss/home.scss';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

function Home() {
    let history = useHistory();






    return (

        <Container className="home-page m-0 p-0" fluid>

            <div className="d-flex justify-content-center bg-dark">
                <Card className="bg-dark text-white">
                    <Card.Img src={HomeBanner} alt="Card image" style={{ maxWidth: "900px" }} />
                    <Card.ImgOverlay>
                        <Card.Title className="title-text no-hover">Breathe New Life Into Your Business</Card.Title>
                        <Card.Text className="subtitle-text">
                            Whether you're established or just starting out, {<br />}
                            TrinityRecode can help.
                        </Card.Text>
                        <div className="d-flex justify-content-end">
                            <Card
                                bg="primary"
                                text="light"
                                className="book-now mb-2"
                            >

                                <Card.Body>
                                    <Card.Title className="card-title">Book a complementary evaluation today!</Card.Title>
                                    <Card.Text>
                                        We'll give you a risk free assessment of your opportunity and identify areas where we can help.
                                    </Card.Text>
                                    <Card.Footer className="bg-primary border border-0 m-0 p-0 d-flex justify-content-end"><Button className="contact-button" variant="secondary" href="/Contact?select=Customer_Inquiry">Contact</Button></Card.Footer>
                                </Card.Body>
                            </Card>
                            <Button className="book-now-mobile contact-button" href="/Contact?select=Customer_Inquiry" variant="secondary">Reach Out Today!</Button>

                        </div>
                    </Card.ImgOverlay>
                </Card>
            </div>
            <Row className="d-flex justify-content-center mt-5 mt-md-3">
                <Card className="card-main p-3">
                    <Card.Img variant="top" src={WebDev} />
                    <Card.Body>
                        <Card.Title>Web Development</Card.Title>
                        <Card.Text>
                            Offering a complete web development solution, our tailored solutions run the gamut from simple single page sites, to full stack web applications.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer className="bg-white">
                        <small className="text-muted"><Button variant="outline-primary" href="/Services/WebDev">Learn More</Button></small>
                    </Card.Footer>
                </Card>
                <Card className="card-main p-3">
                    <Card.Img variant="top" src={Consulting} />
                    <Card.Body>
                        <Card.Title>Technology Consulting</Card.Title>
                        <Card.Text>
                            We have decades of experience developing and deploying technology solutions. Partner with us to ensure your goals are met efficiently and effectively.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer className="bg-white">
                        <small className="text-muted"><Button variant="outline-primary" href="/Services/Consulting">Learn More</Button></small>
                    </Card.Footer>
                </Card>
                <Card className="card-main p-3">
                    <Card.Img variant="top" src={Solution} />
                    <Card.Body>
                        <Card.Title>Scaled Solutions</Card.Title>
                        <Card.Text>
                            Every project has a budget. Not every solution is cost-effective.  We understand how to deliver a successful targeted solution, without wasting time and resources.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer className="bg-white">
                        <small className="text-muted"><Button variant="outline-primary" href="/Services/Solutions">Learn More</Button></small>
                    </Card.Footer>
                </Card>
            </Row>
        </Container>
    )
}

export default Home
