import { useState, useEffect } from 'react'
import { isset, getType, is_string, roundTo, is_array, arrayRemove } from '../functions/library';


function getSavedValue(persist, initialValue) {
    if (!isset(persist) && !getType(initialValue, "function")) return initialValue;
    const savedValue = JSON.parse(localStorage.getItem(persist));
    if (isset(savedValue)) return savedValue;
    if (getType(initialValue, "function")) return initialValue();
    return initialValue;
}

function createValueArray(initialValue, valueArray) {
    if (isset(initialValue)) {
        let newValueArray = [];
        if (!is_array(valueArray)) {
            if (isset(valueArray)) newValueArray = [roundTo(valueArray, 0, false)];
        } else {
            newValueArray.push(...valueArray);
        }
        if (initialValue > 0) newValueArray.push(roundTo(initialValue, 0, false));
        if (initialValue < 0) arrayRemove(newValueArray, Math.abs(initialValue));
        newValueArray = newValueArray.sort().filter((num, position, array) => {
            return !position || num !== array[position - 1];
        });
        return newValueArray;
    }
}

function useGetMinMax(initialValue, minOrMax = "min", persist = null) {
    const p_int = `${persist}_int`;
    const p_array = `${persist}_array`;
    const [returnType, setReturnType] = useState(minOrMax);
    const [value, setValue] = useState(() => {
        return getSavedValue(p_int, initialValue);
    });
    const [valueArray, setValueArray] = useState(() => {
        return getSavedValue(p_array, initialValue);
    });

    function setNewMinMaxValue(newValue) {
        newValue = roundTo(newValue, 0, false);
        const newValueArray = createValueArray(newValue, valueArray);
        if (isset(newValueArray) && is_array(newValueArray)) {
            setValueArray(newValueArray);
        }
    }


    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const newValueArray = createValueArray(initialValue, valueArray);
            if (isset(newValueArray) && is_array(newValueArray)) {
                setValueArray(newValueArray);
            }
        }
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line 
    }, []);

    //Cache if persist is set
    useEffect(() => {
        let mounted = true;
        if (mounted && isset(persist)) {
            localStorage.setItem(`${persist}_int`, value);
            localStorage.setItem(`${persist}_array`, valueArray);
        }
        return () => {
            mounted = false;
        }
    }, [value, valueArray, persist]);


    useEffect(() => {
        let mounted = true;
        if (isset(valueArray) && is_array(valueArray)) {
            if (isset(returnType) && is_string(returnType) && Math.hasOwnProperty(returnType.toLocaleLowerCase())) {
                if (mounted) setValue(Math[returnType.toLocaleLowerCase()](...valueArray));
            }

        }
        return () => {
            mounted = false;
        }
    }, [valueArray, returnType]);

    return [value, setNewMinMaxValue, setReturnType];
}

export default useGetMinMax
