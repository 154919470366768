import React, { useState, useEffect } from 'react'
import { Image, Nav } from 'react-bootstrap';
import './scss/consulting.scss';

import BannerImage from '../../images/consulting-banner.jpg';
import EventTech from './Consulting/EventTech';
import { useLocation, useHistory } from 'react-router-dom';
import ConsultingMain from './Consulting/ConsultingMain';
import ComputerHardware from './Consulting/ComputerHardware';
import { isset } from '../../functions/library';
import Networking from './Consulting/Networking';
import Troubleshooting from './Consulting/Troubleshooting';
import Virtual from './Consulting/Virtual';


function Consulting() {
    const [showConsultingMain, setShowConsultingMain] = useState(true);
    const [showEventTech, setShowEventTech] = useState(false);
    const [showComputerHardware, setShowComputerHardware] = useState(false);
    const [showNetworking, setShowNetworking] = useState(false);
    const [showRepair, setShowRepair] = useState(false);
    const [showVirtual, setShowVirtual] = useState(false);

    const history = useHistory();
    const location = useLocation();

    const tabSetters = {
        consulting_main: setShowConsultingMain,
        event_tech: setShowEventTech,
        computer_hardware: setShowComputerHardware,
        networking: setShowNetworking,
        troubleshooting: setShowRepair,
        virtual_platforms: setShowVirtual,
    }

    const tabValues = {
        consulting_main: showConsultingMain,
        event_tech: showEventTech,
        computer_hardware: showComputerHardware,
        networking: showNetworking,
        troubleshooting: showRepair,
        virtual_platforms: showVirtual,
    }

    const handleTabSelect = (e) => {
        const userClicked = e.target.dataset.rrUiEventKey;
        Object.entries(tabSetters).forEach((entry) => {
            const selected = entry[0];
            const tabSetter = entry[1];
            let toSet = false;
            if (selected === userClicked) toSet = true;
            tabSetter(toSet);
        });
        const newPath = `/Services/Consulting?tab=${userClicked}`;
        history.push(newPath);
    }

    useEffect(() => {
        let mounted = true;
        const urlSelected = location.search.split("=")[1];
        let activeSetter = "consulting_main";
        Object.entries(tabValues).forEach((entry) => {
            if (entry[1] === true) activeSetter = entry[0];
        });
        if (urlSelected && (urlSelected !== activeSetter)) {
            if (isset(tabValues[urlSelected])) {
                if (mounted) handleTabSelect({ target: { dataset: { rrUiEventKey: urlSelected } } });
            }

        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [location, tabValues]);

    return (
        <div className='consulting-page'>

            <div className="header bg-dark text-white">
                <Image className="header-image" src={BannerImage} alt="Card image" fluid />
                <div className="header-overlay">
                    <h1 className="header-title mt-2 mt-xl-5">Technology Consulting</h1>
                    <h2 className="header-subtitle pt-2">
                        The word <div className='d-inline italic text-light'>technology</div> in the modern age covers a wide range of disciplines. <br className='display-none-mobile' />Here at TrinityRecode, we advise you on the full array of diverse technological services and solutions.
                    </h2>
                </div>
            </div>
            <div className='w-100'>
                <div className='consult-bar'>
                    <Nav justify variant="tabs" defaultActiveKey="consulting_main">
                        <Nav.Item className='consulting-link-box'>
                            <Nav.Link eventKey="consulting_main" active={showConsultingMain} onClick={(e) => handleTabSelect(e)}>Consulting Main</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='consulting-link-box'>
                            <Nav.Link eventKey="computer_hardware" active={showComputerHardware} onClick={(e) => handleTabSelect(e)}>Computer Hardware</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='consulting-link-box'>
                            <Nav.Link eventKey="event_tech" active={showEventTech} onClick={(e) => handleTabSelect(e)}>Event Technology</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='consulting-link-box'>
                            <Nav.Link eventKey="networking" active={showNetworking} onClick={(e) => handleTabSelect(e)}>Networking<div className='mobile-only'><br /></div></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='consulting-link-box'>
                            <Nav.Link eventKey="troubleshooting" active={showRepair} onClick={(e) => handleTabSelect(e)}>Troubleshooting &amp; Repair</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='consulting-link-box'>
                            <Nav.Link eventKey="virtual_platforms" active={showVirtual} onClick={(e) => handleTabSelect(e)}>Virtual Platforms</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                {showConsultingMain ? <ConsultingMain /> : <React.Fragment />}
                {showEventTech ? <EventTech /> : <React.Fragment />}
                {showComputerHardware ? <ComputerHardware /> : <React.Fragment />}
                {showNetworking ? <Networking /> : <React.Fragment />}
                {showRepair ? <Troubleshooting /> : <React.Fragment />}
                {showVirtual ? <Virtual /> : <React.Fragment />}

            </div>





        </div>
    )
}

export default Consulting
