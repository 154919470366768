import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router';
import { isset } from '../../../../functions/library';

/**
 * 
 * @param {string} className Class to apply to the outer div
 * @param {boolean} isInternal True if the link redirects to an internal page. Default: true
 * @param {string} address The internal address e.g. "/Login" or external url 
 * @param {string} text The Text to display, rather than the link.
 * @returns 
 */
export const HyperLink = ({ className, isInternal, address, text }) => {
    const [internalRedirect, setInternalRedirect] = useState(true);
    const [linkAddress, setLinkAddress] = useState("");



    useEffect(() => {
        let mounted = true;

        if (mounted && isset(isInternal)) setInternalRedirect(isInternal);
        if (mounted && isset(address)) setLinkAddress(address);


        return () => mounted = false;
    }, [isInternal, address]);


    const handleClick = (event) => {
        event.preventDefault();
        if (internalRedirect) {
            <Redirect push to="/Login" />
        }
        window.location.assign(linkAddress);
    }
    if (!isset(text)) {
        text = linkAddress
    }
    return (
        <div className={className}>
            <a href={linkAddress} onClick={handleClick} >{text}</a>
        </div>
    )
}
