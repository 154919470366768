import { callAxios } from "../functions/APIHelpers";
import { getRandomID, isset, localMessage } from "../functions/library";
import { THROW_ERROR_LOCATION } from "../functions/siteDefaults";
import EndpointToken from "./EndpointToken";


export class APICall {
    id = "";
    endpoint = EndpointToken;
    caller = "";

    constructor(endpointObject = EndpointToken, caller) {
        if (!endpointObject.isset) this.sendError1();
        if (!isset(caller)) this.sendError2();
        this.endpoint = endpointObject;
        this.caller = caller;
        this.id = "APICall_ID_" + getRandomID(16);
    }

    get location() {
        return this.endpoint.url;
    }

    get url() {
        return this.endpoint.url;
    }

    /**
     * Sets the response from the API to the endpoint
     * @param {{}} responseObject
     */
    set response(responseObject) {
        this.endpoint.response = responseObject;
    }

    get response() {
        return this.endpoint.response;
    }

    async execute() {
        const method = this.endpoint.method;
        const url = this.endpoint.url;
        const headers = this.endpoint.headers;
        const body = this.endpoint.body;
        const auth = this.endpoint.requiresAuth;
        const apiResponse = await callAxios(method, url, headers, body, auth);
        return apiResponse;
    }


    sendError1() {
        localMessage(`Invalid constructor argument #1 - APICall supplied with incomplete Endpoint token.`, `${THROW_ERROR_LOCATION}`, "ERROR", { class: "APICall", function: "constructor" });
    }
    sendError2() {
        localMessage(`Invalid constructor argument #2 - caller cannot be blank.`, `${THROW_ERROR_LOCATION}`, "ERROR", { class: "APICall", function: "constructor" });
    }
    //End of APICall Class
}

export default APICall
