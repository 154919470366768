import React from 'react'
import { Container } from 'react-bootstrap';


import './scss/dashboard.scss';


function Dashboard() {
    //const pageName = "Dashboard";
    // const pageId = getRandomNum(100000000, 999999999);

    return (
        <Container fluid className="py-5 bg-dark">
            <div className='d-flex justify-content-center'>
                <h1 className='text-white'>Oops, nothing here yet.</h1>
            </div>

        </Container>
    )
}

export default Dashboard
