import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap';

import HardwareImage1 from "../../../images/hardware-image-1.jpg";
import HardwareImage2 from "../../../images/hardware-image-2.jpg";
import HardwareImage3 from "../../../images/hardware-image-3.jpg";
import HardwareImage4 from "../../../images/hardware-image-4.jpg";

function ComputerHardware() {
    return (
        <Container className='mt-5'>
            <Row className='event-tech-info mt-5 mb-5 pb-3 p-lg-2'>
                <Col md={6} className="no-mobile">
                    <Row >
                        <Col xs={7}>
                            <Image className="pt-lg-2" src={HardwareImage1} fluid />
                        </Col>
                        <Col xs={5}>
                            <Row className='hardware-images-column-2 pt-0'>
                                <Col>
                                    <Image src={HardwareImage4} fluid />
                                </Col>
                            </Row>
                            <Row className='hardware-images-column-2'>
                                <Col>
                                    <Image src={HardwareImage3} fluid />
                                </Col>
                            </Row>
                            <Row className='hardware-images-column-2'>
                                <Col>
                                    <Image src={HardwareImage2} fluid />
                                </Col>
                            </Row>


                        </Col>
                    </Row>
                </Col>

                <Col md={6}>
                    <div className='d-flex align-items-baseline'>
                        <i className="fas fa-desktop fa-3x text-primary pe-3 ms-0 no-hover" />
                        <h3 className='text-black'>Computer Hardware</h3>
                    </div>
                    <h4 className='text-secondary pt-2'>We'll help you select the right hardware for your needs.</h4>
                    <div className='d-flex'>
                        <i className="fas fa-bolt ps-1 pe-2 no-hover"></i>
                        <h4 className='text-primary h4-highlight'>On budget &amp; scaled to perform.</h4>
                        <i className="fas fa-bolt fa-flip-horizontal pe-1 no-hover"></i>
                    </div>

                    <p className='text-dark pt-1 pe-4  mt-1'>Desktops, thin clients, laptops, tablets, network attached storage, VR... the list is endless.  It seems almost monthly there's a new device hitting the market for business and consumers alike. What is right for your business? <a href="/Contact?select=Consulting">Let TrinityRecode help.</a> </p>
                    <p className='text-dark pt-1 pe-4  mt-1'>Not only can we recommend the right solution, but we also offer built-to-order desktops to help your business scale.</p>

                </Col>
                <Col md={6} className="mobile-only">
                    <Row>
                        <Col xs={7}>
                            <Image src={HardwareImage1} fluid />
                        </Col>
                        <Col xs={5}>
                            <Row className='hardware-images-column-2 pt-1 pb-2'>
                                <Col>
                                    <Image src={HardwareImage4} fluid />
                                </Col>
                            </Row>
                            <Row className='hardware-images-column-2 pb-2'>
                                <Col>
                                    <Image src={HardwareImage3} fluid />
                                </Col>
                            </Row>
                            <Row className='hardware-images-column-2'>
                                <Col>
                                    <Image src={HardwareImage2} fluid />
                                </Col>
                            </Row>


                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default ComputerHardware
