import React, { useState, useEffect } from "react";
import buttonImageThin from "../buttons/img/close-button-thinner-default.png";
import buttonImageThinOver from "../buttons/img/close-button-thinner-over.png";
import buttonImageThinDown from "../buttons/img/close-button-thinner-down.png";
import './closeButton.scss';
import { isset } from "../../../../../functions/library";

const CloseButton = ({ className, clickHandler, name, buttonSize }) => {
	const [buttonImage, setButtonImage] = useState(buttonImageThin);
	const [size, setSize] = useState("25px");
	if (!name) {
		name = "defaultCloseButton";
	}

	useEffect(() => {
		let mounted = true;

		if (isset(buttonSize)) {
			switch (buttonSize) {
				case "xxl":
					if (mounted) setSize("40px");
					break;
				case "xl":
					if (mounted) setSize("35px");
					break;
				case "lg":
					if (mounted) setSize("30px");
					break;
				case "md":
					if (mounted) setSize("25px");
					break;
				case "sm":
					if (mounted) setSize("20px");
					break;
				case "xs":
					if (mounted) setSize("15px");
					break;

				default:
					if (mounted) setSize(buttonSize);
					break;
			}
		}
		return () => {
			mounted = false;
		}
	}, [buttonSize])

	return (
		<div className={`close-button-main ${className}`}
			onClick={clickHandler}
			onMouseOver={() => setButtonImage(buttonImageThinOver)}
			onMouseDown={() => setButtonImage(buttonImageThinDown)}
			onMouseUp={() => setButtonImage(buttonImageThinOver)}
			onMouseOut={() => setButtonImage(buttonImageThin)}
			onMouseLeave={() => setButtonImage(buttonImageThin)}

		>
			<img src={buttonImage} className="image" alt="Close button" name={name}
				style={{
					maxWidth: size,
					maxHeight: size,
				}}
			/>
		</div>
	);
};

export default CloseButton;
