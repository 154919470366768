import { propExists, isset } from '../functions/library';


/** 
 * Custom console log that can not be blocked by conventional means. Useful in React developmode. NOTE: ensure shadowCopyConsoleLog() is place first in your script before all other JS or HTML.  For React, place at top of index.js just after import statements before any JSX.
 * @param {boolean} verbose True if messages are to be logged to the console, false if hidden. Default: true.
 * @returns {[log:function, error:function, isBlocked:function]} Returns an array of functions. 
 * log: Mirror of the console.log function that returns true if the logged message shown normally, false if it was attempted to be blocked by another script. 
 * error: Mirror of the console.error function that returns true if the logged message shown normally, false if it was attempted to be blocked by another script. 
 * isBlocked: Returns true if the standard console.log function is blocked from output, false otherwise. 
 */
function useMyConsole(verbose = true) {

    const isBlocked = () => {
        if (propExists("window.consoleLog.log") && window.console.log.toString() !== window.consoleLog.log.toString()) return true;
        return false;
    }

    /**
     * A mirror of console.log that will still output content even when console.log is blocked from output.
     * @param  {...any} message The message to be logged to the console. Accepts standard console.log paramaters
     * @returns {boolean} True if the output was normal, false if it was blocked in the normal console.log function.
     */
    const log = (...message) => {
        if (!isset(message)) return;
        if (propExists("window.consoleLog.log") && window.console.log.toString() !== window.consoleLog.log.toString()) {
            if (verbose) window.consoleLog.warn.call(null, ...[...message, `|| THIS WAS BLOCKED CONSOLE OUTPUT`]);
            return false;
        }
        if (verbose) console.log(...message);
        return true;
    }


    const error = (...message) => {
        if (!isset(message)) return;
        if (propExists("window.consoleLog.error") && window.console.error.toString() !== window.consoleLog.error.toString()) {
            if (verbose) window.consoleLog.error.call(null, ...[...message, `|| THIS WAS BLOCKED CONSOLE OUTPUT`]);
            return false;
        }
        if (verbose) console.error(...message);
        return true;
    }


    return [log, error, isBlocked];
}

export default useMyConsole
