import React, { useState } from 'react'
import { Button, Card, Col, Container, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import ExperienceImage1 from "../../../images/consulting-tri-top-1.jpg";
import ExperienceImage2 from "../../../images/consulting-tri-top-2.jpg";
import ExperienceImage3 from "../../../images/consulting-tri-bottom.jpg";
import ModalSimple from '../components/parts/modals/ModalSimple';

function EventTech() {
    const [showPassiveModal, setShowPassiveModal] = useState(false);
    const [showActiveModal, setShowActiveModal] = useState(false);
    const [showRFPModal, setShowRFPModal] = useState(false);
    const [showSourcingModal, setShowSourcingModal] = useState(false);
    return (
        <Container className='mt-5'>
            <Row className='event-tech-info mt-5 mb-5 pb-3 p-lg-2'>
                <Col md={6} className="no-mobile">
                    <Row className='pb-2'>
                        <Col className='px-2'>
                            <Image src={ExperienceImage1} alt="Projector" fluid />
                        </Col>
                        <Col className='px-2'>
                            <Image src={ExperienceImage2} alt="Mixer and microphone" fluid />
                        </Col>
                    </Row>
                    <Row className='pt-2'>
                        <Col className='px-2'>
                            <Image src={ExperienceImage3} alt="Technology meeting with presenter" fluid />
                        </Col>
                    </Row>

                </Col>

                <Col md={6}>
                    <div className='d-flex align-items-baseline'>
                        <i className="fas fa-microphone fa-3x text-primary pe-3 ms-0 no-hover" />
                        <h3 className='text-black'>Event Technology</h3>
                    </div>
                    <h4 className='text-secondary pt-2'>We have extensive experience in Audio Visual event support spanning two decades.</h4>
                    <p className='text-dark pt-1 pe-4  mt-1'>Whether you need advice on your current A/V estimate, or a full RFP and support solution, we can help. TrinityRecode will make sure you don't pay for equipment that you don't need, while at the same time, ensuring you have the most relevant solution to meet your goals.  We'll also ensure your solution is properly scaled for your event venue size as well as considerations for safety and power distribution. <a href="/Contact?select=Consulting">See how we can help.</a></p>

                </Col>
                <Col md={6} className="mobile-only">
                    <Row className='pb-2'>
                        <Col className='px-2'>
                            <Image src={ExperienceImage1} alt="Projector" fluid />
                        </Col>
                        <Col className='px-2'>
                            <Image src={ExperienceImage2} alt="Mixer and microphone" fluid />
                        </Col>
                    </Row>
                    <Row className='pt-2'>
                        <Col className='px-2'>
                            <Image src={ExperienceImage3} alt="Technology meeting with presenter" fluid />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className='event-tech-packages'>
                <h1 className='text-center text-primary no-shadow'>Event Technology Consulting Packages</h1>
                <Col className='mb-3 mb-md-0'>
                    <div className="d-flex justify-content-center">
                        <Card
                            bg="light"
                            text="dark"
                            style={{ width: '18rem' }}
                            className="mt-4 border-0"
                        >
                            <Card.Header className="consulting-title border-0 bg-light p-0 mb-0">
                                <Card.Title className='text-secondary'>Passive Assessment</Card.Title>
                            </Card.Header>
                            <Card.Body className="pt-1 px-0">
                                <Card.Text className='card-text'>
                                    Helps you determine if the estimate you recieved from your vendor is applicapable to your needs and priced fairly.
                                </Card.Text>
                                <Button variant="info" onClick={() => setShowPassiveModal(true)}>Details</Button>
                                <ModalSimple showModal={showPassiveModal} showCallback={setShowPassiveModal}
                                    title="Passive Assessment"
                                    body={<div>
                                        <ul>
                                            <li className='bullet-point'><strong>Vendor Estimate Review: </strong>We'll review your estimate with you via email, phone, or virtual meeting.</li>
                                            <li className='bullet-point'><strong>Guidance &amp; Recomendations: </strong>We'll offer guidance on equipment, labor requirements, power requirements, and pricing.</li>
                                            <li className='bullet-point'><strong>Pricing: </strong> Tiered pricing starting at $75 or 2.5% of the reviewed estimate cost, under $7,500. Whichever is&nbsp;
                                                <OverlayTrigger placement="top" overlay={<Tooltip style={{ maxWidth: "max-content", background: "none" }} id="tooltip-top">We always select the most cost effective fee schedule to minimize your consulting cost.</Tooltip>}>
                                                    <div className='d-inline'>
                                                        <div className='text-secondary bold underline-wavy d-inline'>lower.</div>
                                                    </div>
                                                </OverlayTrigger>
                                                <a href='/Contact?select=Consulting pt-2'> Contact us</a> for additional pricing tiers over $7,500.</li>
                                        </ul>
                                        <muted className='italic'><small>* Minimum fee of $25</small></muted>
                                    </div>
                                    }

                                />
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
                <Col className='mb-3 mb-md-0'>
                    <div className="d-flex justify-content-center">
                        <Card
                            bg="light"
                            text="dark"
                            style={{ width: '18rem' }}
                            className="mt-4 border-0"
                        >
                            <Card.Header className="consulting-title border-0 bg-light p-0 mb-0">
                                <Card.Title className='text-secondary'>Active Assessment</Card.Title>
                            </Card.Header>
                            <Card.Body className="pt-1 px-0">
                                <Card.Text className='card-text'>
                                    Includes a passive assessment, plus we'll negotiate on your behalf, ensuring you have what you need with the best pricing.
                                </Card.Text>
                                <Button variant="info" onClick={() => setShowActiveModal(true)}>Details</Button>
                                <ModalSimple showModal={showActiveModal} showCallback={setShowActiveModal}
                                    title="Active Assessment"
                                    body={<div>
                                        <ul>
                                            <li className='bullet-point'><strong>Vendor Estimate Review: </strong>We'll review your estimate with you via email, phone, or virtual meeting.</li>
                                            <li className='bullet-point'><strong>Guidance &amp; Recomendations: </strong>We'll offer guidance on equipment, labor requirements, power requirements, and pricing.</li>
                                            <li className='bullet-point'><strong>Vendor Negotiation: </strong>We're highly skilled at working with Event Technology providers. We'll reach out to the vendor on your behalf to apply our recomended revisions, as well as help reduce your estimate cost.</li>

                                            <li className='bullet-point'><strong>Pricing: </strong> Tiered pricing starting at $150 or 5% of the reviewed estimate cost, under $7,500. Whichever is&nbsp;
                                                <OverlayTrigger placement="top" overlay={<Tooltip style={{ maxWidth: "max-content", background: "none" }} id="tooltip-top">We always select the most cost effective fee schedule to minimize your consulting cost.</Tooltip>}>
                                                    <div className='d-inline'>
                                                        <div className='text-secondary bold underline-wavy d-inline'>lower.</div><br />
                                                    </div>
                                                </OverlayTrigger>
                                                <a href='/Contact?select=Consulting'>Contact us</a> for additional pricing tiers over $7,500.</li>
                                        </ul>
                                        <muted className='italic'><small>* Minimum fee of $50</small></muted>
                                    </div>
                                    }
                                />
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
                <Col className='mb-3 mb-md-0'>
                    <div className="d-flex justify-content-center">
                        <Card
                            bg="light"
                            text="dark"
                            style={{ width: '18rem' }}
                            className="mt-4 border-0"
                        >
                            <Card.Header className="consulting-title border-0 bg-light p-0 mb-0">
                                <Card.Title className='text-secondary'>RFP Management</Card.Title>
                            </Card.Header>
                            <Card.Body className="pt-1 px-0">
                                <Card.Text className='card-text'>
                                    Give us your needs, we'll work with your vendor (or send out RFPs) on your behalf. Ensures you're recieving the best value and most effective solution.
                                </Card.Text>
                                <Button variant="info" onClick={() => setShowRFPModal(true)}>Details</Button>
                                <ModalSimple showModal={showRFPModal} showCallback={setShowRFPModal}
                                    title="RFP Management"
                                    body={<div>
                                        <ul>
                                            <li className='bullet-point'><strong>Needs Assessment: </strong>We'll discuss your needs with you via email, phone, or virtual meeting.</li>
                                            <li className='bullet-point'><strong>Guidance &amp; Recomendations: </strong>We'll offer guidance on equipment, labor requirements, power requirements, and pricing.</li>
                                            <li className='bullet-point'><strong>Request For Proposal: </strong>We're highly skilled at working with Event Technology providers. We'll reach out to vendors on your behalf for proposals, present them to you, and give our recomendation on which you should select.</li>

                                            <li className='bullet-point'><strong>Pricing: </strong> Tiered pricing starting at $300 or 5% of the reviewed estimate cost, under $10,000. Whichever is&nbsp;
                                                <OverlayTrigger placement="top" overlay={<Tooltip style={{ maxWidth: "max-content", background: "none" }} id="tooltip-top">We always select the most cost effective fee schedule to minimize your consulting cost.</Tooltip>}>
                                                    <div className='d-inline'>
                                                        <div className='text-secondary bold underline-wavy d-inline'>lower.</div><br />
                                                    </div>
                                                </OverlayTrigger>
                                                <a href='/Contact?select=Consulting'>Contact us</a> for additional pricing tiers over $10,000.</li>
                                        </ul>
                                        <muted className='italic'><small>* Minimum fee of $100 per vendor</small></muted>
                                    </div>
                                    }
                                />
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
                <Col className='mb-3 mb-md-0'>
                    <div className="d-flex justify-content-center">
                        <Card
                            bg="light"
                            text="dark"
                            style={{ width: '18rem' }}
                            className="mt-4 border-0"
                        >
                            <Card.Header className="consulting-title border-0 bg-light p-0 mb-0">
                                <Card.Title className='text-secondary'>Independent Sourcing</Card.Title>
                            </Card.Header>
                            <Card.Body className="pt-1 px-0">
                                <Card.Text className='card-text'>
                                    We'll help you determine your needs and use our sources to aquire your equipment and labor. Let us eliminate the middle man, returning the savings to you.
                                </Card.Text>
                                <Button variant="info" onClick={() => setShowSourcingModal(true)}>Details</Button>
                                <ModalSimple showModal={showSourcingModal} showCallback={setShowSourcingModal}
                                    title="Independent Sourcing"
                                    body={<div>
                                        <ul>
                                            <li className='bullet-point'><strong>Needs Assessment: </strong>We'll discuss your needs with you via email, phone, or virtual meeting.</li>
                                            <li className='bullet-point'><strong>Guidance &amp; Recomendations: </strong>We'll offer guidance on equipment, labor requirements, power requirements, and pricing.</li>
                                            <li className='bullet-point'><strong>Direct Equipment &amp; Labor: </strong>We have contacts with direct suppliers of equipment and labor resources, cutting out the middleman. We'll compile an estimate for you, direct from suppliers, at a reduced cost from what you'd ordinarily receive from an Event Technology vendor or in-house provider.</li>

                                            <li className='bullet-point'><strong>Pricing: </strong> Tiered pricing starting at $500 or 5% of the reviewed estimate cost, under $15,000. Whichever is&nbsp;
                                                <OverlayTrigger placement="top" overlay={<Tooltip style={{ maxWidth: "max-content", background: "none" }} id="tooltip-top">We always select the most cost effective fee schedule to minimize your consulting cost.</Tooltip>}>
                                                    <div className='d-inline'>
                                                        <div className='text-secondary bold underline-wavy d-inline'>lower.</div><br />
                                                    </div>
                                                </OverlayTrigger>
                                                <a href='/Contact?select=Consulting'>Contact us</a> for additional pricing tiers over $15,000.</li>
                                        </ul>
                                        <muted className='italic'><small>* Minimum fee of $300</small></muted>
                                    </div>
                                    }
                                />
                            </Card.Body>
                        </Card>
                    </div>
                </Col>

            </Row>
        </Container>
    )
}

export default EventTech
