import React, { useState, useEffect } from 'react'
import { Container, Button, Form } from 'react-bootstrap'
import { Redirect } from 'react-router';
import ServerFeedback, { logRequest } from '../../functions/ServerFeedback';
import { genValidationObject, getEmptyMessages, validateInput } from '../../functions/library';
import { useAPI, useAPIResult } from '../../hooks/useAPI';
import EndpointToken from '../../classes/EndpointToken';
import { REGISTER_ENDPOINT } from '../../functions/siteDefaults';
import TextInput from './components/parts/input/TextInput';
import CheckBoxInput from './components/parts/input/CheckBoxInput';
import { HyperLink } from './components/parts/HyperLink';
import './scss/register.scss';


function Register() {
    const PAGENAME = "Register_Page";
    const [busy, request, call] = useAPI(PAGENAME);
    const [callID, setCallID] = useState(0);
    const [apiLoading, error, response, complete] = useAPIResult(callID);
    const [messages, setMessages] = useState(getEmptyMessages());
    const [loading, setLoading] = useState(false);
    const [isRedirected, setisRedirected] = useState(false);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [termsAccept, setTermsAccept] = useState(false);

    const setters = {
        userName: setUserName,
        password: setPassword,
        repeatPassword: setRepeatPassword,
        termsAccept: setTermsAccept,
    }

    useEffect(() => {
        let mounted = true;
        if (mounted && isRedirected) {
            <Redirect push to="/Login" />
        }
        return () => mounted = false;
    }, [isRedirected]);

    useEffect(() => {
        let mounted = true;
        if (!apiLoading && complete && mounted) {
            setLoading(false);
            setMessages(response.messages);
            logRequest(response.messages);
            if (!error) {
                setTimeout(() => {
                    setisRedirected(true);
                }, 1000);
            }
        }
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line
    }, [apiLoading, complete, busy])

    const handleInput = (input) => {
        let validation = genValidationObject(null, { runValidation: true });
        validation = genValidationObject(validation, {
            runValidation: true,
            field: {
                key: input.name,
                rule: input.validationType,
                fieldResponse: `That character is not permitted for your ${input.description}.`
            }
        });
        validateInput(validation, { name: input.name, value: input.value }, setters[input.name], setMessages);
    }


    const handleRegister = () => {
        if (loading) return;
        setLoading(true);
        const registerEndpoint = new EndpointToken(REGISTER_ENDPOINT, { userName, password, repeatPassword, termsAccept });
        const Apicall = request(registerEndpoint, PAGENAME);
        const id = call(Apicall);
        setCallID(id);
        setMessages({
            ...getEmptyMessages(),
            form: { 0: "Submitted, please wait..." }
        });
    }

    return (

        isRedirected ? <Redirect push to="/Login" /> :

            <Container className="main-container py-5 m-0 bg-dark" fluid>
                <div className="d-flex justify-content-center align-items-center" style={{ height: "75vh" }}>

                    <div className="m-0 p-4 bg-light rounded rounded-3">
                        <h1 className="h1-title">Private Registration</h1>
                        <h2 className="h2-title">All new accounts must be pre-approved</h2>
                        <hr />
                        <Form >
                            <TextInput
                                className=""
                                inputOptions={{
                                    useLabel: true,
                                    inputLabel: "Email address"
                                }}
                                htmlType="email"
                                autoComplete="username"
                                inputName="userName"
                                feedbackDescription="Email Address"
                                validationType="TEXT_SINGLE_LINE"
                                inputValue={userName}
                                inputFunction={handleInput}
                                messages={messages}
                            />
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>

                            <TextInput
                                className="mt-3"
                                inputOptions={{
                                    useLabel: true,
                                    inputLabel: "Password"
                                }}
                                htmlType="password"
                                inputName="password"
                                feedbackDescription="New Password"
                                validationType="PASSWORD"
                                inputValue={password}
                                inputFunction={handleInput}
                                messages={messages}
                            />
                            <TextInput
                                className=""
                                inputOptions={{
                                    useLabel: true,
                                    inputLabel: "Repeat Password"
                                }}
                                htmlType="password"
                                inputName="repeatPassword"
                                feedbackDescription="Repeat Password"
                                validationType="PASSWORD"
                                inputValue={repeatPassword}
                                inputFunction={handleInput}
                                messages={messages}
                            />


                            <CheckBoxInput
                                className="mt-2"
                                inputOptions={{
                                    useLabel: true,
                                    inputLabel: "Terms & Conditions",
                                    trueFalseSubstitute: { true: "Terms Accepted", false: "Please accept terms." }
                                }}
                                inputName="termsAccept"
                                feedbackDescription="Acceptance of site terms"
                                inputValue={termsAccept}
                                inputFunction={handleInput}
                                messages={messages}
                            />

                            <HyperLink
                                className="italic"
                                text="See what our terms and conditions entail"
                                isInternal={true}
                                address="/Terms"
                            />



                            <div className="d-flex justify-content-lg-start mt-3">
                                <Button variant="primary" type="button" onClick={handleRegister}>
                                    Register
                                </Button>
                                <ServerFeedback
                                    className="ms-2"
                                    messages={messages}
                                    type="form"
                                    fieldName=""
                                    success={messages.success}
                                    loading={loading}
                                    formName={PAGENAME}
                                    active={PAGENAME}
                                    ignoreText=""
                                />
                            </div>

                        </Form>
                    </div>
                </div>
            </Container>

    )
}

export default Register
