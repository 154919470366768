import React, { useState, useRef, useEffect } from 'react';
import Cropper from "react-cropper";
import 'cropperjs/dist/cropper.min.css';
import './imageCrop.scss';
import { getType, isset, is_string } from '../functions/library';
import { Col, Button, Row, Container } from 'react-bootstrap';

//TODO: Build out this component correctly so it works properly and possibly takes a callback for the preview. Make the main circle the preview window.

const ImageCrop = ({ src, srcMime, previewCallback, applyHandler, cancelHandler, readyHandler, className }) => {
    const [currentPreviewData, setCurrentPreviewData] = useState("");
    const [originalImage, setOriginalImage] = useState(null);
    const [show, setShow] = useState(true);
    const [ready, setReady] = useState(false);
    const cropperRef = useRef(null);

    const onCrop = () => {
        const imageElement = cropperRef.current;
        const cropper = imageElement.cropper;
        setCurrentPreviewData(cropper.getCroppedCanvas().toDataURL(srcMime));
    };

    //Set original source image for later reset if needed
    useEffect(() => {
        let mounted = true;
        if (is_string(src) && src.length && !isset(originalImage)) {
            if (mounted) setOriginalImage(src);
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [src]);

    //Handles the preview callback - sending data back to the caller on each change
    useEffect(() => {
        let mounted = true;
        if (is_string(currentPreviewData) && currentPreviewData.length) {
            if (mounted) previewCallback({ name: "preview", value: currentPreviewData })
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [currentPreviewData]);

    //Calls the readyHandler and sends the current ready state
    useEffect(() => {
        let mounted = true;
        if (mounted && isset(ready)) {
            if (getType(readyHandler, "function")) readyHandler(ready);
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [ready]);

    function handleRotate(direction) {
        const cropper = cropperRef.current.cropper;
        let degree = 45;
        if (direction === "left") degree = degree * -1;
        cropper.rotate(degree);
    }

    const handleSave = () => {
        const cropper = cropperRef.current.cropper;
        if (getType(applyHandler, "function")) {
            applyHandler({
                name: "apply",
                value: cropper.getCroppedCanvas().toDataURL(srcMime)
            });
        }
        cropper.destroy();
        setShow(false);
    }

    const handleReset = () => {
        const cropper = cropperRef.current.cropper;
        cropper.replace(originalImage);
    }

    const handleCancel = () => {
        const cropper = cropperRef.current.cropper;
        cropper.destroy();
        if (getType(cancelHandler, "function")) {
            cancelHandler({
                name: "cancel",
                value: originalImage
            });
        }
        setShow(false);
    }

    return (
        show ?
            <Container className={`cropper-main ${className}`} fluid>
                <Row >
                    <Col>
                        <div className="d-flex justify-content-end mb-1">
                            <Button className="mx-2" variant="primary" onClick={handleSave}>Apply</Button>
                            <Button className="ms-1" variant="warning" onClick={handleReset}>Reset</Button>
                            <Button className="ms-1" variant="outline-danger" onClick={handleCancel}>Cancel</Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Cropper
                            src={src}
                            style={{ width: "100%" }}
                            // Cropper.js options
                            responsive={true}
                            aspectRatio={1}
                            guides={false}
                            crop={onCrop}
                            ready={() => setReady(true)}
                            ref={cropperRef}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-1">
                        <Button className="mx-3" variant="success" onClick={() => handleRotate("left")}><i className="fas fa-undo"></i></Button>

                        <Button className="mx-3" variant="success" onClick={() => handleRotate("right")}><i className="fas fa-undo fa-flip-horizontal"></i></Button>
                    </Col>
                </Row>

            </Container>
            :
            <React.Fragment />

    );
}

export default ImageCrop
