import React, { useState, useEffect } from 'react';
import { InputGroup, Form, Spinner } from 'react-bootstrap';
import ServerFeedback from '../../../../../functions/ServerFeedback';
import { convertFileSize, getEmptyMessages, getRandomNum, inString, isset, is_string, MyDate } from '../../../../../functions/library';



const FileInput = ({ className, inputOptions, inputType, inputName, isLoading, inputFunction, toggleReset, messages, setMessages, acceptTypes }) => {
    const { useLabel, inputText1, inputText2, inputLabel, text1Wrap, text1MaxWidth, text1FontSize, text1LineHeight } = inputOptions;

    const pageId = getRandomNum(100000000, 999999999);
    const textSuccess = "green";
    const textFailed = "red";
    const shadowColor = "black";
    const [selectedFile, setSelectedFile] = useState(null);
    const [chooseFile, setChooseFile] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [fileSize, setFileSize] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [fileReader, setFileReader] = useState(null);
    const [fileLastModDate, setFileLastModDate] = useState(null);
    const [whiteSpace1, setWhiteSpace1] = useState("nowrap");
    const [maxWidth1, setMaxWidth1] = useState("100vw");
    const [fontSize1, setFontSize1] = useState("100%");
    const [lineHeight1, setLineHeight1] = useState("150%");
    const [accept, setAccept] = useState(".csv, .txt");
    const [resetInput, setResetInput] = useState(toggleReset);


    useEffect(() => {
        let mounted = true;
        if (mounted && isset(text1Wrap) && text1Wrap !== false) {
            setWhiteSpace1("normal");
        }
        if (mounted && isset(text1MaxWidth)) {
            setMaxWidth1(text1MaxWidth);
        }

        if (mounted && isset(text1FontSize)) {
            setFontSize1(text1FontSize);
        }

        if (mounted && isset(text1LineHeight)) {
            setLineHeight1(text1LineHeight);
        }

        if (mounted && isset(acceptTypes)) {
            setAccept(acceptTypes);
        }

        return () => {
            mounted = false;
        }
    }, [text1Wrap, text1MaxWidth, text1FontSize, text1LineHeight, acceptTypes])


    const handleInputState = (event) => {
        if (isset(event.target.files)) {
            if (inString(event.target.files[0].name, ".")) {
                const ext = event.target.files[0].name.split(".")[1].toLowerCase();
                if (!accept.split(",").includes(`.${ext}`)) {
                    setMessages(getEmptyMessages("fields", `${inputName}|That file type is not supported.`), 5000);
                    return;
                }
            }
            setSelectedFile(event.target.files[0]);
        }
    }

    const loadFile = (event) => {
        if (event.type === "loadstart") setFileLoading(true);
        if (event.type === "loadend") {
            setFileLoading(false);
            setFileData(event.target.result)
        }
    }

    //FIle Dialog has been opened, wait for user input.
    useEffect(() => {
        let mounted = true;
        if (selectedFile && mounted) handleFileLoadRequest(selectedFile);
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line
    }, [selectedFile]);

    //Cancel file load
    useEffect(() => {
        let mounted = true;
        if (resetInput !== toggleReset && mounted) {
            if (fileReader) fileReader.abort();
            setFileName(null);
            setFileSize(null);
            setFileType(null);
            setFileData(null);
            setFileReader(null);
            setSelectedFile(null)
            setChooseFile(false);
            setFileLoading(false);
            setResetInput(toggleReset);
        }
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line
    }, [toggleReset]);

    useEffect(() => {
        let mounted = true;
        if (fileData && mounted) {
            inputFunction({
                name: inputName,
                value: {
                    fileData: fileData,
                    fileName: fileName,
                    fileSize: fileSize,
                    fileType: fileType,
                    fileLastModDate: fileLastModDate
                }
            });
        }
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line
    }, [fileData]);

    const handleFileLoadRequest = (thisFile) => {
        isLoading(true);
        const fileReader = new FileReader();
        fileReader.onloadstart = loadFile;
        fileReader.onprogress = loadFile;
        fileReader.onloadend = loadFile;
        fileReader.onload = loadFile;
        if ((is_string(inputType)) && inputType.toUpperCase() === "IMAGE") {
            fileReader.readAsDataURL(thisFile);
        } else {
            fileReader.readAsText(thisFile);
        }

        if (pageId) {
            setFileReader(fileReader);
            setFileName(thisFile.name);
            setFileType(thisFile.type);
            setFileSize(thisFile.size);
            setFileLastModDate(thisFile.lastModifiedDate);
            isLoading(false);
        }
    }

    const handleChooseFile = (e) => {
        const type = e.type;
        if (!fileName) {
            switch (type) {
                case "mouseover":
                    setChooseFile(true);
                    break;
                case "mouseout":
                    setChooseFile(false);
                    break;

                default:
                    break;
            }
        }
    }

    return (
        <div className={className}>
            <InputGroup >
                <div className="d-flex w-100" onMouseOver={(e) => handleChooseFile(e)}
                    onMouseOut={(e) => handleChooseFile(e)}
                >
                    <div name="file_input-label" >
                        {
                            !fileName ?
                                !useLabel ? <div>
                                    {
                                        inputText2 ? <InputGroup.Text>{inputText2}</InputGroup.Text> : <div></div>
                                    }
                                    <InputGroup.Text style={{ fontWeight: "bold", whiteSpace: whiteSpace1, maxWidth: maxWidth1, fontSize: fontSize1, lineHeight: lineHeight1 }}>{inputText1}</InputGroup.Text> </div> :
                                    <Form.Label className="w-100 mb-0 pb-1">{inputLabel}</Form.Label>
                                :
                                <InputGroup.Text style={{ fontWeight: "bold", whiteSpace: whiteSpace1, maxWidth: maxWidth1, fontSize: fontSize1, lineHeight: lineHeight1 }}>File Selected</InputGroup.Text>
                        }
                    </div>
                    <div name="file_input-utility" style={{
                        //   textOverflow: 'scroll',
                        overflow: 'hidden'
                    }}>
                        {
                            !fileName ?
                                <React.Fragment> {
                                    chooseFile ?
                                        <input title=" " style={{ color: "transparent" }} id="file_input" type="file" accept={accept}
                                            onChange={e => handleInputState(e)} />
                                        :
                                        <InputGroup.Text  >No file chosen</InputGroup.Text>
                                } </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className="bg-white input-group-text" style={{ overflowX: "auto" }}>{fileName}</div>
                                </React.Fragment>
                        }


                    </div>

                </div>
                {
                    fileName ? <div> <div className="d-flex" style={{ fontSize: "80%" }}>
                        <div className="text-muted italic p-1 pe-2"><strong>Size: </strong>{convertFileSize(fileSize)}</div>
                        <div className="text-muted italic p-1 ps-2"><strong>Last Modified: </strong>{new MyDate(fileLastModDate).string}</div>
                    </div></div> : <React.Fragment />
                }

                {fileLoading ? <Spinner animation="border" role="status" /> : <div></div>}

            </InputGroup>
            <ServerFeedback
                messages={messages}
                type="fields"
                fieldName={inputName}
                success={messages.success}
                loading={fileLoading}
                formName={inputType}
                active={inputType}
                shadow={shadowColor}
                textSuccess={textSuccess}
                textFailed={textFailed}
                ignoreText=""
            />

        </div>
    )
}

export default FileInput
