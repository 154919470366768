import React, { useState, useEffect } from 'react';
import { InputGroup, Form, Dropdown } from 'react-bootstrap';
import ServerFeedback from '../../../../../functions/ServerFeedback';
import { objectsAreEqual, isset, isIndexOf } from '../../../../../functions/library';

/**
 * 
 * @param {String} className The class or classes to be applied to the primary div
 * @param {Object} inputOptions Object containing: useLabel, inputText1, inputText2, inputLabel, DDSText, DDSItems, DDSIgnoreText, text1Wrap, text1MaxWidth, text1FontSize, text1LineHeight
 * @param {string} inputType The switch label name for the modal or group the input belongs to
 * @param {string} inputName The name of the input to applied to the name attribute
 * @param {string} inputValue The input's value - this is usually the state
 * @param {function} inputFunction The callback function for the onChange attribute - this is usually setState
 * @param {Object} messages The messages Object to be used in ServerFeedback
 * @returns JSX Input Control group 
 */
const DropdownList = ({ className, inputOptions, inputType, inputName, inputValue, inputFunction, messages, clearSelected }) => {
    const { useLabel, inputText1, inputText2, inputLabel, DDSItems, DDSIgnoreText, DDSFirstSelect, text1Wrap, text1MaxWidth, text1FontSize, text1LineHeight, menuFontSize, menuMaxHeight } = inputOptions;


    const textSuccess = "green";
    const textFailed = "red";
    const shadowColor = "black";
    const IGNORE_TEXT = DDSIgnoreText ? DDSIgnoreText : "Choose...";
    const [selectedDDSOption, setSelectedDDSOption] = useState(IGNORE_TEXT);
    const [ddsDisplayList, setDdsDisplayList] = useState(DDSItems);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [menuHeightMax, setMenuHeightMax] = useState("100vh")
    const [menuScrollable, setMenuScrollable] = useState(false);
    const [whiteSpace1, setWhiteSpace1] = useState("nowrap");
    const [maxWidth1, setMaxWidth1] = useState("100vw");
    const [fontSize1, setFontSize1] = useState("100%");
    const [ddsFontSize, setDdsFontSize] = useState("1em");
    const [lineHeight1, setLineHeight1] = useState("150%");
    const [resetToggle, setResetToggle] = useState("");


    useEffect(() => {
        let mounted = true;
        if (mounted && isset(text1Wrap) && text1Wrap !== false) {
            setWhiteSpace1("normal");
        }
        if (mounted && isset(text1MaxWidth)) {
            setMaxWidth1(text1MaxWidth);
        }

        if (mounted && isset(text1FontSize)) {
            setFontSize1(text1FontSize);
        }

        if (mounted && isset(text1LineHeight)) {
            setLineHeight1(text1LineHeight);
        }

        if (mounted && isset(menuFontSize)) {
            setDdsFontSize(menuFontSize);
        }

        if (mounted) {
            if (!isset(menuMaxHeight)) setMenuScrollable(false);
            if (isset(menuMaxHeight)) {
                setMenuHeightMax(menuMaxHeight);
                setMenuScrollable(true);
            }
        }
        return () => {
            mounted = false;
        }
    }, [text1Wrap, text1MaxWidth, text1FontSize, text1LineHeight, menuMaxHeight])


    const handleDDS1Select = (e) => {
        const selectedItem = e.target.text;
        if (selectedItem !== IGNORE_TEXT) {
            setSelectedDDSOption(selectedItem);
            inputFunction({ name: inputName, value: selectedItem });
            setMenuIsOpen(false);
        }
    }




    const ddsToggle = (e) => {
        setMenuIsOpen(!menuIsOpen);
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (!ddsDisplayList) {
                setDdsDisplayList(DDSItems);
            }
            if (!objectsAreEqual(DDSItems, ddsDisplayList)) {
                setDdsDisplayList(DDSItems);
            }
        }
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line
    }, [DDSItems])


    useEffect(() => {
        let mounted = true;
        if (mounted && isIndexOf(Object.values(ddsDisplayList), DDSFirstSelect)) {
            setSelectedDDSOption(DDSFirstSelect);
            inputFunction({ name: inputName, value: DDSFirstSelect });
            setMenuIsOpen(false);
        }
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let mounted = true;
        if (mounted && clearSelected !== resetToggle) {
            setResetToggle(clearSelected);
            setSelectedDDSOption("");
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [clearSelected]);

    useEffect(() => {
        let mounted = true;
        //Added to ensure option shows as selected when input value changes externally.
        if (mounted && isset(inputValue) && inputValue !== DDSIgnoreText && inputValue !== selectedDDSOption) {
            setSelectedDDSOption(inputValue);
        }

        return () => {
            mounted = false;
        }
        // eslint-disable-next-line
    }, [inputValue])


    return (
        <div className={className}>
            <InputGroup
                style={{
                    display: "flex", width: "max-content"
                }}
            >
                <div className="d-flex align-items-center">
                    {
                        !useLabel ? <React.Fragment>
                            {
                                inputText2 ? <InputGroup.Text>{inputText2}</InputGroup.Text> : <React.Fragment />
                            }
                            <InputGroup.Text style={{ fontWeight: "bold", whiteSpace: whiteSpace1, maxWidth: maxWidth1, fontSize: fontSize1, lineHeight: lineHeight1 }}>{inputText1}</InputGroup.Text> </React.Fragment> :
                            <Form.Label className="w-100 mb-0 pb-1">{inputLabel}</Form.Label>
                    }

                </div>

                <Dropdown
                    onToggle={e => ddsToggle(e)}
                    show={menuIsOpen}

                >
                    <Dropdown.Toggle
                        className="px-2 mb-1 pb-1"
                        variant="secondary"
                        id="input-group-dropdown-2"
                        align="end"
                        size="sm"
                        style={{
                            fontSize: ddsFontSize,
                        }}
                    >
                        {inputValue}
                        {/* selectedDDSOption !== IGNORE_TEXT ? selectedDDSOption : "" */}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        style={{
                            maxHeight: menuHeightMax,
                            overflowY: menuScrollable ? "scroll" : "auto",
                        }}
                    >
                        {Object.values(ddsDisplayList).map((value, index) => {
                            return <Dropdown.Item id={`${inputName}_DDS_${index}`} active={selectedDDSOption === value ? true : false} onClick={e => handleDDS1Select(e)} eventKey={value} key={index} style={{ fontSize: ddsFontSize }}>{value}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>

                </Dropdown>

            </InputGroup>
            <ServerFeedback
                messages={messages}
                type="fields"
                fieldName={inputName}
                success={messages.success}
                loading={false}
                formName={inputType}
                active={inputType}
                shadow={shadowColor}
                textSuccess={textSuccess}
                textFailed={textFailed}
                ignoreText=""
            />

        </div>
    )
}

export default DropdownList

