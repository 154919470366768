import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import ImageCrop from '../../../../../components/ImageCrop'
import { genValidationObject, isset, is_string, propExists, validateInput, VALIDATE_TEXT_SINGLE_LINE } from '../../../../../functions/library'
import ProfileImage from '../../../../header/parts/ProfileImage'
import EmptyPanel from '../../../components/EmptyPanel'
import FileInput from '../../../components/parts/input/FileInput';
import useToggle from '../../../../../hooks/useToggle';
import './editProfileImage.scss';

import emptyProfileImage from '../../../../../images/user.png';
import TextInput from '../../../components/parts/input/TextInput'
import SiteImage from '../../../../../classes/SiteImage'
import { useMessages } from '../../../../../hooks/useAPI'

const EditProfileImage = ({ className, changesAreSaved, setChangesAreSaved, currentProfile, setCurrentProfile }) => {
    const [profileImage, setProfileImage] = useState(emptyProfileImage);
    const [originalImage, setOriginalImage] = useState(emptyProfileImage);
    const [displayedImage, setDisplayedImage] = useState(profileImage);
    const [fileName, setFileName] = useState(propExists(currentProfile, "userProfilePhotoFileName", ""));
    const [fileMime, setFileMime] = useState(propExists(currentProfile, "profilePhotoMime", ""))
    const [newImageLoaded, setNewImageLoaded] = useState(false);
    const [cropperReady, setCropperReady] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [cancelImageLoad, toggleCancelImageLoad] = useToggle(false);
    const [loadedUserDisplayName, setLoadedUserDisplayName] = useState(propExists(currentProfile, "userDisplayName", ""));
    const [userDisplayName, setUserDisplayName] = useState(loadedUserDisplayName);
    const [messages, setMessages] = useMessages();


    useEffect(() => {
        let mounted = true;
        if (mounted && profileImage === emptyProfileImage && currentProfile.profilePhoto && !newImageLoaded) {
            setProfileImage(currentProfile.profilePhoto);
            setDisplayedImage(currentProfile.profilePhoto);
            if (originalImage === emptyProfileImage) setOriginalImage(currentProfile.profilePhoto);
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [profileImage, currentProfile.profilePhoto, newImageLoaded]);


    useEffect(() => {
        let mounted = true;
        if (newImageLoaded) {
            if (mounted) toggleCancelImageLoad();
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [loadedUserDisplayName, userDisplayName, displayedImage, originalImage, newImageLoaded]);

    useEffect(() => {
        let mounted = true;
        if (changesAreSaved && mounted) {
            setLoadedUserDisplayName(propExists(currentProfile, "userDisplayName", loadedUserDisplayName));
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [changesAreSaved]);

    const handleUserDisplayName = (data) => {
        if (!isset(data)) return;
        const validation = genValidationObject({}, {
            runValidation: true,
            field: data.name,
            rule: VALIDATE_TEXT_SINGLE_LINE,
        });
        //Validates and sets the value
        if (validateInput(validation, data, setUserDisplayName, setMessages)) {
            //  setChangesAreSaved(false);
            setCurrentProfile(
                (curProfile) => {
                    return {
                        ...curProfile,
                        userDisplayName: data.value
                    }
                }
            )
            return;
        }
    }

    const handlePreview = (data) => {
        setDisplayedImage(data.value);
    }

    const handleApplyImage = (data) => {
        if (!cropperReady) return;
        setChangesAreSaved(false);
        setDisplayedImage(data.value);
        const ProfileImage = new SiteImage("profilePhoto", { fullImageString: displayedImage });
        setCurrentProfile(
            (curProfile) => {
                return {
                    ...curProfile,
                    profilePhoto: data.value,
                    profilePhotoMime: ProfileImage.imageMime,
                    userProfilePhotoFileName: fileName
                }
            }
        )
    }

    const loadNewImage = (e) => {
        if (e.value.fileData) {
            setProfileImage(e.value.fileData);
            setDisplayedImage(e.value.fileData);
            setFileMime(e.value.fileType);
            setFileName(e.value.fileName);
            setNewImageLoaded(true);
        }
    }

    const cancelFileLoad = (e) => {
        console.log(e);
        setNewImageLoaded(false);
        toggleCancelImageLoad();
        if (is_string(currentProfile.profilePhoto) && currentProfile.profilePhoto.length) {
            setDisplayedImage(currentProfile.profilePhoto);
            return;
        }
        setDisplayedImage(emptyProfileImage);
    }

    return (
        <div className={className}>
            <EmptyPanel
                classMainIn="widget-nohover w-100 my-2"
                title="Public Info"
            >

                {changesAreSaved ? <React.Fragment /> : <div className="unsaved-alert">* Your changes are not saved *</div>}

                <Container fluid>
                    <Row>
                        <Col xs={{ span: 12, order: "last" }} lg={{ span: 5, order: "first" }} >
                            <div className="d-flex justify-content-center">
                                <div className="image-frame">
                                    <div className="image-header">Profile Image</div>
                                    {(newImageLoaded && !cropperReady) || imageLoading ?
                                        <Spinner animation="border" role="status" />
                                        :
                                        <ProfileImage className="p-0 m-0" image={displayedImage} size="4xl" />
                                    }

                                </div>
                            </div>
                        </Col>
                        <Col xs={12} lg={7} >
                            <p className="italic m-2">Your display name and profile image are your public identity on the site. Other users DO NOT have access to your real first and last name, address, phone, and email.</p>
                            <TextInput
                                className="p-2 mt-lg-3"
                                inputOptions={{
                                    inputText1: "Display Name",
                                    text1MinWidth: "min-content"
                                }}
                                htmlType="text"
                                autoComplete="nickname"
                                inputName="userDisplayName"
                                inputValue={userDisplayName}
                                inputFunction={(e) => handleUserDisplayName(e)}
                                messages={messages}
                                feedbackDescription="Display Name"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className="upload-frame">
                                <FileInput
                                    className={""}
                                    inputOptions={{
                                        inputText1: "Upload New"
                                    }}
                                    inputName={"profilePhoto"}
                                    inputType="image"
                                    isLoading={setImageLoading}
                                    inputFunction={(e) => loadNewImage(e)}
                                    toggleReset={cancelImageLoad}
                                    messages={messages}
                                    setMessages={setMessages}
                                    acceptTypes=".jpg, .png, .gif, .png"
                                />
                                {newImageLoaded ?
                                    <ImageCrop
                                        src={profileImage}
                                        srcMime={fileMime}
                                        previewCallback={handlePreview}
                                        applyHandler={(e) => handleApplyImage(e)}
                                        cancelHandler={(e) => cancelFileLoad(e)}
                                        readyHandler={(e) => setCropperReady(e)}
                                    />
                                    :
                                    <React.Fragment />}
                            </div>
                        </Col>

                    </Row>

                </Container>

            </EmptyPanel>
        </div>
    )
}

export default EditProfileImage
