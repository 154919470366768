import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import EndpointToken from '../../classes/EndpointToken'
import { arrayToObject, genValidationObject, getEmptyMessages, getRandomID, inString, validateInput, validateInputs, VALIDATE_EMAIL_ADDRESS_SINGLE, VALIDATE_LETTERS, VALIDATE_LIST, VALIDATE_NOTBLANK, VALIDATE_PHONE_NUMBER, VALIDATE_TEXT_MULTI_LINE, VALIDATE_TEXT_SINGLE_LINE } from '../../functions/library'
import ServerFeedback, { logRequest } from '../../functions/ServerFeedback'
import { CONTACT_ENDPOINT } from '../../functions/siteDefaults'
import { useAPI, useAPIResult } from '../../hooks/useAPI'
import useScrollReset from '../../hooks/useScrollReset'
import DropdownList from './components/parts/input/DropdownList'
import TextInput from './components/parts/input/TextInput'
import TextInputMulti from './components/parts/input/TextInputMulti'
import './scss/contact.scss'

function Contact() {
    const PAGENAME = "Contact_Page";
    const [busy, request, call] = useAPI(PAGENAME);
    const [callID, setCallID] = useState(0);
    const [apiLoading, error, response, complete] = useAPIResult(callID);
    const location = useLocation();
    const IGNORE_TEXT = "Choose...";
    const [messages, setMessages] = useState(getEmptyMessages());
    const [loading, setLoading] = useState(false);
    const [contactFullName, setName] = useState("");
    const [contactEmail, setEmail] = useState("");
    const [contactMobile, setMobile] = useState("");
    const [contactSubject, setSubject] = useState(IGNORE_TEXT);
    const [contactMessage, setContactMessage] = useState("");
    const [ddsOptions] = useState([
        "Audio Visual", "Billing", "Customer Inquiry", "Consulting", "Employment", "General Question", "RFP Submission", "Site Inspection", "Support", "Web Development",
    ]);
    const [messageIsSent, setMessageIsSent] = useState(false);
    useScrollReset();

    const pageId = getRandomID(16);

    const setters = {
        contactFullName: setName,
        contactEmail: setEmail,
        contactMobile: setMobile,
        contactMessage: setContactMessage
    }



    useEffect(() => {
        let mounted = true;
        if (inString(location.search, "select=")) {
            const toSelect = location.search.split("=")[1].split("_").map((word) => {
                return (word.charAt(0).toUpperCase() + word.slice(1));
            }).join(" ");
            if (ddsOptions.includes(toSelect)) {
                if (mounted) setSubject(toSelect);
            }
        }
        return () => mounted = false;
    }, [location.search, ddsOptions]);

    useEffect(() => {
        let mounted = true;
        if (mounted && messageIsSent) {
            setName("");
            setEmail("");
            setMobile("");
            setSubject(IGNORE_TEXT);
            setContactMessage("");
            setMessageIsSent(false);
        }
        return () => mounted = false;
    }, [messageIsSent]);

    useEffect(() => {
        let mounted = true;
        if (!apiLoading && complete && mounted) {
            setLoading(false);
            setMessages(response.messages);
            logRequest(response.messages);
            if (!error && mounted) {
                setMessageIsSent(true);
            }
        }
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line
    }, [apiLoading, complete, busy])

    const handleInput = (e) => {
        const validationObject = genValidationObject({}, { runValidation: false, field: e.name, key: e.name, rule: e.validationType });
        validateInput(validationObject, e, setters[e.name], setMessages);
    }

    const handleDDS = (e) => {
        if (e.value !== IGNORE_TEXT) {
            setSubject(e.value);
        }
    }

    const handleSubmit = () => {
        let validationObject = genValidationObject({}, { runValidation: true, field: "contactSubject", key: "contactSubject", rule: VALIDATE_LIST, ruleOption: arrayToObject(ddsOptions) });
        validationObject = genValidationObject(validationObject, { globalRule: VALIDATE_NOTBLANK });
        if (validateInputs(validationObject, { contactFullName, contactEmail, contactMobile, contactSubject, contactMessage }, setMessages)) {
            if (loading) return;
            setLoading(true);
            const registerEndpoint = new EndpointToken(CONTACT_ENDPOINT, { contactFullName, contactEmail, contactMobile, contactSubject, contactMessage });
            const Apicall = request(registerEndpoint, PAGENAME);
            const id = call(Apicall);
            setCallID(id);
            setMessages({
                ...getEmptyMessages(),
                form: { 0: "Submitted, please wait..." }
            });
        }
    }




    return (
        <Container className="main-container bg-dark mt-0" id={`login_page_${pageId}`} fluid>
            <div className="d-flex justify-content-center align-items-center">
                <div className="contact-form p-4  bg-light rounded rounded-3">
                    <Row >
                        <h1 className="text-center">Contact Us</h1>
                        <h2 className="text-center">Reach out and let us know how we can help.</h2>
                        <Col>
                            <TextInput
                                className="input-text"
                                inputOptions={{
                                    inputText1: "Full Name",
                                    text1Wrap: "",
                                    text1MaxWidth: "min-content",
                                    text1MinWidth: "max-content",
                                    text1FontSize: "1em"
                                }}
                                htmlType="text"
                                autoComplete="name"
                                validationType={VALIDATE_LETTERS}
                                inputName="contactFullName"
                                inputValue={contactFullName}
                                inputFunction={handleInput}
                                messages={messages}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextInput
                                className="input-text"
                                inputOptions={{
                                    inputText1: "Email",
                                    text1Wrap: "",
                                    text1MaxWidth: "min-content",
                                    text1MinWidth: "max-content",
                                    text1FontSize: "1em"
                                }}
                                htmlType="email"
                                autoComplete="email"
                                validationType={VALIDATE_EMAIL_ADDRESS_SINGLE}
                                inputName="contactEmail"
                                inputValue={contactEmail}
                                inputFunction={handleInput}
                                messages={messages}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextInput
                                className="input-text"
                                inputOptions={{
                                    inputText1: "Mobile Number",
                                    text1Wrap: "",
                                    text1MaxWidth: "min-content",
                                    text1MinWidth: "max-content",
                                    text1FontSize: "1em"
                                }}
                                htmlType="text"
                                autoComplete="tel-national"
                                validationType={VALIDATE_PHONE_NUMBER}
                                inputName="contactMobile"
                                inputValue={contactMobile}
                                inputFunction={handleInput}
                                messages={messages}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DropdownList
                                className="input-text"
                                inputOptions={{
                                    inputText1: "Subject",
                                    DDSText: "",
                                    DDSItems: ddsOptions,
                                    DDSIgnoreText: IGNORE_TEXT,
                                }}
                                inputType="contactSubject"
                                inputName="contactSubject"
                                inputValue={contactSubject}
                                inputFunction={handleDDS}
                                messages={messages}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextInputMulti
                                className="input-text"
                                inputOptions={{
                                    inputText1: "Message",
                                    text1MaxWidth: "min-content",
                                    text1MinWidth: "max-content",
                                }}
                                htmlType="text"
                                inputRows="4"
                                autoComplete="none"
                                validationType={VALIDATE_TEXT_MULTI_LINE}
                                inputName="contactMessage"
                                inputValue={contactMessage}
                                inputFunction={handleInput}
                                messages={messages}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ServerFeedback
                                className=""
                                messages={messages}
                                type="form"
                                success={messages.success}
                                loading={loading}
                                formName={PAGENAME}
                                active={PAGENAME}
                                textAlign=""
                                ignoreText=""

                            />
                        </Col>
                        <Col>
                            <div className="d-flex justify-content-end w-100"><Button className="" variant="primary" onClick={handleSubmit} >Send</Button></div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    )
}

export default Contact
