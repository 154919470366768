import React, { useState, useEffect, useRef } from 'react';
import { InputGroup, Form, FormControl } from 'react-bootstrap';
import ServerFeedback from '../../../../../functions/ServerFeedback';
import { propExists, getRandomNum, getType, isset, objectCopy, objectTextToKey } from '../../../../../functions/library';
import './textInput.scss';

//TODO: Consider adding validity check using https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#dom-cva-setcustomvalidity

/**
 * 
 * @param {String} className The class or classes to be applied to the primary div
 * @param {Object} inputOptions Object containing: useLabel, inputText1, inputText2, inputLabel, text1Wrap, text1MaxWidth, text1MinWidth, text1Align (left, center, right), text1FontSize
 * @param {string} inputType *Optional* The switch label name for the modal or group the input belongs to. Default: inputName 
 * @param {string} htmlType The html control type field value. Default: text
 * @param {string} autoComplete The html spec ASCII case-insensitive match allowing for browser auto complete. @link https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
 * @param {string} inputName The name of the input to applied to the name attribute
 * @param {number} inputRows The number of starting rows for the input
 * @param {string} inputValue The input's value - this is usually the state
 * @param {function} inputFunction The callback function for the onChange attribute - this is usually setState
 * @param {string} inputPlaceholder Optional Placeholder
 * @param {Object} messages The messages Object to be used in ServerFeedback
 * @param {function} infoCallBack A callback function that returns an info object on the current element including:  id, width, height, & content.
 * @param {string} feedbackDescription A user facing feedback description of the form, also used in the aria-label.
 * @param {boolean} readOnly Set to TRUE to make the input read-only, effectively disabled.
 * @returns JSX Bootstrap 5 Input Control group 
 */
const TextInputMulti = ({ className, inputOptions, inputType, htmlType, autoComplete, validationType, inputName, inputRows, inputValue, inputFunction, inputPlaceholder, messages, infoCallBack, feedbackDescription, readOnly }) => {
    const { useLabel, inputText1, inputText2, inputLabel, inputTextBack, text1Align, text1Wrap, text1MaxWidth, text1MinWidth, text1FontSize, text1LineHeight } = inputOptions;
    const textSuccess = "green";
    const textFailed = "red";
    const shadowColor = "black";
    const [whiteSpace1, setWhiteSpace1] = useState("nowrap");
    const [maxWidth1, setMaxWidth1] = useState("100vw");
    const [minWidth1, setMinWidth1] = useState("5vw");
    const [text1Alignment, setText1Alignment] = useState("flex-start");
    const [fontSize1, setFontSize1] = useState("100%");
    const [lineHeight1, setLineHeight1] = useState("150%");
    const [rows, setRows] = useState(4);
    const inputText1_ref = useRef(null);
    const inputGroup_ref = useRef(null);
    const edit_ref = useRef(null);
    const main_ref = useRef(null);
    const infoCallBackDefaultObject = { id: null, width: null, height: null, content: null };
    const refs = { main_ref, inputGroup_ref, inputText1_ref, edit_ref };

    if (!isset(inputType)) inputType = inputName;


    useEffect(() => {
        let mounted = true;
        if (mounted && isset(text1Wrap) && text1Wrap !== false) {
            setWhiteSpace1("normal");
        }
        if (mounted && isset(text1MaxWidth)) {
            setMaxWidth1(text1MaxWidth);
        }

        if (mounted && isset(text1MinWidth)) {
            setMinWidth1(text1MinWidth);
        }

        if (mounted && isset(text1FontSize)) {
            setFontSize1(text1FontSize);
        }

        if (mounted && isset(text1LineHeight)) {
            setLineHeight1(text1LineHeight);
        }

        if (mounted && isset(inputRows)) {
            setRows(inputRows);
        }

        if (mounted && isset(text1Align)) {
            let textAlign = "flex-start";
            switch (text1Align) {
                case "center":
                    textAlign = "center";
                    break;
                case "right":
                    textAlign = "flex-end";
                    break;
                default:
                    break;
            }
            setText1Alignment(textAlign);
        }


        return () => {
            mounted = false;
        }
    }, [text1Wrap, text1MaxWidth, text1FontSize, text1LineHeight, text1MinWidth, text1Align, inputFunction])



    //Return Info
    useEffect(() => {
        if (window["lastRefs"] === undefined) {
            window["lastRefs"] = {};
        }
        let mounted = true;
        // console.log(getType(infoCallBack));
        if (getType(infoCallBack, "function")) {
            const infoCallBackObject = {};
            Object.entries(refs).forEach((entry) => {
                const refName = entry[0];
                const thisElementName = `${refName.split("_")[0]}_${inputName}`;
                const thisInfo = objectCopy(infoCallBackDefaultObject);
                // eslint-disable-next-line
                const thisRef = eval(refName);

                const elementName = propExists(thisRef, "current.attributes.name.value");

                if (isset(elementName)) {
                    thisInfo.id = propExists(thisRef, "current.attributes.id.value");
                    thisInfo.width = propExists(thisRef, "current.offsetWidth");
                    thisInfo.height = propExists(thisRef, "current.offsetHeight");
                    thisInfo.content = propExists(thisRef, "current.textContent");
                    // thisInfo.element = propExists(thisRef, "current");
                    infoCallBackObject[thisElementName] = thisInfo;
                    infoCallBackObject["elementName"] = inputName;
                    infoCallBackObject["action"] = "SET";
                    if (mounted) window["lastRefs"][inputName] = infoCallBackObject;
                }
            });
            if (mounted) infoCallBack(infoCallBackObject);

        }
        return () => {
            mounted = false;
            const lastRefs = window["lastRefs"];
            if (isset(lastRefs)) {
                Object.entries(lastRefs).forEach((entry) => {
                    const name = entry[0];
                    if (lastRefs.hasOwnProperty(name)) {
                        lastRefs[name].action = "UNSET";
                    }
                });
                if (getType(infoCallBack, "function")) infoCallBack(lastRefs[inputName]);
                delete window["lastRefs"][inputName];
                if (!Object.keys(window["lastRefs"]).length) delete window["lastRefs"];
            }

        }
        // eslint-disable-next-line
    }, [inputText1_ref, inputGroup_ref, edit_ref, main_ref])


    return (
        <div className={`${className}`} ref={main_ref} name={`main_${inputName}`} id={`main_${objectTextToKey(inputText1 || getRandomNum().toString())}`}>
            <div className="d-flex w-100" ref={inputGroup_ref} name={`inputGroup_${inputName}`} id={`inputGroup_${objectTextToKey(inputText1 || getRandomNum().toString())}`}> {
                !useLabel ? <React.Fragment>
                    {
                        inputText2 ? <InputGroup.Text>{inputText2}</InputGroup.Text> : <React.Fragment />
                    }
                    {inputText1 ?
                        <InputGroup.Text ref={inputText1_ref} name={`inputText1_${inputName}`} id={`inputText1_${objectTextToKey(inputText1 || getRandomNum().toString())}`} style={{ fontWeight: "bold", whiteSpace: whiteSpace1, maxWidth: maxWidth1, minWidth: minWidth1, fontSize: fontSize1, lineHeight: lineHeight1, justifyContent: text1Alignment }}>{inputText1}</InputGroup.Text>
                        : <React.Fragment />}
                </React.Fragment> :
                    <Form.Label className="w-100 mb-0 pb-1">{inputLabel}</Form.Label>
            }

                <FormControl className="" as="textarea" rows={rows} type={htmlType || "text"} ref={edit_ref} id={`edit_${objectTextToKey(inputText1 || getRandomNum().toString())}`} aria-label={`Input Box - ${feedbackDescription}`} name={`edit_${inputName}`} placeholder={inputPlaceholder} autoComplete={autoComplete || "text"} onChange={e => inputFunction({ name: inputName, value: e.target.value, description: feedbackDescription, validationType: validationType || "SAFE" })} value={inputValue}
                    style={{ fontSize: fontSize1, minWidth: "min-content", backgroundColor: readOnly ? "#f2f2f2" : "" }} disabled={readOnly} />
                {inputTextBack ? <InputGroup.Text>{inputTextBack}</InputGroup.Text> : <React.Fragment />}
            </div>
            <ServerFeedback
                messages={messages}
                type="fields"
                //fieldName={`edit_${inputName}`}
                fieldName={`${inputName}`}
                success={messages.success}
                loading={false}
                formName={inputType}
                active={inputType}
                shadow={shadowColor}
                textSuccess={textSuccess}
                textFailed={textFailed}
                ignoreText=""
            />

        </div>
    )
}

export default TextInputMulti
