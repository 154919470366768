import React, { useState } from 'react'
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap';
import './scss/solutions.scss';

import BannerImage from '../../images/solutions-banner.jpg';
import { getType } from '../../functions/library';


function Solutions() {
    const [showComputerOverlay, setShowComputerOverlay] = useState(true);
    const [showDataOverlay, setShowDataOverlay] = useState(true);
    const [showEventOverlay, setShowEventOverlay] = useState(true);

    const [computerOpacity, setComputerOpacity] = useState(.45);
    const [dataOpacity, setDataOpacity] = useState(.45);
    const [eventsOpacity, setEventsOpacity] = useState(.45);
    const [hide, setHide] = useState(null);


    const overlays = {
        computer: setShowComputerOverlay,
        data: setShowDataOverlay,
        events: setShowEventOverlay
    }

    const opacities = {
        computer: setComputerOpacity,
        data: setDataOpacity,
        events: setEventsOpacity
    }


    const handleHideOverlay = (id) => {
        console.log("hide", id);
        const targetID = `overlay_${id}`;
        const overlay = window.document.getElementById(targetID);
        if (overlay) {
            overlay.style.transition = "0.3s";
        }
        opacities[id](0);
        clearTimeout(hide);
        setHide(
            setTimeout(() => {
                overlays[id](false);
            }, 333)
        );
        Object.entries(overlays).forEach((entry) => {
            if (entry[0] !== id) entry[1](true);
        })
        Object.entries(opacities).forEach((entry) => {
            if (entry[0] !== id) entry[1](.45);
        })
    }


    const handleShowOverlay = (id) => {
        console.log("show", id);
        const targetID = `overlay_${id}`;
        const overlay = window.document.getElementById(targetID);
        if (overlay) overlay.style.transition = "0.3s";
        if (hide) {
            clearTimeout(hide);
            overlays[id](true);
            setHide(
                setTimeout(() => {
                    opacities[id](.45)
                }, 100));
        }
    }

    const removeOverlay = (e) => { //FIXME: Try using all ID's with an _events, etc. then stripping off the events to make changes to the element with only the events ID
        if (e.target.id) {
            const overlay_id = e.target.id.split("_")[1];
            handleHideOverlay(overlay_id);
        }
    }

    const replaceOverlay = (e) => {
        if (e.target.id) {
            const overlay_id = e.target.id.split("_")[1];
            handleShowOverlay(overlay_id);
        }
    }

    return (
        <div className='solutions-page'>
            <div className="header bg-dark text-white">
                <Image className="header-image" src={BannerImage} alt="Card image" fluid />
                <div className="header-overlay">
                    <h1 className="header-title mt-2 mt-xl-5">Custom Solutions</h1>
                    <h2 className="header-subtitle pt-2">
                        Out of the box or mainstream, TrinityRecode can help you find the right solution. <br className='display-none-mobile' /> <a className='text-warning' href="/Contact?select=Consulting">Speak with us</a> today!
                    </h2>
                </div>
            </div>

            <Container>
                <div className="row-container" id="container_computer" bg="light" onMouseOver={(e) => removeOverlay(e)} onMouseOut={(e) => replaceOverlay(e)}>
                    {showComputerOverlay ? <div className='row-overlay no-mobile' id="overlay_computer" style={{ opacity: computerOpacity, }} >
                        <div className="row-overlay-text" id="overlayText_computer">
                            <div className='' id="overlayTextDiv_computer">
                                Computer
                            </div>
                        </div>
                    </div> : <React.Fragment />}
                    <h2 className='text-center p-0 mt-5 bg-fade-vert text-white mobile-only pb-0 mb-0' >Computer</h2>
                    <Row className='d-flex justify-content-center p-4 pt-2 mt-lg-5' >
                        <Col className='d-flex justify-content-center pb-2' >
                            <Card style={{ width: '21rem' }} bg="info" text="white" border="dark" >
                                <Card.Header>
                                    <div className='d-flex align-items-center'>
                                        <i className="fas fa-laptop fa-2x pe-3 text-white no-hover" />
                                        <Card.Title className="no-hover pt-2">Hardware</Card.Title>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <ul>
                                            <li>Workstations</li>
                                            <li>Mini-PCs</li>
                                            <li>Laptops</li>
                                            <li>Mobile</li>
                                        </ul>
                                        If a good solution doesn't exist in the current marketplace, we may be able to manufacture custom in-house!
                                    </Card.Text>

                                </Card.Body>
                                <Card.Footer className='bg-info border border-0'>
                                    <Button className='w-100' variant="primary" href="/Contact?select=Customer_Inquiry">Find Out How</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col className='d-flex justify-content-center'>
                            <Card style={{ width: '21rem' }} bg="info" text="white" border="dark">
                                <Card.Header>
                                    <div className='d-flex align-items-center'>
                                        <i className="fas fa-window-restore fa-2x pe-3 text-white no-hover" />
                                        <Card.Title className="no-hover pt-2">Software</Card.Title>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <ul>
                                            <li>Microsoft OS</li>
                                            <li>Office Suite</li>
                                            <li>Adobe Suite</li>
                                            <li>CRM</li>
                                            <li>...and more!</li>
                                        </ul>
                                        We'll help you source the right software solution to maximize productivity.
                                    </Card.Text>

                                </Card.Body>
                                <Card.Footer className='bg-info border border-0'>
                                    <Button className='w-100' variant="primary" href="/Contact?select=Customer_Inquiry">Contact Software Specialist</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="row-container" id="container_data" onMouseOver={(e) => removeOverlay(e)} onMouseOut={(e) => replaceOverlay(e)}>
                    {showDataOverlay ? <div className='row-overlay no-mobile' style={{ opacity: dataOpacity, }} id="overlay_data">
                        <div className="row-overlay-text" id="overlayText_data">
                            <div id="overlayTextDiv_data">
                                Data &amp; Internet
                            </div>
                        </div>
                    </div> : <React.Fragment />}
                    <h2 className='text-center p-0 mt-4 bg-fade-vert text-white mobile-only pb-0 mb-0'> Data &amp; Internet</h2>
                    <Row className='d-flex justify-content-center p-4 pt-md-4 pt-1' >
                        <Col className='d-flex justify-content-center pb-2' >
                            <Card style={{ width: '21rem' }} bg="warning" text="dark" border="grey" >
                                <Card.Header>
                                    <div className='d-flex align-items-center'>
                                        <i className="fas fa-hdd fa-2x pe-3 text-white no-hover" />
                                        <Card.Title className="no-hover pt-2">Storage</Card.Title>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <ul>
                                            <li>Local Storage (SSD, M.2, Raid)</li>
                                            <li>Network Attached Storage (NAS)</li>
                                            <li>Cloud Storage (Dropbox, Drive, etc)</li>
                                        </ul>
                                        Keep your data secure and quickly accessible with the right storage solution recommended by TrinityRecode.
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className='bg-warning border border-0'>
                                    <Button className='w-100' variant="primary" href="/Contact?select=Customer_Inquiry">Contact Storage Expert</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col className='d-flex justify-content-center pb-2' >
                            <Card style={{ width: '21rem' }} bg="warning" text="dark" border="grey" >
                                <Card.Header>
                                    <div className='d-flex align-items-center'>
                                        <i className="fas fa-database fa-2x pe-3 text-white no-hover" />
                                        <Card.Title className="no-hover pt-2">Recovery</Card.Title>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <ul>
                                            <li>Backup Solutions</li>
                                            <li>Deleted Data Restoration</li>
                                            <li>Data Deduplication</li>
                                        </ul>
                                        All your data is important. That's why we only recommend the most effective solutions.
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className='bg-warning border border-0'>
                                    <Button className='w-100' variant="primary" href="/Contact?select=Customer_Inquiry">Find Out How</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col className='d-flex justify-content-center' >
                            <Card style={{ width: '21rem' }} bg="warning" text="dark" border="grey">
                                <Card.Header>
                                    <div className='d-flex align-items-center'>
                                        <i className="fas fa-wifi fa-2x pe-3 text-white no-hover" />
                                        <Card.Title className="no-hover pt-2">Wi-Fi & Network</Card.Title>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <ul>
                                            <li>Access Points</li>
                                            <li>Hardwire Solutions</li>
                                            <li>Network Stability</li>
                                            <li>Switching &amp; Routing</li>
                                        </ul>
                                        All your data is important. That's why we only recommend the most effective solutions.
                                    </Card.Text>

                                </Card.Body>
                                <Card.Footer className='bg-warning border border-0'>
                                    <Button className='w-100' variant="primary" href="/Contact?select=Customer_Inquiry">Get Your Options</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="row-container" id="container_events" onMouseOver={(e) => removeOverlay(e)} onMouseOut={(e) => replaceOverlay(e)}>
                    {showEventOverlay ? <div className='row-overlay no-mobile' style={{ opacity: eventsOpacity, }} id="overlay_events">
                        <div className="row-overlay-text" id="overlayText_events">
                            <div id="overlayTextDiv_events">
                                Events
                            </div>
                        </div>
                    </div> : <React.Fragment />}

                    <h2 className='text-center p-0 mt-4 bg-fade-vert text-white mobile-only pb-0 mb-0'>Events</h2>
                    <Row className='d-flex justify-content-center p-4 pt-md-4 pt-1' >
                        <Col className='d-flex justify-content-center'>
                            <Card style={{ width: '21rem' }} bg="danger" text="white" border="grey" >
                                <Card.Header>
                                    <div className='d-flex align-items-center'>
                                        <i className="fas fa-users fa-2x pe-3 text-white no-hover" />
                                        <Card.Title className="no-hover pt-2">Meetings *</Card.Title>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <ul>
                                            <li>Audio, Video, Lighting, &amp; Set Design</li>
                                            <li>Contract Negotiation</li>
                                            <li>Equipment</li>
                                            <li>Labor</li>
                                            <li>Power Distribution</li>
                                            <li>RFP Process Management</li>
                                            <li>... and more!</li>
                                        </ul>
                                        We have over 20 years in the Audio Visual Event Technology arena. Let us put our experience to work to help you deliver an impactful and budget friendly event for your attendees!
                                    </Card.Text>

                                </Card.Body>
                                <Card.Footer>

                                    <Button className='w-100 mb-2' variant="primary" href="/Services/Consulting?tab=event_tech">More Details</Button>
                                    <small>* TrinityRecode does not provide any Audio Visual Services. We act <div className='d-inline underline'>only</div> in a consulting capacity.</small>


                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col className='d-flex justify-content-center'>
                            <Card style={{ width: '21rem' }} bg="danger" text="white" border="grey">
                                <Card.Header>
                                    <div className='d-flex align-items-center'>
                                        <i className="fas fa-person-booth fa-2x pe-3 text-white no-hover" />
                                        <Card.Title className="no-hover pt-2">Conventions &amp; Expo *</Card.Title>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <ul>
                                            <li>Contract Negotiation</li>
                                            <li>Décor & Draping</li>
                                            <li>Equipment</li>
                                            <li>Floorplan Design</li>
                                            <li>Labor</li>
                                            <li>Power Distribution</li>
                                            <li>Vendor Management</li>
                                            <li>... and more!</li>
                                        </ul>
                                        Our experience in exposition and conventions will give you the edge you need to ensure your attendees, vendors, and bottom line exceed expectations.
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <Button className='w-100 mb-2' variant="primary" href="/Services/Consulting?tab=event_tech">More Details</Button>
                                    <small>* TrinityRecode does not provide any Audio Visual Services. We act <div className='d-inline underline'>only</div> in a consulting capacity.</small>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col className='d-flex justify-content-center'>
                            <Card style={{ width: '21rem' }} bg="danger" text="white" border="grey">
                                <Card.Header>
                                    <div className='d-flex align-items-center'>
                                        <i className="fas fa-user-tag fa-2x pe-3 text-white no-hover" />
                                        <Card.Title className="no-hover pt-2">Charitable *</Card.Title>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <ul>
                                            <li>Audio Visual</li>
                                            <li>Banquets</li>
                                            <li>Décor & Draping</li>
                                            <li>Floorplan Design</li>
                                            <li>Gala</li>
                                            <li>Labor &amp; Power Management</li>
                                            <li>Lighting Design</li>
                                            <li>... and more!</li>
                                        </ul>
                                        Our experience in exposition and conventions will give you the edge you need to ensure your attendees, vendors, and bottom line exceed expectations.
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <Button className='w-100 mb-2' variant="primary" href="/Services/Consulting?tab=event_tech">More Details</Button>
                                    <small>* TrinityRecode does not provide any Audio Visual Services. We act <div className='d-inline underline'>only</div> in a consulting capacity.</small>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>



        </div>
    );
}

export default Solutions
