import { useEffect, useState } from "react"
import { isset } from "../functions/library"

/**
 * 
 * @param {[]} loadingArray An array of boolean loading indicators
 * @returns {boolean} True if loading, false if not
 */
function checkForLoading(loadingArray = []) {
    if (!isset(loadingArray)) return false;
    let loadingReturn = false;
    loadingArray.forEach((loading) => {
        if (loading) loadingReturn = true;
    });
    return loadingReturn;
}

const useLoading = (loadingArray = []) => {
    const [loading, setLoading] = useState(checkForLoading(loadingArray));

    useEffect(() => {
        let mounted = true;
        const loadingResult = checkForLoading(loadingArray);
        if (loadingResult !== loading) {
            if (mounted) setLoading(loadingResult);
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [loadingArray]);

    return [loading, setLoading];
}

export default useLoading