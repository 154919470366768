import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { getEmptyMessages, getEmptyProfile, getEmptyUser, getRandomNum, isset } from '../../../functions/library';
import DropdownList from '../../pages/components/parts/input/DropdownList';
import ProfileImage from './ProfileImage';
import './scss/profileDds.scss';


export const ProfileDds = ({ className, siteAuth, setCurrentLocation }) => {
    const [auth, setAuth] = useState({ user: getEmptyUser(), profile: getEmptyProfile() });
    const [displayName, setDisplayName] = useState("Welcome");
    const [profilePhoto, setProfilePhoto] = useState("");
    const [resetDDS, setResetDDS] = useState("");
    const ddsItems = { Profile: "/Setup/Profile", Setup: "/Setup" };
    let history = useHistory();

    useEffect(() => {
        let mounted = true;
        if (mounted && isset(auth.profile.profilePhoto)) {
            setProfilePhoto(auth.profile.profilePhoto);
        }
        return () => mounted = false;
    }, [auth.profile.profilePhoto]);


    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (!Object.values(ddsItems).includes(window.location.pathname)) {
                setResetDDS(getRandomNum(1000000, 9999999));
            }
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [window.location.pathname]);

    useEffect(() => {
        let mounted = true;
        if (mounted && isset(siteAuth)) setAuth(siteAuth);
        return () => mounted = false;
    }, [siteAuth]);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            let thisDisplayName = auth.user.userName;
            if (isset(auth.profile.userDisplayName)) {
                thisDisplayName = auth.profile.userDisplayName;
            }
            setDisplayName(thisDisplayName);
        }
        return () => mounted = false;
    }, [auth.profile.userDisplayName, auth.user.userName]);

    const handleMenuSelect = (e) => {
        setCurrentLocation(ddsItems[e.value]);
        history.push(ddsItems[e.value]);
    }

    return (

        <div className={className}>
            <div className="d-flex align-items-center">
                <ProfileImage
                    className="rounded-3"
                    image={profilePhoto}
                    size="sm"
                />
                <DropdownList
                    className=""
                    inputOptions={{
                        useLabel: false,
                        inputText1: displayName,
                        DDSText: displayName,
                        DDSItems: Object.keys(ddsItems),
                        text1FontSize: ".75rem"
                    }}
                    inputName="ProfileDDS"
                    inputFunction={handleMenuSelect}
                    clearSelected={resetDDS}
                    messages={getEmptyMessages()}
                />
            </div>
        </div>

    )
}
