import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { getEmptyProfile, isset, propExists } from '../../../functions/library';
import EmptyPanel from '../components/EmptyPanel';
import EditProfileImage from './components/profile/EditProfileImage';
import EditProfileInfo from './components/profile/EditProfileInfo';
import { useAPI, useAPIResult, useMessages } from '../../../hooks/useAPI';
import './scss/profile.scss'
import EndpointToken from '../../../classes/EndpointToken';
import MessagesObject from '../../../classes/APIReturns/MessagesObject';
import { CREATE_PROFILE_ENDPOINT, UPDATE_PROFILE_ENDPOINT } from '../../../functions/siteDefaults';
import { useAuth } from '../../router/ProvideAuth';
import SiteImage from '../../../classes/SiteImage';
import ServerFeedback from '../../../functions/ServerFeedback';
import ProfileObject from '../../../classes/APIReturns/ProfileObject';



function Profile() {
    const PAGENAME = "Profile_Page";
    const auth = useAuth();
    const [originalProfile, setOriginalProfile] = useState(null);
    const [currentProfile, setCurrentProfile] = useState(propExists(auth, "profile", getEmptyProfile()));
    // const [messages, setMessages] = useState(getEmptyMessages());
    const [changesAreSaved, setChangesAreSaved] = useState(true);
    const [busy, request, call] = useAPI(PAGENAME);
    const [callID, setCallID] = useState(0);
    const [loading, error, response, complete] = useAPIResult(callID);
    const [messages, setMessages] = useMessages();

    useEffect(() => {
        let mounted = true;
        if (!isset(originalProfile) && mounted) setOriginalProfile(auth.profile);
        return () => mounted = false;
        // eslint-disable-next-line
    }, [auth.profile]);

    // eslint-disable-next-line
    useEffect(() => {
        let mounted = true;
        if (mounted && isset(originalProfile)) {
            let saved = true;
            Object.entries(currentProfile).forEach((entry) => {
                const name = entry[0];
                const value = entry[1];
                if (originalProfile[name] !== value) saved = false;
            });
            if (changesAreSaved !== saved) setChangesAreSaved(saved);
        }
        return () => mounted = false;
    });

    useEffect(() => {
        let mounted = true;

        if (!loading && complete && !error) {
            if (mounted) {
                auth.replaceProfile(response);
                const Messages = new MessagesObject(response);
                setMessages(Messages);
                setChangesAreSaved(true);
                setOriginalProfile(new ProfileObject(response));
            }

        }

        if (!loading && complete && error) {
            if (mounted) setMessages(new MessagesObject(response));
        }

        return () => mounted = false;
        // eslint-disable-next-line
    }, [busy, loading, error, response, complete]);

    useEffect(() => {
        let mounted = true;
        if (mounted && propExists(auth, "profile.profileID", false)) {
            setCurrentProfile(auth.profile);
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [auth.profile, auth.profile.profilePhoto]);



    const saveChanges = () => {
        const saveProfile = generateEndpointToken();
        const Apicall = request(saveProfile, PAGENAME);
        const id = call(Apicall);
        setCallID(id);
    }



    const generateEndpointToken = () => {
        const ENDPOINT = currentProfile.profileID > 0 ? UPDATE_PROFILE_ENDPOINT : CREATE_PROFILE_ENDPOINT;
        const profilePhoto = new SiteImage("profilePhoto", { fullImageString: currentProfile.profilePhoto })
        currentProfile.profilePhoto = profilePhoto.base64String;
        return new EndpointToken(ENDPOINT, { ...currentProfile, ...auth.session });
    }


    return (

        <div className="widget-panel w-100 p-2">
            <EmptyPanel
                classMainIn="widget-nohover w-100"
                showHeader={false}
            >
                <div className="d-flex justify-content-between px-3 pb-2 align-items-center">
                    <div className="w-25"></div> <h1>Edit Your Profile</h1>
                    <div className="w-25">
                        {!changesAreSaved ? <i className="fas fa-hand-point-right fa-2x m-2 bounce"></i> : <React.Fragment />}
                        <Button className="btn-lg" variant="primary" disabled={changesAreSaved} onClick={saveChanges} >Save</Button>
                    </div>

                </div>
                <ServerFeedback
                    className="p-2"
                    messages={messages}
                    type="Form"
                    success={propExists(messages, "success", false)}
                    loading={loading}
                    formName={PAGENAME}
                    active={PAGENAME}
                />
                <Container fluid>
                    <Row>
                        <Col xs={12} md={6}> <EditProfileImage changesAreSaved={changesAreSaved} setChangesAreSaved={setChangesAreSaved} currentProfile={currentProfile} setCurrentProfile={setCurrentProfile} /> </Col>
                        <Col xs={12} md={6}> <EditProfileInfo changesAreSaved={changesAreSaved} setChangesAreSaved={setChangesAreSaved} currentProfile={currentProfile} setCurrentProfile={setCurrentProfile} /> </Col>
                    </Row>
                </Container>
            </EmptyPanel>

        </div>

    )
}

export default Profile
