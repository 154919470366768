import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap';
import { genValidationObject, validateInput, VALIDATE_TEXT_SINGLE_LINE } from '../../../../../functions/library';
import { useMessages } from '../../../../../hooks/useAPI'
import EmptyPanel from '../../../components/EmptyPanel'
import TextInput from '../../../components/parts/input/TextInput';

const EditProfileInfo = ({ className, changesAreSaved, currentProfile, setCurrentProfile }) => {
    const [messages, setMessages] = useMessages();

    //TODO: Build this out. Build similar structure to EditProfileImage. 



    const handleProfileChange = (e) => {
        const validation = genValidationObject({}, {
            runValidation: true,
            field: e.name,
            rule: e.validationType
        });
        validateInput(validation, e, (value) => {
            setCurrentProfile((profile) => {
                return {
                    ...profile,
                    [e.name]: value
                }
            })
        }, setMessages);
    }



    return (
        <div className={className}>
            <EmptyPanel
                classMainIn="widget-nohover w-100 my-2"
                title="Required Private Information"
            >
                {changesAreSaved ? <React.Fragment /> : <div className="unsaved-alert">* Your changes are not saved *</div>}
                <Form className="mb-2">
                    <Container>
                        <p className="italic" >We keep all this information private for verification purposes only.</p>
                        <Row>
                            <Col xs={12} lg={6}>
                                <TextInput
                                    className=""
                                    inputOptions={{
                                        inputText1: "First Name",
                                        text1MinWidth: "min-content"
                                    }}
                                    htmlType="text"
                                    autoComplete="given-name"
                                    validationType={VALIDATE_TEXT_SINGLE_LINE}
                                    inputName="userFirstName"
                                    inputValue={currentProfile.userFirstName}
                                    inputFunction={handleProfileChange}
                                    messages={messages}
                                    feedbackDescription="First Name"
                                />
                            </Col>
                            <Col xs={12} lg={6}>
                                <TextInput
                                    className="mt-2 mt-lg-0"
                                    inputOptions={{
                                        inputText1: "Last Name",
                                        text1MinWidth: "min-content"
                                    }}
                                    htmlType="text"
                                    autoComplete="family-name"
                                    validationType={VALIDATE_TEXT_SINGLE_LINE}
                                    inputName="userLastName"
                                    inputValue={currentProfile.userLastName}
                                    inputFunction={handleProfileChange}
                                    messages={messages}
                                    feedbackDescription="Last Name"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} lg={8}>
                                <TextInput
                                    className=""
                                    inputOptions={{
                                        inputText1: "Street",
                                        text1MinWidth: "min-content"
                                    }}
                                    htmlType="text"
                                    autoComplete="address-line1"
                                    validationType={VALIDATE_TEXT_SINGLE_LINE}
                                    inputName="userStreet1"
                                    inputValue={currentProfile.userStreet1}
                                    inputFunction={handleProfileChange}
                                    messages={messages}
                                    feedbackDescription="Street"
                                />
                            </Col>
                            <Col xs={6} lg={4}>
                                <TextInput
                                    className="mt-2 mt-lg-0"
                                    inputOptions={{
                                        inputText1: "Unit",
                                        text1MinWidth: "min-content"
                                    }}
                                    htmlType="text"
                                    autoComplete="address-line2"
                                    validationType={VALIDATE_TEXT_SINGLE_LINE}
                                    inputName="userStreet2"
                                    inputValue={currentProfile.userStreet2}
                                    inputFunction={handleProfileChange}
                                    messages={messages}
                                    feedbackDescription="Unit"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs={12} lg={5}>
                                <TextInput
                                    className=""
                                    inputOptions={{
                                        inputText1: "City",
                                        text1MinWidth: "min-content"
                                    }}
                                    htmlType="text"
                                    autoComplete="address-level2"
                                    validationType={VALIDATE_TEXT_SINGLE_LINE}
                                    inputName="userCity"
                                    inputValue={currentProfile.userCity}
                                    inputFunction={handleProfileChange}
                                    messages={messages}
                                    feedbackDescription="City"
                                />
                            </Col>
                            <Col xs={6} lg={3}>
                                <TextInput
                                    className="mt-2 mt-lg-0"
                                    inputOptions={{
                                        inputText1: "State",
                                        text1MinWidth: "min-content"
                                    }}
                                    htmlType="text"
                                    autoComplete="address-level1"
                                    validationType={VALIDATE_TEXT_SINGLE_LINE}
                                    inputName="userState"
                                    inputValue={currentProfile.userState}
                                    inputFunction={handleProfileChange}
                                    messages={messages}
                                    feedbackDescription="State"
                                />
                            </Col>
                            <Col xs={6} lg={4}>
                                <TextInput
                                    className="mt-2 mt-lg-0"
                                    inputOptions={{
                                        inputText1: "Zip",
                                        text1MinWidth: "min-content"
                                    }}
                                    htmlType="text"
                                    autoComplete="postal-code"
                                    validationType={VALIDATE_TEXT_SINGLE_LINE}
                                    inputName="userZip"
                                    inputValue={currentProfile.userZip}
                                    inputFunction={handleProfileChange}
                                    messages={messages}
                                    feedbackDescription="Zip Code"
                                />
                            </Col>

                        </Row>

                        <Row className="mt-4">
                            <Col xs={12} lg={6}>
                                <TextInput
                                    className=""
                                    inputOptions={{
                                        inputText1: "Mobile Number",
                                        text1MinWidth: "min-content"
                                    }}
                                    htmlType="Telephone"
                                    autoComplete="tel-national"
                                    validationType={VALIDATE_TEXT_SINGLE_LINE}
                                    inputName="userMobileNumber"
                                    inputValue={currentProfile.userMobileNumber}
                                    inputFunction={handleProfileChange}
                                    messages={messages}
                                    inputPlaceholder="908-867-5309"
                                    feedbackDescription="Mobile Number"
                                />
                            </Col>
                            <Col xs={12} lg={6}>
                                <TextInput
                                    className="mt-2 mt-lg-0"
                                    inputOptions={{
                                        inputText1: "Home Number",
                                        text1MinWidth: "min-content"
                                    }}
                                    htmlType="Telephone"
                                    autoComplete="tel-national"
                                    validationType={VALIDATE_TEXT_SINGLE_LINE}
                                    inputName="userHomeNumber"
                                    inputValue={currentProfile.userHomeNumber}
                                    inputFunction={handleProfileChange}
                                    messages={messages}
                                    inputPlaceholder="732-867-5309"
                                    feedbackDescription="Home Number"
                                />
                            </Col>
                        </Row>

                    </Container>
                </Form>

            </EmptyPanel>
        </div>
    )
}

export default EditProfileInfo
