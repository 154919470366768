import React from 'react'
import { Image } from 'react-bootstrap';

import LogoMain from "../../../images/logo.png"

export default function Logo() {
    return (
        <div className='d-flex justify-content-center align-items-center w-100 h-50'>
            <img alt="logo" src={LogoMain} />
        </div>
    )
}
