import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { getType } from "../../../../../functions/library";


function ModalSimple({ showModal, showCallback, title, body }) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        let mounted = true;
        if (mounted && getType(showCallback, 'function')) {
            showCallback(show);
        }
        return () => mounted = false;
    }, [show, showCallback]);

    useEffect(() => {
        let mounted = true;
        if (showModal !== show) {
            if (mounted) setShow(showModal);
        }
        return () => mounted = false;
    }, [showModal, show]);

    const handleClose = () => {
        showCallback(false);
    }


    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">{body}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalSimple;