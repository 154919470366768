import { MyDate } from "../../functions/library";
import APIReturn from "./APIReturn";


export default class SessionObject extends APIReturn {
    type = "SessionObject";
    isAuth = false;
    userID = 0;
    sessionID = 0;
    accessToken = "";
    accessTokenExpiry = "";
    refreshTokenExpiry = "";

    constructor(data) {
        super(data);
        if (!this.isset) {
            delete this.data;
            delete this.messages;
            return;
        }
        if (
            !this.data
            || (!this.data.hasOwnProperty("sessionID")
                && !this.data.hasOwnProperty("accessToken")
                && !this.data.hasOwnProperty("accessTokenExpiry")
                && !this.data.hasOwnProperty("refreshTokenExpiry")
                && !this.data.hasOwnProperty("userID"))
        ) {
            delete this.data;
            delete this.messages;
            return;
        }
        this.isAuth = this.success;
        this.sessionID = this.data.sessionID;
        this.accessToken = this.data.accessToken;
        this.accessTokenExpiry = new MyDate(this.data.accessTokenExpiry).string;
        this.refreshTokenExpiry = new MyDate(this.data.refreshTokenExpiry).string;
        this.userID = this.data.userID;
        delete this.data;
        delete this.messages;
    }

    get accessTokenIsExpired() {
        return new MyDate(this.accessTokenExpiry).dateBeforeOrAfter() === "BEFORE"; //TODO: This needs to be tested
    }
}

