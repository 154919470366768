import { useState } from "react";
import { getType } from "../functions/library";

/**
 * Returns a toggled value everytime toggleValue() is called.
 * @param {boolean} defaultValue A default true or false value
 * @function toggleValue() - Accepts an emphatic state to be set, or if passed null, will toggle the current value to it's opposite. i.e. false rather than true, true rather than false.
 * @returns {[boolean, function]} An array containing the value at index 0 and a toggleValue function at index 1. 
 */
const useToggle = (defaultValue) => {
    const [value, setValue] = useState(defaultValue);

    /** @function toggleValue */
    function toggleValue(value) {
        setValue(currentValue =>
            getType(value, "boolean") ? value : !currentValue
        );
    }

    return [value, toggleValue];
}

export default useToggle;