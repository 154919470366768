import React from "react";
import PropTypes from "prop-types";
import { boolval, isset, is_array } from "./library";
import { isString } from "lodash";

/**
 * 
 * @param {string} className Main div class, empty by default
 * @param {{}} messages Message data object containing a Form and/or Field object as a param
 * @param {string} type One of two types (Form or Field)
 * @param {string} fieldName Used if Type is "Field" to denote specific field feedback to display
 * @param {boolean} success Message is a success message or a failed/validation error message
 * @param {boolean} loading Displays a fontawesome loading spinner when TRUE
 * @param {string} formName Used in conjunction with active when multiple forms are present to differentiate forms
 * @param {string} active Used in conjunction with formName - will display feedback when equal
 * @param {string} shadow possible shadow options: (white, black, white-glow, black-glow)
 * @param {string} textSuccess  Text color on success 
 * @param {string} textFailed Text color on failed
 * @param {string} textAlign Text Alignment - Left, Center, Right
 * @param {string} ignoreText Form Responses to ignore and not display
 * 
 * @returns 
 */
const ServerFeedback = ({
	className, // Main div class, empty by default
	messages, // Message data object containing a Form and/or Field object as a param
	type, // One of two types (form or fields)
	fieldName, // Used if Type is "Field" to denote specific field feedback to display
	success, // Message is a success message or a failed/validation error message
	loading, // Displays a fontawesome loading spinner when TRUE
	formName, // Used in conjunction with active when multiple forms are present to differentiate forms
	active, // Used in conjunction with formName - will display feedback when equal
	shadow, // possible shadow options: (white, black, white-glow, black-glow)
	textSuccess, // Text color on success 
	textFailed, // Text color on failed
	textAlign, // Text Alignment - Left, Center, Right
	ignoreText, // Form Responses to ignore and not display
}) => {
	if (active !== formName) return <React.Fragment />;
	if (isString(type)) type = type.toLowerCase();
	if (!isset(messages[type])) return <div></div>;
	let { form, fields } = messages;

	let mainClass = "";
	let validationClass = "validation-error";
	let textShadow = false;
	let textColor = false;
	let textAlignment = textAlign === "left" || textAlign === "center" || textAlign === "right" ? `text-${textAlign}` : type === "form" ? "text-center" : "text-left";

	type === "form" ? (mainClass = `${textAlignment} validation-larger`) : (mainClass = textAlignment);

	boolval(success) === true ? (validationClass = "validation-success") : (validationClass = "validation-error");

	if (shadow === "white") textShadow = "2px 2px 2px #FFFFFF";
	if (shadow === "black") textShadow = "2px 2px 2px #000000";
	if (shadow === "white-glow") textShadow = "0px 0px 4px #FFFFFF";
	if (shadow === "black-glow") textShadow = "0px 0px 4px #000000";
	if (shadow === "none") textShadow = "none";
	if (textSuccess && success === true) textColor = textSuccess;
	if (textFailed && success === false) textColor = textFailed;

	if (ignoreText && form !== undefined && Object.values(form).length) {
		Object.entries(form).forEach((entry) => {
			const [index, value] = entry;
			if (value === ignoreText) {
				delete form[index];
			}
		});
	}



	const styleObject = {};

	const insertLineBreak = () => <br />;

	const arrayModEach = (arrayIn, modificationFunction) => {
		if (isset(arrayIn) && is_array(arrayIn)) {
			const returnArray = [];
			arrayIn.forEach((value, index) => {
				returnArray.push(modificationFunction(value, index));
			});
			return returnArray;
		}
		return arrayIn;
	};


	if (textColor) {
		styleObject["color"] = textColor;
	}

	if (textShadow) {
		styleObject["textShadow"] = textShadow;
	}

	if (messages !== undefined) {
		if (
			(form !== undefined && Object.values(form).length) ||
			(fields !== undefined && Object.values(fields).length)
		) {
			return (
				<div className={className}>
					<div className={mainClass}>
						<small className={validationClass} id={`${formName}_error_${type === "form" ? "form" : `field_${fieldName}`}`}>
							{type === "form"
								? Object.values(messages.form).map((message, index) => (
									<span key={`${fieldName}_${type}Error_${index}`} style={styleObject}>
										{message} {loading ? <i className="fas fa-spinner fa-spin" /> : false}
									</span>
								))
								: <div>{arrayModEach(messages.fields[fieldName], (value, index) => {
									if (messages.fields[fieldName].length > 1) {
										return <div key={`${fieldName}_${type}Error_${index}`}>{index + 1}: {value}{insertLineBreak()}</div>;
									}
									return <div>{value}{insertLineBreak()}</div>;

								})}</div>}
						</small>
					</div>
				</div>
			);
		}
	}
	return <div></div>;
};

ServerFeedback.propTypes = {
	messages: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
	fieldName: PropTypes.string,
	success: PropTypes.bool.isRequired,
	loading: PropTypes.bool,
	shadow: PropTypes.string,
	textSuccess: PropTypes.string,
	textFailed: PropTypes.string,
};

export default ServerFeedback;

export const logRequest = (messages) => {
	const requestArray = messages.request || [];
	if (Array.isArray(requestArray)) {
		requestArray.forEach((message) => {
			if (typeof message === "object" && message !== null) {
				Object.values(message).forEach((objMessages) => {
					console.log(objMessages);
				});
			} else {
				console.log(message);
			}
		});
	} else {
		if (messages.request) {
			if (typeof messages.request === "object" && messages.request !== null) {
				Object.values(messages.request).forEach((objMessages) => {
					if (objMessages !== undefined) {
						console.log(objMessages);
					}
				});
			} else {
				if (messages.request !== undefined) {
					console.log(messages.request);
				}
			}
		} else {
			console.log("Server did not return a response.");
		}
	}
};
