import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap';

import TroubleShootingMain from "../../../images/Troubleshooting-main-image.jpg";

export default function Troubleshooting() {
    return (
        <Container className='mt-5'>
            <Row className='event-tech-info mt-5 mb-5 pb-3 p-lg-2'>
                <Col md={6} className="no-mobile">
                    <Row >
                        <Col xs={12}>
                            <Image className="pt-lg-2" src={TroubleShootingMain} fluid />
                        </Col>
                    </Row>
                </Col>

                <Col md={6}>
                    <div className='d-flex align-items-baseline'>
                        <i className="fas fa-tools fa-3x text-primary pe-3 ms-0 no-hover" />
                        <h3 className='text-black'>Troubleshooting & Repair</h3>
                    </div>
                    <h4 className='text-secondary pt-2'>Is your current solution in need of intervention?</h4>
                    <p className='text-dark pt-1 mt-3'>
                        Even if we didn't advise you on your current technology, we may be able to offer or advise on support services.  <a href="/Contact?select=Support">Let us advise you </a> or even provide direct troubleshooting support for your current hardware or software.</p>
                    <p className='text-dark mb-1'> Some common issues we support directly: </p>
                    <div className=''>
                        <ul className=''>

                            <li>Data Recovery</li>
                            <li>Desktop / Workstation Troubleshooting</li>
                            <li>Desktop / Workstation Upgrade & Repair</li>
                            <li>Networking & Routing</li>
                            <li>Perfomance & Productivity Enhacements</li>
                        </ul>
                    </div>

                </Col>
                <Col md={6} className="mobile-only">
                    <Row>
                        <Col xs={12}>
                            <Image src={TroubleShootingMain} fluid />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container >
    )
}
