import { useEffect, useState } from "react"


/**
 * 
 * @param {*} scrollTopOnLoad 
 * @returns {[scrollPosition:{ top: number, left: number}, setScrollPosition:function, setScrollBehavior:function, resetScroll:function]} [scrollPosition:{}, setScrollPosition:function, setScrollBehavior:function, resetScroll:function]
 */
const useScrollReset = (scrollTopOnLoad = true) => {
    const [scrollPosition, setScrollPosition] = useState({ top: window.document.scrollTop, left: window.document.scrollLeft });
    const [scrollBehavior, setScrollBehavior] = useState("smooth");

    useEffect(() => {
        let mounted = true;
        if (scrollTopOnLoad) {
            if (mounted) window.scrollTo(
                {
                    top: 0,
                    left: 0,
                    behavior: scrollBehavior
                }
            );
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, []);


    const resetScroll = () => {
        window.scrollTo(
            {
                top: 0,
                left: 0,
                behavior: scrollBehavior
            }
        );
    }

    return [scrollPosition, setScrollPosition, setScrollBehavior, resetScroll];
}

export default useScrollReset