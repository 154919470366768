import React, { useEffect, useState } from 'react'
import SiteImage from '../classes/SiteImage';
import { isset } from '../functions/library';
import './img.scss';

//TODO: complete the jsdoc here.

/**
 * Comprehensive Image component that has an auto width feature, when supplying only height. Also allows for a pretected image if protectImage is true.
 * @param {string} className The outer most div's class
 * @returns {Element}
 */
export const Img = ({ className, imageClass, overlayClass, description, fileName, imageData, imageMime, internalType, SiteImageIn, heightIn, widthIn, protectImage }) => {

    const [data, setData] = useState("");
    const [alt, setAlt] = useState("");
    const [height, setHeight] = useState("100%");
    const [width, setWidth] = useState("100%");
    const [userImageClass, setUserImageClass] = useState("");
    const [userOverlayClass, setUserOverlayClass] = useState("");

    useEffect(() => {
        let mounted = true;
        if (mounted && heightIn) {
            setHeight(heightIn);
        }
        if (mounted && widthIn) {
            setWidth(widthIn);
        }
        return () => mounted = false;
    }, [widthIn, heightIn]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (isset(imageClass)) {
                setUserImageClass(imageClass)
            }
            if (isset(overlayClass)) {
                setUserOverlayClass(overlayClass)
            }
        }
        return () => mounted = false;
    }, [imageClass, overlayClass]);

    useEffect(() => {
        let mounted = true;
        if (mounted && SiteImageIn.imageData) {
            setData(SiteImageIn.imageData);
            setAlt(SiteImageIn.description);
        }
        if (mounted && !SiteImageIn.imageData) {
            const siteImage = new SiteImage(internalType, { fileName, imageMime, base64String: imageData });
            setData(siteImage.imageData);
            setAlt(description);
        }
        return () => mounted = false;
    }, [SiteImageIn, fileName, imageData, imageMime, description, internalType]);

    return (
        <div className={`container ${className}`}>
            <img className={`image ${userImageClass}`} alt={alt} src={data} height={height} width={width ? width : "auto"} />
            {protectImage ? <div className={`overlay ${userOverlayClass}`}></div> : <React.Fragment />}
        </div>
    )
}
