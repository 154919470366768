import { inString, propExists } from "../functions/library";
import APIReturn from "./APIReturns/APIReturn";


//TODO: Add methods to get native height and width, add methods to convert binary to base64

/**
 * Constructs an image to be exported directly to an image tag.
 */
export class SiteImage {
    Image = new Image();
    loaded = false;
    description = "";
    fileName = "";
    base64String = "";
    imageMime = "";
    height = 0;
    width = 0;
    #data = "";


    constructor(type = "profilePhoto", props = { fullImageString: "", fileName: "", imageMime: "", description: "", base64String: "", APIReturn: APIReturn }) {
        this.description = propExists(props, "description", "");
        this.fileName = propExists(props, "fileName", "");
        this.imageMime = propExists(props, "imageMime", "");
        this.base64String = propExists(props, "base64String", "");
        this.imageData = propExists(props, "fullImageString", "");
        if (propExists(props, `data.${type}`, false)) {
            this.imageData = propExists(props, `data.${type}`, "");
            this.imageMime = propExists(props, `data.${type}Mime`, "");
            this.fileName = propExists(props, "data.userProfilePhotoFileName", false);
            if (!this.fileName) this.fileName = propExists(props, `data.${type}FileName`, "");
            this.description = this.fileName;
        }
        if (!this.imageData && (this.imageMime && this.base64String)) {
            this.buildImage(this.imageMime, this.base64String);
        }
        if (!this.description && type) {
            this.description = type;
        }
        if (this.imageData) {
            this.Image = new Image();
            this.Image.onload = () => {
                this.loaded = true;
            };
            this.Image.src = this.imageData;
        }

        if (this.#data && !this.imageMime) {
            this.imageMime = this.extractImageMime(this.#data);
            this.base64String = this.extractImageBase(this.#data);
        }
    }

    /**
     * @param {string} fullImageString The complete base64 image string with mime and data head tags.
     */
    set imageData(fullImageString = "") {
        this.#data = fullImageString;
    }

    get imageData() {
        return this.#data;
    }

    get height() {
        return this.Image.height;
    }

    get width() {
        return this.Image.width;
    }

    set height(height = 0) {
        this.Image.height = height;
    }

    set width(width = 0) {
        this.Image.width = width;
    }

    buildImage(imageMime = "", base64String = "") {
        if (!imageMime) imageMime = this.imageMime;
        if (!base64String) base64String = this.base64String;
        this.imageData = `data:${imageMime};base64,${base64String}`;
        return this.imageData;
    }

    extractImageMime(fullImageString = "") {
        if (!fullImageString) return;
        let imageData = fullImageString.split(",")[0];
        if (!inString(imageData, ";")) return;
        return imageData.split(";")[0].split(":")[1];
    }

    extractImageBase(fullImageString = "") {
        if (!fullImageString) return;
        if (!inString(fullImageString, ",")) return;
        return fullImageString.split(",")[1];
    }

    get() {
        return this.imageData;
    }

    //End of SiteImage Class
}

export default SiteImage