import { convertType, getType, isset, localMessage } from "../functions/library";
import { API_BASE_URL, AUTH_TOKEN_LOCATION, BODY_LOCATION, CONTENT_TYPE_KEYWORD, THROW_ERROR_LOCATION, URL_LOCATION } from "../functions/siteDefaults";


/**
 * Creates an EndpointToken token to be used in the APICall Class constructor. Contains the method, location, and arguments required to make the api call and return a result.
 * @property {string} method The request method used for this EndpointToken request
 * @property {{}} headers Object containing all headers required for this EndpointToken, inclusive of Authorization
 * @property {string} url The url, complete with any paramaters
 * @property {{}} body Object containing all body fields and values
 * @setter bodyField - Sets a field or fields in the BODY_LOCATION
 * @property {{statusCode: 0, success: null, messages: {request: {}, form: {}, fields: {}}, data: null}} response Object containing the api response, inclusive of: statusCode, success, messages, and data. 
 * 
 */
export class EndpointToken {
    isset = false;
    name = "";
    method = "";
    headers_without_auth = {
        [CONTENT_TYPE_KEYWORD]: "",
    }
    requiresAuth = false;
    baseURL = API_BASE_URL; //API base address
    location = ""; // e.g. users/sessions.php - use an EndpointToken constant
    args = { [URL_LOCATION]: {}, [BODY_LOCATION]: {}, [AUTH_TOKEN_LOCATION]: {} };
    response = {
        statusCode: 0,
        success: null,
        messages: {
            request: {},
            form: {},
            fields: {}
        },
        data: null
    };


    /**
     * 
     * @param {{name:string,method:string,location:string,contentType:string,args:{}}} endpoint_constant An EndpointToken constant containing the EndpointToken setup information
     * @param {{}} args an object containing fieldName:value pairs. The constructor will sort them to the appropirate request locations. 
     * @returns {EndpointToken} An EndpointToken token to be used in the APICall class
     */
    constructor(endpoint_constant, args = {}, auth = {}) {
        this.name = endpoint_constant.name;
        this.method = endpoint_constant.method;
        this.location = endpoint_constant.location;
        this.headers_without_auth[CONTENT_TYPE_KEYWORD] = endpoint_constant.contentType;
        this.setArgs(endpoint_constant, args);
        this.isset = true;
    }

    get url() {
        let fullUrl = `${this.baseURL}${this.location}`;
        if (isset(this.args[URL_LOCATION])) {
            fullUrl += "?";
            Object.entries(this.args[URL_LOCATION]).forEach((entry, index) => {
                if (index > 0) fullUrl += "&";
                const fieldName = entry[0];
                const value = entry[1];
                fullUrl += `${fieldName}=${value}`;
            });
        }
        return fullUrl;
    }

    get headers() {
        if (!isset(this.args[AUTH_TOKEN_LOCATION])) return this.headers_without_auth;
        this.requiresAuth = true;
        return {
            ...this.headers_without_auth,
            [AUTH_TOKEN_LOCATION]: `Bearer ${this.args[AUTH_TOKEN_LOCATION]["accessToken"]}`
        }
    }

    get body() {
        return this.args[BODY_LOCATION];
    }

    set body(bodyObject) {
        this.args[BODY_LOCATION] = bodyObject;
    }

    /**
     * Sets a field in the BODY_LOCATION
     * @param {{}} fieldObject Object containing the field name(s) and value pair(s)
     */
    set bodyField(fieldObject = {}) {
        this.args[BODY_LOCATION] = {
            ...this.args[BODY_LOCATION],
            ...fieldObject
        }
    }

    /**
     * Sets the Authorization header with the access token.
     * @param {string} accessToken The access token string as returned from a login or refresh api request.
     */
    set Authorization(accessToken = "") {
        this.args[AUTH_TOKEN_LOCATION]["accessToken"] = accessToken;
    }


    setAuth(auth) {
        if (isset(auth)) {
            this.args[AUTH_TOKEN_LOCATION]["accessToken"] = auth.session.accessToken;
        }
    }

    setArgs(endpoint_constant, args) {
        if (!isset(args)) return;
        const argFields = Object.keys(args);
        Object.entries(endpoint_constant.args).forEach((entries) => {
            const requestLocation = entries[0];
            const locationObject = entries[1];
            //Ensure requestLocation exists and is valid
            if (!Object.keys(this.args).includes(requestLocation)) {
                localMessage(`Invalid location '${requestLocation}'. Location does not exist in EndpointToken Class.`, `${THROW_ERROR_LOCATION}`, "ERROR", { class: "EndpointToken", function: "setArgs" });
            }
            //Loop and set param args in EndpointToken args
            Object.entries(locationObject).forEach((entries2) => {
                const availableField = entries2[0];
                const varType = getType(entries2[1]);
                if (argFields.includes(availableField)) {
                    let argValue = args[availableField];
                    if (!getType(argValue, varType)) {
                        argValue = convertType(argValue, varType);
                    }
                    //Check to see if field is already set under correct requestLocation to prevent collision
                    if (isset(this.args[requestLocation][availableField])) {
                        localMessage(`Invalid arg '${availableField}'. Field already exists in args list.`, `${THROW_ERROR_LOCATION}`, "ERROR", { class: "EndpointToken", function: "setArgs" });
                    }
                    //Set var in EndpointToken args property under correct requestLocation
                    this.args[requestLocation][availableField] = argValue;
                }
            });
        });
    }


    //End of EndpointToken Class
}

export default EndpointToken