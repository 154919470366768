import { arrayToObject, inString, isset, is_array, is_object, is_string, localMessage } from "../../functions/library";
import { THROW_ERROR_LOCATION } from "../../functions/siteDefaults";
import APIReturn from "./APIReturn";




/**
 * A Messages object containing API returned messages
 * @property {{0:string}} request Contains detailed messages from the server, recommended to be pushed to the console
 * @property {{0:string}} form Contains user feedback messages of a general nature for whole form feedback
 * @property {{fieldName:string}} fields Object containing fieldName:message pairs, dedicated for specific field feedback.
 * @method getField(fieldName) Returns the specific field as specified in argument #1.
 * @method getForm(index=0) Returns the specific form message as specified in argument #1. Default: index 0
 */
export default class MessagesObject extends APIReturn {
    type = "MessagesObject";
    #request = {};
    #form = {};
    #fields = {};
    success = false;


    /**
     * Creates a Messages object containing request, form, and fields properties
     * @param {*} data 
     * @property {{0:string}} request Contains detailed messages from the server, recommended to be pushed to the console
     * @property {{0:string}} form Contains user feedback messages of a general nature for whole form feedback
     * @property {{fieldName:string}} fields Object containing fieldName:message pairs, dedicated for specific field feedback.
     * @returns 
     */
    constructor(apiResponse) {
        super(apiResponse);
        if (!this.isset) {
            delete this.data;
            delete this.messages;
            return;
        }
        if (
            !this.messages.hasOwnProperty("request")
            && !this.messages.hasOwnProperty("form")
            && !this.messages.hasOwnProperty("fields")
            && !this.messages.hasOwnProperty("success")
        ) {
            console.log("missing request, form, fileds, or success.", this);
            delete this.data;
            delete this.messages;
            return;
        }
        this.request = this.messages.request;
        this.form = this.messages.form;
        this.fields = this.messages.fields;
        this.success = this.messages.success;
        delete this.data;
        delete this.messages;
    }

    set request(requests) {
        if (isset(requests)) {
            if (is_string(requests)) {
                this.#request = { 0: requests };
            }
            if (is_array(requests)) {
                this.#request = arrayToObject(requests);
            }
            if (is_object(requests)) {
                this.#request = arrayToObject(Object.values(requests));
            }
        }
    }

    get request() {
        return this.#request;
    }

    set form(formResponses) {
        if (isset(formResponses)) {
            if (is_string(formResponses)) {
                this.#form = { 0: formResponses };
            }
            if (is_array(formResponses)) {
                this.#form = arrayToObject(formResponses);
            }
            if (is_object(formResponses)) {
                this.#form = arrayToObject(Object.values(formResponses));
            }
        }
    }

    get form() {
        return this.#form;
    }

    /**
     * @param {string|{}} fieldsIn A pipe delimited string containing the fieldName|message or an object with the field names as keys and messages as values.
     */
    set fields(fieldsIn) {
        if (isset(fieldsIn)) {
            if (is_string(fieldsIn)) {
                if (inString(fieldsIn, "|")) {
                    this.#fields = { [fieldsIn.split("|")[0]]: fieldsIn.split("|")[1] };
                    return;
                }
                localMessage(`Fields message supplied to MessagesObject as string without pipe (|) delimiter. No fields messages set.`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "set fields" });
            }
            if (is_array(fieldsIn)) {
                localMessage(`Fields message supplied to MessagesObject as array. Array is not supported. No fields messages set.`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "set fields" });
            }
            if (is_object(fieldsIn)) {
                this.#fields = fieldsIn;
            }
        }
    }

    get fields() {
        return this.#fields;
    }

    /**
     * Returns a specific fields message
     * @param {*} fieldName The name of the field
     * @returns {string} The field message
     */
    getfield(fieldName) {
        if (this.fields.hasOwnProperty(fieldName)) return this.fields[fieldName];
        return "";
    }
    getForm(index = 0) {
        if (this.form.hasOwnProperty(index)) return this.form[index];
        return "";
    }


    //End of MessagesObject Class
}

