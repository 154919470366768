import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Img } from '../../components/Img'
import GoogleLogoSmall from "../../images/google-logo-small.png"
import TRLogoFullSmall from "../../images/logo-full-small.png"

function Footer() {
    const history = useHistory();
    return (
        <footer className="footer text-center text-lg-start text-muted">

            <section
                className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom bg-white"
            >

                <div className="me-5 d-none d-lg-block">
                    <span>Get connected with us on social:</span>
                </div>


                <div>
                    <a href="/" className="me-4 text-reset">
                        <i className="fab fa-facebook-square" style={{ color: "#4267B2" }}></i>
                    </a>
                    <a href="/" className="me-4 text-reset">
                        <i className="fab fa-twitter" style={{ color: "#1DA1F2" }}></i>
                    </a>
                    <a href="/" className="me-4 text-reset mt-0 pt-0">
                        <img src={GoogleLogoSmall} alt="google logo" width="23px" />
                    </a>
                    <a href="/" className="me-4 text-reset">
                        <i className="fab fa-instagram" style={{ color: "#FD1D1D" }}></i>
                    </a>
                    <a href="/" className="me-4 text-reset">
                        <i className="fab fa-linkedin" style={{ color: "#0e76a8" }}></i>
                    </a>
                    <a href="/" className="me-4 text-reset">
                        <i className="fab fa-github" style={{ color: "#6cc644" }}></i>
                    </a>
                </div>

            </section>



            <section className="">
                <Container className="text-center text-md-start mt-5">

                    <Row className="mt-3">

                        <Col className="mx-auto mb-4" md={3} lg={4} xl={3}>

                            <div className="mb-4 mt-0 pt-0">
                                <img src={TRLogoFullSmall} alt="logo" style={{ maxWidth: "100%" }} />
                            </div>
                            <p>
                                We believe in equitable partnerships, targeted solutions, and clear communication.
                            </p>
                            {/*<!-- Start Verified By Bizapedia Site Seal Tag (Do Not Edit) -->*/}
                            <a href="https://www.bizapedia.com/nj/trinityrecode-llc.html" target="blank" tabIndex="-1">
                                <img style={{ width: "142px", height: "52px", border: "none", display: "block" }}
                                    src="https://www.bizapedia.com/seal/?p=nj/trinityrecode-llc" alt="Company profile for Trinityrecode LLC."
                                    onContextMenu={() => { alert('Use without permission is prohibited. Please use the left mouse button to view the company profile.'); return false; }} />
                            </a>
                            {/*<!-- End Verified By Bizapedia Site Seal Tag -->*/}

                        </Col>



                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                            <h6 className="text-uppercase fw-bold mb-4">
                                Services
                            </h6>
                            <p>
                                <a href="/Services/WebDev" className="text-reset">Web Development</a>
                            </p>
                            <p>
                                <a href="/Services/Consulting" className="text-reset">Technology Consulting</a>
                            </p>
                            <p>
                                <a href="/Services/SiteRefurb" className="text-reset">Site Refurbishment</a>
                            </p>
                            <p>
                                <a href="/Services/Solutions" className="text-reset">Custom Solutions</a>
                            </p>
                        </div>



                        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

                            <h6 className="text-uppercase fw-bold mb-4">
                                Useful links
                            </h6>
                            <p>
                                <a href="/Contact?select=Customer_Inquiry" className="text-reset">Pricing</a>
                            </p>
                            <p>
                                <a href="/Login" className="text-reset">Quotes</a>
                            </p>
                            <p>
                                <a href="/Login" className="text-reset">Orders</a>
                            </p>
                            <p>
                                <a href="/Contact?select=General_Question" className="text-reset">Help</a>
                            </p>
                        </div>

                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

                            <h6 className="text-uppercase fw-bold mb-4">
                                Contact Us
                            </h6>
                            <p className='d-flex align-items-center'><i className="fas fa-home me-3" onClick={() => window.location.assign("https://www.google.com/maps/place/Red+Bank,+NJ/@40.3486968,-74.0694255,17.25z/data=!4m5!3m4!1s0x89c22e05387272ff:0xc63def69afbc1a2f!8m2!3d40.3470543!4d-74.0643065")}></i>Red Bank, NJ 07701 US</p>
                            <p className='d-flex align-items-center' >
                                <i className="fas fa-envelope me-3" onClick={() => window.location.assign("mailto:webmaster@trinityrecode.com")}></i>
                                webmaster@trinityrecode.com
                            </p>
                            <p className='d-flex align-items-center'><i className="fas fa-phone me-3" onClick={() => window.location.assign("tel:+7328527431")}></i>732-852-7431</p>

                        </div>

                    </Row>

                </Container>
            </section>



            <div className="text-center p-4" style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                © 2021 Copyright:
                <a className="text-reset fw-bold" href="https://trinityrecode.com/">TrinityRecode.com</a>
            </div>

        </footer>
    )
}

export default Footer
