import React, { useContext, useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom';
import { Container, Button, Navbar, Nav } from 'react-bootstrap';
import { authContext } from '../router/ProvideAuth';
import './navigation.scss';
import { ProfileDds } from './parts/ProfileDds';
import LogoSmall from "../../images/logo.png"

//TODO: Build profile dropdown, profile picture icon, and page to create and modify profile


function Navigation() {
    const [currentLocation, setCurrentLocation] = useState("/About");
    const auth = useContext(authContext);
    let history = useHistory();


    useEffect(() => {
        let mounted = true;
        if (currentLocation !== window.location.pathname) {
            if (mounted) setCurrentLocation(window.location.pathname);
        }
        return () => {
            mounted = false;
        }
    }, [history.location, currentLocation]);


    return (
        <React.Fragment>
            <Navbar bg="light" variant="light" expand="lg" style={{ zIndex: 2147483647 }}>
                <Container className="" fluid>
                    <Link className="navbar-brand font-weight-bold" to="/Home"><img src={LogoSmall} alt="logo" style={{ maxWidth: "30px" }} /><div className="d-inline">TrinityRecode</div></Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="nav-bar-links me-auto" >

                            <Link
                                className={`nav-link ${currentLocation === "/Home" ? "active" : ""}`}
                                onClick={() => setCurrentLocation("/Home")}
                                to="/Home">Home</Link>
                            <Link
                                className={`nav-link ${currentLocation === "/Services" ? "active" : ""}`}
                                onClick={() => setCurrentLocation("/Services")}
                                to="/Services">Services</Link>
                            <Link
                                className={`nav-link ${currentLocation === "/Contact" ? "active" : ""}`}
                                onClick={() => setCurrentLocation("/Contact")}
                                to="/Contact">Contact</Link>
                            <Link
                                className={`nav-link ${currentLocation === "/About" ? "active" : ""}`}
                                onClick={() => setCurrentLocation("/About")}
                                to="/About">About</Link>



                            {auth.user.isAuth ? <React.Fragment>
                                <Link
                                    className={`nav-link ${currentLocation === "/Dashboard" ? "active" : ""}`}
                                    onClick={() => setCurrentLocation("/Dashboard")}
                                    to="/Dashboard">Dashboard</Link>

                                <Link
                                    className={`nav-link ${(currentLocation === "/Setup" || currentLocation === "/Setup/Profile") ? "active" : ""}`}
                                    onClick={() => setCurrentLocation("/Setup")}
                                    to="/Setup">Setup</Link>
                            </React.Fragment> : <React.Fragment></React.Fragment>}
                        </Nav>
                    </Navbar.Collapse>
                    {!auth.user.isAuth ? <Link
                        className="btn btn-outline-primary"
                        onClick={() => setCurrentLocation("/Login")}
                        to="/Login">Login</Link>
                        : <div className="d-flex align-items-center text-white justify-content-end">
                            <ProfileDds
                                className="pe-2"
                                siteAuth={auth}
                                setCurrentLocation={setCurrentLocation}
                            />
                            <Button variant="outline-primary"
                                onClick={() => {
                                    auth.signout(() => history.push("/"), auth.session);
                                }}
                            >
                                Sign out
                            </Button>
                        </div>}
                </Container>
            </Navbar>
        </React.Fragment>
    )
}

export default Navigation
