import Axios from "axios";
import APIReturn from "../classes/APIReturns/APIReturn";
import { getEmptyResponse, isset, propExists } from "./library";
import { IS_DEVELOPMENT_VERSION, DEV_IP_HEADER } from "./siteDefaults";

/**
 * 
 * @param {*} method 
 * @param {*} url 
 * @param {*} headers 
 * @param {*} body 
 * @param {*} requiresAuth 
 */
export const callAxios = async (method = "", url = "", headers = {}, body = {}) => {
    method = method.toLowerCase();
    if (IS_DEVELOPMENT_VERSION) headers = { [DEV_IP_HEADER[0]]: DEV_IP_HEADER[1], ...headers };
    let ApiReturn = new APIReturn(null);
    let response = null;
    const axiosPayload = isset(body) ? {
        method,
        url,
        data: body,
        headers,
    } : {
        method,
        url,
        headers,
    };
    try {
        Axios.defaults.withCredentials = true;
        response = await Axios(axiosPayload);
        ApiReturn = new APIReturn(propExists(response, "data"));
    } catch (error) {
        if (error.hasOwnProperty("response")) {
            if (isset(error.response) && error.response.hasOwnProperty("data")) {
                return error.response.data;
            }
        }
        return getEmptyResponse();
    }

    return ApiReturn;

}
