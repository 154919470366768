//eslint-disable-next-line
'use strict';
import { detect } from "detect-browser";
import { isEqual, difference, toString, toArray, toPlainObject } from 'lodash';
import { ACCESSTOKEN_LENGTH, REFRESHTOKEN_NAME, ACCESSTOKEN_REFRESH_FREQUENCY, DEBUG, THROW_ERROR_LOCATION } from "./siteDefaults";

// ##### CONSTANTS ########## CONSTANTS ########## CONSTANTS ########## CONSTANTS #####
export const VALIDATE_NOTBLANK = "NOTBLANK";

export const VALIDATE_CHECKBOX = "CHECKBOX";

export const VALIDATE_INTEGERS = "INTEGERS";

export const VALIDATE_NUMBERS = "NUMBERS";

export const VALIDATE_CURRENCY = "CURRENCY";

export const VALIDATE_LETTERS = "LETTERS";

export const VALIDATE_TEXT_SINGLE_LINE = "TEXT_SINGLE_LINE";

export const VALIDATE_TEXT_MULTI_LINE = "TEXT_MULTI_LINE";

export const VALIDATE_PHONE_NUMBER = "VALIDATE_PHONE_NUMBER";

export const VALIDATE_EMAIL_ADDRESS = "VALIDATE_EMAIL_ADDRESS";
export const VALIDATE_EMAIL_ADDRESS_SINGLE = "VALIDATE_EMAIL_ADDRESS_SINGLE";

export const VALIDATE_SAFE = "SAFE";

export const VALIDATE_PASSWORD = "PASSWORD";

export const VALIDATE_LIST = "LIST";

export const VALIDATE_FILE = "FILE";

//#####* REACT DEVELOPMENT HELPERS *#####* REACT DEVELOPMENT HELPERS *#####* REACT DEVELOPMENT HELPERS *#####*

/**
 * Tests if strict mode is emabled, returing true if 'use strict' is set on the current script.
 * @returns {Boolean} True if 'use strict' stict mode is enabled, false otherwise.
 */
export const isStrictMode = () => {
    try {
        // eslint-disable-next-line
        test = "test";
        if (isset(test)) return false;
    } catch (error) {
        return true;
    }
    return true;
}

/**
 * Clones the console log function to a seperate global variable to be used if the standard console object has been disabled or otherwise becomes inaccessible.  Is required to be placed before the render in index.js when using the hook useMyConsole.
 * @returns void
 */
export const shadowCopyConsoleLog = () => {
    if (!window.hasOwnProperty("consoleLog")) {
        window["consoleLog"] = {
            assert: console.assert,
            clear: console.clear,
            debug: console.debug,
            log: console.log,
            dir: console.dir,
            info: console.info,
            warn: console.warn,
            error: console.error,
            trace: console.trace,
            count: console.count,
            countReset: console.countReset,
            time: console.time,
            timeEnd: console.timeEnd,
            timeLog: console.timeLog,
            timeStamp: console.timeStamp,
            group: console.group,
            groupCollapsed: console.groupCollapsed,
            groupEnd: console.groupEnd,
        };
        Object.freeze(window["consoleLog"]);
    }
    if (propExists("window.consoleLog.log") && window.console.log.toString() !== window.consoleLog.log.toString()) {
        window["console"]["log"] = window.consoleLog.log;
    }
}

export const sleep = (delay) => {
    var start = new Date().getTime();
    while (new Date().getTime() < start + delay);
};

//#####* VARIABLE CONTENT HELPERS *#####* VARIABLE CONTENT HELPERS *#####* VARIABLE CONTENT HELPERS *#####*


export const is_boolean = (varIn) => {
    if (typeof varIn === "boolean" && (varIn === true || varIn === false)) return true;
    return false;
}

export const is_string = (varIn) => {
    if (typeof varIn === "string") return true;
    return false;
};

export const is_number = (varIn) => {
    if (!isNaN(varIn) && typeof varIn === "number") return true;
};

export const isFloat = (varIn, strict = true) => {
    if (!strict && is_string(varIn)) varIn = parseFloat(varIn);
    if (isNaN(varIn) || typeof varIn !== "number") return false;
    if (varIn.toString().split(".").length > 1) return true;
    return false;
}

/**
 * Ensures js object is a true array type
 * @param {Mixed} varIn 
 * @returns false if not an Array - length if is Array
 */
export const is_array = (varIn) => {
    if (varIn === null || varIn === undefined) return false;
    try {
        varIn.push("Test");
        varIn.pop();
        varIn.unshift("Test");
        varIn.shift();
        return true;
    } catch (e) {
        return false;
    }
    // if (typeof varIn === "object" && varIn.hasOwnProperty("length")) return true;
    //  return false;
};

export const is_object = (varIn) => {
    if (varIn === null || varIn === undefined) return false;
    try {
        varIn.push("Test");
        varIn.pop();
        varIn.unshift("Test");
        varIn.shift();
        return false;
    } catch (e) {
        if (typeof varIn === "object") return true;
        return false;
    }
};

export const is_symbol = (varIn) => {
    return getType(varIn, "symbol");
}

/**
 * Tests if the supplied 'varIn' is a function.
 * @param {*} varIn The variable to test for function type.
 * @returns {boolean} True if a valid function.
 */
export const is_function = (varIn) => {
    return varIn instanceof Function;
}

/**
 * Tests the equality of two function definitions - does not test their results. This can determine if a callBack function has been changed, etc. Note: If a non-function value is supplied to either argument, the function will call 'areEqual' and return that result.
 * @param {function} functionOne The function to test against 'functionTwo'.
 * @param {function} functionTwo The function to test against 'functionOne'.
 * @param {boolean} throwError If true, will throw error on invalid argument - i.e. supplying a non-function.
 * @returns {boolean} True if the functions are equal.
 */
export const functionsAreEqual = (functionOne, functionTwo, throwError = true) => {
    if (DEBUG) throwError = true;
    if (!is_function(functionOne) || !is_function(functionTwo)) {
        const invalidParam = !is_function(functionOne) ? "functionOne" : "functionTwo";
        const types = { functionOne: getType(functionOne), functionTwo: getType(functionTwo) };
        if (throwError) localMessage(`Paramater '${invalidParam}' should be a valid function for accurate comparison. Type ${types[invalidParam]} was supplied. Returning result of function 'areEqual' instead.`, `${THROW_ERROR_LOCATION}`, "WARNING", { function: "functionsAreEqual" });
        return areEqual(functionOne, functionTwo);
    }
    return functionOne.toString() === functionTwo.toString();
}

/**
 * Tests if a variable can be iterated over, i.e. it is an object or an array.
 * @param {*} varIn The variable to test.
 * @returns {boolean} True if the value can be iterated over. This is true if it is an array or object type. False if any other type.
 */
export const is_iterable = (varIn) => {
    if (varIn === undefined || varIn === null) return false;
    if (is_array(varIn)) return true;
    if (is_object(varIn)) return true;
    return false;
}

/**
 * Determines if a variable has been set by the user to a value other than null or undefined.  By default, typing a variable to an empty Object, Array, or String will return false. i.e. These are considered as NOT SET, since they are empty. Optionally, an empty string, array or object returns can be set in the options to return a specific value if not set. e.g. if you wish an empty object to be considered as SET, assign object:true in the 'empty' paramater. NOTE: A Zero (0) integer is considered as set, and will return true.  A boolean false is considered as set, and will return true.
 * @param {*} varIn A variable to be checked for a set value.
 * @param {{string:boolean,array:boolean,object:boolean}} empty The return value if the function encounters an empty string, array, or object. Default: False.
 * @returns {boolean} True if 'varIn' has an actual value set, false otherwise.
 */
export const isset = (varIn, empty = { string: false, array: false, object: false }) => {
    if (varIn === null) return false;
    if (varIn === undefined) return false;

    if (is_string(varIn)) {
        if (varIn === "") return empty.string || false;
        return true;
    }

    if (is_object(varIn)) {
        if (!Object.keys(varIn).length) return empty.object || false;
        return true;
    }
    if (is_array(varIn)) {
        if (!varIn.length) return empty.array || false;
        return true;
    }

    if (varIn === true) return true;
    if (varIn === false) return true;
    if (varIn === 0) return true;
    return true;
}

/**
 * Determines if an array of variables each have been set by the user to a value other than null or undefined.  By default, typing a variable to an empty Object, Array, or String will return false. i.e. These are considered as NOT SET, since they are empty. Optionally, an empty string, array or object returns can be set in the options to return a specific value if not set. e.g. if you wish an empty object to be considered as SET, assign object:true in the 'empty' paramater. NOTE: A Zero (0) integer is considered as set, and will return true.  A boolean false is considered as set, and will return true.
 * @param {[] | {}} arrayOfVars An array of values to check for values. Calls the function 'isset' for each element of the array. Will accept an object, but discards keys. This function will exit at the first encounter of a non-set element.
 * @param {{string:boolean,array:boolean,object:boolean}} empty The return value if the function encounters an empty string, array, or object. Default: False.
 * @returns {boolean} True if 'arrayOfVars' has an actual values set for each element, false otherwise.
 */
export const issetAll = (arrayOfVars, empty = { string: false, array: false, object: false }) => {
    if (!isset(arrayOfVars)) return false;
    if (is_object(arrayOfVars)) arrayOfVars = Object.values(arrayOfVars);
    if (!is_array(arrayOfVars)) return false;
    for (let index = 0; index < arrayOfVars.length; index++) {
        const varIn = arrayOfVars[index];
        if (!isset(varIn, empty)) return false;
    }
    return true;
}

/**
 * Tests if supplied value is both set and is a string. See isset() & is_string() for rules.
 * @param {*} varIn A variable to test for string type and a present value.
 * @param {[...string] | string} reject A string, or array of string values to test against 'varIn'.  A positive match will result in a FALSE return from this function.
 * @returns {boolean} True if 'varIn' contains a valid value and is a string type.
 */
export const stringIsset = (varIn, reject = []) => {
    if (!isset(varIn) || !is_string(varIn)) return false;
    if (is_string(reject) && varIn === reject) return false;
    if (is_array(reject) && isIndexOf(reject, varIn)) return false;
    return true;
}

/**
 * Determines if a variable, property, or element both has been declared and exists with any value other than undefined. Null is considered a value in this instance. If an object or array is supplied with the 'optionalPropertyOrIndex' param, 'exists' will check if that property or index exists withing the supplied 'varIn' object or array.
 * @param {*} varIn The variable or variable property's value to test for existance.
 * @param {string | number} optionalPropertyOrIndex The optional property or index to test for existance.
 * @returns {boolean} True if exists, false otherwise.
 */
export const exists = (varIn, optionalPropertyOrIndex) => {
    const OPI = optionalPropertyOrIndex;
    const varType = getType(varIn);
    if (typeof varIn === "undefined") return false;
    if (varType === "function") return true;
    if (varType === "object" || varType === "array") {
        if (!isset(OPI)) return true;
        if (varType === "object") {
            if (varIn.hasOwnProperty(OPI)) return true;
            if (isset(varIn[OPI])) return true;
        }
        if (varType === "array") {
            if (OPI === "length" && varIn.hasOwnProperty(OPI)) return true;
            if (!isNaN(parseInt(OPI)) && varIn.length > parseInt(OPI)) return true;
        }
        return false;
    }
    return true;
}

/**
 * Checks and returns the result of an object prop tree, reperesented as a string value. i.e. Do not submit the actual variable, but submit it in quotes as: "object.param1.param2.valueToGet". The function will progressivly drill down, throwing a null value if any level is undefined. The root object must be supplied in 'objectRoot'.  This produces a similar result to using object?.prop except will return null if not found, as opposed to undefined.
 * @param {{} | []} objectRoot An object or array who's nested property or array you wish to access.
 * @param {string} propString The string representation of an object and it's property tree to value target. DO NOT include the objectRoot in the propString. This will result in null.
 * @param {*} nullValue A substitute for the returned null value if the target property does not exist. e.g. return a boolean FALSE instead of null. Default: null
 * @returns {* | null} The resulting value of the supplied object string, true if the value is just a string, and null if anything is undefined.
 */
export function propExists(objectRoot, propString, nullValue = null) {
    if (!isset(objectRoot) || !isset(propString)) return nullValue;
    if ((!is_object(objectRoot) && !is_array(objectRoot)) || !is_string(propString)) return nullValue;
    const keyArray = propString.split(".");
    let obj = objectRoot;
    if (!exists(objectRoot)) return nullValue;
    for (let index = 0; index < keyArray.length; index++) {
        if (!exists(obj, keyArray[index])) return nullValue;
        obj = obj[keyArray[index]];
    }
    //if (parseStr(obj) === parseStr(objectRoot)) return nullValue;
    return isset(obj) ? obj : nullValue;
}

export const boolval = (varIn) => {
    return Boolean(varIn);
}

export const intval = (varIn) => {
    if (is_boolean(varIn)) {
        if (varIn) return 1;
        return 0;
    }
    if (!is_iterable(varIn) && !is_function(varIn) && isset(varIn)) return parseInt(varIn);
    if (is_object(varIn)) return objectLen(varIn, true);
    if (is_array(varIn)) return varIn.length;
    return 0;
}

export const floatval = (varIn) => {
    if (!is_iterable(varIn) && !is_function(varIn) && isset(varIn)) return parseFloat(varIn);
    if (is_object(varIn)) return objectLen(varIn, true);
    if (is_array(varIn)) return varIn.length;
    return 0.0;
}

export const strval = (varIn) => {
    return toString(varIn);
}

export const arrval = (varIn) => {
    return toArray(varIn);
}

export const objval = (varIn) => {
    return toPlainObject(varIn);
}


/**
 * Type checks a variable and returns the type.  Optionally will will return a Boolean checked against the type supplied in testVal.
 * @param {Any} varIn Reguired: The variable to type check.
 * @param {String} testVal Optional: String value indicating which type should return true.
 * @returns {String|Boolean} The a string representation of the type unless testVal is used, then return Boolean if testVal matches the type of varIn.
 */
export const getType = (varIn, testVal = undefined) => {
    let varType;

    if (varIn === null) varType = "null";
    if (varIn === undefined) varType = "undefined";
    if (!isset(varType) && is_boolean(varIn)) varType = "boolean";
    if (!isset(varType) && is_string(varIn)) varType = "string";
    if (!isset(varType) && is_array(varIn)) varType = "array";
    if (!isset(varType) && is_object(varIn)) varType = "object";
    if (!isset(varType) && varIn instanceof Function) varType = "function";
    if (!isset(varType)) varType = typeof varIn;

    switch (testVal) {
        case undefined:
        case null:
            return varType;

        default:
            return testVal.toLowerCase() === varType;
    }
}

/**
 * Returns an array's element's variable types in a supplied array or object. If a searchType is supplied, then an array of only those types will be returned.  This is useful to alternately count the number of a specific variable type in an object or an array.
 * @param {[]|{}} arrayOrObjectIn The array or object to search within (only one level deep).
 * @param {string | []} searchType A variable type to search for: (null, undefined, boolean, number, string, array, object, function).  May be an array of variable types.
 * @param {boolean} throwError If True, will produce an error on invalid arguments.
 * @returns {[..."variableType"]} An array of variable types
 */
export const getTypes = (arrayOrObjectIn, searchType = undefined, throwError = false) => {
    if (DEBUG) throwError = true;
    if (!is_array(arrayOrObjectIn) && !is_object(arrayOrObjectIn)) {
        if (throwError) localMessage(`Parameter 'arrayOrObjectIn' must be either an array or an object. Type ${getType(arrayOrObjectIn)} was supplied. Empty array returned.`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "getTypes" });
        return [];
    }
    if (is_number(searchType) || is_boolean(searchType) || getType(searchType, "symbol") || getType(searchType, "function")) {
        if (throwError) localMessage(`Parameter 'searchType' must be either an array, a string, or not set. Type ${getType(arrayOrObjectIn)} was supplied. Empty array returned.`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "getTypes" });
        return [];
    }
    const returnTypes = [];
    let varIn = arrayOrObjectIn;
    if (getType(varIn, "object")) varIn = Object.values(arrayOrObjectIn);
    varIn.forEach((value) => {
        if (!isset(searchType)) return returnTypes.push(getType(value));
        if (is_array(searchType)) {
            searchType.forEach((type) => {
                if (getType(value, type)) return returnTypes.push(type);
            });
        }
        if (is_string(searchType) && getType(value, searchType)) returnTypes.push(getType(value));
    });
    return returnTypes;
}

/**
 * Converts a variable to the target type, if possible.
 * @param {*} varIn The variable to convert
 * @param {*} targetType The target type. Must be one of: null, undefined, boolean, string, array, object
 * @param {*} throwError If true, will throw an error and terminate execution on error. False will return varIn unchanged.
 * @returns The converted variable, if possible. 
 */
export const convertType = (varIn, targetType, throwError = false) => {
    if (DEBUG) throwError = true;
    if (!isset(varIn)) {
        if (throwError) localMessage(`Invalid argument #1 - must supply a variable to convert.`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "convertType" });
        return varIn;
    }
    if (!isset(targetType)) {
        if (throwError) localMessage(`Invalid argument #2 - must supply a target type to convert to.`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "convertType" });
        return varIn;
    }
    switch (targetType.toLowerCase()) {
        case "null":
            return null;
        case "undefined":
            return undefined;
        case "boolean":
            return boolval(varIn);
        case "string":
            return strval(varIn);
        case "array":
            return arrval(varIn);
        case "object":
            return objval(varIn);
        default:
            if (throwError) localMessage(`Invalid argument #2 - not a valid type to convert to. Must be one of: null, undefined, boolean, string, array, object`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "convertType" });
            return varIn;
    }
}

/**
 * Returns the caclulated length of supplied variable, given the expected variable type.  Will return 0 if the an unexpected type is supplied.
 * @param {*} varIn The variable whos length you want returned.
 * @param {*} expectedTypes The expected types of variables whos lengths should be calculated.
 * @returns {number} The length of the supplied variable, given its expected type.
 */
export const thisLength = (varIn, expectedTypes = ["string", "object", "array", "number", "symbol"]) => {
    let returnLen = 0;
    const thisType = getType(varIn);
    if (is_object(expectedTypes)) expectedTypes = Object.values(expectedTypes);
    if (is_string(expectedTypes) && inString(expectedTypes, "|")) expectedTypes = expectedTypes.split("|");
    if (is_string(expectedTypes)) expectedTypes = [expectedTypes];
    expectedTypes.forEach((expectedType) => {
        const testType = expectedType.toLowerCase();
        if (thisType === testType) {
            if (testType === "string") returnLen = varIn.length;
            if (testType === "object") returnLen = Object.values(varIn).length;
            if (testType === "array") returnLen = varIn.length;
            if (testType === "number") returnLen = parseStr(varIn).length;
            if (testType === "boolean") returnLen = parseStr(varIn).length;
            if (testType === "symbol") returnLen = parseStr(varIn.description).length
        }
    });
    return returnLen;
}

/**
 * |EXPERIMENTAL| Tests if two variables are equal.  Ignores memory location equalivalance for Objects, Arrays, and Symbols and focuses on contents.  If objects have circular references, will check loose equality and only 3 levels deep.
 * @param {*} varOne 
 * @param {*} varTwo 
 * @returns 
 */
export const areEqual = (varOne, varTwo) => {
    const vars = [varOne, varTwo];
    const types = getTypes(vars);
    if (types[0] !== types[1]) return false;
    switch (types[0]) {
        case "undefined":
        case "null":
        case "number":
        case "string":
            break;
        case "object":
            return objectsAreEqual(varOne, varTwo);
        case "array":
            varOne = arrayCopy(varOne).join("-");
            varTwo = arrayCopy(varTwo).join("-");
            break;
        case "function":
            varOne = JSON.stringify(varOne);
            varTwo = JSON.stringify(varTwo);
            break;
        case "symbol":
            varOne = Symbol.description
            varTwo = Symbol.description
            break;

        default:
            break;
    }
    return varOne === varTwo;
}

//####* NUMERIC HELPERS *########* NUMERIC HELPERS *########* NUMERIC HELPERS *########* NUMERIC HELPERS *##*


/**
 * Always returns an integer.
 * @param {*} integer Anything that you want to be treated as an integer.
 * @param {*} alwaysPositive If true, any negative value will be returned as true.
 * @returns {number} A floored integer. Returns 0 if anything that is not a number or can't be parsed as a number is supplied.
 */
export const wholeNumber = (integer, alwaysPositive = false) => {
    const intIn = parseInt(integer);
    if (intIn < 1 || isNaN(intIn) || intIn === null || intIn === undefined) {
        return 0;
    }
    if (alwaysPositive && intIn < 0) return intIn * -1;
    return intIn;
};

/**
 * An alias of wholeNumber. 
 * @param {*} integer Anything that you want to be treated as an integer and floored.
 * @param {*} alwaysPositive If true, any negative value will be returned as true.
 * @returns {number} A floored integer. Returns 0 if anything that is not a number or can't be parsed as a number is supplied.
 */
export const floor = (integer, alwaysPositive = false) => {
    return wholeNumber(integer, alwaysPositive);
}


export const getRandomNum = (min = 1, max = 100000000) => {
    //const ranNum = Math.floor(Math.random() * (max - min)) + min;
    //const dateadder = (Date.now() / max) * ranNum;
    //return Math.floor(ranNum + dateadder);
    return Math.floor(Math.random() * (max - min)) + min;
};


/**
 * Rounds a number to a specified decimal place, returning an integer, float, or string (if padEnd is true).
 * @param {number} number Any number or string formatted as a number that you wish to round.
 * @param {number} decimalPlace An integer indicating how many decimal places to round to. Default: 2
 * @param {Boolean} padEnd Pads the end of the return value with zeros to the requested number of decimal places.
 * @returns {Number|String} Returns the number rounded to the requested decimal place.  If padEnd is set to TRUE, will return a string.
 */
export const roundTo = (number, decimalPlace = 2, padEnd = false, throwError = true) => {
    decimalPlace++;
    if (is_string(number)) {
        number = formatNumStripCurrency(number);
    }
    if (is_number(number)) number = parseFloat(number);
    if (isNaN(number)) {
        if (throwError) localMessage(`Function 'roundTo' expects paramater 'number' to be an integer, float, or a string formatted to currency. ${getType(number)} was supplied. A zero value was substituted.`, "log", "error");
        number = 0;
    }
    const one = "1";
    const multiplier = parseInt(one.padEnd(parseInt(decimalPlace), 0));
    const rounded = Math.round((number + Number.EPSILON) * multiplier) / multiplier;
    let newRounded = rounded;
    if (rounded.toString().split(".").length < 2) {
        if (padEnd) {
            newRounded = rounded + ".";
            newRounded = newRounded.padEnd((newRounded.length + decimalPlace - 1), "0");
        }
    } else if (rounded.toString().split(".").length > 1 && rounded.toString().split(".")[1].length < decimalPlace - 1) {
        if (padEnd) {
            decimalPlace--;
            newRounded = rounded.toString().split(".")[0] + "." + rounded.toString().split(".")[1].padEnd(decimalPlace, 0);
        }
    }
    return newRounded;
};


//####* NUMERIC STRING HELPERS *########* NUMERIC STRING HELPERS *########* NUMERIC STRING HELPERS *########*



/**
 * Strips all non numeric or decimal characters and returns a float point number.
 * @param {*} numberIn String with currency or other non numeric characters to be stripped and converted.
 * @returns Float formatted number
 */
export const formatNumStripCurrency = (numberIn) => {
    if (is_string(numberIn)) {
        const newNum = parseFloat(numberIn.replaceAll(/[^0-9.-]/g, "").trim());
        return newNum;
    }
    return parseFloat(numberIn);
}

/**
 * Converts a number to a currency formatted string, complete with comma's seperating one thousand. e.g. 3047.5 to $3,047.50
 * @param {number} numberIn 
 * @returns {string} A string containing the number rounded and padded to 2 decimals with an added dollar sign. "$1.00"
 */
export const formatNumberToCurrency = (numberIn = 0) => {
    if (!is_number) {
        numberIn = parseFloat(numberIn.replace(/[^0-9.$\s]/, "").trim());
    }
    numberIn = `${roundTo(numberIn, 2, true)}`;
    const wholeNumber = numberIn.split(".")[0];
    const decimalsPlace = numberIn.split(".")[1];
    const wholeNumberArray = wholeNumber.split("");
    const originalLegnth = wholeNumberArray.length;
    let commaTicker = 0;
    let newNumberArray = [];
    for (let index = 0; index < originalLegnth; index++) {
        const thisNum = wholeNumberArray.pop();
        newNumberArray.unshift(thisNum);
        commaTicker++;
        if (commaTicker === 3) {
            commaTicker = 0;
            newNumberArray.unshift(",");
        }
    }
    return `$${newNumberArray.join("").replace("-,", "-").replace(/^,/, "")}.${decimalsPlace}`;
}



//####*  STRING HELPERS *########*  STRING HELPERS *########*  STRING HELPERS *########*  STRING HELPERS *###*



/**
 * Searches a string for a sub-string. Returns true on success, false on failure.
 * @param {string} haystack The string to search within.
 * @param {string | number} needle The string value to search for.
 * @param {*} throwError 
 * @returns 
 */
export const inString = (haystack, needle, throwError = false) => {
    if (!is_string(haystack) || !isset(haystack)) {
        if (throwError) localMessage(`Function 'inString' parameter 'haystack' expects a string type. ${getType(haystack)} type was supplied.`, 'throw', 'error', { function: "inString" });
        return false;
    }
    if (!isset(needle)) {
        if (throwError) localMessage(`Function 'needle' parameter was empty.`, 'throw', 'error', { function: "inString" });
        return false;
    }
    if (is_number(needle)) needle = parseStr(needle);
    if (!is_string(needle)) needle = needle.toString();
    if (haystack.search(needle) > -1) return true;
    return false;
}

/**
 * Capitalizes the first letter of the supplied string in 'varIn'. All othe variable types are returned unaltered.
 * @param {string | *} varIn The string whos first letter you want capitalized.
 * @returns {string | *} The supplied string with a capitalized first letter.  Returns the unaltered varIn if anything but a string is supplied.
 */
export const capitalizeFirstLetter = (varIn) => {
    if (typeof varIn !== "string") return varIn;
    varIn = varIn.toLowerCase();
    return varIn.charAt(0).toUpperCase() + varIn.slice(1).toLowerCase();
};

/**
 * Capitalizes the first letter of every word supplied in 'textIn'.
 * @param {string} textIn 
 * @returns {string} The supplied 'textIn' string converted to title case. 
 */
export const textToTitleCase = (textIn = "") => {
    if (!is_string(textIn)) {
        localMessage(`Paramater 'textIn' must be a string. ${getType(textIn)} was supplied and returned unaltered.`, "log", "warning");
        return textIn;
    }
    const textInArray = textIn.trim().split(" ");
    const returnString = [];
    textInArray.forEach((word) => {
        returnString.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    });
    return returnString.join(" ");
}

/**
 * Converts a non-string value to a string type (if possible) and returns its string reperesentation.  Objects are JSON.stringify, Arrays are joined using a comma. Numbers are returned in template literal.
 * @param {*} value Any value to return as a string.
 * @returns {string} The string representation of the supplied 'value'.
 */
export const parseStr = (value, neverEmpty = true) => {
    if (!neverEmpty && (value === null || value === undefined)) return "";
    if (is_object(value)) return JSON.stringify(value);
    if (is_array(value)) return `[${value.join(",")}]`;
    if (is_symbol(value)) {
        const desc = value.description;
        if (is_object(desc)) return `${JSON.stringify(desc)}`;
        if (!neverEmpty && (desc === null || desc === undefined)) return "";
        return `${value.description.toString()}`
    }
    if (typeof value === "function") return value.toString();
    return `${value}`;
};

export const objectToString = (objectIn, throwError = false) => {
    if (DEBUG) throwError = true;
    try {
        return JSON.stringify(objectIn);
    } catch (error) {
        if (throwError) localMessage(`Unable to convert object to string using JSON.stringify. Error:${error.message}. Fallback used.`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "objectToString" });
    }
    return objectIn.toString();   //FIXME: Need better implimentation

}

/**
 * Converts a string character to the ASCII or UTF-16 (capped at 65535) code equivalent.
 * @param {String} character Any valid ASCII character. NOTE: characters such as NULL are rejected by default, but can be allowed if 'limitPrintable' set to false.
 * @param {Boolean} throwError Will throw and error on error if set to true. Returns empty string otherwise.
 * @param {Boolean} limitPrintable True to restrict character values under 32. e.g. NULL, LINEFEED, RETURN, etc.
 * @returns {Number|String} The ASCII or UTF-16 numerical code equivalent. Returns an empty string on failure.
 */
export const convertCharToASCII = (character, throwError = true, limitPrintable = true) => {
    let error = false;
    if (is_string(character)) {
        const charCode = character.charCodeAt(0);
        if (charCode > 65535) error = "Character code exceeded cap. Limited at 65535.";
        if (limitPrintable && charCode < 32) error = "Character value was less than 32 & 'limitPrintable' was set to true.";
        if (isNaN(charCode)) error = "Does not appear to be a valid character.";
        if (!error) return charCode;
    }
    if (!error) error = "Supplied 'character' must be a string.";
    if (throwError) localMessage(`Supplied paramater 'character' in function convertCharToASCII did not produce an ASCII match. Empty String returned. Error: ${error}`, `${THROW_ERROR_LOCATION}`, "error");
    return "";
}

/**
 * Converts an ASCII or UTF-16 numerical code to its string equivalent. 
 * @param {Number} numericalASCII Any valid numerical ASCII or UTF-16 character code. NOTE: characters such as NULL are rejected by default, but can be allowed if 'limitPrintable' set to false. IMPORTANT: Character codes exceeding 65535 are always rejected.
 * @param {Boolean} throwError Will throw and error on error if set to true. Returns empty string otherwise.
 * @param {Boolean} limitPrintable True to restrict character values under 32. e.g. NULL, LINEFEED, RETURN, etc.
 * @returns {String} The ASCII or UTF-16 numerical code string equivalent.
 */
export const convertASCIIToChar = (numericalASCII, throwError = true, limitPrintable = true) => {
    let error = false;
    if (is_string(numericalASCII)) numericalASCII = parseInt(numericalASCII.trim());
    if (is_number(numericalASCII)) {
        if (numericalASCII > 65535) error = "Character code exceeds cap. Limited at 65535.";
        const charToReturn = String.fromCharCode(numericalASCII);
        if (limitPrintable && numericalASCII < 32) error = "Character value was less than 32 & 'limitPrintable' was set to true.";
        if (!error && isset(charToReturn)) return charToReturn;
    }
    error = "Please supply a numerical value for 'numericalASCII'.";
    if (throwError) localMessage(`Supplied paramater 'numericalASCII' in function convertASCIIToChar did not produce a character match. Empty String returned. Error: ${error}`, `${THROW_ERROR_LOCATION}`, "error");
    return "";
}

/**
 * Filters a 2 dimensional Object or Boolean and converts boolean values to specified textual option.
 * @param {*} valuesIn A 2 dimensional Object or Boolean containing boolean value(s) to convert.
 * @param {*} returnOption A String signifing the return text option. YESNO, TRUEFALSE, CUSTOM 
 * @param {*} customObject Optional Object containing the custom text substitutes for true and false
 * @returns Mixed: The modified Object with converted Boolean to String values, or string depending on input.
 */
export const boolToText = (valuesIn, returnOption = "YESNO", customObject = { true: "", false: "" }) => {
    // valuesIn should be 2 dimensional array Obj.data->0->obj(bools here)
    let valueObject = valuesIn;
    if (is_object(valueObject)) {
        Object.values(valueObject).forEach((element, index) => {
            for (const [key, value] of Object.entries(element)) {
                if (typeof value === "boolean") {
                    switch (returnOption) {
                        case "YESNO":
                            if (value === true) valueObject[index][key] = "Yes";
                            if (value === false) valueObject[index][key] = "No";
                            break;
                        case "TRUEFALSE":
                            if (value === true) valueObject[index][key] = "TRUE";
                            if (value === false) valueObject[index][key] = "FALSE";
                            break;
                        case "CUSTOM":
                            if (value === true) valueObject[index][key] = customObject.true;
                            if (value === false) valueObject[index][key] = customObject.false;
                            break;

                        default:
                            break;
                    }
                }
            }
        });
        return valueObject;
    }
    if (is_boolean(valuesIn)) {
        let returnString = "undefined";
        switch (returnOption) {
            case "YESNO":
                if (valuesIn === true) returnString = "Yes";
                if (valuesIn === false) returnString = "No";
                break;
            case "TRUEFALSE":
                if (valuesIn === true) returnString = "TRUE";
                if (valuesIn === false) returnString = "FALSE";
                break;
            case "CUSTOM":
                if (valuesIn === true) returnString = customObject.true;
                if (valuesIn === false) returnString = customObject.false;
                break;

            case "INTEGERS":
                if (valuesIn === true) returnString = 1;
                if (valuesIn === false) returnString = 0;
                break;
            default:
                break;
        }
        return returnString;
    }
};

/**
 * Converts a string value to a boolean. Accepts values such as Yes, NO, TRUE, FALSE, or custom mappings.
 * @param {string} boolStringIn The text string you want converted to a boolean. 
 * @param {string} returnOption Selects how the text in 'boolStringIn' is mapped to TRUE and FALSE. Options: "YESNO", "TRUEFALSE", "CUSTOM".
 * @param {{true:string,false:string}} customObject An object with string values for true and false.
 * @returns {boolean} A boolean value equivalent to the options selected.
 */
export const textToBool = (boolStringIn, returnOption = "YESNO", customObject = { true: "", false: "" }) => {
    if (is_string(boolStringIn)) {
        let returnBool = undefined;
        switch (returnOption) {
            case "YESNO":
                if (boolStringIn === "Yes") returnBool = true;
                if (boolStringIn === "No") returnBool = false;
                break;
            case "TRUEFALSE":
                if (boolStringIn === "TRUE" || boolStringIn === "true") returnBool = true;
                if (boolStringIn === "FALSE" || boolStringIn === "false") returnBool = false;
                break;
            case "CUSTOM":
                if (boolStringIn === customObject.true) returnBool = true;
                if (boolStringIn === customObject.false) returnBool = false;
                break;

            default:
                break;
        }
        return returnBool;
    }
}

/**
 * Converts a numerical (or string representing a number) to the requested file size.
 * @param {number|string} fileSize 
 * @param {string} suffixIn The format of the numerical data being supplied for conversion. One of: KB, MB, GB, TB, or BYTES.  BYTES is set by default.
 * @param {string} suffixOut The format to be returned after conversion. One of: "BYTES, KB, MB, GB, or TB" Set to KB by default.
 * @param {number} rounding The decimal place to round the final converted value to.
 * @param {boolean} padEnd True if the returned string is to be padded to match the rounding decimal place. False by default.
 * @returns {string} The converted 'fileSize' to the requested format in "suffixOut" with the added string suffix. e.g. 1000 to 1KB
 */
export const convertFileSize = (fileSize = 0, suffixIn = "bytes", suffixOut = "KB", rounding = 2, padEnd = false) => {
    rounding = parseInt(rounding);
    suffixIn = suffixIn.toUpperCase();
    suffixOut = suffixOut.toUpperCase();
    let sizeInBytes = parseFloat(fileSize);
    let sizeToReturn;

    switch (suffixIn) {
        case "KB":
            sizeInBytes = sizeInBytes * 1000;
            break;
        case "MB":
            sizeInBytes = sizeInBytes * 1000000;
            break;
        case "GB":
            sizeInBytes = sizeInBytes * 1000000000;
            break;
        case "TB":
            sizeInBytes = sizeInBytes * 1000000000000;
            break;
        case "BYTES":
        default:
            break;
    }
    switch (suffixOut) {
        case "BYTES":
            sizeToReturn = sizeInBytes;
            break;
        case "KB":
            sizeToReturn = sizeInBytes / 1000;
            break;
        case "MB":
            sizeToReturn = sizeInBytes / 1000000;
            break;
        case "GB":
            sizeToReturn = sizeInBytes / 1000000000;
            break;
        case "TB":
            sizeToReturn = sizeInBytes / 1000000000000;
            break;

        default:
            break;
    }
    return `${roundTo(sizeToReturn, rounding, padEnd)}${suffixOut}`
}



//------------------------------------------------------------------------------------------------------------
//####*  ARRAY HELPERS *########*  ARRAY HELPERS *########*  ARRAY HELPERS *########*  ARRAY HELPERS *#######*
//------------------------------------------------------------------------------------------------------------




/**
 * Converts supplied 'varIn' to an array type (if possible) and always returns an array. Conversion may be accomplished by equivalency or encasement, depending on supplied type.
 * @param {*} varIn Variable to be converted to an array type.
 * @param {{stringDelimiter: ",", objectUseKeys: false, functionToString: false, symbolUseDescription: true, nullValue: [], undefinedValue: []}} options Modifys the conversion behavior of certain variable types. 
 * @option stringDelimiter : string - Defines what delimiter is used to split the string into an array.
 * @option objectUseKeys : boolean - If true, will fill the returned array with the Object's key's, rather than the values; False is default, and returns Object.values();
 * @option functionToString : boolean - If true, will run .toString() on the function before encapsulation.
 * @option undefinedValue : any = The value to use in place of undefined, assigning undefined will return [undefined], etc.  
 * @option nullValue : any = The value to use in place of null, assigning null will return [null], etc. 
 * @param {boolean} throwError If TRUE, will generate a console warning when a direct equivalent conversion can't be made. Default: false.
 * @returns {[]} An array, converted from the supplied variable.
 */
export const parseArr = (varIn, options = {}, throwError = false) => {
    if (DEBUG) throwError = true;
    const defaultOptions = { stringDelimiter: ",", objectUseKeys: false, functionToString: false, symbolUseDescription: true, nullValue: [], undefinedValue: [] };
    if (!is_object(options)) {
        if (throwError) localMessage(`Parameter 'options' must be a valid object type. Default options used.`, `${THROW_ERROR_LOCATION}`, "WARNING", { function: "parseArr" });
        options = defaultOptions;
    }
    options = { ...defaultOptions, ...options };
    const { stringDelimiter, objectUseKeys, functionToString, symbolUseDescription, nullValue, undefinedValue } = options;

    let returnArray = [varIn]; //Fallback encapsulation return
    switch (getType(varIn)) {
        default:
            break;
        case "undefined":
            returnArray = nullValue;
            break;
        case "null":
            returnArray = undefinedValue;
            break;
        case "number":
            break;
        case "function":
            if (functionToString) returnArray = [varIn.toString()];
            break;
        case "symbol":
            if (symbolUseDescription) returnArray = [varIn.description];
            if (is_object(returnArray[0])) {
                returnArray = Object.values(returnArray[0]);
                if (objectUseKeys) returnArray = Object.keys(returnArray[0]);
            }
            if (is_string(returnArray[0])) returnArray = returnArray[0].split(stringDelimiter);
            break;
        case "string":
            returnArray = varIn.split(stringDelimiter);
            break;
        case "array":
            returnArray = varIn.map((value) => {
                return value;
            });
            break;
        case "object":
            returnArray = Object.values(varIn);
            if (objectUseKeys) returnArray = Object.keys(varIn);
            break;
    }
    if (!is_array(returnArray)) returnArray = [returnArray]; // Ensure array is returned
    return returnArray;
}

/**
 * Returns a copy of the supplied array. 
 * @param {[]} arrayToCopy The array you wish to copy.
 * @param {*} throwError If true, will throw an error if a non-array is supplied for 'arrayToCopy'. If false, will attempt to convert to an array and return. Default: False
 * @returns {[]} A copy of the array, using Array.slice(0);
 */
export const arrayCopy = (arrayToCopy = [], throwError = false) => {
    if (!is_array(arrayToCopy)) {
        if (throwError) localMessage(`Paramater 'arrayToCopy' in function 'arrayCopy' must be an array. ${getType(arrayToCopy)} supplied.`, `${THROW_ERROR_LOCATION}`, "error", { function: "arrayCopy" });
        if (is_object(arrayToCopy)) return Object.values(arrayToCopy);
        return [arrayToCopy];
    }
    return arrayToCopy.slice(0);
}

/**
 * Returns the last index of the supplied Array (or object).
 * @param {[] | {}} arrayOrObject The array (or object) who's last index you want to retrive.
 * @param {boolean} throwError True if you want a custom error object thrown. The object will post to the console if not caught.
 * @returns {number | undefined} The last index of the supplied array (or object).
 */
export const lastIndex = (arrayOrObject, throwError = false, errorOnObject = false) => {
    if (throwError && (!is_array(arrayOrObject) && !is_object(arrayOrObject))) localMessage(`Parameter "arrayOrObject" in function "lastIndex" must be an array or an object. ${getType(arrayOrObject)} was supplied.`, `${THROW_ERROR_LOCATION}`, "error", { function: "lastIndex" });
    if (!isset(arrayOrObject)) {
        if (throwError) localMessage(`Supplied parameter "arrayOrObject" is empty. Returning undefined.`);
        return undefined;
    }
    let thisArray = arrayOrObject;
    if (is_object(arrayOrObject)) {
        if (errorOnObject || throwError) localMessage(`Supplied parameter "arrayOrObject" in function "lastIndex" must be an array. Object was supplied.`, `${THROW_ERROR_LOCATION}`, "error", { function: "lastIndex" });
        thisArray = Object.keys(arrayOrObject);
    }
    if (thisArray.length < 1) {
        if (throwError) localMessage(`Supplied parameter "arrayOrObject" is empty. Returning undefined.`, `${THROW_ERROR_LOCATION}`, "error", { function: "lastIndex" });
        return undefined;
    }
    return (thisArray.length - 1);
}

/**
 * Returns the last key of the supplied Array (or object).
 * @param {[] | {}} arrayOrObject The array (or object) who's last key you want to retrive.
 * @param {boolean} throwError True if you want a custom error object thrown. The object will post to the console if not caught.
 * @returns {string | number | undefined} The last key of the supplied array (or object).
 */
export const lastKey = (arrayOrObject, throwError = false) => {
    if (throwError && (!is_array(arrayOrObject) && !is_object(arrayOrObject))) localMessage(`Parameter "arrayOrObject" in function "lastKey" must be an array or an object. ${getType(arrayOrObject)} was supplied.`, `${THROW_ERROR_LOCATION}`, "error", { function: "lastKey" });
    if (!isset(arrayOrObject)) {
        if (throwError) localMessage(`Supplied parameter "arrayOrObject" is empty. Returning undefined.`);
        return undefined;
    }
    let thisArray = arrayOrObject;
    if (is_object(arrayOrObject)) {
        thisArray = Object.keys(arrayOrObject);
        return thisArray.pop();
    }
    if (thisArray.length < 1) {
        if (throwError) localMessage(`Supplied parameter "arrayOrObject" is empty. Returning undefined.`, `${THROW_ERROR_LOCATION}`, "error", { function: "lastKey" });
        return undefined;
    }
    return (thisArray.length - 1);
}


/**
 * Returns the last value in an array (or object, though object order is never guarenteed). 
 * @param {[] | {}} arrayOrObject The array (or object) whos last value you want to retrieve. 
 * @param {*} throwError If true, will error on invalid 'arrayOrObject' argument.
 * @returns {* | undefined} Returns the last value, or undefined if no value present.
 */
export const lastValue = (arrayOrObject, throwError = false) => {
    if (throwError && (!is_array(arrayOrObject) && !is_object(arrayOrObject))) localMessage(`Parameter "arrayOrObject" in function "lastValue" must be an array or an object. ${getType(arrayOrObject)} was supplied.`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "lastValue" });
    if (!isset(arrayOrObject)) {
        if (throwError) localMessage(`Supplied parameter "arrayOrObject" is empty. Returning undefined.`);
        return undefined;
    }
    let thisArray = arrayOrObject;
    if (is_object(arrayOrObject)) thisArray = Object.values(arrayOrObject);
    if (thisArray.length < 1) {
        if (throwError) localMessage(`Supplied parameter "arrayOrObject" is empty. Returning undefined.`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "lastValue" });
        return undefined;
    }
    return thisArray[thisArray.length - 1];
}

/**
 * Modifies the original supplied array using the Durstenfeld shuffle algorithm.
 * @param {[]} arrayIn Array to shuffle. Note: will modify the original array.
 * @returns {[]} The same instance of the supplied array, modified with the Durstenfeld shuffle algorithm.
 */
export const arrayShuffle = (arrayIn) => {
    for (var i = arrayIn.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = arrayIn[i];
        arrayIn[i] = arrayIn[j];
        arrayIn[j] = temp;
    }
    return arrayIn;
}

/**
 * Tests if a value supplied in 'needle' is present in the 'haystack' array, returning a boolean true if found.
 * @param {[] | {}} haystack An array to search for the 'needle' value. NOTE: Will not recurse, only looks one level deep.
 * @param {*} needle The value to search for: uses Array.indexOf() to commit search.
 * @returns {Boolean} True if found, false if not.
 */
export const isIndexOf = (haystack = [], needle = "") => {
    if (is_object(haystack)) haystack = Object.values(haystack);
    const index = haystack.indexOf(needle);
    if (!isNaN(index) && index > -1) {
        return true;
    }

    return false;
};

/**
 * A psudeo-alias of Array.prototype.indexOf(), except will accept an object and convert to array values before returning index.  This will effectively treat an object who's order is known and expected (although object order is never guarenteed) as an array.
 * @param {[] | {}} haystack Any array or object to be searched for 'needle' value.
 * @param {*} needle The value to search for in the 'haystack' array.
 * @returns {number} The index if found, or -1 on failure.
 */
export const getIndex = (haystack = [], needle = "") => {
    if (!is_iterable(haystack)) return -1;
    if (is_object(haystack)) haystack = Object.values(haystack);
    return haystack.indexOf(needle);
}

/**
 * Finds the minimum and maximum values in an array of numbers.
 * @param {[...number]} items An array of numbers (or numeric strings) to be reduced to a minimum and maximum
 * @returns {[min:number,max:number]} An array containing the minimum and maximum values.
 */
export const minMax = (items = []) => {
    if (is_object(items)) items = Object.values(items); //TODO: Make a way to return the correct keys if this occurs in an object, rather than an array.
    return items.reduce((itemBin, value) => {
        itemBin[0] = itemBin[0] === undefined || value < itemBin[0] ? value : itemBin[0];
        itemBin[1] = itemBin[1] === undefined || value > itemBin[1] ? value : itemBin[1];
        return itemBin;
    }, []);
};

/**
 * Removes the values or indicies supplied in valueToRemove and/or indexToRemove and returns a new array. Does not alter the original array supplied in haystackArrayIn.
 * @param {Array} haystackArrayIn The array to search within that needs items removed.
 * @param {String} valueToRemove A search value to remove from haystackArrayIn. All matching values will be removed.
 * @param {String} indexToRemove A search index to remove from haystackArrayIn
 * @returns {[]} 
 */
export const arrayRemove = (haystackArrayIn, valueToRemove = null, indexToRemove = null) => {
    return haystackArrayIn.filter((haystackValue, haystackIndex) => {
        if (isset(valueToRemove) && isset(indexToRemove)) {
            if (haystackValue !== valueToRemove && haystackIndex !== indexToRemove) return true;
        }
        if (isset(valueToRemove) && valueToRemove !== haystackValue) return true;
        if (isset(indexToRemove) && indexToRemove !== haystackIndex) return true;
        return false;
    });
}

/**
 * Filters an array for a string value and removes it.
 * @param {[]} arrayHaystack The array to search within.
 * @param {string} stringNeedle The string value to search for and remove. NOTE: Removes all matching values!
 * @param {boolean} throwError If True, will throw an error on type mismatch, otherwise if false, will return the 'arrayHaystack' unchanged. Default:true - expects an array.
 * @returns {[]} The filtered array.
 */
export const arrayRemoveString = (arrayHaystack, stringNeedle, throwError = true) => {
    if (!is_array(arrayHaystack)) {
        if (throwError) localMessage(`Paramater 'arrayHaystack' in function 'arrayRemoveString' must be an array. ${getType(arrayHaystack)} supplied.`, "log", "ERROR");
        return arrayHaystack;
    }
    if (!is_string(stringNeedle)) {
        if (throwError) localMessage(`Paramater 'stringNeedle' in function 'arrayRemoveString' must be a string. ${getType(stringNeedle)} supplied.`, "log", "ERROR");
        return arrayHaystack;
    }
    return arrayHaystack.filter((value) => {
        return value !== stringNeedle;
    });
}

/**
 * Acts directly on the supplied array, removing all the elements.
 * @param {Array} arrayToEmpty The array to be emptyed of all elements.
 * @returns {Integer} Number of array elements removed from array.
 */
export const arrayEmpty = (arrayToEmpty = []) => {
    if (!is_array(arrayToEmpty)) {
        throw localMessage(`Paramater 'arrayToEmpty' in function 'arrayEmpty' must be an array. ${getType(arrayToEmpty)} supplied.`, "log", "ERROR");
    }
    if (!arrayToEmpty.length) {
        localMessage(`Paramater 'arrayToEmpty' in function 'arrayEmpty' is already empty.`, "log", "alert");
        return [];
    }
    let removedCount = 0;
    const totalElements = arrayToEmpty.length;
    for (let index = 0; index < totalElements; index++) {
        if (arrayToEmpty.pop()) removedCount++;
    }
    return removedCount;
}

/**
 * Computes the sum of an array's values.
 * @param {[]} arrayIn An array of values for which you wish to compute the total.
 * @param {boolean} header True if the value at position index 0 is considered a header or otherwise should not be added into the total. Sum will begin at position 1. False will use the entire array. Default: False
 * @param {boolean} currency True if the values in the array are in currency string format, false if are numbers or floats. Default: False
 * @returns {number} The sum of the array values.
 */
export const getArrayTotal = (arrayIn, header = false, currency = false) => {
    //If currency, remove dollar sign.
    arrayIn = arrayIn.map((value) => {
        return formatNumStripCurrency(value);
    });
    const sumReduce = (runningTotal, currentValue) => {
        if (!currency) return formatNumStripCurrency(runningTotal) + formatNumStripCurrency(currentValue);
        if (is_string(runningTotal)) runningTotal = formatNumStripCurrency(runningTotal);
        if (is_string(currentValue)) currentValue = formatNumStripCurrency(currentValue);
        return formatNumStripCurrency(runningTotal) + formatNumStripCurrency(currentValue);
    }
    if (!header && is_array(arrayIn)) {
        return arrayIn.reduce(sumReduce);
    } else if (!header && is_object(arrayIn)) {
        return Object.values(arrayIn).reduce(sumReduce);
    } else if (header && is_array(arrayIn)) {
        arrayIn.shift();
        return arrayIn.reduce(sumReduce);
    } else if (header && is_object(arrayIn)) {
        const returnArray = Object.values(arrayIn);
        returnArray.shift();
        return returnArray.reduce(sumReduce);
    } else {
        const suppliedType = typeof (arrayIn);
        console.log(`%c->Invalid Paramater supplied to getColumnTotal. Must be Array or Object, ${suppliedType} supplied.`,
            `color: darkred; font-weight: bold; `);
        return 0;
    }
}

/**
 * Returns the numerical average of all values in an array. Will accept number values or strings that calculate to numbers. e.g. "-$1,250.12"  will be converted to -1250.12. Any non-calculable string values will be stripped before averaging. 
 * @param {[number | string]} arrayIn An array containing numbers or strings that parse to numbers/floats
 * @param {boolean} header If true, the first index of the array will be omitted from the array average calculation.  False will utilize the entire array.  Default: false.
 * @returns {number} The average of the array values
 */
export const getArrayAverage = (arrayIn, header = false) => {
    const sumReduce = (runningTotal, currentValue) => parseFloat(formatNumStripCurrency(runningTotal)) + parseFloat(formatNumStripCurrency(currentValue));
    if (!header && is_array(arrayIn)) {
        return arrayIn.reduce(sumReduce) / arrayIn.length;
    } else if (!header && is_object(arrayIn)) {
        return Object.values(arrayIn).reduce(sumReduce) / Object.values(arrayIn).length;
    } else if (header && is_array(arrayIn)) {
        arrayIn.shift();
        return arrayIn.reduce(sumReduce) / (arrayIn.length - 1);
    } else if (header && is_object(arrayIn)) {
        const returnArray = Object.values(arrayIn);
        returnArray.shift();
        return returnArray.reduce(sumReduce) / (Object.values(arrayIn).length - 1);
    } else {
        const suppliedType = typeof (arrayIn);
        console.log(`%c->Invalid Paramater supplied to getColumnTotal. Must be Array or Object, ${suppliedType} supplied.`,
            `color: darkred; font-weight: bold; `);
        return 0;
    }
}

/**
 * Converts an array to an object. Optionally use either the index as keys or the value as keys (putting the index as the value).
 * @param {[]} arrayIn The array you want converted.
 * @param {string} keys Either: INDEX or VALUE. Default: Index.  Index - uses the index of each array element as the object key, placing the element value as the object key value. Value - uses the value of each array element as the object key, placing array elements index as the object key's value.
 * @returns {{}} Object using 'arrayIn' index and values as keys and values.
 */
export const arrayToObject = (arrayIn = [], keys = "INDEX", throwError = false) => {
    if (DEBUG) throwError = true;
    if (!is_array(arrayIn)) {
        if (throwError) localMessage(`Paramater 'arrayIn' in function 'arrayToObject' must be an array. ${typeof arrayIn} supplied.`, "log", "ERROR");
        return {};
    }
    if (!arrayIn.length) return {};
    let returnObject = {};
    switch (keys.toUpperCase()) {
        default:
        case "INDEX":
            arrayIn.forEach((value, index) => {
                returnObject[index] = value;
            });
            return returnObject;
        case "VALUE":
            arrayIn.forEach((value, index) => {
                returnObject[value] = index;
            });
            return returnObject;
    }
}


/**
 * Returns an array containing the difference between the two supplied arrays. Uses the LoDash difference function calculating SameValueZero (NaN === NaN returns true). If this function fails or produces an error, a simple Array.filter method is used.
 * @param {*} array1 The initial array to compare.
 * @param {*} array2 The additional array to compare against the initial array.
 * @param {boolean} throwError If true, will log an error on invalid arguments or failed LoDash function.
 * @returns {[]} An array containing the different elements from both arrays.
 */
export const arrayDifference = (array1, array2, throwError = false) => {
    if (DEBUG) throwError = true;
    if (!is_array(array1) || !is_array(array2)) {
        if (throwError) localMessage(`Both parameters in function arrayDifference must be valid array types. Empty array will be returned if conversion cannot take place.`, `$ {THROW_ERROR_LOCATION}`, "ERROR", { function: "arrayDifference" });
        if (is_object(array1)) array1 = Object.values(array1);
        if (is_object(array2)) array2 = Object.values(array2);
        if (!is_array(array1) || !is_array(array2)) return [];
    }
    try {
        const arrayDiff = difference(array1, array2);
        if (is_array(arrayDiff)) return arrayDiff;
        if (throwError) localMessage(`LoDash function difference didnot return a valid array. Fallback function used.`, `${THROW_ERROR_LOCATION}`, "WARNING", { function: "arrayDifference|difference" });
    } catch (error) {
        if (throwError) localMessage(`LoDash function difference produced an error. Error: ${error.message}. Fallback function used.`, `${THROW_ERROR_LOCATION}`, "WARNING", { function: "arrayDifference|difference" });
    }
    return array1.filter((value) => {
        return !isIndexOf(array2, value);
    });

}

/**
 * 
 * @param {[]} haystackArray The array to search within an count
 * @param {[operator:string,value:string]} conditions An array consisting of [operator, value].  Value can be the exact value, or a type. Acceptable operators are: [">", "<", ">=", "<=", "=", "!", "=TYPE", "!TYPE"]. If an operator not listed is supplied the function will return 0.
 * @param {boolean} throwError If true, will log an error on invalid argument.
 * @returns {number} The count, if success. 0 on fail.
 */
export const arrayCountIf = (haystackArray, conditions = [], throwError = false) => {
    if (DEBUG) throwError = true;
    const operators = [">", "<", ">=", "<=", "=", "!", "!=", "=TYPE", "!TYPE", "!=TYPE"];
    let count = 0;
    if (!is_array(haystackArray)) {
        if (throwError) localMessage(`Parameter 'haystackArray' must be an array type. Type ${getType(haystackArray)} supplied. Function returned 0 count by default.`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "arrayCountIf" });
        return 0;
    }
    if (!isset(conditions) || (is_array(conditions) && !conditions.length)) {
        if (throwError) localMessage(`No conditions supplied, count returned array length by default.`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "arrayCountIf" });
        return haystackArray.length;
    }
    if (conditions.length === 1) {
        const match = conditions[0];
        haystackArray.map((value) => {
            if (value === match) count++;
            return true;
        });
        return count;
    }
    if (conditions.length === 2) {
        //check operators
        let operator = "=";
        if (inString(conditions[0].toUpperCase(), "TYPE")) conditions[1] = conditions[1].toLowerCase();
        const needle = conditions[1];
        if (isIndexOf(operators, conditions[0].toUpperCase())) operator = conditions[0].toUpperCase();
        switch (operator) {
            case ">":
                haystackArray.map((value) => {
                    if (value > needle) count++;
                    return true;
                });
                break;
            case "<":
                haystackArray.map((value) => {
                    if (value < needle) count++;
                    return true;
                });
                break;
            case ">=":
                haystackArray.map((value) => {
                    if (value >= needle) count++;
                    return true;
                });
                break;
            case "<=":
                haystackArray.map((value) => {
                    if (value <= needle) count++;
                    return true;
                });
                break;
            case "=":
                haystackArray.map((value) => {
                    if (value === needle) count++;
                    return true;
                });
                break;
            case "!":
            case "!=":
                haystackArray.map((value) => {
                    if (value !== needle) count++;
                    return true;
                });
                break
            case "TYPE":
            case "=TYPE":
                haystackArray.map((value) => {
                    if (getType(value, needle)) count++;
                    return true;
                });
                break
            case "!TYPE":
            case "!=TYPE":
                haystackArray.map((value) => {
                    if (getType(value) !== needle) count++;
                    return true;
                });
                break

            default:
                if (throwError) localMessage(`Supplied operator does not exist. 0 count returned by default.`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "arrayCountIf" });
                return 0;
        }
        return count;
    }
    return count; // change to add in multi array capability.
}

//------------------------------------------------------------------------------------------------------------
//####*  OBJECT HELPERS *########*  OBJECT HELPERS *########*  OBJECT HELPERS *########*  OBJECT HELPERS *###*
//------------------------------------------------------------------------------------------------------------



/**
 * Creates an array of keys (indexed if strictOrder is true) and values from an object.  Each key:value pair get its own element, with a reference to its parent object. This function is used in comparing objects and called in function objectsAreEqual.
 * @see objectsAreEqual
 * @param {{}} objectIn The object to generate a fingerprint for.
 * @param {boolean} strictOrder If true, the fingerprint will ensure the object order is included in the fingerprint.
 * @returns {[]} An array containing key:value pairs, as strings, bound with their parent key (and index order if strictOrder is true).
 */
export const objectFingerprint = (objectIn, strictOrder = false) => {
    const items = [];
    let count = 0;
    function detect(objectIn, prevKey = "initial") {
        count++;
        if (objectIn && is_iterable(objectIn)) {
            for (let key in objectIn) {
                let thisValue = parseStr(objectIn[key]);
                if (is_iterable(objectIn[key])) {
                    if (is_array(objectIn[key])) thisValue = `array(${objectIn[key].length})`;
                    if (is_object(objectIn[key])) thisValue = `object(${objectLen(objectIn[key])})`;
                }
                if (!strictOrder) items.push(`${prevKey}|${key}:${thisValue}`);
                if (strictOrder) items.push(`${count}|${prevKey}|${key}:${thisValue}`);
                if (objectIn.hasOwnProperty(key)) {
                    detect(objectIn[key], key);
                }
            }
        }
        return items;
    }
    return detect(objectIn);
}

/**
 * Tests if an object contains any functions.  Useful when converting objects to JSON and back to objects to prevent loosing data, amoung other uses.
 * @param {{}} objectIn Object to inspect for functions.
 * @returns {boolean} True if an object contains one or more functions, false if none are present.
 */
export const objectHasFunctions = (objectIn) => {
    function detect(objectIn) {
        if (objectIn && is_iterable(objectIn)) {
            for (let key in objectIn) {
                if (is_function(objectIn[key])) return true;
                if (objectIn.hasOwnProperty(key)) detect(objectIn[key], key);
            }
        }
        return false;
    }
    return detect(objectIn);
}

/**
 * Tests both object equality an structure. An psudeo-alias of objectsAreEqual with the strictOrder flag set to TRUE.
 * @see objectsAreEqual
 * @param {{}} object_1 The first object to compare against the second.
 * @param {{}} object_2 The second object to compare against the first.
 * @param {boolean} throwError If true, will log errors on invalid arguments and equivalency checks.
 * @returns {boolean} True if objects are equal, false if not.
 */
export const objectsAreIdentical = (object_1 = null, object_2 = null, throwError = false) => {
    if (DEBUG) throwError = true;
    return objectsAreEqual(object_1, object_2, true, throwError);
}

/**
 * Tests object equality.  An object is considered equal if its contained information is equivalent with the other object. i.e. The first objects keys & values, when called externally, will produce the same result as the second object.
 * @param {{}} object_1 The first object to compare against the second.
 * @param {{}} object_2 The second object to compare against the first.
 * @param {boolean} strictOrder If true, will check for object order and specific content. Both objects must be completely identical in structure and values, though not the necessarily the same reference in memory.
 * @param {boolean} throwError If true, will log errors on invalid arguments and equivalency checks.
 * @returns {boolean} True if objects are equal, false if not.
 */
export const objectsAreEqual = (object_1 = null, object_2 = null, strictOrder = false, throwError = false) => {
    if (DEBUG) throwError = true;
    //Reject anything not an object or null
    if (!is_object(object_1) || !is_object(object_2)) {
        const whichObj = !getType(object_1, "object") ? "object_1" : "object_2";
        const type = { object_1: getType(object_1), object_2: getType(object_2), }
        if (throwError) localMessage(`Parameter '${whichObj}' must be a true object type. Type ${type[whichObj]} was supplied. Function returned false by default.`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "objectsAreEqual" });
        return false;
    }
    if (!strictOrder) {
        try {
            return isEqual(object_1, object_2);
        } catch (error) {
            if (throwError) localMessage(`Lodash isEqual failed, fallback will be used. Error:${error.message}`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "objectsAreEqual|isEqual" });
        }
    }

    //Fallback if lodash fails or strictOrder used
    if (strictOrder) {
        try {
            if (!isEqual(object_1, object_2)) return false;
        } catch (error) {
            if (throwError) localMessage(`Lodash isEqual failed, fallback will be used. Error:${error.message}`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "objectsAreEqual|isEqual" });
        }

        if (!objectHasFunctions(object_1) && !objectHasFunctions(object_2)) {
            try {
                if (JSON.stringify(object_1) !== JSON.stringify(object_2)) return false;
            } catch (error) {
                if (throwError) localMessage(`Strict object comparison not possible with JSON.stringify. Error: ${error.message}. Fallback calculation used.`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "objectsAreEqual|JSON.stringify" });
            }
        }
        if (throwError) localMessage(`Unable to compare using JSON.stringify as one or more objects contains functions, which are igonred in JSON.stringify.`, `${THROW_ERROR_LOCATION}`, "WARNING", { function: "objectsAreEqual|JSON.stringify" });
    }
    const arr1 = objectFingerprint(object_1, strictOrder); // get prop depth
    const arr2 = objectFingerprint(object_2, strictOrder); // get prop depth
    if (arr1.length !== arr2.length) return false;
    if (arrayDifference(arr1, arr2).length) return false;
    return true;
};


/**
 * Calculates the number of properties (or indices) on the object. Returns the first level count by default (deepLength = false).
 * @param {{}} objectIn Object whoes legnth you want to find.
 * @param {boolean} deepLength If true, will recurse into each iterable object or array, counting the number of keys and indices. Default: false.
 * @returns {number} The object length, aka the number of properties on the first level of the supplied object.
 */
export const objectLen = (objectIn, deepLength = false) => {
    if (!is_object(objectIn)) return 0;
    if (!deepLength) return parseInt(Object.keys(objectIn).length);
    const keys = [];
    function detect(objectIn) {
        if (objectIn && is_iterable(objectIn)) {
            for (let key in objectIn) {
                keys.push(key);
                if (objectIn.hasOwnProperty(key)) detect(objectIn[key]);
            }
        }
        return keys.length;
    }
    return detect(objectIn);
};

/**
 * Reverses the props and values of the first level of the supplied object in 'objectIn'. Similar to Array.prototype.reverse()
 * @param {{}} objectIn Object whos props (keys) and values you wish to reverse.
 * @param {function} keyCallback A callback function to run on the prop (key) in the supplied 'objectIn'. This will result in the return object values being modified by this callback. e.g. to make a key more readable by adding in spaces or text case.
 * @param {function} valueCallback A callback function to run on the value in the supplied 'objectIn'. This will result in the return object props (keys) being modified by this callback. e.g. remove spaces or convert objects to symbols to be used as object props.
 * @returns {{}} The object with reversed props and values.
 */
export const objectFlipKeyValue = (objectIn, keyCallback = null, valueCallback = null) => {
    let flippedObject = {};
    for (var key in objectIn) {
        let newKey = key;
        let newValue = objectIn[key];
        if (getType(keyCallback, "function")) newKey = keyCallback(newKey);
        if (getType(valueCallback, "function")) newValue = valueCallback(newValue);
        flippedObject[newValue] = newKey;
    }
    return flippedObject;
}

/**
 * Returns a shadow  copy of the supplied object, creating a new memory reference. Will not copy prototypes, getters, or setters.
 * @param {{}} objectToCopy The object to copy.
 * @param {boolean} throwError If true, will error on invalid object argument, or if the supplied object contains a circular reference. Function applys 3 attempts to copy. 1: JSON.stringify.parse, 2:Object.assign, 3. spread operator.  If all three fail, a single tier object copy will be performed with a map. Errors only logged if 'throwError' is true.
 * @returns {{}} A new object, copied from the supplied object.
 */
export const objectCopy = (objectToCopy, throwError = false) => {
    if (DEBUG) throwError = true;
    if (!is_object(objectToCopy)) {
        if (throwError) localMessage(`Paramater 'objectToCopy in function 'objectCopy' requires an object. Supplied ${getType(objectToCopy)} was returned unaltered.`, `${THROW_ERROR_LOCATION}`, "ERROR");
        return objectToCopy;
    }

    try {
        return JSON.parse(JSON.stringify(objectToCopy));
    } catch (error) {
        if (throwError) localMessage(`Function 'objectCopy' failed on initial copy attempt. Error: ${error.message}.`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "objectCopy", error });
    }
    try {
        const toReturn = Object.assign({}, objectToCopy);
        if (!is_object(toReturn)) throw new Error("Object assign failed to return a valid object.");
        return toReturn;
    } catch (error) {
        if (throwError) localMessage(`Function 'objectCopy' failed on second copy attempt with Object.assign. Error: ${error.message}`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "objectCopy", error });
    }
    try {
        return { ...objectToCopy };
    } catch (error) {
        if (throwError) localMessage(`Function 'objectCopy' failed on third copy attempt. Error: ${error.message}`, `${THROW_ERROR_LOCATION}`, "ERROR", { function: "objectCopy", error });
    }
    //Final fallbock!
    const returnObject = {};
    Object.entries(objectToCopy).forEach((entry) => {
        let value = entry[1];
        if (is_object(value)) value = { ...entry[1] };
        if (is_array(value)) value = [...entry[1]];
        returnObject[entry[0]] = value;
    });
    return returnObject;
}

/**
 * Copys an object's property and value to the same location in another object. Original structure in both object is maintained, creating a merged object.
 * @param {*} objectFrom 
 * @param {*} propLocation 
 * @param {*} objectTo 
 * @param {*} throwError 
 * @returns 
 */
export const objectCopyProp = (objectFrom, propLocation = "", objectTo = {}, throwError = false) => {
    if (DEBUG) throwError = true;
    if (!is_object(objectFrom)) {
        if (throwError) localMessage(`Parameter 'objectFrom' must be a valid object. Type ${getType(objectFrom)} supplied. Empty object returned.`, `${THROW_ERROR_LOCATION}`, "error", { function: "objectCopyProp" });
        return {};
    }
    if (!is_string(propLocation)) {
        if (throwError) localMessage(`Parameter 'propLocation' must be a valid string. Type ${getType(propLocation)} supplied. Supplied object returned.`, `${THROW_ERROR_LOCATION}`, "error", { function: "objectCopyProp" });
        return { ...objectFrom };
    }
    const propValue = propExists(objectFrom, propLocation); // Returns null if not exist
    if (!propValue && throwError) localMessage(`Value at location '${propLocation}' was null, undefined, or unreachable. A null value was copied instead, generating the prop location in target object.'`, `${THROW_ERROR_LOCATION}`, "error", { function: "objectCopyProp" });

    return objectCreateProp(objectTo, propLocation, propValue);


}

/**
 * Creates an object property (key) at the specified location, with an optional specified value. If no value supplied, null is used. No property values are overwritten.  If a non array/object value is encountered along the propLocation tree before the target, it is converted to an array encasing the offending value at position 0. WARNING: Will mutate the original array unless 'objectCopy' is set to TRUE.
 * @param {{} | null} object The object to have the generated property assigned.  If empty, a new object is created.
 * @param {string} propLocation The property or property location. e.g. Object = {key1 : { key2: undefined}}, supply the sting value "key1.key2"
 * @param {*} propValue Optional value to set, null will be used otherwise.
 * @param {boolean} copyObject If TRUE, a copy of object in should be created before processing, preventing mutation of origional objectIn argument. If FALSE, will mutate the current objectIn.
 * @param {boolean} throwError True for errors thrown on non-object or non-string argument errors.
 * @returns {{}} Modified object 'objectIn' with the created property tree and assigned value.
 */
export const objectCreateProp = (object, propLocation = "0", propValue = null, copyObject = false, throwError = false) => {
    let objectIn = object;
    if (DEBUG) throwError = true;
    if (!isset(objectIn)) objectIn = {};
    if (!is_object(objectIn)) {
        if (throwError) localMessage(`Parameter 'objectIn' must be a valid object. Type ${getType(objectIn)} supplied. Empty object was substituted.`, `${THROW_ERROR_LOCATION}`, "error", { function: "objectCreateProp" });
        objectIn = {};
    }
    if (copyObject) objectIn = objectCopy(objectIn);
    if (!is_string(propLocation)) {
        if (throwError) localMessage(`Parameter 'propLocation' must be a valid string. Type ${getType(propLocation)} supplied. Supplied object returned unaltered.`, `${THROW_ERROR_LOCATION}`, "error", { function: "objectCreateProp" });
        return objectIn;
    }
    const propArray = propLocation.split(".");
    const lastProp = propArray.pop();
    const lastObject = propArray.reduce((obj, prop) => {
        if (!is_object(obj[prop]) && !is_array(obj[prop])) {
            if (throwError) localMessage(`A non array/object was encountered. Type ${getType(obj[prop])} was encased in an array at position 0. Target path maintained.`, `${THROW_ERROR_LOCATION}`, "warning", { function: "objectCreateProp" });
            return obj[prop] = [obj[prop]] || {};
        }
        return obj[prop] = obj[prop] || {};
    }, objectIn);
    lastObject[lastProp] = propValue;
    return objectIn;



}


export const objectTextToKey = (textToConvert, throwError = true) => {
    if (!is_string(textToConvert)) {
        const supplied = typeof textToConvert;
        throw new Error(`Function objectTextToKey expected paramater 1 'textToConvert' to be a string. ${supplied} was supplied.`);
    }

    const convertArray = textToConvert.split('');
    let returnKeyArray = [];
    for (let index = 0; index < convertArray.length; index++) {
        const thisChar = convertArray[index];
        if (thisChar.match(new RegExp("[a-zA-Z0-9]"))) {
            returnKeyArray.push(thisChar);
            continue;
        }
        returnKeyArray.push(`_${convertCharToASCII(thisChar, throwError)}_`);
    }
    return returnKeyArray.join("").replaceAll("__", "_");
}

export const objectKeyToText = (keyToConvert) => {
    if (!is_string(keyToConvert)) {
        const supplied = typeof keyToConvert;
        throw new Error(`Function objectKeyToText expected paramater 1 'keyToConvert' to be a string. ${supplied} was supplied.`);
    }

    const convertArray = keyToConvert.split("_");
    let returnTextArray = [];
    for (let index = 0; index < convertArray.length; index++) {
        const thisChar = convertArray[index];
        if (thisChar.match(new RegExp("[a-zA-Z]")) || thisChar.match(new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$"))) {
            returnTextArray.push(thisChar);
            continue;
        }
        if (!thisChar.match(new RegExp("[a-zA-Z]")) && thisChar.match(new RegExp("[0-9]"))) {
            returnTextArray.push(convertASCIIToChar(thisChar));
        }
    }
    return returnTextArray.join("");
}

/**
 * Returns the first occurance of an object whos value is the greatest.  Successive properties (keys) whos values are equal to the max will be ignored.
 * @param {{}} objectIn The object to search within for a max value
 * @param {boolean} throwError Throw error on true.
 * @returns {string} The key with the max value, or empty string on failure.
 */
export const objectGetMaxValueKey = (objectIn, throwError = false) => {
    if (DEBUG) throwError = true;
    if (!is_object(objectIn)) {
        if (throwError) localMessage(`Paramater 'objectIn' must be an object. Empty string returned.`);
        return "";
    }
    const maxValue = minMax(Object.values(objectIn))[1];
    if (!isset(maxValue)) return "";
    if (isIndexOf(objectIn, maxValue)) {
        return Object.keys(objectIn)[Object.values(objectIn).indexOf(maxValue)];
    }
    return "";
}

/**
 * Returns the key differences between the haystackObject and needleObject. Specifically returning an Object with missing keys, additional keys, and a boolean if the keys match or not.
 * @param {{}} haystackObject Object to search for matching keys within
 * @param {{}} needleObject Object that has the keys to search for
 * @returns {{missing:[],additional:[],match:boolean}} Object containing 3 properties: missing, additional, and match. 
 */
export const objectKeyDifference = (haystackObject, needleObject) => {
    let needle = objectCopy(needleObject);
    let missing = [];
    let additional = [];
    let match = true;
    Object.keys(haystackObject).forEach((key) => {
        if (needle.hasOwnProperty(key)) {
            return delete needle[key];
        }
        missing.push(key);
    });
    if (isset(needle)) {
        Object.keys(needle).forEach((key) => {
            additional.push(key);
        });
    }
    if (missing.length !== 0 || additional.length !== 0) match = false;
    return { missing, additional, match };
}



//------------------------------------------------------------------------------------------------------------
//####* TABLE OBJECT HELPERS *########*  TABLE OBJECT HELPERS *########* TABLE OBJECT HELPERS *#############*
//------------------------------------------------------------------------------------------------------------



/**
 * Returns the specified column.
* @param {object} table The table object containing the rows and columns
* @param {number} columnNum The column number you wish to retrive, starting at 1.
* @param {string} returnType The return type or: OBJECT_ALL - returning 
*/
export const getColumn = (table = {}, columnNum = 1, returnType = "OBJECT_ALL") => {
    let totalRowCount;
    let totalColumnCount;
    let returnedColumnName;
    let returnedRowNames = [];
    let returnedColumnObject = {};
    let returnedColumnObjectNamed = {};
    let returnedColumnArray = [];

    try {
        totalRowCount = Object.values(table).length;
        totalColumnCount = Object.values(Object.values(table)[Object.keys(table)[0]]).length;
        Object.values(table).forEach((row, rowNum) => {
            const tempRowNum = rowNum + 1;
            const rowName = Object.values(row)[0];
            returnedRowNames.push(rowName);
            Object.entries(row).forEach((entry, colNum) => {
                const columnNumber = colNum + 1;
                const colName = entry[0];
                const colValue = entry[1];
                if (is_number(columnNum) && parseInt(columnNum) === parseInt(columnNumber)) {
                    returnedColumnName = colName;
                    returnedColumnObject[0] = colName;
                    returnedColumnObject[tempRowNum] = colValue;
                    returnedColumnObjectNamed[rowName] = colValue;
                    returnedColumnArray.push(colValue);
                }
            })
        })
    } catch (error) {
        console.log(error);
        console.log(error.message);
        console.log(`%c->Could not get table columns - table may not be correct object format.`,
            `color: darkred; font-weight: bold; `);
        return table;
    }
    let returnValue;
    switch (returnType) {
        case "OBJECT_ALL":
            returnValue = {
                rowsCount: totalRowCount,
                columnCount: totalColumnCount,
                columnName: returnedColumnName,
                rowNames: returnedRowNames,
                [columnNum]: returnedColumnObject,
                [returnedColumnName]: returnedColumnObjectNamed
            };
            break;
        case "OBJECT":
            returnValue = {
                ...returnedColumnObject
            };
            break;
        case "ARRAY":
            returnValue = returnedColumnArray;
            break;

        default:
            returnValue = {
                rowsCount: totalRowCount,
                columnCount: totalColumnCount,
                [columnNum]: returnedColumnArray
            };
            break;
    }
    return returnValue;
}


/**
 * Directly modifies the supplied table object, replacing the indicated column with the supplied replacement.
 * @param {Object} tableIn The table object to manipulate
 * @param {Integer} columnNumberToReplace The column number to replace from tableIn
 * @param {Array} replacement The replacement column Array
 */
export const tableReplaceColumn = (tableIn = {}, columnNumberToReplace = 0, replacement = []) => {
    Object.entries(tableIn).forEach((entryRow) => {
        const rowId = entryRow[0];
        const row = entryRow[1];
        Object.entries(row).forEach((entry, index) => {
            const thisColNum = index + 1;
            const columnId = entry[0];

            if (thisColNum === columnNumberToReplace) {
                tableIn[rowId][columnId] = replacement.shift();
            }
        })
    })
}

/**
 * NOTE: not all options are currently available! Formats the column data of the table.
 * @param {Object} tableIn The table object to be formated.
 * @param {Object} options Format options to apply to the table data
 * @returns {Object} Returns the modified tableIn object.
 */
export const formatTableData = (tableIn = {}, options = { USD: false, TITLECASE: false, UPPERCASE: false, WHOLENUMBERS: false, NUMBERSROUNDED: false, BOOLTOTEXT: false, DATEMDY: false, DATEMDYHMS: false, DATELONG: false, DAYSUFFIX: false }) => {

    tableIn = objectCopy(tableIn); // Ensures a new object is returned and that the original reference isn't mutated.

    const selectedOptions = Object.entries(options).map((entry) => {
        const thisOption = entry[0];
        const thisOptionCols = entry[1];
        if (thisOptionCols) return thisOption;
        return null;
    }).filter((value) => {
        return isset(value);
    });


    const modifyColumns = (tableIn, selectedOption, callBack) => {
        for (let index = 0; index < options[selectedOption].length; index++) {
            const thisColumnNumber = options[selectedOption][index];
            let thisColumn = getColumn(tableIn, thisColumnNumber, "ARRAY");
            for (let index = 0; index < thisColumn.length; index++) {
                thisColumn[index] = callBack(thisColumn[index]);
            }
            tableReplaceColumn(tableIn, thisColumnNumber, thisColumn);
        }
    }

    const optionFunctions = {
        USD: formatNumberToCurrency,
        DAYSUFFIX: formatAddDaySuffix,
        TITLECASE: textToTitleCase,
        BOOLTOTEXT: (column) => boolToText(column, "YESNO"),
        DATEMDY: (column) => new MyDate(column).date,
        DATEMDYHMS: (column) => new MyDate(column).string
    };


    selectedOptions.forEach((thisOption) => {
        if (optionFunctions.hasOwnProperty(thisOption)) {
            modifyColumns(tableIn, thisOption, optionFunctions[thisOption]);
        }
    });
    return tableIn;
}


export const getChartHeader = (target, data) => {
    /* This function can return the count, header values, or a specific header value */
    if (typeof data === "object" && data !== null && data.hasOwnProperty(0)) {
        //Ensure correct depth
        if (Object.keys(data[0]).length < 2) {
            target = "FAILED";
        }
    }
    if (!data || (data.constructor === Object && Object.keys(data).length === 0)) {
        target = "FAILED";
    }
    let toReturn = false;
    switch (target) {
        case "count":
            toReturn = Object.keys(data[0]).length;
            break;
        case "headers":
            let rawHeaders = Object.keys(data[0]);
            toReturn = [];
            rawHeaders.forEach((value, index) => {
                toReturn.push(objectKeyToText(Object.keys(data[0])[index]));
            });
            break;
        case "keys":
            toReturn = Object.keys(data[0]);
            break;

        case "FAILED":
            toReturn = false;
            break;

        default:
            if (typeof target === "number") {
                let index = target - 1;
                toReturn = Object.keys(data[0])[index];
            }
            break;
    }
    return toReturn;
};


export const getChartData = (target, data) => {
    //	console.log(target);
    //	console.log(data);
    if (typeof data !== "object" || data === null || Object.keys(data).length < 1) {
        target = "FAILED";
    }
    let toReturn = false;
    switch (target) {
        case "rowcount":
            toReturn = Object.keys(data).length;
            break;
        case "allrows":
            let rowCount = Object.keys(data).length;
            let rows = [];
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    const row = Object.values(data[key]);
                    rows.push(row);
                }
            }
            if (rowCount === rows.length) toReturn = rows;
            break;
        case "FAILED":
            toReturn = false;
            break;

        default:
            if (typeof target === "number") {
                let index = target - 1;
                toReturn = Object.keys(data[index]);
            }
            break;
    }
    return toReturn;
};


/**
* @param {object} table The table object containing the rows and columns
* @param {mixed} rowNum Typically a number, but will accept string values
* @param {string} returnType The return type or: OBJECT_ALL - returning 
*/
export const getRow = (table = {}, rowNum = 0, returnType = "OBJECT_ALL", throwError = true) => {
    let totalRowCount;
    let totalColumnCount;
    let returnTable = {};
    try {
        totalRowCount = Object.values(table).length;
        totalColumnCount = Object.values(Object.values(table)[0]).length;
        returnTable = Object.values(table)[rowNum - 1];
    } catch (error) {
        if (throwError) localMessage(`Could not get table rows - table may not be correct object format.`, `${THROW_ERROR_LOCATION}`, "error", { function: "getRow" });
        return table;
    }
    let returnValue = null;
    switch (returnType) {
        case "OBJECT_ALL":
            returnValue = {
                rowsCount: totalRowCount,
                columnCount: totalColumnCount,
                [rowNum]: returnTable
            };
            break;
        case "OBJECT":
            returnValue = {
                [rowNum]: returnTable
            };
            break;
        case "ARRAY":
            returnValue = Object.values(returnTable);
            break;

        default:
            returnValue = {
                rowsCount: totalRowCount,
                columnCount: totalColumnCount,
                [rowNum]: Object.values(table[rowNum])
            };
            break;
    }
    return returnValue;
}


/**
 * Returns a row Array with total columns for the supplied table.
 * @param {{}} tableIn Object containing table data
 * @param {boolean} rowHeaders Table has row Headers. **Only true is currently implimented
 * @param {boolean} columnHeaders Table has column headers. **Not currently implimented
 * @param {boolean} useCurrency Return totals in currency format
 * @returns {[]} Array containing the column totals.
 */
export const getTableColumnTotalsRow = (tableIn, rowHeaders = true, columnHeaders = false, useCurrency = false) => {
    let returnValue = {};
    switch (rowHeaders) {
        case true:
            let allColumns = [];
            const col1 = getColumn(tableIn, 1, "OBJECT_ALL");
            const colCount = col1.columnCount;
            for (let index = 0; index < colCount; index++) {
                if (index > 0) {
                    if (useCurrency) {
                        allColumns.push(
                            formatNumberToCurrency(getArrayTotal(getColumn(tableIn, (index + 1), "ARRAY"), false, useCurrency))
                        );
                    } else {
                        allColumns.push(
                            roundTo(getArrayTotal(getColumn(tableIn, (index + 1), "ARRAY"), false, useCurrency), 2, true)
                        );
                    }

                }
            }
            returnValue = ["Totals", ...allColumns];
            break;
        case false:

            break;

        default:
            break;
    }
    return returnValue;
}


/**
 * Returns the average of all columns in each row. Columns may be skipped by including in the column numbers in the columnsToSkip array.
 * @param {*} tableIn The table object whos columns you want to average
 * @param {*} columnsToSkip Any columns you wish to skip. Note if rowheaders is true, the column after is 1
 * @param {*} rowHeaders If the table contains row headers. This will remove them before processing.
 * @param {*} columnHeaders If the table contains column headers. This will remove them before processing and return an array with "Average" listed as a column header at position 0.
 * @param {*} useCurrency If you wish the values to be returned in currency format.
 * @returns Array containing the averages ordered in alignment with the table rows.
 */
export const getTableRowAverageColumn = (tableIn = {}, columnsToSkip = [], rowHeaders = true, columnHeaders = false, useCurrency = false) => {
    let returnValue = {};
    let allRowAverages = [];
    let allRowsHeaders = [];
    const col1 = getColumn(tableIn, 1, "OBJECT_ALL");
    const rowsCount = col1.rowsCount;
    //const tableColumnHeaders = tableIn[0];
    for (let index = 0; index < rowsCount; index++) {
        const thisRow = getRow(tableIn, (index + 1), "ARRAY");
        if (columnHeaders) continue;
        if (useCurrency) {
            if (rowHeaders) {
                const thisHeader = thisRow.shift();
                allRowsHeaders.push(thisHeader);
                let rowSum = 0;
                let addendsCount = 0;
                thisRow.forEach((thisColumnValue, index) => {
                    const currentColumn = index + 1;
                    if (!columnsToSkip.includes(currentColumn)) {
                        rowSum = rowSum + parseFloat(formatNumStripCurrency(thisColumnValue, "$"));
                        addendsCount++;
                    }
                });
                const thisRowAverage = `$ ${roundTo(rowSum / addendsCount, 2, true)}`;
                allRowAverages.push(thisRowAverage);
            } else {
                let rowSum = 0;
                let addendsCount = 0;
                thisRow.forEach((thisColumnValue, index) => {
                    const currentColumn = index + 1;
                    if (!columnsToSkip.includes(currentColumn)) {
                        rowSum = rowSum + parseFloat(formatNumStripCurrency(thisColumnValue, "$"));
                        addendsCount++;
                    }
                });
                const thisRowAverage = `$ ${roundTo(rowSum / addendsCount, 2, true)}`;
                allRowAverages.push(thisRowAverage);
            }
        } else {
            if (rowHeaders) {
                const thisHeader = thisRow.shift();
                allRowsHeaders.push(thisHeader);
                let rowSum = 0;
                let addendsCount = 0;
                thisRow.forEach((thisColumnValue, index) => {
                    const currentColumn = index + 1;
                    if (!columnsToSkip.includes(currentColumn)) {
                        rowSum = rowSum + parseFloat(formatNumStripCurrency(thisColumnValue));
                        addendsCount++;
                    }
                });
                const thisRowAverage = rowSum / addendsCount;
                allRowAverages.push(thisRowAverage);
            } else {
                let rowSum = 0;
                let addendsCount = 0;
                thisRow.forEach((thisColumnValue, index) => {
                    const currentColumn = index + 1;
                    if (!columnsToSkip.includes(currentColumn)) {
                        rowSum = rowSum + parseFloat(formatNumStripCurrency(thisColumnValue));
                        addendsCount++;
                    }
                });
                const thisRowAverage = rowSum / addendsCount;
                allRowAverages.push(thisRowAverage);
            }
        }
    }
    if (columnHeaders) returnValue = ["Average", ...allRowAverages];
    if (!columnHeaders) returnValue = allRowAverages;

    return returnValue;

}


/**
 * Inserts a column array into supplied table, returning a modified table.
 * @param {*} tableIn The source table needing a column inserted.
 * @param {*} columnInsertPosition The column number to insert the column.
 * @param {*} insertColumnArray An array containing the column to insert.
 * @param {*} rowInsertID The custom row ID, if none supplied a random ID will be generated
 * @returns Object containing the table with row inserted
 */
export const tableInsertColumn = (tableIn = {}, columnInsertPosition = 0, insertColumnArray = [], rowInsertID = "") => {
    if (!rowInsertID) getRandomNum(100000000, 900000000);
    let returnTable = {};
    let usedRows = [];
    Object.entries(tableIn).forEach((entryRow) => {
        const rowId = entryRow[0];
        const row = entryRow[1];
        Object.entries(row).forEach((entry, index) => {
            const thisColNum = index + 1;
            const columnId = entry[0];
            if (!returnTable.hasOwnProperty(rowId)) returnTable[rowId] = {}
            if (thisColNum === columnInsertPosition) {
                returnTable[rowId][rowInsertID] = insertColumnArray.shift();
                usedRows.push(rowId);
            }
            returnTable[rowId][columnId] = tableIn[rowId][columnId];
        });
    });
    if (insertColumnArray.length) {
        insertColumnArray.forEach((colValue, rowId) => {
            if (!usedRows.includes(rowId)) {
                returnTable[rowId][rowInsertID] = colValue;
            }
        })
    }
    return returnTable;
}


/**
 * Creates a copy of tableIn (supplied table) and removes the colums indicated in columnsToRemove, returning the modified table.
 * @param {Object} tableIn Table object to remove columns from
 * @param {Array} columnsToRemove the column numbers you wish removed, starting at 1. 
 * @param {Boolean} rowHeaders True if each column contains row headers, e.g. The first column contains titles or headers and is really considered row 0.
 * @returns {Object} A modified table object.
 */
export const tableRemoveColumn = (tableIn = {}, columnsToRemove = [], rowHeaders = false) => {
    let returnTable = JSON.parse(JSON.stringify(tableIn));
    Object.values(returnTable).forEach((rowObject, rowIndex) => {
        Object.entries(rowObject).forEach((row, colIndex) => {
            const colId = row[0];
            const columnNumber = colIndex + 1;
            if (rowHeaders && colIndex === 0) {
                //Do Nothing
            } else {
                if (columnsToRemove.includes(columnNumber)) {
                    delete returnTable[rowIndex][colId];
                }
            }
        })
    })
    return returnTable;
}


export const getTableColumnAverageRow = (tableIn, rowHeaders = true, columnHeaders = false, useCurrency = false) => {
    let allColumns = [];
    const col1 = getColumn(tableIn, 1, "OBJECT_ALL");
    const colCount = col1.columnCount;
    for (let index = 0; index < colCount; index++) {
        const thisColumn = getColumn(tableIn, (index + 1), "ARRAY");
        const thisColumnAverage = getArrayAverage(thisColumn, false, useCurrency);
        if (!rowHeaders && index === 0) allColumns.push(formatNumber(thisColumnAverage, useCurrency));
        if (index > 0) allColumns.push(formatNumber(thisColumnAverage, useCurrency));
    }
    if (rowHeaders) return ["Average", ...allColumns];
    return allColumns;

    function formatNumber(thisColumnAverage, useCurrency) {
        if (useCurrency) return formatNumberToCurrency(thisColumnAverage);
        return roundTo(thisColumnAverage, 2, true);
    }
}


//####* DATE & TIME HELPERS *########*  DATE & TIME HELPERS *########*  DATE & TIME HELPERS *###############* 



/**
 * Tests if the supplied 'data' is a valid date value or object.
 * @param {Number|String|{}} date A date as either numberic or string timestamp, or a date object.
 * @returns {Boolean} True if 'date' is valid and/or can be converted to a valid date object.
 */ //TODO: Make this also accept and recognize the xVDate object...perhaps?
export const isValidDate = (date) => {
    if (is_number(date) && date > 1000000000) {
        date = new Date(date);
        return date instanceof Date && !isNaN(date);
    }
    if (is_string(date)) {
        if (date.includes("/") || date.includes("-")) {
            date = new Date(date);
            return date instanceof Date && !isNaN(date);
        }
    }
    if (is_object(date)) {
        date = new Date(date);
        return date instanceof Date && !isNaN(date);
    }
    return false;
};

/**
 * Returns the months of the year. Optionally will return the month names, or convert an array of month numbers or names to their opposite corrosponding variable counterpart. e.g. [5,"March",6] becomes ["May", 3, "June"]
 * @param {string} returnType Determines how the return data is structured. One of: NAMES, NUMBERS, or SWAP. NAMES: Will return month names only. NUMBERS: Will return month numbers only. SWAP: will return the opposite of the value supplied in the 'options' array.
 * @param {[]} options If month values are supplied in 'options' array, they will be converted based on the 'returnType' set. If options is empty or not set, all months will be returned in the format supplied in 'returnType'.
 * @param {boolean} logErrors True to log any error that occurs to the console.  False by default.
 * @returns {[...months:string|number]} An array of months. Either numeric, string, or mixed based on what is selected in returnType, and the options supplied. Returns an empty array on error, logging the error message in the console if log errors is set to true.
 */
export const getMonths = (returnType = "NAMES|NUMBERS|SWAP", options = [], logErrors = true) => {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    try {
        if (!isset(returnType)) return monthNumbers;
        if (is_string(returnType) && !isset(options)) {
            returnType = returnType.toUpperCase();
            if (returnType === "NAMES") return monthNames;
            if (returnType === "NUMBERS") return monthNumbers;
        }
        let returnArray = [];
        if (isset(options)) {
            if (is_array(options)) {
                options.forEach((month, index) => {
                    month = capitalizeFirstLetter(month); //Only affects a string value, numbers are unaltered.
                    if (returnType === "NAMES") {
                        if (wholeNumber(month) > 0 && wholeNumber(month) < 13) returnArray.push(monthNames[month - 1]);
                        if (isIndexOf(monthNames, month)) returnArray.push(month);
                    }
                    if (returnType === "NUMBERS") {
                        if (wholeNumber(month) > 0 && wholeNumber(month) < 13) returnArray.push(month);
                        if (isIndexOf(monthNames, month)) returnArray.push(monthNames[index + 1]);
                    }
                    if (returnType === "SWAP") {
                        if (wholeNumber(month) > 0 && wholeNumber(month) < 13) returnArray.push(monthNames[month - 1]);
                        if (isIndexOf(monthNames, month)) returnArray.push(monthNames[index + 1]);
                    }
                });
                return returnArray;
            }
        }
    } catch (error) {
        if (logErrors) localMessage(error.message, "log", "error");
        return [];
    }
    return [];
}


export const convertMonth = (month = null) => {
    if (!month) return 0;
    const monthNames = {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
    };
    const monthNumbers = {
        january: 1,
        february: 2,
        march: 3,
        april: 4,
        may: 5,
        june: 6,
        july: 7,
        august: 8,
        september: 9,
        october: 10,
        november: 11,
        december: 12,
    };
    let toReturn = "TEXT";
    if (isNaN(month)) toReturn = "NUMBER";
    switch (toReturn) {
        case "TEXT":
            if (!monthNames.hasOwnProperty(month)) return "";
            return monthNames[month];
        case "NUMBER":
            if (!monthNumbers.hasOwnProperty(month)) return 0;
            return monthNumbers[month];
        default:
            return 0;
    }
};

export const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
}


/**
 * A custom datetime class with additional properties and methods allowing for easier date manipulation.
 * @class MyDate
 * @argument {string | {}} dtsIn
 * @property {string} type The type of this class object
 * @property {boolean} hasError If an error occured during the construct or any method call.
 * @property {array} errors A list of errors that occured
 * @property {string} string The local time string
 * 
 */
export class MyDate {
    /** @lends MyDate */
    /**
     * @constructs MyDate
     * @param {string} dtsIn Any valid Date class object or valid date string.
     * * @property {string} type The type of this class object
     * @property {boolean} hasError If an error occured during the construct or any method call.
     * @property {array} errors A list of errors that occured
     * @property {string} string The local time string
     * @method dateBeforeOrAfter
     * @returns {{type:string}} A custom datetime class object
     */
    constructor(dtsIN = "") {
        this.type = "DATEOBJECT_TRINITYRECODE";
        this.dtsIN = dtsIN;
        this.hasError = false;
        this.errors = [];
        this.string = new Date(dtsIN).toLocaleString();
        if (!isset(dtsIN)) dtsIN = new Date().toLocaleString();
        if (!isValidDate(dtsIN) || this.string === "Invalid Date") {
            this.hasError = true;
            this.string = dtsIN;
            this.errors.push("Invalid Date Supplied");
        }
        this.now = Date.now();
        this.nowString = new Date(Date.now()).toLocaleString();
        this.Date = new Date(dtsIN);
    }
    toString = () => this.string;

    reset() {
        this.Date = new Date(this.dtsIN);
        this.string = new Date(this.dtsIN).toLocaleString();
        if (!isValidDate(this.dtsIN) || this.string === "Invalid Date") {
            this.hasError = true;
            this.string = this.dtsIN;
            this.errors.push("Invalid Date Supplied");
        }
    }
    DTO() {
        let tempObject = { month: "", day: "", year: "", hour: "", minute: "", second: "", meridiem: "" };
        let swapDate = this.string.split(",");
        if (this.hasError) swapDate = new Date().toLocaleString().split(",");
        this.errors.push("Method DTO called on invalid date, current datetime supplied.");
        swapDate = [...swapDate[0].trim().split("/"), ...swapDate[1].trim().split(":")];
        const dateKeyArray = ["month", "day", "year", "hour", "minute", "second", "meridiem"];
        swapDate.forEach((value, index) => {
            if (index === 5) {
                tempObject[dateKeyArray[index]] = value.split(" ")[0];
                tempObject[dateKeyArray[6]] = value.split(" ")[1];
            } else {
                tempObject[dateKeyArray[index]] = value;
            }
        });
        return tempObject;
    };
    get timeStamp() {
        return this.string;
    }
    get year() {
        return this.DTO().year;
    };
    get month() {
        return this.DTO().month;
    };
    get monthName() {
        const month = this.DTO().month;
        return convertMonth(month);
    };
    get day() {
        return this.DTO().day;
    };
    get hour() {
        return this.DTO().hour;
    };
    get minute() {
        return this.DTO().minute;
    };
    get second() {
        return this.DTO().second;
    };
    get meridiem() {
        return this.DTO().meridiem;
    };
    get date() {
        return `${this.month}/${this.day}/${this.year}`;
    };
    get time() {
        return `${this.hour}:${this.minute}:${this.second} ${this.meridiem}`;
    };
    get Full12h() {
        return `${this.month}/${this.day}/${this.year} ${this.hour}:${this.minute}:${this.second} ${this.meridiem}`;
    };
    get Full24h() {
        let newHour = this.hour;
        if (this.meridiem === "PM") newHour = parseInt(newHour) + 12;
        if (newHour > 23) newHour = newHour - 12;
        if (parseStr(newHour).length < 2) newHour = `0${newHour}`;
        return `${this.month}/${this.day}/${this.year} ${newHour}:${this.minute}:${this.second}`;
    }

    /**
     * Determines if the current date object is before, equal, or after the supplied datetime, or current datetime if none supplied.
     * @param {string} dateTwo A valid datetime string who you wish to compare to the current class object datetime.
     * @returns {string} Returns "BEFORE", "EQUAL", "AFTER", or "ERROR" in relation to the comparison. i.e. the current datetime class object is 'BEFORE' the supplied or current datetime, and so forth.
     */
    dateBeforeOrAfter(dateTwo) {
        if (!isset(dateTwo) || dateTwo.toUpperCase() === "NOW") dateTwo = new Date(this.now).toLocaleString();
        const dateOne = this.string;
        const dt1MS = Date.parse(dateOne);
        const dt2MS = Date.parse(dateTwo);
        if (!isValidDate(dt2MS)) {
            this.hasError = true;
            this.errors.push("Invalid date string supplied to dateBeforeOrAfter.");
            return "ERROR";
        }
        if (dt1MS > dt2MS) return "AFTER";
        if (dt1MS === dt2MS) return "EQUAL";
        return "BEFORE";
    }

    static humanUnit(data, dataFormat = "SECONDS") {
        let secs = 0; let mins = 0; let hours = 0; let days = 0; let weeks = 0;
        switch (dataFormat) {
            case "SECONDS":
                mins = roundTo(data / 60, 2);
                if (mins < 60) return { data: mins, unit: "minutes", string: `${mins} minutes` };
                hours = roundTo(mins / 60, 2);
                if (hours < 24) return { data: hours, unit: "hours", string: `${hours} hours` };
                days = roundTo(hours / 24, 2);
                if (days < 7) return { data: days, unit: "days", string: `${days} days` };
                weeks = roundTo(days / 7, 2);
                return { data: weeks, unit: "weeks", string: `${weeks} weeks` };
            case "MINUTES":
                if (data < 1) {
                    secs = roundTo(60 * data, 2);
                    return { data: secs, unit: "seconds", string: `${secs} seconds` };
                }
                if (data < 60) return { data: mins, unit: "minutes", string: `${mins} minutes` };
                hours = roundTo(data / 60, 2);
                if (hours < 24) return { data: hours, unit: "hours", string: `${hours} hours` };
                days = roundTo(hours / 24, 2);
                if (days < 7) return { data: days, unit: "days", string: `${days} days` };
                weeks = roundTo(days / 7, 2);
                return { data: weeks, unit: "weeks", string: `${weeks} weeks` };
            default:
                break;
        }
        return data;
    }

    /**
     * Calculates the difference between the current datetime class object and either the supplied date in 'dateTwo' or the current datetime.
     * @param {string|{}} dateTwo The date to compare against the current class datetime object. If empty, the current datetime is used.
     * @param {string} returnFormat The unit of time to return. One of: MILLISECONDS, SECONDS, MINUTES, HOURS, DAYS, MONTHS, YEARS
     * @param {string} returnType The type of variable to return. One of: POSITIVENUMBER, OBJECT, REALNUMBER
     * @returns {number | {positivenumber:number, realDifference: number, milliseconds: number, seconds: number,minutes: number,hours: number, days: number, weeks: number, months:number, years:number}} Either a number (float or int) or an object containing all the returnFormat options
     */
    dateDifference(dateTwo = "", returnFormat = "SECONDS", returnType = "POSITIVENUMBER") {
        const dateOne = this.string;
        returnFormat = returnFormat.toUpperCase();
        returnType = returnType.toUpperCase();
        if (!isset(dateTwo) || dateTwo === "NOW") dateTwo = new Date(Date.now()).toLocaleString();
        if (is_object(dateTwo)) dateTwo = dateTwo.string || dateTwo.toString();
        const dt1MS = Date.parse(dateOne);
        const dt2MS = Date.parse(dateTwo);
        let difference = parseInt(dt1MS) - parseInt(dt2MS);
        const realDifference = difference;
        if (difference < 0 && returnType === "POSITIVENUMBER") difference = difference * -1;
        const secondsDiff = roundTo(difference / 1000, 0);
        const minutesDiff = roundTo(secondsDiff / 60, 1);
        const hoursDiff = roundTo(minutesDiff / 60, 1);
        const daysDiff = roundTo(hoursDiff / 24, 1);
        const weeksDiff = roundTo(daysDiff / 7, 1);
        const d1 = new MyDate(dateOne);
        const d2 = new MyDate(dateTwo);
        const d1DaysInMo = getDaysInMonth(d1.month, d1.year);
        const d2DaysInMo = getDaysInMonth(d2.month, d2.year);
        const d1m = parseInt(d1.month) + parseFloat(parseInt(d1.day) / parseInt(d1DaysInMo));
        const d2m = parseInt(d2.month) + parseFloat(parseInt(d2.day) / parseInt(d2DaysInMo));
        const monthDiff = roundTo(parseFloat(d1m) - parseFloat(d2m), 1);
        const yearsDiff = roundTo(daysDiff / 365, 1);

        let returnData;
        switch (returnFormat) {
            case "MILLISECONDS":
            case "MILISECONDS":
                return difference;
            case "SECONDS":
                returnData = secondsDiff;
                break;
            case "MINUTES":
                returnData = minutesDiff;
                break;
            case "HOURS":
                returnData = hoursDiff;
                break;
            case "DAYS":
                returnData = daysDiff;
                break;
            case "WEEKS":
                returnData = weeksDiff;
                break;
            case "MONTHS":
                returnData = monthDiff;
                if (returnData < 0 && returnType === "POSITIVENUMBER") returnData = returnData * -1;
                break;
            case "YEARS":
                returnData = yearsDiff;
                if (returnData < 0 && returnType === "POSITIVENUMBER") returnData = returnData * -1;
                break;
            default:
                break;
        }
        switch (returnType) {
            case "POSITIVENUMBER":
            case "OBJECT":
                if (returnType === "POSITIVENUMBER") return returnData;
                return {
                    positivenumber: returnData < 0 ? returnData * -1 : returnData,
                    realDifference: realDifference,
                    milliseconds: difference,
                    seconds: secondsDiff,
                    minutes: minutesDiff,
                    hours: hoursDiff,
                    days: daysDiff,
                    weeks: weeksDiff,
                    months: monthDiff,
                    years: yearsDiff
                };
            case "REALNUMBER":
                return returnData;

            default:
                break;
        }
    }

    /**
         * Adds a time unit to the current datetime object, the current datetime, or a supplied datetime string.
         * @param {Integer} valueToAdd Integer value to add to the date
         * @param {String} unit Unit of the value. SECONDS, MINUTES, HOURS, DAYS, WEEKS, MONTHS, YEARS
         * @param {String} dateString Datestring of the date to perform addition on. Leave null for the current datetime object, "NOW" for the current datetime, or supply any valid datetime string. If left null, the value is added to the current datetime, and stored.
         * @returns {String} Datetime string increased by the supplied value in valueToAdd
         */
    add(valueToAdd, unit = "SECONDS", dateString = null) {
        if (!isset(dateString)) dateString = this.string;
        if (dateString.toUpperCase() === "NOW") dateString = new Date(Date.now()).toLocaleString();
        const dateInMS = Date.parse(dateString);
        switch (unit) {
            case "SECONDS":
                valueToAdd = valueToAdd * 1000;
                break;
            case "MINUTES":
                valueToAdd = valueToAdd * 1000 * 60;
                break;
            case "HOURS":
                valueToAdd = valueToAdd * 1000 * 60 * 60;
                break;
            case "DAYS":
                valueToAdd = valueToAdd * 1000 * 60 * 60 * 24;
                break;
            case "WEEKS":
                valueToAdd = valueToAdd * 1000 * 60 * 60 * 24 * 7;
                break;
            case "MONTHS":
                valueToAdd = valueToAdd * 2628000000;
                break;
            case "YEARS":
                valueToAdd = valueToAdd * 1000 * 60 * 60 * 24 * 365;
                break;

            default:
                break;
        }
        const newDateMS = dateInMS + valueToAdd;
        if (dateString === this.string) {
            this.Date = new Date(newDateMS);
            this.string = this.Date.toLocaleString();
        }
        return new Date(newDateMS).toLocaleString();
    }
}


/**
 * 
 * @param {String} dtsIN Date string to convert to custom date object.
 * @properties now: now in milliseconds since epoch. 
 * | nowString: Current date time represented as a string. 
 * | string: string representation of the supplied dtsIN string.
 * | isError: Boolean - true if error has occured
 * | errors: Object - contains errors that occured
 * @methods getDTO: returns this same date time Object with a newly supplied input dtsIN string
 * | humanUnit: converts Seconds or Minutes to a human readable format depending on duration, returning either minutes, hours, days, or weeks.
 * | dateBeforeOrAfter: returns the word BEFORE or AFTER depending on whether a dateOne is before or after dateTwo.
 * | dateAdd: adds 
 * @returns A date object with properties and methods.
 */
export const xVDate_DEPRECATED = (dtsIN = "") => {
    if (!dtsIN) dtsIN = new Date().toLocaleString();
    if (!isValidDate(dtsIN)) {
        return {
            string: dtsIN,
            isError: true,
            errors: { 0: "Invalid Date Supplied" },
        };
    }
    let tempDate = {};
    tempDate = {
        type: "XV_DATE",
        now: Date.now(),
        nowString: new Date(Date.now()).toLocaleString(),
        string: new Date(dtsIN).toLocaleString(),
        isError: false,
        errors: {},
        getDTO: (dateTimeString = "") => {
            if (!dateTimeString) dateTimeString = new Date(dtsIN).toLocaleString();
            if (!isValidDate(dateTimeString)) {
                tempDate.isError = true;
                tempDate.errors = {
                    ...tempDate.errors,
                    [objectLen(tempDate.errors)]: `Invalid Date Supplied to getDTO. Suppplied: ${dateTimeString}`,
                };
                return tempDate;
            }
            let swapDate = dateTimeString.split(",");
            swapDate = [...swapDate[0].trim().split("/"), ...swapDate[1].trim().split(":")];
            const dateKeyArray = ["month", "day", "year", "hour", "minute", "second", "meridiem"];
            let tempObject = {};
            swapDate.forEach((value, index) => {
                if (index === 5) {
                    tempObject[dateKeyArray[index]] = value.split(" ")[0];
                    tempObject[dateKeyArray[6]] = value.split(" ")[1];
                } else {
                    tempObject[dateKeyArray[index]] = value;
                }
            });
            return tempObject;
        },
        get year() {
            return this.getDTO().year;
        },
        get month() {
            return this.getDTO().month;
        },
        get monthName() {
            const month = this.getDTO().month;
            return convertMonth(month);
        },
        get day() {
            return this.getDTO().day;
        },
        get hour() {
            return this.getDTO().hour;
        },
        get minute() {
            return this.getDTO().minute;
        },
        get second() {
            return this.getDTO().second;
        },
        get meridiem() {
            return this.getDTO().meridiem;
        },
        get date() {
            return `${this.month}/${this.day}/${this.year}`;
        },
        get time() {
            return `${this.hour}:${this.minute}:${this.second} ${this.meridiem}`;
        },
        get Full12h() {
            return `${this.month}/${this.day}/${this.year} ${this.hour}:${this.minute}:${this.second} ${this.meridiem}`;
        },
        get Full24h() {
            let newHour = this.hour;
            if (this.meridiem === "PM") newHour = parseInt(newHour) + 12;
            if (newHour > 23) newHour = newHour - 12;
            if (parseStr(newHour).length < 2) newHour = `0${newHour}`;
            return `${this.month}/${this.day}/${this.year} ${newHour}:${this.minute}:${this.second}`;
        },
        humanUnit: (data, dataFormat = "SECONDS") => {
            let secs = 0;
            let mins = 0;
            let hours = 0;
            let days = 0;
            let weeks = 0;
            switch (dataFormat) {
                case "SECONDS":
                    mins = roundTo(data / 60, 2);
                    if (mins < 60) return { data: mins, unit: "minutes", string: `${mins} minutes` };
                    hours = roundTo(mins / 60, 2);
                    if (hours < 24) return { data: hours, unit: "hours", string: `${hours} hours` };
                    days = roundTo(hours / 24, 2);
                    if (days < 7) return { data: days, unit: "days", string: `${days} days` };
                    weeks = roundTo(days / 7, 2);
                    return { data: weeks, unit: "weeks", string: `${weeks} weeks` };
                case "MINUTES":
                    if (data < 1) {
                        secs = roundTo(60 * data, 2);
                        return { data: secs, unit: "seconds", string: `${secs} seconds` };
                    }
                    if (data < 60) return { data: mins, unit: "minutes", string: `${mins} minutes` };
                    hours = roundTo(data / 60, 2);
                    if (hours < 24) return { data: hours, unit: "hours", string: `${hours} hours` };
                    days = roundTo(hours / 24, 2);
                    if (days < 7) return { data: days, unit: "days", string: `${days} days` };
                    weeks = roundTo(days / 7, 2);
                    return { data: weeks, unit: "weeks", string: `${weeks} weeks` };
                default:
                    break;
            }
            return data;
        },
        dateBeforeOrAfter: (dateOne, dateTwo = "") => {
            if (dateOne.toUpperCase() === "NOW") dateOne = new Date(Date.now()).toLocaleString();
            if (dateTwo.toUpperCase() === "NOW") dateTwo = new Date(Date.now()).toLocaleString();
            const dt1MS = Date.parse(dateOne);
            const dt2MS = Date.parse(dateTwo);
            if (dt1MS > dt2MS) return "AFTER";
            if (dt1MS === dt2MS) return "EQUAL";
            return "BEFORE";
        },
        /**
         * 
         * @param {String} dateString Datestring of the date to perform addition on.
         * @param {Integer} valueToAdd Integer value to add to the date
         * @param {String} unit Unit of the value. SECONDS, MINUTES, HOURS, DAYS, WEEKS, MONTHS, YEARS
         * @returns {String} Datestring increased by the supplied value in valueToAdd
         */
        dateAdd: (dateString, valueToAdd, unit = "SECONDS") => {
            if (dateString.toUpperCase() === "NOW") dateString = new Date(Date.now()).toLocaleString();
            const dateInMS = Date.parse(dateString);
            switch (unit) {
                case "SECONDS":
                    valueToAdd = valueToAdd * 1000;
                    break;
                case "MINUTES":
                    valueToAdd = valueToAdd * 1000 * 60;
                    break;
                case "HOURS":
                    valueToAdd = valueToAdd * 1000 * 60 * 60;
                    break;
                case "DAYS":
                    valueToAdd = valueToAdd * 1000 * 60 * 60 * 24;
                    break;
                case "WEEKS":
                    valueToAdd = valueToAdd * 1000 * 60 * 60 * 24 * 7;
                    break;
                case "MONTHS":
                    valueToAdd = valueToAdd * 2628000000;
                    break;
                case "YEARS":
                    valueToAdd = valueToAdd * 1000 * 60 * 60 * 24 * 365;
                    break;

                default:
                    break;
            }
            const newDateMS = dateInMS + valueToAdd;
            return new Date(newDateMS).toLocaleString();
        },
        dateDifference: (dateOne, dateTwo = "", returnFormat = "SECONDS", returnType = "POSITIVENUMBER") => {
            returnFormat = returnFormat.toUpperCase();
            returnType = returnType.toUpperCase();
            if (!dateTwo) dateTwo = new Date(Date.now()).toLocaleString();
            if (is_object(dateOne)) dateOne = dateOne.string;
            if (is_object(dateTwo)) dateTwo = dateTwo.string;
            const dt1MS = Date.parse(dateOne);
            const dt2MS = Date.parse(dateTwo);
            let difference = parseInt(dt1MS) - parseInt(dt2MS);
            const realDifference = difference;
            if (difference < 0 && returnType === "POSITIVENUMBER") difference = difference * -1;
            const secondsDiff = roundTo(difference / 1000, 0);
            const minutesDiff = roundTo(secondsDiff / 60, 1);
            const hoursDiff = roundTo(minutesDiff / 60, 1);
            const daysDiff = roundTo(hoursDiff / 24, 1);
            const weeksDiff = roundTo(daysDiff / 7, 1);
            let returnData;
            switch (returnFormat) {
                case "MILLISECONDS":
                case "MILISECONDS":
                    return difference;
                case "SECONDS":
                    returnData = secondsDiff;
                // eslint-disable-next-line
                case "MINUTES":
                    returnData = minutesDiff;
                // eslint-disable-next-line
                case "HOURS":
                    returnData = hoursDiff;
                // eslint-disable-next-line
                case "DAYS":
                    returnData = daysDiff;
                // eslint-disable-next-line
                case "WEEKS":
                    returnData = weeksDiff;
                // eslint-disable-next-line
                default:
                    break;
            }
            switch (returnType) {
                case "POSITIVENUMBER":
                case "OBJECT":
                    if (returnType === "POSITIVENUMBER") return returnData;
                    return {
                        positivenumber: returnData < 0 ? returnData * -1 : returnData,
                        realDifference: realDifference,
                        seconds: secondsDiff,
                        minutes: minutesDiff,
                        hours: hoursDiff,
                        days: daysDiff,
                        weeks: weeksDiff,
                    };
                case "REALNUMBER":
                    return returnData;

                default:
                    break;
            }
        },
    };
    return tempDate;
};


/**
 * Adds the english day suffix to the numerical day.
 * @param {number} numericalDay The day numerically
 * @returns {string} The suffixed day string. e.g. "1st" or "3rd"
 */
export const formatAddDaySuffix = (numericalDay) => {
    if (is_string) numericalDay = parseInt(numericalDay);
    let suffixedNumer = `${numericalDay}`;
    const suffixes = {
        st: [1, 21, 31],
        nd: [2, 22],
        rd: [3, 23],
        th: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 24, 25, 26, 27, 28, 29, 30]
    }
    Object.entries(suffixes).forEach((suffixEntry) => {
        const suffix = suffixEntry[0];
        const matchArray = suffixEntry[1];
        if (matchArray.includes(numericalDay)) {
            suffixedNumer = `${numericalDay}${suffix}`;
        }
    });
    return suffixedNumer;
}


//####* GENERATE HELPERS *#######* GENERATE HELPERS *#######* GENERATE HELPERS *#######* GENERATE HELPERS *##*



export const loop = (callBack, index = 0, max = 2) => {
    for (index; index < max; index++) {
        //TODO: Had an idea here - not sure what it was... 

    }
}

/**
 * Gets a boolean true or false with psudeorandom probability of 50%. //TODO: Test average probability!!
 * @returns {Boolean} Returns a psudeorandom true or false on every call.
 */
export const getCoinToss = () => {
    return Math.round(((Math.random() * 10) / 10.1)) > 0 ? true : false;
}

/**
 * Generates a Pseudo-random character.
 * @returns {string} a random character, A-Z or a-z
 */
export const getRandomLetter = () => {
    const charArray = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'P', 'O', 'I', 'U', 'Y', 'T', 'R', 'E', 'W', 'Q', 'L', 'K', 'J', 'H', 'G', 'F', 'D', 'S', 'A', 'M', 'N', 'B', 'V', 'C', 'X', 'Z'];
    arrayShuffle(charArray);
    return charArray[getRandomNum(1, 52) - 1];
}


/**
 * Generates a psudeo-random ID string consisting of 0-9. a-z. and A-Z characters.
 * @param {number} byteLen Will produce a string of 'byteLen' bytes in length. Default: 256
 * @param {number} grouping Groups bytes by supplied 'grouping' number, inserting a dash (-) between them. Default: 0
 * @param {boolean} byteLimit If true, the number of dashes (-) from and grouping will be included in the string legnth, limiting the returned string to specifically the length supplied in 'byteLen'. Default: TRUE
 * @param {string} groupDivider A string seperator to use if grouping is set above 0. Default: (-)
 * @returns {string} A psudeo-random string consisting of 0-9. a-z. and A-Z characters.
 */
export const getRandomID = (byteLen = 256, grouping = 0, byteLimit = true, groupDivider = "-") => {
    const idArray = [];
    let byteAdder = 0;
    for (let index = 0; index < byteLen; index++) {
        const thisByte = getCoinToss() ? getRandomLetter() : getRandomNum(1, 9).toString();
        idArray.push(thisByte);
        if ((((index + 1) > grouping && !isFloat((index + 1) / grouping)) || (index + 1) === grouping) && (index + 1 !== byteLen) && grouping > 0) {
            idArray.push(groupDivider);
            byteAdder++;
        }
    }
    const joined = idArray.join("");
    const toReturn = (new Blob([joined]).size === byteLen + byteAdder) ? joined : getRandomID(byteLen, grouping);
    if (byteLimit) return toReturn.substr(0, byteLen);
    return toReturn;
}


//####* FORM VALIDATION HELPERS *########* FORM VALIDATION HELPERS *########* FORM VALIDATION HELPERS *#####*

/**
 * Handles rule validation application, testing the supplied input string against the selected rule.  Will optionally return the response instead of the test result if responseReturn is true.
 * @param {String} userInput String containing the user input from the desired form
 * @param {String} validateAs String containing the desired rule. One of: NOTBLANK, INTEGERS, NUMBERS, CURRENCY, LETTERS, TEXT_SINGLE_LINE, LIST
 * @param {Boolean} responseReturn Returns the failed validation default response if TRUE, false by default
 * @param {Object} ruleOption Optional. Contains additional data required to process the selected rule. e.g. if LIST is supplied in validateAs, ruleOption should contain the one dimensional list object to compare against.
 * @returns Mixed: Boolean if responseReturn is false indicating validation success or fail; String if responseReturn is true containing the default response for the selected rule (supplied in validateAs).
 */
export const preValidate = (userInput = "", validateAs = "", responseReturn = false, ruleOption = null) => {

    switch (validateAs) {
        case VALIDATE_NOTBLANK:
            if (responseReturn) return "This field cannot be blank";
            return isset(userInput);
        case VALIDATE_CHECKBOX:
            if (responseReturn) return "You must check this box.";
            if (userInput === 0 || userInput === 1 || userInput === false || userInput === true) return true;
            return false;
        case VALIDATE_INTEGERS:
            if (responseReturn) return "Use only whole numbers without decimal";
            return new RegExp("[^0-9]").exec(userInput) ? false : true;
        case VALIDATE_NUMBERS:
            if (responseReturn) return "Use numbers and the decimal only";
            return new RegExp("[^0-9.]").exec(userInput) ? false : true;
        case VALIDATE_CURRENCY:
            if (responseReturn) return "Use numbers and the decimal only";
            return new RegExp("[^0-9.$,-]").exec(userInput) ? false : true;
        case VALIDATE_LETTERS:
            // eslint-disable-next-line
            if (responseReturn) return "Use letters only";
            return new RegExp("[^ a-zA-Z]").exec(userInput) ? false : true;
        case VALIDATE_TEXT_SINGLE_LINE:
            if (responseReturn) return "You may not use the following characters: < > ; [ ] { } \ ~ ` = + or new lines";
            // eslint-disable-next-line
            return new RegExp("[^ \t0-9.\:a-zA-Z!@#$%^?&*(),_|'\\\"\-]").exec(userInput) ? false : true;
        case VALIDATE_TEXT_MULTI_LINE:
            if (responseReturn) return "You may not use the following characters: < > ; [ ] { } \\ ~ ` = +";
            // eslint-disable-next-line
            return new RegExp("[^ \s \n\r\t0-9.\:a-zA-Z!@#$%^?&*(),_|'\\\"\-]").exec(userInput) ? false : true;
        case VALIDATE_EMAIL_ADDRESS:
            if (responseReturn) return "Please enter a valid email address.";
            // eslint-disable-next-line
            return new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?").exec(userInput) ? true : false;
        case VALIDATE_EMAIL_ADDRESS_SINGLE:
            // eslint-disable-next-line
            if (responseReturn) return "That character is not valid for an email address.";
            return new RegExp("[^0-9.a-zA-Z@!#$%&'*+-/=?^_{|}]").exec(userInput) ? false : true;
        case VALIDATE_PHONE_NUMBER:
            // eslint-disable-next-line
            if (responseReturn) return "That character is not valid for a phone number.";
            return new RegExp("[^0-9.() -]").exec(userInput) ? false : true;
        case VALIDATE_SAFE:
            // eslint-disable-next-line
            if (responseReturn) return "You may not use the following characters: < > ; [ ] \ ~ ` = or new lines";
            return new RegExp("[^ /t0-9./:a-zA-Z!@#$%^?&*()/,_|'\"+{}-]").exec(userInput) ? false : true;
        case VALIDATE_PASSWORD:
            // eslint-disable-next-line
            if (responseReturn) return "";
            return true;
        case VALIDATE_LIST:
            if (responseReturn) return "Please use an option from the list.";
            const result = Object.values(ruleOption).filter((value) => {
                const thisRegex = new RegExp(userInput, "i");
                return value.match(thisRegex);
            });
            return result.length;
        case VALIDATE_FILE:
            const { fileData, fileName, fileType } = userInput;
            const { acceptedFileExtsArray } = ruleOption;
            if (responseReturn) {
                return verifyDocType(fileData, fileName, fileType, acceptedFileExtsArray).errors;
            }
            return verifyDocType(fileData, fileName, fileType, acceptedFileExtsArray).passed;

        default:
            break;
    }
}

/**
 * Validates inputs sequentially. Designed to be used on live text fields while the user is typing. Should be called with the onChange handler. Will set error messages with setMessage callback of the field and rule in violation.
 * 
 * @param {Object} validationObject A validation object created using genValidationObject(), or manually constructed. 
 * @param {Object} dataIn Object containing a 'name' and 'value' property, representing the user input.
 * @param {Function} processInputCallback Required: Callback function that processes the input. This should involve at minimum the display of the input, restricting the user from even violating the rule.
 * @param {Function} setMessages Required: Callback function that sets the error messages for display.
 * @returns {Boolean} True: validation passed. False: validation failed.
 */
export const validateInput = (validationObject, dataIn, processInputCallback, setMessages) => {
    let passed = true;
    if (validationObject.runValidation === false) return processInputCallback(dataIn.value);
    if (validationObject.runValidation === true) {
        //Process Global rule on input
        if (validationObject.hasOwnProperty("globalRules")) {
            Object.values(validationObject.globalRules).forEach((globalRule) => {
                if (preValidate(dataIn.value, globalRule.rule)) {
                    processInputCallback(dataIn.value);
                    setMessages(getEmptyMessages());
                } else {
                    passed = false;
                    const response = globalRule.hasOwnProperty("fieldResponse") ? globalRule.fieldResponse : preValidate(dataIn.value, globalRule.rule, true);
                    const formResponse = globalRule.hasOwnProperty("formResponse") ? globalRule.formResponse : "You have an error in your form, check your entries.";
                    setMessages({
                        form: {
                            0: formResponse
                        },
                        fields: {
                            [dataIn.name]: response
                        },
                        success: false,
                    })
                }
            })
        }
        // Process individual input rule
        Object.entries(validationObject.rules).forEach((entry) => {
            const [field, ruleObject] = entry;
            if (dataIn.name === field) {
                //Field rules exist - run pre-validation
                if (preValidate(dataIn.value, ruleObject.rule, false, ruleObject.ruleOption)) {
                    processInputCallback(dataIn.value);
                    setMessages(getEmptyMessages());
                } else {
                    passed = false;
                    const response = ruleObject.hasOwnProperty("fieldResponse") ? ruleObject.fieldResponse : preValidate(dataIn.value, ruleObject.rule, true, ruleObject.ruleOption);
                    const formResponse = ruleObject.hasOwnProperty("formResponse") ? ruleObject.formResponse : "You have an error in your form, check your entries.";
                    setMessages({
                        form: {
                            0: formResponse
                        },
                        fields: {
                            [field]: response
                        },
                        success: false,
                    })
                }
            } else {
                //No field rules Exist
            }
        });
    }
    return passed;
} // END validateInput


/**
 * Validates an entire form, displaying multiple errors at once. Designed to be used on form submition or prior to form submission. Should be called with the onSubmit, or onClick (for submit button) handler. Will set error messages with setMessage callback of the fields and rules in violation.
 * 
 * @param {Object} validationObject Object containing the validation rule set in the following format:
 * validationObject = {
 *		runValidation: true,
 *      rules: {
 *           FIELDNAME: { rule: "RULENAME", fieldResponse: "RESPONSE", formResponse: "RESPONSE" }
 *       },
 *       globalRules: {
 *           0: { rule: "RULENAME", fieldResponse: "RESPONSE" }
 *       } 
 * }
 * @param {Object} dataInObject Object containing input field names as property keys and the corresponding input value. {INPUTFIELDNAME:"Input Value"} 
 * @param {Function} setMessages Required: Callback function to return the error messages.
 * @returns {Boolean} TRUE if validation passed, FALSE if validation failed (setMessages will contain the errors).  Note: setMessages is called regardless of validation result.
 */
export const validateInputs = (validationObject, dataInObject, setMessages) => {
    if (validationObject.runValidation === false) return true;
    if (validationObject.runValidation === true) {
        let returnMessages = getEmptyMessages();
        let validationPassed = true;
        Object.entries(dataInObject).forEach((dataInEntry) => {
            const dataIn = {
                name: dataInEntry[0],
                value: dataInEntry[1]
            }
            //Process Global rule on input
            if (validationObject.hasOwnProperty("globalRules")) {
                Object.values(validationObject.globalRules).forEach((globalRule, index) => {
                    if (!preValidate(dataIn.value, globalRule.rule)) {
                        //Rule failed, look for response and add to message object
                        const response = globalRule.hasOwnProperty("fieldResponse") ? globalRule.fieldResponse : preValidate(dataIn.value, globalRule.rule, true);
                        const formResponse = globalRule.hasOwnProperty("formResponse") ? globalRule.formResponse : "You have an error in your form, check your entries.";
                        validationPassed = false;
                        returnMessages["form"][index] = formResponse;
                        returnMessages["fields"][dataIn.name] = response;
                        returnMessages["success"] = false;
                    }
                })
            }
            // Process individual input rule
            Object.entries(validationObject.rules).forEach((entry, index) => {
                const [field, ruleObject] = entry;
                if (dataIn.name === field) {
                    //Field rules exist - run pre-validation
                    if (!preValidate(dataIn.value, ruleObject.rule, false, ruleObject.ruleOption)) {
                        const response = ruleObject.hasOwnProperty("fieldResponse") ? ruleObject.fieldResponse : preValidate(dataIn.value, ruleObject.rule, true);
                        const formResponse = ruleObject.hasOwnProperty("formResponse") ? ruleObject.formResponse : "You have an error in your form, check your entries.";
                        validationPassed = false;
                        returnMessages.form[index] = formResponse;
                        returnMessages.fields[field] = response;
                        returnMessages.success = false;
                    }
                }
            });


        });
        if (!objectsAreEqual(getEmptyMessages(), returnMessages)) setMessages(returnMessages);
        return validationPassed;
    }

} // END validateInputs


export const verifyDocType = (fileData = "", fileName = "", fileMime = "", acceptTypes = []) => {
    if (!isset(fileData) || !is_string(fileData)) { localMessage("'file' property must be a non-empty string.", "log", "ERROR"); return { passed: false, errors: ["'file' property must be a non-empty string."] } }
    if (!isset(fileName) || !is_string(fileName)) { localMessage("'fileName' property must be a non-empty string.", "log", "ERROR"); return { passed: false, errors: ["'fileName' property must be a non-empty string."] } }
    if (!fileName.match(".")) { localMessage("'fileName' property must inlcude the concatenated file extention.", "log", "ERROR"); return { passed: false, errors: ["'fileName' property must inlcude the concatenated file extention."] } }
    if (!isset(fileMime) || !is_string(fileMime)) { localMessage("'fileMime' property must be a non-empty string.", "log", "ERROR"); return { passed: false, errors: ["'fileMime' property must be a non-empty string."] } }
    if (!isset(acceptTypes) || !is_array(acceptTypes)) { localMessage("'acceptTypes' property must be a non-empty array.", "log", "ERROR"); return { passed: false, errors: ["'acceptTypes' property must be a non-empty array."] } }

    const supportedTypes = {
        csv: {
            // eslint-disable-next-line
            accept: [`^(?:[/0-9a-zA-Z,/_=:|+#!@$%^&)(*.'"\r\n\t -]{1,500000})$`, `(?:\"([^\"]*(?:\"\"[^\"]*)*)\")|([^\",]+)`],
            // eslint-disable-next-line
            reject: ["^(?:<\?xml version)", "[^\x00-\x7F]+"],
            additionalTypes: ["txt"],
            mimes: ["application/vnd.ms-excel", "application/text"]
        }
    }
    // Prepare input props
    acceptTypes = acceptTypes.map((value) => {
        return value.toLowerCase().replaceAll(".", "");
    });
    fileMime = fileMime.trim();
    const fileNameArray = fileName.split(".");
    const file = {
        name: fileNameArray[0],
        ext: fileNameArray[1]
    }

    //Run Rules
    let passed = true;
    let errors = [];
    Object.entries(supportedTypes).forEach(entry => {
        const supportedExt = entry[0];
        const acceptRegexpArray = entry[1]["accept"];
        const rejectRegexpArray = entry[1]["reject"];
        const additionalExtsArray = entry[1]["additionalTypes"];
        const supportedMimesArray = entry[1]["mimes"];
        //CHECK FILE EXTENTION
        if (acceptTypes.includes(supportedExt)) {
            const thisExtArray = [supportedExt];
            additionalExtsArray.forEach((value) => thisExtArray.push(value));
            if (!thisExtArray.includes(file.ext)) {
                passed = false;
                errors.push(`File Extention is not correct. Please use: ${acceptTypes.join(",")} only.`);
            }
        } else {
            passed = false;
            errors.push(`File Extention is not supported. Please use: ${acceptTypes.join(",")} only.`);
        }
        //CHECK FILE MIME
        if (!supportedMimesArray.includes(fileMime)) {
            passed = false;
            errors.push(`File mime type is not correct. Please use: ${supportedMimesArray.join(",")} files only.`);
        }
        //CHECK FILE DATA
        acceptRegexpArray.forEach((thisRegexp) => {
            const thisRegex = new RegExp(thisRegexp);
            const acceptMatch = fileData.match(thisRegex);
            if (!is_array(acceptMatch) || !propExists(acceptMatch, "acceptMatch.length")) {
                passed = false;
                errors.push(`File data does not match expected type. Please use true: ${acceptTypes.join(",")} files only. Error 101`);
            }
        });
        rejectRegexpArray.forEach((thisRegexp) => {
            const rejectMatch = fileData.match(new RegExp(thisRegexp));
            if (is_array(rejectMatch) && rejectMatch.length) {
                passed = false;
                errors.push(`File data does not match expected type. Please use true: ${acceptTypes.join(",")} files only. Error 102`);
            }
        });

    });
    return {
        passed,
        errors,
    }

}// END verifyDocType

/**
 * Generates or Modifies a validation object to be passed to the validateInput or validateInputs function. 
 * @param {null|{}} currentValidationObject The current validation object if exists
 * @param {boolean} options.runValidation If false, validation will not be run
 * @param {String|{}} options.field 
 * @param {String} options.key
 * @param {String} options.rule
 * @param {{}} options.ruleOption Options specific to the rule used. e.g. For list types this will be all accepted list items and ruleOption should contain the one dimensional list object to compare against, or for fileinput a list of accepted file types
 * @param {String} options.fieldResponse
 * @param {String} options.formResponse
 * @param {String|{}} options.globalRule
 * @param {String} options.globalFieldResponse
 * @param {String} options.globalFormResponse
 * @usage 
 * @example genValidationObject({runValidation:true,field:"MyInputName",rule:"MyRuleName"})
 * @returns {{}} A validation object that can be passed in again as a first paramater to add additional rules.
 *  @example {Object} validationObject = {
            runValidation: true,
            rules: {
                FIELDNAMEASKEY: {
                    rule: "TEXT_SINGLE_LINE",
                    ruleOption: {},
                    fieldResponse: "My custom field response",
                    formResponse: "My custom form response",
                }
            },
            globalRules: {
                0: {
                    rule: "GLOBALRULENAME",
                    fieldResponse: "Custom global field response",
                    formResponse: "Custom global form response"
                }
            }
        };
 */
export const genValidationObject = (currentValidationObject = {}, options = {}) => {

    const optionTypes = {
        runValidation: ["boolean"],
        field: ["string", "object"],
        key: ["string"],
        rule: ["string"],
        ruleOption: ["object"],
        fieldResponse: ["string"],
        formResponse: ["string"],
        globalRule: ["string", "object"],
        globalFieldResponse: ["string"],
        globalFormResponse: ["string"],
    }
    let validate = {
        runValidation: false,
        rules: {
            // NewCategoryName: { rule: "TEXT_SINGLE_LINE" },
        },
        globalRules: {
            // 0: { rule: "NOTBLANK" }
        }
    }

    const checkValueTypes = (valueObject, ruleObject) => {
        let passed = true;
        Object.entries(valueObject).forEach((entry) => {
            const optionName = entry[0];
            const optionValue = entry[1];
            if (!optionTypes.hasOwnProperty(optionName)) {
                throw localMessage(`Paramater 'options.${optionName}' in function 'genValidationObject' is not a valid option. Please supply a valid option.`, "log", "error");
            }
            const allowedTypes = optionTypes[optionName];
            if (!isIndexOf(allowedTypes, getType(optionValue))) {
                throw localMessage(`Paramater 'options.${optionName}' in function 'genValidationObject' has a value that is not a valid type. Valid Types: ${allowedTypes.join(", ")}. ${getType(optionValue)} was supplied.`, "log", "error");
            }
            if (ruleObject) {
                if (!getType(optionValue, allowedTypes[0])) {
                    throw localMessage(`Paramater 'options.${optionName}' in function 'genValidationObject' has a value that is not a valid type. Valid Type: ${allowedTypes[0]}. ${getType(optionValue)} was supplied.`, "log", "error");
                }
            }
            if (!isset(optionValue)) {
                localMessage(`Paramater 'options.${optionName}' in function 'genValidationObject' has no value set.${getType(optionValue)} was supplied. No changes will be made to any supplied 'currentValidationObject'`, "log", "warning");
                passed = false;
            }
        });
        return passed;
    }

    if (!isset(currentValidationObject) && !isset(options)) return validate;
    if (isset(currentValidationObject) && !isset(options)) return currentValidationObject;
    if (isset(currentValidationObject)) validate = objectCopy(currentValidationObject);
    if (!checkValueTypes(options)) return validate;
    if (options.hasOwnProperty("runValidation")) {
        validate.runValidation = options.runValidation;
    }
    //Setting the individual field rules
    if (options.hasOwnProperty("field")) {
        if (getType(options.field, "string")) {
            validate.rules = {
                ...validate.rules,
                [options.field]: { rule: "SAFE" }
            }
            if (options.hasOwnProperty("rule")) {
                validate.rules[options.field].rule = options.rule;
            }
            if (options.hasOwnProperty("ruleOption")) {
                validate.rules[options.field]["ruleOption"] = options.ruleOption;
            }
            if (options.hasOwnProperty("fieldResponse")) {
                validate.rules[options.field]["fieldResponse"] = options.fieldResponse;
            }
            if (options.hasOwnProperty("formResponse")) {
                validate.rules[options.field]["formResponse"] = options.formResponse;
            }
        }

        if (getType(options.field, "object")) {
            if (options.field.hasOwnProperty("rule") && checkValueTypes(options.field, true)) {
                validate = {
                    ...validate,
                    rules: {
                        ...validate.rules,
                        [options.field.key]: { ...options.field },
                    }
                }
                delete validate.rules[options.field.key].key;
            }
        }
    }
    //Setting any global rules
    if (options.hasOwnProperty("globalRule")) {
        const globalRuleCount = Object.values(validate.globalRules).length;
        const globalRuleKey = globalRuleCount > 0 ? parseInt(globalRuleCount) + 1 : 0;
        if (getType(options.globalRule, "string")) {
            validate = {
                ...validate,
                globalRules: {
                    ...validate.globalRules,
                    [globalRuleKey]: { rule: options.globalRule },
                }
            }
            if (options.hasOwnProperty("globalFieldResponse")) {
                validate.globalRules[globalRuleKey]["fieldResponse"] = options.globalFieldResponse;
            }
            if (options.hasOwnProperty("globalFormResponse")) {
                validate.globalRules[globalRuleKey]["formResponse"] = options.globalFormResponse;
            }
        }

        if (getType(options.globalRule, "object")) {
            if (!checkValueTypes(options.globalRule, true)) {
                validate = {
                    ...validate,
                    globalRules: {
                        ...validate.globalRules,
                        [globalRuleKey]: { ...options.globalRule }
                    }
                }
            }
        }
    }
    return validate;
}


//####* DOM MANIPULATION HELPERS *#######* DOM MANIPULATION HELPERS *#######* DOM MANIPULATION HELPERS *######



export const getElementDimensions = (elementId) => {
    const element = { width: null, height: null, margin: [], border: [], padding: [] }
    if (!isset(elementId)) return element;
    const thisElement = document.getElementById(elementId);
    if (thisElement) {
        const clientWidth = thisElement.clientWidth;
        const clientHeight = thisElement.clientHeight;
        const clientMargin = { marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0 };
        const clientPadding = { paddingTop: 0, paddingBottom: 0, PaddingLeft: 0, paddingRight: 0 };
        const clientBorder = { borderTopWidth: 0, borderBottomWidth: 0, borderLeftWidth: 0, borderRightWidth: 0 };
        const elementArray = [clientMargin, clientPadding, clientBorder];
        const style = getComputedStyle(thisElement);
        elementArray.forEach((thisObject, index) => {
            Object.keys(thisObject).forEach((key) => {
                if (exists(style, key)) elementArray[index][key] = parseInt(style.key) || 0;
            });
        });
        element.width = clientWidth;
        element.height = clientHeight;
        element.margin = [clientMargin.marginBottom, clientMargin.marginLeft, clientMargin.marginRight, clientMargin.marginTop]
        element.border = [clientBorder.borderBottomWidth, clientBorder.borderLeftWidth, clientBorder.borderRightWidth, clientBorder.borderTopWidth];
        element.padding = [clientPadding.paddingBottom, clientPadding.PaddingLeft, clientPadding.paddingRight, clientPadding.paddingTop];
    }
    return element;
}


//####* SESSION & STORAGE HELPER *########* SESSION & STORAGE HELPER *########* SESSION & STORAGE HELPER *####

/**
 * Checks if an HTTPONLY cookie exists.
 * @param {string} cookiename The name of the cookie you are checking for existance.
 * @returns {boolean} True if the cookie exists, false if it does not exist.
 */
export const doesHttpOnlyCookieExist = (cookiename) => {
    /* Adapted from Eric Labashosky on stackoverflow.com */
    const d = new Date();
    d.setTime(d.getTime() + (1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = cookiename + "=test_value;path=/;" + expires;
    return document.cookie.indexOf(cookiename + '=') === -1;
}

/**
 * Returns an empty user object.
 * @returns {{isAuth:boolean,userID:number,profileID:number,userType:number,userTypeApproval:boolean,userName:string,sessionID:number}} An empty user object.
 */
export const getEmptyUser = () => {
    return {
        isAuth: false,
        userID: 0,
        sessionID: 0,
        profileID: 0,
        userTypeID: 0,
        userTypeIDApproval: false,
        userName: "",
    };
};

/**
 * Checks to see if the supplied is a valid user object with the correct keys. DOES NOT type check values.
 * @param {{isAuth: boolean, userID: number, profileID: number, userType: number, userTypeApproval: boolean, userName: string, sessionID: number}} user A user object
 * @returns {boolean} True if contains the correct, and only the correct keys, false otherwise.
 */
export const isUserObject = (user) => {
    if (!is_object(user)) return false;
    let testUser = objectCopy(user);
    Object.keys(getEmptyUser()).forEach((key) => {
        if (testUser.hasOwnProperty(key)) delete testUser[key];
    });
    if (isset(testUser)) return false;
    return true;
}

export const getEmptyProfile = () => {
    return {
        userID: 0,
        profileID: 0,
        userFirstName: "",
        userLastName: "",
        userStreet1: "",
        userStreet2: "",
        userCity: "",
        userState: "",
        userZip: "",
        userMobileNumber: "",
        userHomeNumber: "",
        profilePhoto: "",
        userProfilePhotoFileName: "",
        profilePhotoMime: "",
        userDisplayName: "",
    }
}

/**
 * Returns an empty session object.
 * @returns {{sessionID:number,accessToken:string,accessTokenExpiry:string,refreshTokenExpiry:string,sessionSync:boolean,lastSync:string}} An empty session object.
 */
export const getEmptySession = () => {
    return {
        isAuth: false,
        userID: 0,
        sessionID: 0,
        accessToken: "",
        accessTokenExpiry: new Date(0).toLocaleString(),
        refreshTokenExpiry: new Date(0).toLocaleString(),
    }
}

/**
 * Checks to see if the supplied is a valid session object with the correct keys. DOES NOT type check values.
 * @param {{sessionID:number,accessToken:string,accessTokenExpiry:string,refreshTokenExpiry:string,sessionSync:boolean,lastSync:string}} session 
 */
export const isSessionObject = (session) => {
    if (!is_object(session)) return false;
    let testSession = objectCopy(session);
    Object.keys(getEmptySession()).forEach((key) => {
        if (testSession.hasOwnProperty(key)) delete testSession[key];
    });
    if (isset(testSession)) return false;
    return true;
}


/**
 * Checks if valid session information exists and is not expired. The assumption is that submitting this session information to the auth API for refresh should return a positive result.  NOTE: This function DOES NOT verify that the session info IS valid, only that it appears valid and eligible to be submitted to the API to be authenticated and verify authorization.
 * @param {{isAuth:boolean,userID:number,profileID:number,userType:number,userTypeApproval:boolean,userName:string,sessionID:number}} user A user object.
 * @param {{sessionID:number,accessToken:string,accessTokenExpiry:string,refreshTokenExpiry:string,sessionSync:boolean,lastSync:string}} session A session object.
 * @returns {boolean} True if the session is valid and eligible for token refresh, false if re-auth is needed.
 */
export function sessionLooksValid(user, session) {
    if (!issetAll([user, session])) return false;
    const { sessionID, accessToken, accessTokenExpiry, refreshTokenExpiry } = session;
    const { isAuth, sessionID: sessionId, userID } = user;
    //Ensure all properties are set
    if (!issetAll([sessionID, accessToken, accessTokenExpiry, refreshTokenExpiry, isAuth, sessionId, userID])) return false;
    //Data integrity check
    if (sessionID !== sessionId || !is_number(sessionID) || sessionID < 1) return false;
    // verify access token is the right length & isn't expired 
    if (accessToken.length !== ACCESSTOKEN_LENGTH) return false;
    const accessExpiry = new MyDate(accessTokenExpiry);
    const refreshExpiry = new MyDate(refreshTokenExpiry);
    if (accessExpiry.dateBeforeOrAfter() === "BEFORE" || accessExpiry.hasError) return false;
    // verify refresh token isn't expired
    if (refreshExpiry.dateBeforeOrAfter() === "BEFORE" || refreshExpiry.hasError) return false;
    // verify refresh token exists using doesHttpOnlyCookieExist
    if (!doesHttpOnlyCookieExist(REFRESHTOKEN_NAME)) return false;
    // if all good, return true
    return true;
}


/** A Session object with current info, utilities, etc. */
export class Session {
    /**
     * @param {{isAuth:boolean,userID:number,profileID:number,userTypeID:number,userTypeIDApproval:boolean,userName:string,sessionID:number}} user The user object returned from the API
     * @param {{sessionID:number,accessToken:string,accessTokenExpiry:string,refreshTokenExpiry:string}} session The session object returned from the API
     */
    constructor(user, session) {
        this.hasError = false;
        this.errors = [];
        this.user = user;
        try {
            if (is_string(user)) this.user = JSON.parse(user);
        } catch (error) {
            this.hasError = true;
            this.errors.push(`Invalid JSON string supplied as user.`);
            user = getEmptyUser();
        }
        this.session = session;
        try {
            if (is_string(session)) this.session = JSON.parse(session);
        } catch (error) {
            this.hasError = true;
            this.errors.push(`Invalid JSON string supplied as session.`);
            session = getEmptySession();
        }
        if (!isUserObject(this.user)) {
            this.hasError = true;
            this.errors.push("An invalid user object was supplied.");
            this.user = getEmptyUser();
            user = this.user;
        }
        if (!isSessionObject(this.session)) {
            this.hasError = true;
            this.errors.push("An invalid session object was supplied.");
            this.session = getEmptySession();
            session = this.session;
        }
        this.isAuth = user.isAuth;
        this.userID = user.userID;
        this.profileID = user.profileID;
        this.userType = user.userTypeID;
        this.isApproved = user.userTypeIDApproval;
        this.userName = user.userName;
        this.sessionID = session.sessionID;
        this.accessToken = session.accessToken;
        this.accessExpiry = session.accessTokenExpiry;
        this.refreshExpiry = session.refreshTokenExpiry;
        this.validateSession();
    }

    get isset() {
        if (!isUserObject(this.user) || !isSessionObject(this.session)) return false;
        return (!objectsAreEqual(this.user, getEmptyUser()) && !objectsAreEqual(this.session, getEmptySession()));
    }

    get refreshTokenExists() {
        if (!doesHttpOnlyCookieExist(REFRESHTOKEN_NAME)) {
            this.hasError = true;
            this.errors.push("Refresh Token does not exist.");
            return false;
        }
        return true;
    }

    get isValid() {
        return !this.hasError;
    }

    get isExpired() {
        return this.refreshTokenIsExpired();
    }

    get canRefresh() {
        if (this.hasError) return false;
        if (this.refreshTokenIsExpired()) return false;
        return true;
    }

    get needsRefresh() {
        if (this.accessTokenIsExpired()) return true;
        const accessExpiry = new MyDate(this.accessExpiry);
        const soon = new Date(new Date().getTime() + ACCESSTOKEN_REFRESH_FREQUENCY).toLocaleString();
        if (accessExpiry.dateBeforeOrAfter(soon) === "BEFORE" || accessExpiry.hasError) return true;
        if (accessExpiry.dateBeforeOrAfter(soon) === "EQUAL" || accessExpiry.hasError) return true;
        return false;
    }



    accessTokenIsExpired() {
        const accessExpiry = new MyDate(this.accessExpiry);
        if (accessExpiry.dateBeforeOrAfter() === "BEFORE" || accessExpiry.hasError) return true;
        if (accessExpiry.dateBeforeOrAfter() === "ERROR") {
            this.hasError = true;
            this.errors.push(...accessExpiry.errors());
            return true;
        }
        return false;
    }

    refreshTokenIsExpired() {
        if (!doesHttpOnlyCookieExist(REFRESHTOKEN_NAME)) {
            this.hasError = true;
            this.errors.push("Refresh Token does not exist.");
            return true;
        }
        const refreshExpiry = new MyDate(this.refreshExpiry);
        if (refreshExpiry.dateBeforeOrAfter() === "BEFORE" || refreshExpiry.hasError) {
            this.hasError = true;
            this.errors.push("Refresh token is expired.");
            return true;
        }
        if (refreshExpiry.dateBeforeOrAfter() === "ERROR") {
            this.hasError = true;
            this.errors.push(...refreshExpiry.errors());
            return true;
        }
        return false;
    }

    validateSession() {
        if (this.hasError) return false;
        //Ensure all properties are set
        if (!issetAll([this.isAuth, this.userID, this.profileID, this.userType, this.isApproved, this.userName, this.sessionID, this.accessToken, this.accessExpiry, this.refreshExpiry])) {
            this.hasError = true;
            this.errors.push("One or more required object properties for the user and session objects are not set.")
            return false;
        }
        //Data integrity check
        if (this.session.sessionID !== this.user.sessionID ||
            !is_number(this.session.sessionID)) {
            this.hasError = true;
            this.errors.push("Session ID mismatch with user object. Integrity check failed.");
            return false;
        }
        if (this.session.sessionID < 1) {
            this.hasError = true;
            this.errors.push("Session ID is not valid.");
            return false;
        }
        // verify access token is the right length & isn't expired 
        if (this.accessToken.length < ACCESSTOKEN_LENGTH) {
            this.hasError = true;
            this.errors.push("Access token bit depth incorrect.");
            return false;
        }
        //Verify refresh token is exists and is not expired
        if (this.refreshTokenIsExpired()) return false;
        // if all good, return true
        return true;
    }
}


//####* PAGE HELPERS *########* PAGE HELPERS *########*  PAGE HELPERS *########*  PAGE HELPERS *########*  



export const getEmptyMessages = (messageType = null, messageValue = "") => {
    const emptyMessageObject = {
        request: {},
        form: {},
        fields: {},
        success: false,
    };
    if (isset(messageType)) {
        if (is_string(messageType) && is_string(messageValue) && isset(messageValue)) {
            if (messageType === "fields" && inString(messageValue, "|")) {
                return {
                    ...emptyMessageObject,
                    [messageType]: { [messageValue.split("|")[0]]: messageValue.split("|")[1] }
                };
            }
            return {
                ...emptyMessageObject,
                [messageType]: [messageValue]
            };
        }
        if (is_array(messageType)) {
            let toggle = false;
            const message = [];
            const moreMessages = {}
            messageType.forEach((item) => {
                message.push(item);
                if (toggle) {
                    const value = message.pop();
                    const key = message.pop()
                    if (!is_array(moreMessages[key])) moreMessages[key] = [];
                    moreMessages[key].push(value);
                }
                toggle = !toggle;
            });
            return {
                ...emptyMessageObject,
                ...moreMessages,
            };
        }
    }
    return emptyMessageObject;
};


export const getEmptyResponse = (dataType = null) => {
    let dataValue = null;
    if (isset(dataType)) {
        dataType.toUpperCase();
        if (dataType === "USER") dataType = getEmptyUser();
        if (dataType === "SESSION") dataType = getEmptyUser();
        if (is_object(dataType)) dataValue = dataType;
    }
    return {
        data: dataValue,
        messages: getEmptyMessages(["request", "Default Message Used, server response not understood.", "form", "An unknown error occured, please try again."]),
        statusCode: 503,
    };
};


//####* ERROR HANDLING HELPERS *########* ERROR HANDLING HELPERS *########* ERROR HANDLING HELPERS *########*

/**
 * Sends a user facing message to the supplied location, working in tandem with the setMessages function and the ServerResponse component.  Sends messages to either the console with "REQUEST" or "LOG", a form or field message set in ServerResponse.
 * @param {String} message The message you wish to display.
 * @param {String} pageLocation The location to display the message. (log,request,fields,form,throw)
 * @param {String} type The type of message: SUCCESS, ALERT, WARNING, ERROR
 * @param {Function|{}} setMessages The set messages function passed in as a callback, or an object containing additional properties to be passed to the throw error object.
 * @returns {{stack:string,message:string,log:function}} An error object if thrown is supplied as pageLocation, void otherwise. //TODO: This should usiversally return a custom error object but only throw and exception when throw is selected.  Perhaps write a getStackTrace function using a try/catch and new error object with .stack. test parsing the stack on firefox, chrome and IE.
 */
export const localMessage = (message, pageLocation = "REQUEST", type = "ALERT", setMessages = null) => {
    let pageLocationField = "";
    if (is_string(type)) type = type.toUpperCase();
    if (is_string(pageLocation)) pageLocation = pageLocation.toUpperCase();
    if (is_object(pageLocation)) pageLocationField = Object.values(pageLocation)[0];
    if (is_object(pageLocation)) pageLocation = Object.keys(pageLocation)[0].toUpperCase();
    const returnMessages = getEmptyMessages();
    let thrownError;
    let thisConsoleError = null;
    let thisConsoleStyle = "";
    let consoleLog = console.log;
    const consoleAlertStyle = "color: black; background-color:#fffddb; font-family:sans-serif; font-size: 13px; font-weight: normal";
    const consoleSuccessStyle = "color: darkgreen; font-family:sans-serif; font-size: 12px; font-weight: bold";
    const consoleErrorStyle = "color: darkred; font-family:sans-serif; font-size: 14px; font-weight:bold";
    const consoleWarningStyle = "color: #D2AF00; font-family:sans-serif; font-size: 13px; font-weight:bold; text-shadow: 1px 1px 1px black";

    if (pageLocation === "THROW") type = "ERROR";
    switch (type) {
        case "SUCCESS":
            returnMessages.success = true;
            thisConsoleError = `%c${message}`;
            thisConsoleStyle = consoleSuccessStyle;
            break;
        case "ALERT":
            returnMessages.success = false;
            thisConsoleError = `%c${message}`;
            thisConsoleStyle = consoleAlertStyle;
            break;
        case "WARNING":
            returnMessages.success = false;
            thisConsoleError = `%c${message}`;
            thisConsoleStyle = consoleWarningStyle;
            consoleLog = console.warn;
            break;
        case "ERROR":
            returnMessages.success = false;
            let thisError;
            try {
                throw new Error(message);
            } catch (error) {
                thisError = error;
            }
            if (pageLocation !== "THROW") {
                thisError = {
                    message: thisError.message,
                    stack: thisError.stack
                }
            }
            thisConsoleError = `%c${thisError.message}`;
            thisConsoleStyle = consoleErrorStyle;
            consoleLog = console.error;
            const additionalErrors = getType(setMessages, "function") ? setMessages() : setMessages;
            thrownError = {
                stack: thisError.stack,
                message: message,
                log: () => { console.error(thisConsoleError, thisConsoleStyle) },
                ...additionalErrors,
            }
            break;


        default:
            returnMessages.success = false;
            break;
    }

    switch (pageLocation) {
        case "LOG":
        case "CONSOLE":
        case "REQUEST":
            consoleLog(thisConsoleError, thisConsoleStyle);
            returnMessages.request = { 0: message };
            break;
        case "FORM":
            returnMessages.form = { 0: message };
            break;
        case "FIELD":
        case "FIELDS":
            returnMessages.fields = { [pageLocationField]: message };
            break;
        case "THROW":
            throw thrownError;

        default:
            break;
    }
    if (typeof setMessages === 'function') {
        setMessages(returnMessages);
    }
}


//####* TESTING & DEBUGGING HELPERS *########* TESTING & DEBUGGING HELPERS *########* T&D HELPERS *##########*

export const getBrowser = () => {
    return detect(window.navigator.userAgent);
}


/**
 * 
 * @param {function|{}|string|number|boolean} resolveReturn 
 * @param {function|{}|string|number|boolean} rejectReturn 
 * @param {number} timeDelay 
 * @param {boolean} consoleAlerts 
 * @returns 
 */
export const returnFakeAsyncRequest = (resolveReturn = true, rejectReturn = false, timeDelay = 500, consoleAlerts = false) => {
    if (consoleAlerts) localMessage("Enter returnFakeAsyncRequest");
    const testPromise = new Promise((resolve, reject) => {
        if (consoleAlerts) localMessage("Waiting in returnFakeAsyncRequest");
        setTimeout(() => {
            if (consoleAlerts) localMessage("Returning Resolve fake request in returnFakeAsyncRequest");
            if (resolveReturn) {
                if (getType(resolveReturn, "function")) return resolve(resolveReturn());
                return resolve(resolveReturn);
            }
            if (consoleAlerts) localMessage("Returning Reject in returnFakeAsyncRequest");
            if (getType(rejectReturn, "function")) return reject(rejectReturn());
            return reject(resolveReturn);
        }, timeDelay);

    });
    return testPromise;
}
