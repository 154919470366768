import React, { useState, useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap';
import { arrayCopy, arrayEmpty, arrayRemoveString, exists, getRandomNum, getType, isIndexOf, isset, is_array, is_number, is_object, is_string, localMessage, objectTextToKey } from '../../../../../functions/library';
import './multiSelectList.scss';


/**
 * 
 * @param {String} className The main class of elements outer div.
 * @param {Object} inputOptions Object containing one of: multiItems, removeSelected, resetAble, mouseOver, mouseOut, mouseClick, listMaxWidth, listMaxHeight, textFontSize.
 * | multiItems - Object containing values to be available and displayed in the list.
 * | removeSelected - Boolean denoting if selected items are to be removed.
 * | resetAble - If a reset button will be displayed to reset selections if they have been removed
 * | mouseOver - Boolean that Returns to the inputFunction a mouseover notification for each list item if true.
 * | mouseOut - Boolean that Returns to the inputFunction a mouseout notification for each list item if true.
 * | mouseClick - Boolean that Returns to the inputFunction a mouseclick notification for each list item if true. NOTE: selected values are already returned when selected, this is for advanced development purposes only.
 * | listMaxWidth - String css value for the max width of the main component.
 * | listMaxHeight - String css value for the max width of the main component. Component will show a scroll if set below the visible items limit. Handled automatically.
 * | textFontSize - String css value for the list item font size.
 * @param {String} inputType Sets the type attribute for the main div.
 * @param {String} inputName Sets the name attribute for the main div.
 * @param {Mixed} inputValue A string, object, or array containing the item or items to be preselected. Preselects only once on load.
 * @param {Function} inputFunction The callback function called to return a selected items array.
 * @param {Any} resetList Any value other than null or undefined that is different than the preceeding supplied value will trigger a reset of the list. e.g. changing resetList from true to false, or 0 to 1, or even 1 to 443, will trigger a reset.
 * @returns {Object} Depending on the selected options, will return an object containing the following properties: selected, mouseover, mouseout, mouseclick
 * selected - {selected:[array of selected items]}
 * mouseover - {mouseover: {id:"item element id", value:"Value of item", event:{event object}}}
 * mouseout - {mouseout: {id:"item element id", value:"Value of item", event:{event object}}}
 * mouseclick - {mouseclick: {id:"item element id", value:"Value of item", event:{event object}}}
 */
const MultiSelectList = ({ className, inputOptions, inputType, inputName, inputValue, inputFunction, resetList }) => {
    const { multiItems, removeSelected, resetAble, mouseOver, mouseOut, mouseClick, textWrap, listMaxWidth, listMaxHeight, textFontSize, toolTips, toolTipSpeed, } = inputOptions;


    const [selectedMultiItems, setSelectedMultiItems] = useState([]);
    const [removedItems, setRemovedItems] = useState([]);
    const [multiDisplayList, setMultiDisplayList] = useState([]);
    const [useHover, setUseHover] = useState(false);
    const [hoverContent, setHoverContent] = useState("");
    const [hoverSpeed, setHoverSpeed] = useState("tooltip-slow");
    const [removeItems, setRemoveItems] = useState(false);
    const [resetButton, setResetButton] = useState(true);
    const [useMouseOver, setUseMouseOver] = useState(false);
    const [useMouseOut, setUseMouseOut] = useState(false);
    const [useMouseClick, setUseMouseClick] = useState(false);
    const [mouseEvent, setMouseEvent] = useState({});
    const [maxWidth, setMaxWidth] = useState("100vw");
    const [maxHeight, setMaxHeight] = useState("40vh");
    const [fontSize, setFontSize] = useState("100%");
    const pageName = "MultiSelectList";
    const pageId = getRandomNum(1000000000, 9999999999);
    const MainElement = useRef(null);


    useEffect(() => {
        let mounted = true;

        if (mounted && isset(listMaxWidth)) {
            setMaxWidth(listMaxWidth);
        }

        if (mounted && isset(listMaxHeight)) {
            setMaxHeight(listMaxHeight);
        }

        if (mounted && isset(textFontSize)) {
            setFontSize(textFontSize);
        }

        if (mounted && multiItems !== undefined && multiItems !== null) {
            //     console.log(`${inputName} multiItems = ${JSON.stringify(multiItems)}`);
            if (is_object(multiItems)) setMultiDisplayList(Object.values(multiItems));
            if (is_array(multiItems)) setMultiDisplayList(multiItems);
            if (is_string(multiItems) || is_number(multiItems)) {
                localMessage(`Paramater 'inputOptions.multiItems' must be an array or enumerable object. ${getType(multiItems)} supplied.`, "log", "warning");
            }
        }

        if (mounted && isset(removeSelected)) {
            setRemoveItems(removeSelected);
        }

        if (mounted && isset(resetAble)) {
            setResetButton(resetAble);
        }
        if (mounted && isset(mouseOver)) {
            setUseMouseOver(mouseOver);
        }
        if (mounted && isset(mouseOut)) {
            setUseMouseOut(mouseOut);
        }
        if (mounted && isset(mouseClick)) {
            setUseMouseClick(mouseClick);
        }
        if (mounted && isset(toolTips)) {
            setUseHover(true);
        }

        if (mounted && isset(toolTipSpeed)) {
            let toolTipSpeedClass = "tooltip-normal";
            switch (toolTipSpeed) {
                case "very-fast":
                    toolTipSpeedClass = "tooltip-very-fast"
                    break;
                case "fast":
                    toolTipSpeedClass = "tooltip-fast";
                    break;
                case "slow":
                    toolTipSpeedClass = "tooltip-slow";
                    break;
                case "instant":
                    toolTipSpeedClass = "tooltip-instant";
                    break;
                case "normal":
                    toolTipSpeedClass = "tooltip-normal";
                    break;


                default:
                    toolTipSpeedClass = "tooltip-normal";
                    break;
            }
            setHoverSpeed(toolTipSpeedClass);
        }

        return () => {
            mounted = false;
        }
    }, [textWrap, listMaxWidth, listMaxHeight, multiItems, textFontSize, removeSelected, resetAble, mouseOver, mouseOut, mouseClick, toolTips, toolTipSpeed])

    //Set preselected values
    useEffect(() => {
        let mounted = true;
        if (mounted && isset(inputValue)) {
            if (is_string(inputValue)) {
                // eslint-disable-next-line
                inputValue = [inputValue];
            }
            if (is_object(inputValue)) {
                inputValue = Object.values(inputValue);
            }
            if (is_array(inputValue)) {
                setSelectedMultiItems(inputValue);
                inputFunction({ selected: inputValue });
            }
        }
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line
    }, []);

    //Handle selected items callback
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            inputFunction({ selected: selectedMultiItems });
        }
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line
    }, [selectedMultiItems]);

    //handle mouse event callback requests
    useEffect(() => {
        let mounted = true;
        if (mounted && isset(mouseEvent)) {
            if (useMouseOut && mouseEvent.hasOwnProperty("mouseout")) {
                inputFunction(mouseEvent);
            }
            if (useMouseOver && mouseEvent.hasOwnProperty("mouseover")) {
                setToolTips(mounted, mouseEvent);
                inputFunction(mouseEvent);
            }
            if (useMouseClick && mouseEvent.hasOwnProperty("click")) {
                inputFunction(mouseEvent);
            }
        }
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line
    }, [mouseEvent]);

    useEffect(() => {
        let mounted = true;
        if (mounted && isset(resetList)) {
            if (removedItems.length) arrayEmpty(removedItems);
            setRemovedItems(arrayCopy(removedItems));
            if (selectedMultiItems.length) arrayEmpty(selectedMultiItems);
            setSelectedMultiItems((arrayCopy(selectedMultiItems)));
        }
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line
    }, [resetList])


    const setToolTips = (mounted, mouseEvent) => {
        if (isset(toolTips) && exists(mouseEvent, "mouseover") && exists(mouseEvent.mouseover, "value")) {
            if (is_object(toolTips)) {
                //       console.log(toolTips);
                const thisKey = objectTextToKey(mouseEvent.mouseover.value);
                if (isset(thisKey) && toolTips.hasOwnProperty(thisKey)) {
                    if (mounted) setHoverContent(toolTips[thisKey].trim());
                }
            }
            if (is_string(toolTips)) {
                if (mounted) setHoverContent(toolTips.trim());
            }
            if (is_array(toolTips)) {
                localMessage(`Component MultiSelectList:${inputName} 'inputOptions.toolTips' props can not be an array. Supply a string or Object only.`)
            }

        }
    }

    const handleMouseEvent = (e) => {
        const type = e.type;
        const itemId = e.id;
        const itemName = e.textContent;
        setMouseEvent({
            [type]: { id: itemId, value: itemName, event: e }
        });
    }

    const handleItemSelect = (e) => {
        const item = e.textContent;
        handleMouseEvent(e);
        const newitemArray = arrayCopy(selectedMultiItems);
        if (!isIndexOf(selectedMultiItems, item)) {
            newitemArray.push(item);
            setSelectedMultiItems(newitemArray);
            removedItems.push(item);
            if (removeItems) setRemovedItems(arrayCopy(removedItems));
        } else {
            arrayEmpty(newitemArray);
            const newArray = arrayRemoveString(selectedMultiItems, item, false);
            newitemArray.push(...newArray);
            setSelectedMultiItems(newitemArray);
        }
    }

    return (
        <div className={`MultiSelectList ${className}`}
            id={`${pageName}_${pageId}`}
            type={inputType}
            name={inputName}
            ref={MainElement}
            style={{
                maxWidth: maxWidth,
                maxHeight: maxHeight,
                overflowY: "auto"
            }}>
            {multiDisplayList.map((item, index) => {
                return removeItems && isIndexOf(selectedMultiItems, item) ? <div key={index}></div> :

                    <div
                        className={`MultiSelectList-Item ${isIndexOf(selectedMultiItems, item) ? "item-active" : ""}`} key={index} id={`MultiSelectList-Item-${index}`} value={item}
                        onClick={(e) => handleItemSelect({ type: "click", id: `MultiSelectList-Item-${index}`, textContent: item, event: e })}
                        onMouseOver={(e) => handleMouseEvent({ type: "mouseover", id: `MultiSelectList-Item-${index}`, textContent: item, event: e })}
                        onMouseOut={(e) => handleMouseEvent({ type: "mouseout", id: `MultiSelectList-Item-${index}`, textContent: item, event: e })}

                    >
                        <div style={{ fontSize: fontSize }}>{item}</div>
                        <div className={`${useHover ? `tooltip ${hoverSpeed}` : "hide"}`} >{hoverContent}</div>
                    </div>;
            })}
            {removedItems.length > 0 && resetButton ? <Button className="m-1" variant="warning"
                onClick={() => {
                    arrayEmpty(removedItems);
                    setRemovedItems(arrayCopy(removedItems));
                    arrayEmpty(selectedMultiItems);
                    setSelectedMultiItems((arrayCopy(selectedMultiItems)));
                    inputFunction({ selected: (arrayCopy(selectedMultiItems)) })
                }}
                size="sm">Reset List</Button>
                :
                <div></div>}
        </div>
    )
}

export default MultiSelectList
