import React, { createContext, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import APIReturn from '../../classes/APIReturns/APIReturn';
import ProfileObject from '../../classes/APIReturns/ProfileObject';
import SessionObject from '../../classes/APIReturns/SessionObject';
import UserObject from '../../classes/APIReturns/UserObject';
import EndpointToken from '../../classes/EndpointToken';
import { callAxios } from '../../functions/APIHelpers';
import { getEmptyProfile, getEmptySession, getEmptyUser, localMessage, propExists, Session } from '../../functions/library';
import { GET_PROFILE_ENDPOINT, LOGIN_ENDPOINT, LOGOUT_ENDPOINT, REFRESH_SESSION_ENDPOINT } from '../../functions/siteDefaults';
import { useSessionStorage } from '../../hooks/useStorage';


export const authContext = createContext();

//Here we setup our actually login and out callbacks
export const siteAuth = {
    isAuthenticated: false,
    ...getEmptyUser(),
    user: getEmptyUser(),
    session: getEmptySession(),
    profile: getEmptyProfile(),


    async signin(cb, credentials) {
        const Login = new EndpointToken(LOGIN_ENDPOINT, credentials);
        const apiResponse = await callAxios(Login.method, Login.url, Login.headers, Login.body);
        const APIreturn = new APIReturn(apiResponse);
        const User = new UserObject(apiResponse);
        const Session = new SessionObject(apiResponse);

        // Set siteAuth with new values 
        siteAuth.isAuthenticated = User.isAuth;
        siteAuth.user = User;
        siteAuth.session = Session;
        //Load profile if available
        let Profile = new ProfileObject(getEmptyProfile());
        if (User.profileID) {
            credentials = { profileID: User.profileID };
            const GetProfile = new EndpointToken(GET_PROFILE_ENDPOINT, credentials);
            GetProfile.requiresAuth = true;
            GetProfile.Authorization = Session.accessToken;
            const apiProfileResponse = await callAxios(GetProfile.method, GetProfile.url, GetProfile.headers, GetProfile.body);
            Profile = new ProfileObject(apiProfileResponse);
            //   const siteImage = new SiteImage("profilePhoto", apiProfileResponse);
            //  console.log("siteImage", siteImage);
            //  console.log("width", siteImage.Image.width);

        }
        siteAuth.profile = Profile;
        cb();
        return APIreturn;
    },

    async refresh(cb, currentUser, currentSession) {
        const credentials = { ...currentUser, ...currentSession };
        const Refresh = new EndpointToken(REFRESH_SESSION_ENDPOINT, credentials);
        const apiResponse = await callAxios(Refresh.method, Refresh.url, Refresh.headers, Refresh.body);
        const APIreturn = new APIReturn(apiResponse);
        const User = new UserObject(apiResponse);
        const Session = new SessionObject(apiResponse);

        // Set siteAuth with new values 
        siteAuth.isAuthenticated = User.isAuth;
        siteAuth.user = User;
        siteAuth.session = Session;
        //Load profile if available
        let Profile = new ProfileObject(getEmptyProfile());
        if (User.profileID) {
            const credentials = { profileID: User.profileID };
            const GetProfile = new EndpointToken(GET_PROFILE_ENDPOINT, credentials);
            GetProfile.requiresAuth = true;
            GetProfile.Authorization = Session.accessToken;
            const apiProfileResponse = await callAxios(GetProfile.method, GetProfile.url, GetProfile.headers, GetProfile.body);
            Profile = new ProfileObject(apiProfileResponse);
        }
        siteAuth.profile = Profile;
        cb();
        return APIreturn;
    },

    async signout(cb, currentSession) {
        const Logout = new EndpointToken(LOGOUT_ENDPOINT, currentSession);
        const apiResponse = await callAxios(Logout.method, Logout.url, Logout.headers, Logout.body);
        const APIreturn = new APIReturn(apiResponse);
        const User = new UserObject(apiResponse);
        const Session = new SessionObject(apiResponse);

        // Set siteAuth with new values 
        siteAuth.isAuthenticated = false;
        siteAuth.user = User;
        siteAuth.session = Session;
        siteAuth.profile = new ProfileObject(getEmptyProfile());
        cb();
        return APIreturn;
    }
};

export default function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            <div className="main-wrapper-div">
                {children}
            </div>
        </authContext.Provider>
    );
}

/**
 * The Auth object, containg Auth properties and methods
 * @returns {{user:UserObject, session:SessionObject,profile:ProfileObject,signin:function,refreshSession:function,signout:function, replaceUser:function, replaceProfile:function, replaceSession:function}}
 */
export function useAuth() {
    return useContext(authContext);
}


export function useProvideAuth() {
    const [user, setUser] = useSessionStorage("user", getEmptyUser());
    const [profile, setProfile] = useSessionStorage("profile", getEmptyProfile());
    const [session, setSession, removeSession] = useSessionStorage("session", getEmptySession());


    //Handle session state on page reload
    useEffect(() => {
        let mounted = true;
        const thisSession = new Session(user, session);

        if (mounted && thisSession.isset) {
            if (thisSession.canRefresh && thisSession.needsRefresh) {
                localMessage("Session expired, refreshing...", "log");
                refreshSession(() => { }, user, session);
            }
            if (!thisSession.canRefresh || !thisSession.isValid) {
                localMessage("Session expired, please log back in.", "log", "warning");
                removeSession();
                setUser(getEmptyUser());
            }
            if (thisSession.canRefresh && !thisSession.needsRefresh) {
                // This session is valid. Perhaps do stuff here!
            }
        }
        if (!thisSession.isset && thisSession.isAuth) {
            localMessage("Session corrupted, please log back in.", "log", "warning");
            //  removeSession();
            //  setUser(getEmptyUser());
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [user, session]);

    const refreshSession = async (cb, user, session) => {
        return await siteAuth.refresh(() => {
            if (siteAuth.isAuthenticated === true) {
                setSession(siteAuth.session);
                setUser(siteAuth.user);
                setProfile(siteAuth.profile);
                cb();
            } else {
                setUser(getEmptyUser());
                removeSession();
                cb();
            }
        }, user, session)
    }

    const signin = async (cb, credentials) => {
        return await siteAuth.signin(() => {
            if (siteAuth.isAuthenticated === true) {
                setSession(siteAuth.session);
                setUser(siteAuth.user);
                setProfile(siteAuth.profile);
                cb();
            } else {
                setUser(getEmptyUser());
                removeSession();
                cb();
            }
        }, credentials, user, session);
    };

    const signout = async (cb, currentSession) => {
        return await siteAuth.signout(() => {
            setUser(getEmptyUser());
            setProfile(getEmptyProfile());
            removeSession();
            cb();
        }, currentSession);
    };

    const replaceUser = (newUser = UserObject) => {
        if (propExists(newUser, "type") !== "UserObject") {
            newUser = new UserObject(newUser);
        }
        if (newUser.userID) setUser(newUser);
    }

    /**
     * Replaces the current auth profile object.
     * @param {ProfileObject} newProfile A new Profile Object or fully formed API response.
     */
    const replaceProfile = (newProfile = ProfileObject) => {
        if (propExists(newProfile, "type") !== "ProfileObject") {
            newProfile = new ProfileObject(newProfile);
        }
        if (newProfile.profileID) setProfile(newProfile);
    }

    const replaceSession = (newSession = SessionObject) => {
        if (propExists(newSession, "type") !== "SessionObject") {
            newSession = new SessionObject(newSession);
        }
        if (newSession.sessionID) setSession(newSession);
    }

    return {
        user,
        session,
        profile,
        signin,
        refreshSession,
        signout,
        replaceUser,
        replaceProfile,
        replaceSession
    };
}

export function AuthButton() {
    let location = useLocation();
    let history = useHistory();
    let { from } = location.state || { from: { pathname: "/" } };

    let auth = useAuth();
    let login = () => {

        auth.signin(() => {
            history.replace(from);
        });
    };

    return auth.user ? (
        <p>
            Welcome!{" "}
            <button
                onClick={() => {
                    auth.signout(() => history.push("/"));
                }}
            >
                Sign out
            </button>
        </p>
    ) : (
        <button onClick={login}>Log in</button>
    );
}


