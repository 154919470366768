import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap';

import AWSLogo from "../../../images/aws-logo.png";
import OracleLogo from "../../../images/oracle-logo.png";
import CitrixLogo from "../../../images/citrix-logo.png";
import VMWareLogo from "../../../images/vmware-logo.png";
import VirtualReality from "../../../images/virtual-reality.jpg";
import VirtualMeeting from "../../../images/virtual-meeting.jpg";
import DropBoxLogo from "../../../images/dropbox-logo.png";
import ZenDeskLogo from "../../../images/zendesk-logo.png";
import OfficeLobo from "../../../images/office-logo.png";
import BufferLogo from "../../../images/buffer-logo.png";

export default function Virtual() {
    return (
        <Container className='mt-5'>
            <Row className='event-tech-info mt-5 mb-5 pb-3 p-lg-2'>
                <Col md={6} className="no-mobile">
                    <Row >
                        <Col xs={12} lg={6}>
                            <Row>
                                <Col>
                                    <Image className="pt-lg-4" src={AWSLogo} fluid />
                                </Col>
                                <Col>
                                    <Image className="pt-lg-2" src={OracleLogo} fluid />
                                </Col>
                                <Col>
                                    <Image className="pt-lg-4" src={CitrixLogo} fluid />
                                </Col>
                                <Col>
                                    <Image className="pt-lg-2" src={VMWareLogo} fluid />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Image className="pt-lg-4" src={VirtualMeeting} fluid />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Image className="pt-lg-3" src={VirtualReality} fluid />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Image className="pt-lg-4" src={DropBoxLogo} fluid />
                        </Col>
                        <Col>
                            <Image className="pt-lg-4" src={OfficeLobo} fluid />
                        </Col>
                        <Col>
                            <Image className="pt-lg-4" src={ZenDeskLogo} fluid />
                        </Col>
                        <Col>
                            <Image className="pt-lg-4" src={BufferLogo} fluid />
                        </Col>
                    </Row>
                </Col>

                <Col md={6}>
                    <div className='d-flex align-items-baseline'>
                        <i className="fas fa-vr-cardboard fa-3x text-primary pe-3 ms-0 no-hover" />
                        <h3 className='text-black'>Virtual Platforms</h3>
                    </div>
                    <h4 className='text-secondary pt-2'>VR, Virtual Meetings, & Virtualization</h4>
                    <p className='text-dark pt-1 mt-3'>
                        In today's business environment, virtual technology is rising to the forefront. <a href="/Contact?select=Consulting">Let us advise you </a> on what virtual solution meets your needs.</p>
                    <p className='text-dark mb-1'> Virtual platforms we can advise you on: </p>
                    <div className=''>
                        <ul className=''>

                            <li>Cloud Computing</li>
                            <li>Cloud Storage</li>
                            <li>Database as a Service (DBaaS)</li>
                            <li>Software as a Service (SaaS)</li>
                            <li>Virtual Meetings</li>
                            <li>Virtual Reality & Implementations</li>
                            <li>Virtualization</li>
                        </ul>
                    </div>

                </Col>
                <Col md={6} className="mobile-only">
                    <Row >
                        <Col xs={12}>
                            <Row>
                                <Col>
                                    <Image className="pt-lg-4" src={AWSLogo} fluid />
                                </Col>
                                <Col>
                                    <Image className="pt-lg-2" src={OracleLogo} fluid />
                                </Col>
                                <Col>
                                    <Image className="pt-lg-4" src={CitrixLogo} fluid />
                                </Col>
                                <Col>
                                    <Image className="pt-lg-2" src={VMWareLogo} fluid />
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={6}>
                            <Image className="pt-4" src={VirtualMeeting} fluid />
                        </Col>
                        <Col xs={6}>
                            <Image className="" src={VirtualReality} fluid />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Image className="pt-3" src={DropBoxLogo} fluid />
                        </Col>
                        <Col>
                            <Image className="pt-3" src={OfficeLobo} fluid />
                        </Col>
                        <Col>
                            <Image className="pt-3" src={ZenDeskLogo} fluid />
                        </Col>
                        <Col>
                            <Image className="pt-3" src={BufferLogo} fluid />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container >
    )
}
