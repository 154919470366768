import APIReturn from "./APIReturn";

/**
 * Creates a new UserObject from an API return containing a user record.  
 */
export default class UserObject extends APIReturn {
    type = "UserObject";
    isAuth = false;
    userID = 0;
    sessionID = 0;
    profileID = 0;
    userTypeID = 0;
    userTypeIDApproval = false;
    userName = "";

    /**
     * 
     * @param {{statusCode: number,success: boolean,messages:{request:{},form:{},fields: {}},data: {userID: number,profileID: number,userName: string,userTypeID: number,userTypeIDApproval: number,sessionID: number,accessToken: string, accessTokenExpiry: string,refreshTokenExpiry: string}}}apiResponse A formed response from the API containing a complete User Record
     * @returns {{isAuth:boolean,userID: number,sessionID: number,profileID: number, userTypeID: number, userTypeIDApproval: boolean,userName: string}} UserObject: { isAuth : boolean, userID : number, sessionID : number, profileID : number, userTypeID : number, userTypeIDApproval : boolean, userName : string }
     */
    constructor(apiResponse) {
        super(apiResponse);
        if (!this.isset) {
            delete this.data;
            delete this.messages;
            return;
        }
        if (
            !this.data
            || (!this.data.hasOwnProperty("userID")
                && !this.data.hasOwnProperty("sessionID")
                && !this.data.hasOwnProperty("userTypeID")
                && !this.data.hasOwnProperty("userTypeIDApproval")
                && !this.data.hasOwnProperty("userName")
                && !this.data.hasOwnProperty("profileID"))
        ) {
            delete this.data;
            delete this.messages;
            return;
        }
        this.isAuth = this.success;
        this.userID = this.data.userID;
        this.sessionID = this.data.sessionID;
        this.userTypeID = this.data.userTypeID;
        this.userTypeIDApproval = this.data.userTypeIDApproval;
        this.userName = this.data.userName;
        this.profileID = this.data.profileID;
        delete this.data;
        delete this.messages;
    }
}
