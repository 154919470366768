import React, { useState } from 'react'
import { Button, Card, Col, Container, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'

import ConsultingMainImage from "../../../images/consulting-main.jpg";
import ConsultingMainImageHover from "../../../images/consulting-main-hover.jpg";
import ExperienceImage2 from "../../../images/consulting-tri-top-2.jpg";
import ExperienceImage3 from "../../../images/consulting-tri-bottom.jpg";
import ModalSimple from '../components/parts/modals/ModalSimple';
import { MyDate } from '../../../functions/library';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function ConsultingMain() {
    const year = new MyDate().year;
    const history = useHistory();
    const [showPassiveModal, setShowPassiveModal] = useState(false);
    const [showActiveModal, setShowActiveModal] = useState(false);
    const [showRFPModal, setShowRFPModal] = useState(false);
    const [showSourcingModal, setShowSourcingModal] = useState(false);

    const [mainImageDisplay, setMainImageDisplay] = useState("block");
    const [mainImageDisplayHover, setMainImageDisplayHover] = useState("none");

    const handleConsultMainHover = (e) => {
        if (e.type === "mouseover") {
            setMainImageDisplay("none");
            setMainImageDisplayHover("block");
        }
        if (e.type === "mouseout") {
            setMainImageDisplay("block");
            setMainImageDisplayHover("none");
        }

    }

    return (
        <Container className='mt-5'>
            <Row className='event-tech-info mt-5 mb-5 pb-3 p-lg-2'>
                <Col md={6} className="no-mobile">
                    <Row className='pb-2'>
                        <Col className='px-2'>
                            <div className='consulting-main-container w-100'>
                                <Image style={{ display: mainImageDisplay }} className="consulting-main-image" src={ConsultingMainImage} alt="Map &amp; Compass" fluid />
                                <Image style={{ display: mainImageDisplayHover }} className="consulting-main-image" src={ConsultingMainImageHover} alt="Map &amp; Compass" fluid />
                                <div className='consulting-main-overlay' onClick={() => history.push("/Contact?select=Consulting")} onMouseOver={handleConsultMainHover} onMouseOut={handleConsultMainHover}></div>
                            </div>

                        </Col>
                    </Row>
                </Col>

                <Col md={6}>
                    <div className='d-flex align-items-baseline'>
                        <i className="fas fa-atlas fa-3x text-primary pe-3 ms-0 no-hover" />
                        <h3 className='text-black'>Consulting</h3>
                    </div>
                    <h4 className='text-secondary pt-2'>Let us help you navigate technology in {year} and the years to come.</h4>
                    <p className='text-dark pt-1 pe-4  mt-1'>TrinityRecode is here to help. We can guide you through the myriad solutions available to not only achieve your goals but also streamline your current processes.</p>
                    <Button variant="warning" onClick={() => history.push("/Contact?select=Consulting")} >Book Free Consultation</Button>

                </Col>
                <Col md={6} className="mobile-only">
                    <Row className='pb-2'>
                        <Col className='px-2'>
                            <div className='consulting-main-container w-100'>
                                <Image style={{ display: mainImageDisplay }} className="consulting-main-image" src={ConsultingMainImage} alt="Map &amp; Compass" fluid />
                                <Image style={{ display: mainImageDisplayHover }} className="consulting-main-image" src={ConsultingMainImageHover} alt="Map &amp; Compass" fluid />
                                <div className='consulting-main-overlay' onClick={() => history.push("/Contact?select=Consulting")} onMouseOver={handleConsultMainHover} onMouseOut={handleConsultMainHover}></div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>


        </Container>
    )
}

export default ConsultingMain
