import React, { useState, useEffect } from 'react';
import { InputGroup, Form, FormControl, } from 'react-bootstrap';
import ServerFeedback from '../../../../../functions/ServerFeedback';
import { boolToText, boolval, intval, isset, is_boolean, is_string, objectFlipKeyValue, textToBool } from '../../../../../functions/library';

/**
 * 
 * @param {String} className The class or classes to be applied to the primary div
 * @param {Object} inputOptions Object containing: useLabel, inputText1, inputText2, inputLabel, text1Wrap, text1MaxWidth, text1FontSize
 * @param {string} inputType The switch label name for the modal or group the input belongs to
 * @param {string} inputName The name of the input to applied to the name attribute
 * @param {string} inputValue The input's value - this is usually the state
 * @param {function} inputFunction The callback function for the onChange attribute - this is usually setState
 * @param {string} showTextBox Optionally show a texual representation of the checkbox value
 * @param {Object} messages The messages Object to be used in ServerFeedback
 * @returns JSX Input Control group 
 */
const CheckBoxInput = ({ className, inputOptions, inputType, inputName, feedbackDescription, validationType, inputValue, inputFunction, messages, }) => {
    const { useLabel, inputText1, inputText2, inputLabel, text1Wrap, text1MaxWidth, text1FontSize, text1LineHeight, trueFalseSubstitute } = inputOptions;
    const textSuccess = "green";
    const textFailed = "red";
    const shadowColor = "black";
    const [whiteSpace1, setWhiteSpace1] = useState("nowrap");
    const [maxWidth1, setMaxWidth1] = useState("100vw");
    const [fontSize1, setFontSize1] = useState("100%");
    const [lineHeight1, setLineHeight1] = useState("150%");
    const [checkBoxState, setCheckBoxState] = useState(false);
    const [checkBoxText, setCheckBoxText] = useState("");
    const [useCustom, setUseCustom] = useState(false);


    useEffect(() => {
        let mounted = true;
        if (mounted && isset(text1Wrap) && text1Wrap !== false) {
            setWhiteSpace1("normal");
        }
        if (mounted && isset(text1MaxWidth)) {
            setMaxWidth1(text1MaxWidth);
        }

        if (mounted && isset(text1FontSize)) {
            setFontSize1(text1FontSize);
        }

        if (mounted && isset(text1LineHeight)) {
            setLineHeight1(text1LineHeight);
        }

        if (mounted && isset(trueFalseSubstitute)) {
            if (is_boolean(inputValue) && trueFalseSubstitute.hasOwnProperty("true") && trueFalseSubstitute.hasOwnProperty("false")) {
                setCheckBoxText(boolToText(inputValue, "CUSTOM", trueFalseSubstitute));
                setCheckBoxState(inputValue);
                setUseCustom(true);
            }
            if (is_string(inputValue)) {
                const flipped = objectFlipKeyValue(trueFalseSubstitute);
                if (flipped.hasOwnProperty(inputValue)) {
                    setCheckBoxState(textToBool(inputValue, "CUSTOM", trueFalseSubstitute));
                    setCheckBoxText(inputValue);
                    setUseCustom(true);
                }
            }

            if (!isset(inputValue) && trueFalseSubstitute.hasOwnProperty("true") && trueFalseSubstitute.hasOwnProperty("false")) {
                const value = boolval(inputValue);
                setCheckBoxText(boolToText(value, "CUSTOM", trueFalseSubstitute));
                setCheckBoxState(value);
                setUseCustom(true);
            }
        }

        return () => {
            mounted = false;
        }
        // eslint-disable-next-line
    }, [text1Wrap, text1MaxWidth, text1FontSize, text1LineHeight, trueFalseSubstitute])

    const handleCheckBox = (data) => {
        const custom = useCustom ? "CUSTOM" : "INTEGERS";
        const newStatus = boolToText(data.value, custom, trueFalseSubstitute);
        setCheckBoxText(newStatus);
        setCheckBoxState(data.value);
        inputFunction({
            name: data.name,
            value: intval(data.value),
            valueText: newStatus,
            description: feedbackDescription,
            validationType: validationType || "CHECKBOX"
        });
    }


    return (
        <div className={className}>
            <InputGroup> {
                !useLabel ? <div>
                    {
                        inputText2 ? <InputGroup.Text>{inputText2}</InputGroup.Text> : <div></div>
                    }
                    <InputGroup.Text style={{ fontWeight: "bold", whiteSpace: whiteSpace1, maxWidth: maxWidth1, fontSize: fontSize1, lineHeight: lineHeight1 }}>{inputText1}</InputGroup.Text> </div> :
                    <Form.Label className="w-100 mb-0 pb-1">{inputLabel}</Form.Label>
            }

                <InputGroup.Checkbox aria-label="Checkbox for following text input" name={inputName} onChange={e => handleCheckBox({ e: e, name: e.target.name, value: e.target.checked })} checked={checkBoxState} />

                <FormControl className="bg-white" aria-label="Text input with checkbox" value={checkBoxText} readOnly />


            </InputGroup>
            <ServerFeedback
                messages={messages}
                type="fields"
                fieldName={inputName}
                success={messages.success}
                loading={false}
                formName={inputType}
                active={inputType}
                shadow={shadowColor}
                textSuccess={textSuccess}
                textFailed={textFailed}
                ignoreText=""
            />

        </div>
    )
}

export default CheckBoxInput
