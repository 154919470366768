import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

import NetworkingImage1 from "../../../images/networking-tri-1.jpg";
import NetworkingImage2 from "../../../images/networking-tri-2.jpg";
import NetworkingImage3 from "../../../images/networking-tri-3.jpg";

function Networking() {
    return (
        <Container className='mt-5'>
            <Row className='event-tech-info mt-5 mb-5 pb-3 p-lg-2'>
                <Col md={6} className="no-mobile">
                    <Row >
                        <Col xs={8}>
                            <Image className="pt-lg-2" src={NetworkingImage1} fluid />
                        </Col>
                        <Col xs={4}>
                            <Row className='networking-images-column-2 py-2'>
                                <Col>
                                    <Image src={NetworkingImage3} fluid />
                                </Col>
                            </Row>
                            <Row className='networking-images-column-2 py-2'>
                                <Col>
                                    <Image src={NetworkingImage2} fluid />
                                </Col>
                            </Row>


                        </Col>
                    </Row>
                </Col>

                <Col md={6}>
                    <div className='d-flex align-items-baseline'>
                        <i className="fas fa-wifi fa-3x text-primary pe-3 ms-0 no-hover" />
                        <h3 className='text-black'>Networking</h3>
                    </div>
                    <h4 className='text-secondary pt-2'>Home office, small, or medium size business solutions.</h4>
                    <p className='text-dark pt-1 mt-3'>
                        We hear you. There's tons of options for securely networking your business. <a href="/Contact?select=Consulting">Reach out</a> to TrinityRecode for expert advice and solutions.</p>
                    <p className='text-dark mb-1'> Here's a few of the most common: </p>
                    <div className=''>
                        <ul className=''>
                            <li>What network security solutions should I be considering?</li>
                            <li>Are there secure WiFi solutions for my office within my budget?</li>
                            <li>Should I consider Network Attached Storage?</li>
                            <li>How do I ensure my network can scale up seemlessly as my business grows?</li>
                        </ul>
                    </div>

                </Col>
                <Col md={6} className="mobile-only">
                    <Row>
                        <Col xs={7}>
                            <Image src={NetworkingImage1} fluid />
                        </Col>
                        <Col xs={5}>
                            <Row className='networking-images-column-2 pb-2'>
                                <Col>
                                    <Image src={NetworkingImage3} fluid />
                                </Col>
                            </Row>
                            <Row className='networking-images-column-2'>
                                <Col>
                                    <Image src={NetworkingImage2} fluid />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container >
    )
}

export default Networking
