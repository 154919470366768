export const DEBUG = false;
export const THROW_ERROR_LOCATION = "log";
export const VERSION_NUMBER = "0.1.0 alpha";
export const IS_DEVELOPMENT_VERSION = false; //Change to false for Production

//####* SESSION & AUTH *########* SESSION & AUTH *########* SESSION & AUTH *########* SESSION & AUTH *#########

export const ACCESSTOKEN_LENGTH = 256;
export const ACCESSTOKEN_REFRESH_FREQUENCY = 1000 * 60; //One Minute
export const REFRESHTOKEN_NAME = "refreshToken";



//####* API SETTINGS *########* API SETTINGS *########* API SETTINGS *########* API SETTINGS *#################

export const API_BASE_URL = "https://trinityrecode.com/api/root/";
//export const API_BASE_URL = "http://192.168.1.165:80/";
export const DEV_IP_HEADER = ["Client-Ip", "129.21.0.3"]; // Used for development IP address spoof




//####* ENDPOINT SETUP *########* ENDPOINT SETUP *########* ENDPOINT SETUP *########* ENDPOINT SETUP *#########

export const DEFAULT_SERVER_RESPONSE_REQUEST = "Default Message Used, server response not understood.";
export const DEFAULT_SERVER_RESPONSE_FORM = "An unknown error occured, please try again.";
export const DEFAULT_SERVER_RESPONSE_CODE = 503;

export const CONTENT_TYPE_KEYWORD = "Content-Type";
export const GET_METHOD = "get";
export const POST_METHOD = "post";
export const PUT_METHOD = "put";
export const DELETE_METHOD = "delete";
export const URL_LOCATION = "url";
export const BODY_LOCATION = "body";
export const AUTH_TOKEN_LOCATION = "Authorization";




/**
 * Registers a new user in the database
 * @argument {string} contactFullName - *BODY_LOCATION* - Senders full name
 * @argument {string} contactEmail - *BODY_LOCATION* - Senders email address
 * @argument {string} contactMobile - *BODY_LOCATION* - Senders mobile phone number
 * @argument {string} contactSubject - *BODY_LOCATION* - Senders subject
 * @argument {string} contactMessage - *BODY_LOCATION* - Senders message 
 * @returns {string} data:{userID, userName, userTypeID}, statusCode: 200, success, messages:{request,form,fields}
 */
export const CONTACT_ENDPOINT = {
    name: "contact",
    method: POST_METHOD,
    contentType: "application/json",
    location: "contact.php",
    args: {
        [BODY_LOCATION]: {
            contactFullName: "",
            contactEmail: "",
            contactMobile: "",
            contactSubject: "",
            contactMessage: "",
        }
    }
};


/**
 * Registers a new user in the database
 * @argument {string} userName - *BODY_LOCATION* - the email address of the user
 * @argument {string} password - *BODY_LOCATION* 
 * @argument {string} repeatPassword - *BODY_LOCATION*
 * @argument {bool} termsAccept - *BODY_LOCATION* - the checkbox acceptance during registration
 * @returns {string} data:{userID, userName, userTypeID}, statusCode: 200, success, messages:{request,form,fields}
 */
export const REGISTER_ENDPOINT = {
    name: "register",
    method: POST_METHOD,
    contentType: "application/json",
    location: "users.php",
    args: {
        [BODY_LOCATION]: {
            userName: "",
            password: "",
            repeatPassword: "",
            termsAccept: false
        }
    }
};

/**
 * Creates a new user session in the database
 * @argument {string} userName - *BODY_LOCATION* - the email address of the user
 * @argument {string} password - *BODY_LOCATION* 
 * @returns {string} data:{userID, userName, userTypeID, userTypeApprovalID, sessionID, accessToken, accessTokenExpiry, refreshTokenExpiry}, statusCode: 201, success, messages:{request,form,fields} **NOTE: also will set an HTTPONLY cookie as a refresh token. stored under REFRESHTOKEN_NAME
 */
export const LOGIN_ENDPOINT = {
    name: "login",
    method: POST_METHOD,
    location: "users/sessions.php",
    contentType: "application/json",
    args: {
        [BODY_LOCATION]: {
            userName: "",
            password: "",
        }
    }
};

/**
 * Refreshes a user session, returning a valid token
 * @argument {number} userID - *URL_LOCATION* 
 * @argument {number} sessionID - *URL_LOCATION* 
 * @argument {string} Authorization - *AUTH_TOKEN_LOCATION* - A bearer token string
 * @returns {string} data:{userID, userName, userTypeID, userTypeApprovalID, sessionID, accessToken, accessTokenExpiry, refreshTokenExpiry}, statusCode: 200, success, messages:{request,form,fields} **NOTE: also will set an HTTPONLY cookie as a refresh token. stored under REFRESHTOKEN_NAME
 */
export const REFRESH_SESSION_ENDPOINT = {
    name: "refresh_session",
    method: PUT_METHOD,
    location: "users/sessions.php",
    contentType: "application/json",
    args: {
        [URL_LOCATION]: {
            userID: 0,
            sessionID: 0,
        },
        [AUTH_TOKEN_LOCATION]: {
            accessToken: "",
        }
    }
};

/**
 * Registers a new user in the database
 * @argument {number} sessionID - *URL_LOCATION* 
 * @argument {string} Authorization - *AUTH_TOKEN_LOCATION* - A bearer token string
 * @returns {string} data: null, statusCode: 200, success, messages:{request,form,fields}
 */
export const LOGOUT_ENDPOINT = {
    name: "logout",
    method: DELETE_METHOD,
    location: "users/sessions.php",
    contentType: "application/json",
    args: {
        [URL_LOCATION]: {
            sessionID: 0,
        },
        [AUTH_TOKEN_LOCATION]: {
            accessToken: "",
        }
    }
};


/**
 * Returns a profile, or a specific profile field, from the database
 * @argument {number} profileID - *URL_LOCATION* 
 * @argument {string} targetField - *Optional* Request a specific field rather than the whole profile. Must have credentials to return the requested field.
 * @argument {string} Authorization - *AUTH_TOKEN_LOCATION* - A bearer token string
 * @returns {string} data: profile record or a select field, statusCode: 200, success, messages:{request,form,fields}
 */
export const GET_PROFILE_ENDPOINT = {
    name: "get_profile",
    method: GET_METHOD,
    location: "users/profile.php",
    contentType: "application/json",
    args: {
        [URL_LOCATION]: {
            profileID: 0,
            targetField: ""
        },
        [AUTH_TOKEN_LOCATION]: {
            accessToken: "",
        }
    }
};



/**
 * Updates a profile, or a specific profile field, from the database
 * @argument {number} userID - *BODY_LOCATION*  -REQUIRED-
 * @argument {number} profileID - *BODY_LOCATION*  -REQUIRED-
 * @argument {string} Authorization - *AUTH_TOKEN_LOCATION* - A bearer token string
 * @returns {string} data: null, statusCode: 200, success, messages:{request,form,fields}
 */
export const UPDATE_PROFILE_ENDPOINT = {
    name: "update_profile",
    method: PUT_METHOD,
    location: "users/profile.php",
    contentType: "application/json",
    args: {
        [BODY_LOCATION]: {
            userID: 0,
            profileID: 0,
            userFirstName: "",
            userLastName: "",
            userStreet1: "",
            userStreet2: "",
            userCity: "",
            userState: "",
            userZip: "",
            userMobileNumber: "",
            userHomeNumber: "",
            profilePhoto: "",
            userProfilePhotoFileName: "",
            profilePhotoMime: "",
            userDisplayName: "",
        },
        [AUTH_TOKEN_LOCATION]: {
            accessToken: "",
        }
    }
};


/**
 * Updates a profile, or a specific profile field, from the database
 * @argument {number} userID - *BODY_LOCATION*  -REQUIRED-
 * @argument {string} Authorization - *AUTH_TOKEN_LOCATION* - A bearer token string
 * @returns {string} data: Profile record complete with new Profile ID, statusCode: 200, success, messages:{request,form,fields}
 */
export const CREATE_PROFILE_ENDPOINT = {
    name: "create_profile",
    method: POST_METHOD,
    location: "users/profile.php",
    contentType: "application/json",
    args: {
        [BODY_LOCATION]: {
            userID: 0,
            userFirstName: "",
            userLastName: "",
            userStreet1: "",
            userStreet2: "",
            userCity: "",
            userState: "",
            userZip: "",
            userMobileNumber: "",
            userHomeNumber: "",
            profilePhoto: "",
            userProfilePhotoFileName: "",
            profilePhotoMime: "",
            userDisplayName: "",
        },
        [AUTH_TOKEN_LOCATION]: {
            accessToken: "",
        }
    }
};


