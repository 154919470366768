import React from 'react'
import { Card, Col, Container, Image, Row } from 'react-bootstrap';

import BannerImage from '../../images/services-banner.jpg'

import './scss/services.scss';

function Services() {
    return (
        <div className="services-page">
            <div className="header bg-dark text-white">
                <Image className="header-image" src={BannerImage} alt="Card image" fluid />
                <div className="header-overlay">
                    <h1 className="header-title">What We Offer</h1>
                    <h2 className="header-subtitle">
                        We offer a wide range of scaleable services for small to medium size business. From full stack development to consultation on your current project - we've got you covered.
                    </h2>
                </div>
            </div>



            <Container className="service-cards mt-3">
                <Row >
                    <Col>
                        <div className="d-flex justify-content-center">
                            <Card
                                bg="light"
                                text="dark"
                                style={{ width: '18rem' }}
                                className="my-3"
                            >
                                <Card.Header className="text-center"><a href="/Services/WebDev"><i className="far fa-file-code fa-4x" /></a></Card.Header>
                                <Card.Body>
                                    <Card.Title><a href="/Services/WebDev" className='no-underline'>Web Development</a></Card.Title>
                                    <Card.Text>
                                        From concept to completion, we specialize in full stack web development. <a href="/Contact?select=web_development">Contact us</a>  today for a free consultation and estimate.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-center">
                            <Card
                                bg="light"
                                text="dark"
                                style={{ width: '18rem' }}
                                className="my-3"
                            >
                                <Card.Header className="text-center"><a href="/Services/SiteRefurb"><i className="fas fa-wrench fa-4x" /></a></Card.Header>
                                <Card.Body>
                                    <Card.Title><a href="/Services/SiteRefurb" className='no-underline'>Site Refurbishment</a></Card.Title>
                                    <Card.Text>
                                        Does your site need an overhaul or repair? From complete redesign to a simple addition, we can help.<a href="/Contact?select=web_development">Get a free estimate.</a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-center">
                            <Card
                                bg="light"
                                text="dark"
                                style={{ width: '18rem' }}
                                className="my-3"
                            >
                                <Card.Header className="text-center"><a href="/Services/Consulting"><i className="fas fa-hands-helping fa-4x" /></a></Card.Header>
                                <Card.Body>
                                    <Card.Title><a href="/Services/Consulting" className='no-underline'>Technology Consulting</a></Card.Title>
                                    <Card.Text>
                                        We'll partner with you on your business technology solutions, ensuring you are bet positioned for success. &nbsp;
                                        <a href="/Contact?select=consulting">Get a risk free assessment.</a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-center">
                            <Card
                                bg="light"
                                text="dark"
                                style={{ width: '18rem' }}
                                className="my-3"
                            >
                                <Card.Header className="text-center"> <a href="/Services/Solutions"><i className="fas fa-crosshairs fa-4x" /></a></Card.Header>
                                <Card.Body>
                                    <Card.Title><a href="/Services/Solutions" className='no-underline'>Custom Solutions</a></Card.Title>
                                    <Card.Text>
                                        Let us guide you to a targeted technology solution for your business. We'll size the solution to fit your needs.&nbsp;
                                        <a href="/Contact?select=site_inspection">Let's talk.</a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-center">
                            <Card
                                bg="light"
                                text="dark"
                                style={{ width: '18rem' }}
                                className="my-3"
                            >
                                <Card.Header className="text-center"><a href="/Contact?select=support"><i className="far fa-question-circle fa-4x" /></a></Card.Header>
                                <Card.Body>
                                    <Card.Title><a href="/Contact?select=support" className='no-underline'>Tech Support</a></Card.Title>
                                    <Card.Text>
                                        We can support your current solutions. If your current challanges are going unresolved from your provider, <a href="/Contact?select=site_inspection">see if we can help.</a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Container className="quote mt-5 text-end w-50">
                            <div className='border border-5 border-top-0 border-end-0 border-bottom-0'><p className="text-white my-0 py-0">"It's not the complexity of the project that matters,</p>
                                <p className="text-white">it's the success of the solution." </p>
                                <small className="text-white">~TrinityRecode</small>
                            </div>

                        </Container>
                    </Col>
                </Row>

            </Container>





        </div>

    )
}

export default Services
