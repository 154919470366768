import { isset } from "../../functions/library";
import { DEFAULT_SERVER_RESPONSE_CODE, DEFAULT_SERVER_RESPONSE_FORM, DEFAULT_SERVER_RESPONSE_REQUEST } from "../../functions/siteDefaults";

const REQUEST = "request";
const FORM = "form";
const FIELDS = "fields";


export default class APIReturn {
    #responseID = "";
    #isset = false;
    #statusCode = DEFAULT_SERVER_RESPONSE_CODE;
    #success = false;
    messages = {
        success: this.#success,
        [REQUEST]: { 0: DEFAULT_SERVER_RESPONSE_REQUEST },
        [FORM]: { 0: DEFAULT_SERVER_RESPONSE_FORM },
        [FIELDS]: {}
    };
    data = null;

    constructor(apiReturn = null) {
        if (!isset(apiReturn)) return;
        if (apiReturn instanceof APIReturn) {
            this.#isset = true;
            this.#statusCode = apiReturn.statusCode;
            this.#success = apiReturn.success;
            this.messages = apiReturn.messages;
            this.messages.success = apiReturn.success;
            this.data = apiReturn.data;
        }
        if (!apiReturn.hasOwnProperty("statusCode")
            && !apiReturn.hasOwnProperty("success")
            && !apiReturn.hasOwnProperty("messages")
            && !apiReturn.hasOwnProperty("data")
        ) return;

        this.#isset = true;
        this.#statusCode = apiReturn.statusCode;
        this.#success = apiReturn.success;
        this.messages = apiReturn.messages;
        this.messages.success = apiReturn.success;
        this.data = apiReturn.data;
    }

    get responseID() {
        return this.#responseID;
    }
    get isset() {
        return this.#isset;
    }
    get statusCode() {
        return this.#statusCode;
    }
    get success() {
        return this.#success;
    }

    set responseID(id = "") {
        this.#responseID = id;
    }
    set isset(isset = false) {
        this.#isset = isset;
    }
    set statusCode(statusCode = 0) {
        this.#statusCode = statusCode;
    }
    set success(success = false) {
        this.#success = success;
    }

    //End of APIReturn Class
}
