import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from '../router/ProvideAuth';
import { Container, Form, Button } from 'react-bootstrap'
import ServerFeedback from '../../functions/ServerFeedback';
import { genValidationObject, getEmptyMessages, getRandomNum, objectsAreEqual, validateInput, validateInputs } from '../../functions/library';
import useKeyPress from '../../hooks/useKeyPress';



function Login() {
    const [messages, setMessages] = useState(getEmptyMessages());
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [enterKeyPressed] = useKeyPress("Enter");
    const pageId = getRandomNum(1000000000, 9999999999);
    const PAGENAME = "Login";

    let history = useHistory();
    let location = useLocation();
    let auth = useAuth();

    useEffect(() => {
        let mounted = true;
        if (mounted && enterKeyPressed) {
            handleLogin({ userName, password });
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [enterKeyPressed]);

    const handleLogin = (credentials) => {
        let validation = genValidationObject(null, { runValidation: true });
        validation = genValidationObject(validation, {
            runValidation: true,
            field: {
                key: "userName",
                rule: "TEXT_SINGLE_LINE",
            },
            globalRule: "NOTBLANK",
        });
        validation = genValidationObject(validation, {
            runValidation: true,
            field: {
                key: "password",
                rule: "TEXT_SINGLE_LINE",
            },
            globalRule: "NOTBLANK",
        });
        if (validateInputs(validation, credentials, setMessages)) {
            login(credentials);
        }

    }

    let login = async (credentials) => {
        setLoading(true);
        const returnMessages = await auth.signin(() => {
            // if (from.pathname === "/") from = "/Dashboard";
            // history.replace(from);

        }, credentials);
        setLoading(false);
        setMessages(returnMessages);
    };

    useEffect(() => {
        let mounted = true;
        let { from } = location.state || { from: { pathname: "/" } };
        if (!objectsAreEqual(messages, getEmptyMessages())) {
            if (mounted && messages.success) {
                if (from.pathname === "/") from = "/Dashboard";
                history.replace(from);
            }
        }
        return () => {
            mounted = false
        }
        // eslint-disable-next-line
    }, [messages])


    const handleUserName = (value) => {
        let validation = genValidationObject(null, { runValidation: true });
        validation = genValidationObject(validation, {
            runValidation: true,
            field: {
                key: "userName",
                rule: "TEXT_SINGLE_LINE",
                fieldResponse: "That character is not permitted in an email address."
            }
        });
        validateInput(validation, { name: "userName", value }, setUserName, setMessages);
    }

    const handlePassword = (value) => {
        let validation = genValidationObject(null, { runValidation: true });
        validation = genValidationObject(validation, {
            runValidation: true,
            field: {
                key: "password",
                rule: "TEXT_SINGLE_LINE",
            }
        });
        validateInput(validation, { name: "password", value }, setPassword, setMessages);
    }


    return (
        <Container className="bg-dark" id={`login_page_${pageId}`} fluid>
            <div className="d-flex justify-content-center align-items-center" style={{ height: "75vh" }}>
                <div className="m-0 p-4 bg-light rounded rounded-3">

                    <Form >
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" name="userName" placeholder="Enter email" value={userName} onChange={(e) => handleUserName(e.target.value)} autoComplete="username" />
                            <ServerFeedback
                                className="ms-2"
                                messages={messages}
                                type="fields"
                                fieldName="userName"
                                success={messages.success}
                                loading={loading}
                                formName={PAGENAME}
                                active={PAGENAME}
                            />
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" name="password" placeholder="Password" value={password} onChange={(e) => handlePassword(e.target.value)} autoComplete="current-password" />
                            <ServerFeedback
                                className="ms-2"
                                messages={messages}
                                type="fields"
                                fieldName="password"
                                success={messages.success}
                                loading={loading}
                                formName={PAGENAME}
                                active={PAGENAME}
                                ignoreText=""
                            />
                        </Form.Group>
                        <div className="d-flex justify-content-lg-start">
                            <Button variant="primary" type="button" onClick={() => handleLogin({ userName, password })}>
                                Log in
                            </Button>
                            <ServerFeedback
                                className="ms-2"
                                messages={messages}
                                type="form"
                                fieldName=""
                                success={messages.success}
                                loading={loading}
                                formName={PAGENAME}
                                active={PAGENAME}
                                ignoreText=""
                            />
                        </div>

                    </Form>
                </div>
            </div>
        </Container>
    );
}


export default Login



