import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { getRandomNum, isset, objectTextToKey } from "../../../functions/library";
import CloseButton from "./parts/buttons/CloseButton";
import './emptyPanel.scss';

const EmptyPanel = ({
	children,
	showHeader,
	headBGColor,
	headTXTColor,
	headTXTFontSize,
	bodyBGColor,
	bodyTXTColor,
	title,
	classMainIn,
	closeButton,
	closeHandler,
	restoreHandler,
	restoreButtonText,
}) => {
	const [showPanelHeader, setShowPanelHeader] = useState(true);
	const [headerBGColor, setHeaderBGColor] = useState("white");
	const [headerTXTColor, setHeaderTXTColor] = useState("black");
	const [headerFontSize, setHeaderFontSize] = useState("1.5rem");
	const [bodyBackGColor, setBodyBackGColor] = useState("#E5E5E5");
	const [headerTextColor, setHeaderTextColor] = useState("black");
	const [classMain, setClassMain] = useState("widget");
	const [showCloseButton, setShowCloseButton] = useState(false);
	const [restoreButton, setRestoreButton] = useState("Undo");
	const [isClosed, setIsClosed] = useState(false);
	const baseClass = "widget";

	useEffect(() => {

		let mounted = true;
		if (mounted && isset(showHeader) && showHeader !== showPanelHeader) setShowPanelHeader(showHeader);
		if (mounted && headBGColor && headBGColor !== headerBGColor) setHeaderBGColor(headBGColor);
		if (mounted && headTXTColor && headTXTColor !== headerTXTColor) setHeaderTXTColor(headTXTColor);
		if (mounted && headTXTFontSize && headTXTFontSize !== headerFontSize) setHeaderFontSize(headTXTFontSize);
		if (mounted && bodyBGColor && bodyBGColor !== bodyBackGColor) setBodyBackGColor(bodyBGColor);
		if (mounted && bodyTXTColor && bodyTXTColor !== headerTextColor) setHeaderTextColor(bodyTXTColor);
		if (mounted && classMainIn && classMainIn !== classMain) setClassMain(`${baseClass} ${classMainIn}`);
		if (mounted && closeButton && closeButton !== showCloseButton) setShowCloseButton(closeButton);
		if (mounted && restoreButtonText && restoreButtonText !== restoreButton) setRestoreButton(restoreButtonText);
		return () => {
			mounted = false;
		};
		// eslint-disable-next-line
	}, [headBGColor, bodyBGColor, classMainIn]);


	const closePanel = () => {
		if (typeof closeHandler === "function") {
			closeHandler({ type: "close", title: title, children: children })
		}
		setIsClosed(true);
	}

	const reOpenPanel = () => {
		if (typeof closeHandler === "function") {
			restoreHandler({ type: "open", title: title, children: children })
		}
		setIsClosed(false);
	}


	/*
	
		function initResizeElement() {
			var popups = document.getElementsByClassName("widget");
			var element = null;
			var startX, startY, startWidth, startHeight;
	
			for (var i = 0; i < popups.length; i++) {
				var p = popups[i];
	
				var right = document.createElement("div");
				right.className = "resizer-right";
				p.appendChild(right);
				right.addEventListener("mousedown", initDrag, false);
				right.parentPopup = p;
	
				var bottom = document.createElement("div");
				bottom.className = "resizer-bottom";
				p.appendChild(bottom);
				bottom.addEventListener("mousedown", initDrag, false);
				bottom.parentPopup = p;
	
				var both = document.createElement("div");
				both.className = "resizer-both";
				p.appendChild(both);
				both.addEventListener("mousedown", initDrag, false);
				both.parentPopup = p;
			}
	
			function initDrag(e) {
				element = this.parentPopup;
	
				startX = e.clientX;
				startY = e.clientY;
				startWidth = parseInt(
					document.defaultView.getComputedStyle(element).width,
					10
				);
				startHeight = parseInt(
					document.defaultView.getComputedStyle(element).height,
					10
				);
				document.documentElement.addEventListener("mousemove", doDrag, false);
				document.documentElement.addEventListener("mouseup", stopDrag, false);
			}
	
			function doDrag(e) {
				element.style.width = startWidth + e.clientX - startX + "px";
				element.style.height = startHeight + e.clientY - startY + "px";
			}
	
			function stopDrag() {
				document.documentElement.removeEventListener("mousemove", doDrag, false);
				document.documentElement.removeEventListener("mouseup", stopDrag, false);
			}
		}
	
	*/

	return (
		<div>
			{isClosed ? <Button size="sm" style={{ fontSize: "80%" }} variant="secondary" onClick={() => reOpenPanel()}>{restoreButton}</Button> : <div className={classMain}>
				{showPanelHeader ? <div
					className="header"
					style={{
						backgroundColor: headerBGColor,
						color: headerTXTColor,
						opacity: 1,
						fontSize: headerFontSize
					}}>
					<div></div>
					{isset(title) ? title : " "}
					{showCloseButton ? <CloseButton
						className="m-1"
						clickHandler={closePanel}
						buttonSize="md"
						name={`panelclose-${objectTextToKey(title || getRandomNum().toString())}`}
					/> : <div></div>}
				</div> :
					<React.Fragment>
					</React.Fragment>
				}

				<div
					className={`body ${!showPanelHeader ? "p-1" : ""}`}
					style={{
						backgroundColor: bodyBackGColor,
						color: headerTextColor,
						borderRadius: !showPanelHeader ? "1vw" : ""
					}}>
					{children}
				</div>
			</div>
			}
		</div>
	);
};

export default EmptyPanel;
